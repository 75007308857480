<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-6">Students List</h1>
        <div class="link-container col-md-6">
            <a href="../student/add/" class="content-header-link">Add New Student</a>
            <a href="../student/import/" class="content-header-link">Import Students</a>
        </div>
    </div>

        <div class="panel row">
            <div class="col-md-7">
                <div class="filter-options row">
                    <form [formGroup]="rObj" class="form-flex">
                        <div class="form-group">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-select" >
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData" >
                                        <option [value]="section.id">{{section.name}}</option>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                        <div class="form-group btn-container" style="margin-top: 22px;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="navigate()">Search</button>
                        </div>
                    </form>
                    
                </div>
            </div>
            
            <div class="col-md-5">
                <div class="filter-options">
                    <form [formGroup]="sObj">
                        <label>Search By Name</label>
                        <input type="text"  class="form-control searchInput" formControlName="Input" width="100%">
                        <div class="form-group btn-container ">
                            <button class="btn primary-btn small-btn" [disabled]="!sObj.valid" (click)="searchName()">Search</button>
                        </div> 
                    </form>
                </div>
            </div>
        </div>
       
    

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="studentListData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentListData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                <th class="align-left">Student Name</th>
		                        <th>Register Number</th>
			                    <th>Class</th>
                                <th>Section</th>
			                    <th>Roll Number</th>
		                        <th>Date of Birth</th>
			                    <th>Gender</th>
			                    <th>Blood Group</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentListData">
                                <td>
                                  <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                </td>
                                <td class="align-left">
                                    <img [src]="s.photo_path ? imgURL+s.photo_path : (s.gender == 'male' ? '/assets/img/male-student.png' : '/assets/img/female-student.png')" style="width: 40px; border: 1px solid #dcdcdc;">
                                    {{s.full_name}}
                                </td>
                                
                                <td>{{s.admission_number}}</td>
                                <td>
                                    <ng-container *ngFor="let class of classData">
                                        <ng-container *ngIf="class.ID == s.class">
                                         {{class.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let section of sectionData">
                                        <ng-container *ngIf="section.ID == s.section">
                                         {{section.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{s.roll_no}}</td>
                                <td>{{s.dob}}</td>
                                <td>{{s.gender}}</td>
                                <td>{{s.blood_group}}</td>
                                <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentListData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>         
            </div>
        </div>
    </div>
</div>