import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { CertificateService } from 'src/app/services/certificates.service';
@Component({
    selector: 'app-viewCertificatemodal',
    templateUrl: './viewCertificatemodal.component.html',
    // styleUrls: ['./certificatemodal.component.css'],
})

    export class ViewCertificatemodalModalComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public templateID: number;
    public templateData: any[] = [];
    public studentIDs: any;
    public studentData: any[] = [];
    public templateHtml : any;

    @ViewChild('certificateTemplate') certificateHtml : ElementRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _cService: CertificateService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);
        this._gService.getDataByID(this.templateID, 'certificates').subscribe(c => {
            console.log(c)
            let data = JSON.parse(c.result)
            console.log(data)
           if (c.status == true) {
                this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data[0].html)
           }
        })

        this._gService.showLoader(false);

    }

    // getPopulatedHTML(studentID: number) {
    //     // let student_data = this.studentData.filter(s => s.ID == studentID).map(s => ({'Student Name': s.full_name, 'Class': s.class_name, 'Section': s.section_name}))
    //     let student_data = this.studentData.filter(s => s.ID == studentID).map(s => ({'Student Name': s.full_name}))
    //     console.log(student_data)
    //     let html = this.templateHtml;
    //     console.log(html)
    //     for (const [search, replace] of Object.entries(student_data[0])) {
    //         html = html.replace(new RegExp('{{'+search+'}}', "g"), replace);
    //     }
    //     return this.sanitizer.bypassSecurityTrustHtml(html)
    // }
    
}