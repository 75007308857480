<div class="modal-header">
    <h5 class="modal-title">Add {{lesson_name}} Topics</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div style="margin-left: 86% !important;">
                <button class="btn default-btn extra-small-btn inline" (click)="addTData()">Add </button>
            </div>
            <div formArrayName="Topics">
                <div *ngFor="let detail of tData().controls; let i=index" style="margin-bottom: 15px ;">
                    <div class="row" [formGroupName]="i">
                        <div class="col-md-10">
                            <div class="form-group">
                                <label>Topic Name</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="Name">
                            </div>
                        </div>
                        <div class="col-md-1" >
                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 235%;" (click)="removeTData(i, tData().controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="addTopics()">Save</button>
            </div>
        </form>
    </div>
</div>
