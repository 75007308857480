<div class="modal-header">
    <h5 class="modal-title">Edit Room</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Room No.</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Room_no" >
                <div *ngIf="rObj.controls.Room_no.invalid && (rObj.controls.Room_no.dirty || rObj.controls.Room_no.touched)">
                    <p class="input-error-msg">Please enter room number</p>
                </div>
            </div>

            <div class="form-group">
                <label>Hostels</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Hostels">
                    <option value="">Select</option>
                <option *ngFor="let name of HostelData" value="{{name.ID}}">{{name.name}}</option>
                </select>
                <div *ngIf="rObj.controls.Hostels.invalid && (rObj.controls.Hostels.dirty || rObj.controls.Hostels.touched)">
                    <p class="input-error-msg">Please select hostel</p>
                </div>
            </div>

            <div class="form-group">
                <label>Room Type</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Room_Type">
                    <option value="">Select</option>
                    <!-- <option value="1">1</option>
                    <option value="2">2</option> -->
                <option *ngFor="let type of RoomtypeData" value="{{type.ID}}">{{type.room_type}}</option>
                </select>
                <div *ngIf="rObj.controls.Room_Type.invalid && (rObj.controls.Room_Type.dirty || rObj.controls.Room_Type.touched)">
                    <p class="input-error-msg">Please enter room type</p>
                </div>
            </div>


            <div class="form-group">
                <label>No.of Beds</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="No_beds" >
                <div *ngIf="rObj.controls.No_beds.invalid && (rObj.controls.No_beds.dirty || rObj.controls.No_beds.touched)">
                    <p class="input-error-msg">Please enter number of beds</p>
                </div>
            </div>

            <div class="form-group">
                <label>Cost Per Bed</label>
                <small class="req"> *</small>
                <input type="number"class="form-control" formControlName="Cost">
                <div *ngIf="rObj.controls.Cost.invalid && (rObj.controls.Cost.dirty || rObj.controls.Cost.touched)">
                    <p class="input-error-msg">Please enter cost</p>
                </div>
            </div>

            <div class="form-group">
                <label>Description</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" autocomplete="off" formControlName="Description"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter description</p>
                </div>
            </div>

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateRoomDetails()">Save</button>
            </div>
        </form>
    </div>
</div>
