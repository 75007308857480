<div class="modal-header">
    <h5 class="modal-title">Edit Class Teacher</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Class Name</label>
                <small class="req"> *</small>
                <!-- <input type="" class="form-control" formControlName="Class"> -->
                <select class="form-select" formControlName="Class">
                    <option value="">Select</option>
                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>
                </select>
                <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>

            <div class="form-group">
                <label>Section</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Section">
                    <option value="">Select</option>
                    <ng-container *ngFor="let section of classSectionData" >
                        <option [value]="section.id">{{section.name}}</option>
                    </ng-container>
                </select>
            </div> 
             
            <div class="form-group">
                <label>Teacher</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Teacher">
                    <option value="">Select</option>
                    <option *ngFor="let teacher of teacherData" value="{{teacher.ID}}">{{teacher.full_name}}</option>
                </select>
            </div>
            

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateAssignTeacher()">Update</button>
            </div>
        </form> 
    </div>
</div>
