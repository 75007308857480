<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Approve Leave</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
           <button class="btn primary-btn small-btn" (click)="openApproveLeaveEditModal(0, 'add')">Add Leave</button>
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-header">
                <div id="panel_title" class="panel-title">
                    Name List
                </div>
            </div>
            <div class="panel-body">
                <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="allLeaveData.length">
                    <thead>
                        <tr>
                            <th class="align-left">Role</th>
                            <th>Apply Date</th>
                            <th>Leave Type</th>
                            <th>Leave From Date</th>
                            <th>Leave To Date</th>
                            <th>Reason</th>
                            <th>Attachment</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let leave of allLeaveData">
                            <td class="align-left">{{getUserRoleName(leave.role)}}</td>
                            <td>{{getDate(leave.apply_date)}}</td>
                            <td>
                                <ng-container *ngFor="let type of leaveTypeData">
                                    <ng-container *ngIf="type.ID == leave.leave_type">
                                        {{type.name}}
                                       </ng-container>
                                </ng-container>
                            </td>
                            <td>{{getDate(leave.leave_from_date)}}</td>
                            <td>{{getDate(leave.leave_to_date)}}</td>
                            <td>{{leave.reason}}</td>
                            <td>{{leave.leave_document ? leave.leave_document : '-'}}</td>
                            <td>{{leave.status}}</td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openApproveLeaveEditModal(leave.ID, 'approve')"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(leave.ID, 'leaves', 'approve leave')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table> 
                <div *ngIf="!allLeaveData.length">
                    <div class="noDataWrapper">
                        <img src="/assets/img/No-data-found.png">
                    </div>
                    <p style="text-align: center;">Data Not Found</p>
                </div>     
            </div>
        </div>
    </div>
    </div>
        
