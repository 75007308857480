import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageModalComponent } from 'src/app/components/modal/messagemodal/messagemodal.component';

@Component({
    selector: 'app-students-birthday',
    templateUrl: './birthday.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class BirthdayComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    
    public birthdayData: any[] = [];
    public sectionData: any[] = [];
    public classData: any[] = [];
    public selectedIds : any[] = [];
    // public routeData: any[] = [];
    // public hosteldetailsData: any[] = [];
    // public hostelRoomsTypeData: any[] = [];
    public studentData: any[] = [];
    // public countriesData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    public todayBirthdayTab: boolean = true;
    public birthdayListTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}



    public rObj = this._fBuilder.group({
        Month: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

       this._aService.birthdayData(this.instID, this.activeAcademicID, 'today').subscribe(bRes => {
        console.log(bRes);
        if(bRes.status == true) {
            this.birthdayData = JSON.parse(bRes.result);
        }
        this._gService.showLoader(false);
       })

       this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
        console.log(cRes)
        if (cRes.status === true) {
            this.classData = JSON.parse(cRes.result);
        } else {
            this._gService.triggerSnackbar('No Data Found!', 'error', true);
        }
    })

   
    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
        console.log(sRes)
        if (sRes.status === true) {
            this.sectionData = JSON.parse(sRes.result);
        }
        this._gService.showLoader(false);
    })

    
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.birthdayData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.birthdayData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }



    sendMessage(ids: any ) {
        this.modalRef = this.modalService.show(MessageModalComponent, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                ids: JSON.stringify(ids)
            } 
        });
    }

    setTabActive(tabName: string) {
        if (tabName == 'todayBirthdayTab') {
            this.todayBirthdayTab = true
        } else {
            this.todayBirthdayTab = false
        }
        if (tabName == 'birthdayListTab') {
            this.birthdayListTab = true
        } else {
            this.birthdayListTab = false
        }
    }

    search(){
        if (this.rObj.valid) {
         this._aService.monthBirthdayData(this.instID, this.activeAcademicID, 'month', this.rObj.value.Month).subscribe(bRes => {
            console.log(bRes)
            if(bRes.status == true) {
                this.studentData = JSON.parse(bRes.result);
            }
            this._gService.showLoader(false); 
         })
        }
    }
}