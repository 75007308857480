import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'website-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./../assets/css/style.css']
})

export class WebsiteDefaultHeaderComponent implements OnInit {
    @Input() instID : any;

    public instData: any[] = [];
    public imgURL: string = this._gService.getImgPath();
    
    constructor(
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _cmsService: CmsService,
        private _gService: GlobalService
    ) {}

    ngOnInit(): void {

        this._cmsService.getCmsSettingsBySlug(this._cmsService.getInstSlug()).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.instData = JSON.parse(sRes.result);
                console.log(this.instData)
            }
        })

        // this.instData = this._cmsService.getInstSettingsData(this._cmsService.getInstSlug());

        // this.pageSlug = this._aRoute.snapshot.paramMap.get('slug');

        // this._cmsService.getPageData(1, this.pageSlug).subscribe(pageRes => {
        //     console.log(pageRes)
        //     if (pageRes.status === true) {
        //         this.pageData = JSON.parse(pageRes.result);
        //         console.log(this.pageData[0].title)
        //         this._cmsService.setPageTitle(this.pageData[0].title);
        //     }
        // })

    }

}