import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-classmodal',
    templateUrl: './classmodal.component.html',
    styleUrls: ['./classmodal.component.css'],
})

export class ClassModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public classID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public sectionData: any[] = [];
    public selectSections: string;
    public mediumData: any[] = [];

    public sectionValue: any[] = [];

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    public rObj : any = this._fBuilder.group({
        MediumType: ['', Validators.required],
        Class: ['', Validators.required],
        Section: this._fBuilder.array([], Validators.required)
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
                this.rObj = this._fBuilder.group({
                    MediumType: ['', Validators.required],                    
                    Class: ['', Validators.required],
                    Section: this._fBuilder.array(this.sectionData.reverse().map(x => new FormControl(false), Validators.required))
                })
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        console.log(this.rObj.controls['Section'])

        this._gService.getDataByID(this.classID, 'class').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let cData = JSON.parse(dataRes.result)[0];
                console.log(cData)
                this.rObj.patchValue({
                    MediumType: cData.medium,
                    Class: cData.name,
                    Section: this.sectionData.map(x => ({value: x.ID, isSelected: cData.sections.indexOf(x.ID) > -1})),
                });

                this.sectionValue = JSON.parse(cData.sections);

                console.log(this.sectionValue)

            }
            this._gService.showLoader(false);
        })
    }

    onSectionSelect(index: number, value: number, e: any) {
        const selectedSections = <FormArray>this.rObj.controls.Section;

        if (e.target.checked) {
            selectedSections.controls[index].setValue({value: value, isSelected: true});
        } else {
            selectedSections.controls[index].setValue({value: value, isSelected: false});
        }

        this.sectionValue = selectedSections.value.filter((x: any) => x.isSelected === true).map((x: any) => x.value);

        console.log(this.sectionValue)
          
    }

    updateClass() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'update',
                id: this.classID,
                medium: rObj.MediumType,
                class: rObj.Class,
                section: JSON.stringify(this.sectionValue),
            }
            console.log(ayData)
            this._aService.classData(ayData).subscribe(ayRes => {
                console.log(ayRes)
                this.modalRef.hide();
                if (ayRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Class Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}