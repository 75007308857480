<div class="modal-header">
    <h5 class="modal-title">Edit Circular</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Title</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Title">
                <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                    <p class="input-error-msg">Please enter Title name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Description">
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="Date">
                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                    <p class="input-error-msg">Please enter Date name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Send to</label>
                <small class="req"> *</small>
                <div class="radio required">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="6">
                        <label class="form-check-label" for="Send_to1">
                            Students
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="4">
                        <label class="form-check-label" for="Send_to1">
                            Teachers
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="0">
                        <label class="form-check-label" for="Send_to1">
                            All
                        </label>
                    </div>
                    <div *ngIf="rObj.controls.Send_to.invalid && (rObj.controls.Send_to.dirty || rObj.controls.Send_to.touched)">
                        <p class="input-error-msg">Please select </p>
                    </div>
                </div>
                <!-- <input type="checkbox" class="form-control" formControlName="Send_to">
                <div *ngIf="rObj.controls.Send_to.invalid && (rObj.controls.Send_to.dirty || rObj.controls.Send_to.touched)">
                    <p class="input-error-msg">Please enter Send to name</p>
                </div> -->
            </div>
            <div class="form-group">
                <label>Attach Document</label>
                <!-- <input type="file" class="form-control" formControlName="Leave_document"> -->
                <div class="imgUpload-wrapper">
                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove File</div>  
                    <input type="file" class="form-control" formControlName="Attachment" (change)="onFileChange($event)">
                </div>
                <div *ngIf="rObj.controls.Attachment.invalid && (rObj.controls.Attachment.dirty || rObj.controls.Attachment.touched)">
                    <p class="input-error-msg">Please enter Attachment name</p>
                </div>
            </div>
            <!-- <div class="form-group">
                <label>Attachment</label>
                <small class="req"> *</small>
                <input type="file" class="form-control" formControlName="Attachment">
                <div *ngIf="rObj.controls.Attachment.invalid && (rObj.controls.Attachment.dirty || rObj.controls.Attachment.touched)">
                    <p class="input-error-msg">Please enter Attachment name</p>
                </div>
            </div> -->
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateCircular()">Update</button>
            </div>
        </form> 
    </div>
</div>
