import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { ExaminationService } from 'src/app/services/examination.service';
@Component({
    selector: 'app-marksGrademodal',
    templateUrl: './marksGrademodal.component.html',
    styleUrls: [],
})

export class MarksGradeModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public gradetypeID: number;

    constructor(
        private _gService: GlobalService,
        private _aService: ExaminationService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Grade_name: ['', Validators.required],
        Percentage_from: ['', Validators.required],
        Percentage_upto: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.gradetypeID, 'marks_grade').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Grade_name: sData[0].grade_name,
                    Percentage_from: sData[0].per_from,
                    Percentage_upto: sData[0].per_upto,
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateGrade() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.gradetypeID,
                grade_name: rObj.Grade_name,
                per_from: rObj.Percentage_from,
                per_upto: rObj.Percentage_upto,
            }
            this._aService.gradeTypeData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Grade type  Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}