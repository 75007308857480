<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Destination</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../transport/route/add/" class="content-header-link">Add Route</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Destination
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Route</label>
                                <small class="req"> *</small>
                                <select formControlName="Route" class="form-control" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>
                                 </select>
                                 <div *ngIf="rObj.controls.Route.invalid && (rObj.controls.Route.dirty || rObj.controls.Route.touched)">
                                    <p class="input-error-msg">Please select Route</p>
                                </div>
                        </div>
                        <div class="form-group">
                            <label>Destination Name</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Dest_name" class="form-control" >
                            <div *ngIf="rObj.controls.Dest_name.invalid && (rObj.controls.Dest_name.dirty || rObj.controls.Dest_name.touched)">
                                <p class="input-error-msg">Please enter destination name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Stop Time</label>
                            <small class="req"> *</small>
                            <input type="time" formControlName="Time" class="form-control" >
                            <div *ngIf="rObj.controls.Time.invalid && (rObj.controls.Time.dirty || rObj.controls.Time.touched)">
                                <p class="input-error-msg">Please select time</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Fare</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="Fare" class="form-control" >
                            <div *ngIf="rObj.controls.Fare.invalid && (rObj.controls.Fare.dirty || rObj.controls.Fare.touched)">
                                <p class="input-error-msg">Please enter fare </p>
                            </div>
                        </div>
                       
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveDestination()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                    Destination List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="destinationData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">Route</th>
                                    <th class="align-left">Destination Name </th>
                                    <th class="align-left">Stop Time </th>
                                    <th class="align-left">Fare </th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let dest of destinationData">
                                <td class="align-left">
                                    <ng-container *ngFor="let r of routeData">
                                        <ng-container *ngIf="r.ID == dest.route">
                                        {{r.route_name}}
                                        </ng-container>  
                                    </ng-container>
                            </td>
                                <td>{{dest.destination_name}}</td>
                                <td>{{dest.stop_time}}</td>
                                <td>{{dest.fare}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openDestinationEditModal(dest.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(dest.ID, 'destination', 'destination details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!destinationData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div>
                </div>
            </div>
    </div>
        
</div>