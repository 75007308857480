import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
import * as moment from 'moment';

  
@Component({
    selector: 'app-addRoutemodal',
    templateUrl: './addRoutemodal.component.html',
    // styleUrls: ['./mediummodal.component.css'],
})

export class AddRouteModalComponent {   public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public routeId : any
    public pickUpRouteArray: any[] = [];
    public pickUpDeleteData: any[] = [];
    public mediumData: any[] = [];
    public routeData: any[] = [];
    public routeDeleteIds: any[] = [];
    public pickUpData: any[] = [];
    // public mediumData: any[] = [];

    public routePointPatchDataArray: FormGroup;


    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _tService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        public modalRef: BsModalRef,
        // private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Route: ['', Validators.required],
        // PickUpPoint: [''],
        // Distance: [''],
        // PickUpTime: [''],
        // MonthlyFee: [''],
        route_Data: this._fBuilder.array([]),
    })


    route_Data(): FormArray {
        return this.rObj.get("route_Data") as FormArray
    }

    newRoute_Data(): FormGroup {
        return this._fBuilder.group({
            Id: '',
            PickUpPoint: '', 
            Distance : '',
            PickupTime : moment().format("HH:mm"),
            MonthlyFees : '',
        })
    }

    addRoute_Data() {
        this.route_Data().push(this.newRoute_Data())
        console.log(this.route_Data())
    }

    removeRoute_Data( i: number, Id: any) {
        this.route_Data().removeAt(i);
        this.pickUpDeleteData.push(Id)
        // this.ttData(dayName).controls.value[i]
        // alert(this.routeDeleteIds)
    }
    ngOnInit(): void {

        this._gService.showLoader(true);
        if(this.routeId) {
            this.rObj.patchValue({
                Route : this.routeId,
            })
            this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route_pickup_points').subscribe(pRes => {
                console.log(pRes)
                if (pRes.status === true) {
                    let routeDataAll = JSON.parse(pRes.result)
                    let routeData = routeDataAll.filter((p : any) => p.route_id == this.routeId)
                    console.log(routeData)
                    // let tDataMonday = tData.filter((t: any) => t.day == 1)
                    // console.log(tDataMonday)
                    routeData.forEach((e: any, index : any) => {
                        this.routePointPatchDataArray = this._fBuilder.group({
                            Id: e.ID,
                            PickUpPoint : e.pickup_point_id,
                            Distance : e.distance,
                            PickupTime : e.pickup_time,
                            MonthlyFees : e.monthly_fees,
                        })
                        console.log(this.routePointPatchDataArray)
    
                        if (this.routePointPatchDataArray) {
                            this.route_Data().push(this.routePointPatchDataArray)
                        }
                        console.log(this.route_Data())
                    });
    
                    if (!this.routePointPatchDataArray) {
                        this.addRoute_Data()
                    }
                }
            })
        } else {
            this.addRoute_Data()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'pickup_points').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.pickUpData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.routeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    // openPickUpEditModal(pickUpID: number) {
    //     this.modalRef = this.modalService.show(PickUpModalComponent, {
    //         class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
    //         backdrop : 'static',
    //         keyboard : false,
    //         initialState : {
    //             pickUpID: pickUpID,
    //         } 
    //     });
    // }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    savePoint() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let routeData = this.rObj.controls.route_Data.controls;
            for (let i = 0; i < routeData.length; i++) {
                this.pickUpRouteArray.push(routeData[i].value)
            }
            // this._router.navigate(['/transport/route-pickup-point/'+rObj.Route]);
            let mData = {
                inst_id: this.instID,
                route_id: rObj.Route,
                pickupData: this.pickUpRouteArray,
                del_ids : this.pickUpDeleteData
                // longitude: rObj.Longitude,
            }
            console.log(mData)
            this._tService.pickUpRouteData(mData).subscribe(aRes => {
                console.log(aRes)
                this.modalRef.hide();
                if (aRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Pick Up Route Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}