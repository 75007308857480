import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { InstService } from 'src/app/services/inst.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
  
@Component({
    selector: 'app-deletemultimodal',
    templateUrl: './deletemultimodal.component.html'
})

export class DeleteModalMultiComponent {

    public dataIDs: any;
    public dataCount: number;
    public moduleName: string;
    public moduleTitle: string;

    constructor(
        private _gService: GlobalService,
        private _instService: InstService,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    ngOnInit(): void { }

    deleteMultiModuleData(dataIDs: number) {
        this._gService.showLoader(true)
        let delData = {
            module: this.moduleName,
            data_ids: dataIDs.toString()
        }
        console.log(delData)
        this._gService.deleteModuleMultiData(delData).subscribe(delRes => {
            this.modalRef.hide();
            console.log(delRes)
            if (delRes.status === true) {
                this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url])
                    this._gService.triggerSnackbar('Data Deleted Successfully!', 'success', true) 
                });
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
            this._gService.showLoader(false)
        })
    }
    
}