import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnInit, OnDestroy {

    public visible: boolean = false;

    constructor(
        private _loaderService: LoaderService
    ) { }

  private loaderSubscription: Subscription;

  ngOnInit() {
    this.loaderSubscription = this._loaderService.loaderState.subscribe((state) => {
        this.visible = state.visible;
    });
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }
}