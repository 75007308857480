import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StaffService } from 'src/app/services/staff.service';
import { LeaveModalComponent } from 'src/app/components/modal/leavemodal/leavemodal.component';

@Component({
  selector: 'app-staff-leave',
  templateUrl: './leave.component.html',
})

export class leaveComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public leaveData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
      Name: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leave_type').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.leaveData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openLeaveEditModal(leaveID: number) {
        this.modalRef = this.modalService.show(LeaveModalComponent, {
            class: 'modal-dialog-centered leave-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                leaveID: leaveID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveName() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                leaveType: rObj.Name,
            }
            console.log(lData)
            this._aService.leaveData(lData).subscribe(lRes => {
                console.log(lRes)
                if (lRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Name Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}