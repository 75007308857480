<div class="console-content">     
    <div class="console-content-header row">
        <h1 class="title col-md-3">Academic Years</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../../general/" class="content-header-link">Change Settings</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Edit Academic Year
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Start Year</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="StartYear">
                                <option *ngFor="let year of years" [value]="year">{{year}}</option> 
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Start Month</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="StartMonth">
                                <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option> 
                            </select>
                        </div>
                        <div class="form-group">
                            <label>End Year</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="EndYear">
                                <option *ngFor="let year of years" [value]="year">{{year}}</option> 
                            </select>    
                        </div>
                        <div class="form-group">
                            <label>End Month</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="EndMonth">
                                <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option> 
                            </select>
                        </div>
                        <div class="form-group form-btn-wrapper">
                            <button class="btn primary-btn small-btn" (click)="updateAcademicYear()">Update</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Academic Year List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="academicYearsData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Session</th>
                                <th>Start Month</th>
                                <th>End Month</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>      
                        <tbody>
                        <tr *ngFor="let academicyear of academicYearsData">
                            <td class="align-left">{{academicyear.start_year}}-{{academicyear.end_year}} <span class="active-label" *ngIf="academicyear.ID == activeAcademicID">Active</span></td>
                            <td>{{getMonthName(academicyear.start_month)}}</td>
                            <td>{{getMonthName(academicyear.end_month)}}</td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="goToEditLink(academicyear.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(academicyear.ID, 'academic_years', 'academic year')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>   
                    </table>   
                </div>
            </div>
        </div>
    </div>
        
</div>