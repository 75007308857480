<div class="modal-header">
    <h5 class="modal-title">Edit Marks Grade</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Grade Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Grade_name" >
                <div *ngIf="rObj.controls.Grade_name.invalid && (rObj.controls.Grade_name.dirty || rObj.controls.Grade_name.touched)">
                    <p class="input-error-msg">Please enter Grade name</p>    
                </div>
            </div>
            <div class="form-group">
                <label>Percentage From</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Percentage_from" >
                <div *ngIf="rObj.controls.Percentage_from.invalid && (rObj.controls.Percentage_from.dirty || rObj.controls.Percentage_from.touched)">
                    <p class="input-error-msg">Please enter Percentage from</p>    
                </div>
            </div>
            <div class="form-group">
                <label>Percentage Upto</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Percentage_upto" >
                <div *ngIf="rObj.controls.Percentage_upto.invalid && (rObj.controls.Percentage_upto.dirty || rObj.controls.Percentage_upto.touched)">
                    <p class="input-error-msg">Please enter Percentage_upto</p>    
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateGrade()">Update</button>
            </div>
        </form> 
    </div>
</div>
