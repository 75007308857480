<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Section</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../academic/class/" class="content-header-link">Add Class</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Section
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Section Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Section">
                            <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                <p class="input-error-msg">Please enter section name</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveSection()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Section List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="sectionData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Section Name</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let section of sectionData">
                                <td class="align-left">{{section.name}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openSectionEditModal(section.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(section.ID, 'section', 'section')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!sectionData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>