<div class="console-content">

    <div class="console-content-header row">
        <h1 class="title col-md-3">Activity Log Report</h1>     
    </div>
    <div class="row">
        <div class="col-md-12 ">
            <div class="panel">
                <!-- <div class="panel-header">
                    <h1 id="panel_title" class="title col-md-4">Transport Report</h1>
                </div> -->
            
                <div class="panel-body">   
                    <form [formGroup]="rObj">
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4">
                                    <label>User Type<small class="req"> *</small></label>
                                    <select name="User_type" class="form-select" required="">
                                        <option value="">Select User</option>
                                        <option value="all">All Users</option>
                                        <option value="student">Student</option>
                                        <option value="staff">Staff</option>
                                        <option value="parent">Parents</option>
                                    </select>
                                        <div *ngIf="rObj.controls.User_type.invalid && (rObj.controls.User_type.dirty || rObj.controls.User_type.touched)">
                                            <p class="input-error-msg">Please select user type</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>  
                    </form>     
                </div>
                <div class="panel-body">

                    <!-- <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentAttendance.length">
                        <thead>
                            <tr>
                                <th class="align-left">Student Name</th>
                                <th>Present Count</th>
                                <th>Absent Count</th>
                                <th>Halfday Count</th>
                                <th>Late Count</th>
                                <th>Gross Present Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentAttendance">
                                <td class="align-left">{{s.student_name}}</td>                        
                                <td>{{s.present_count}}</td>
                                <td>{{s.absent_count}}</td>
                                <td>{{s.halfday_count}}</td>
                                <td>{{s.late_count}}</td>
                                <td>{{getPercentage(s.present_count, s.halfday_count, s.late_count)}}%</td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentAttendance.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> -->
                </div>  
            
            </div>
        </div>
    </div>
</div>