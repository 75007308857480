<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Pay Head</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <!--<a href="../../income/add/" class="content-header-link">Add Income</a>-->
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Add Pay Head
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Pay Head</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Pay_head_name" >
                            <div *ngIf="rObj.controls.Pay_head_name.invalid && (rObj.controls.Pay_head_name.dirty || rObj.controls.Pay_head_name.touched)">
                                <p class="input-error-msg">Please enter Pay head name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Pay Head Type</label>
                            <select formControlName="Pay_head_type" class="form-select">
                                <option value="">Select Pay Head Type</option>
                                <option value="addition">Addition</option>
                                <option value="deduction">Deduction</option>
                            </select>
                            <div *ngIf="rObj.controls.Pay_head_type.invalid && (rObj.controls.Pay_head_type.dirty || rObj.controls.Pay_head_type.touched)">
                                <p class="input-error-msg">Please enter Pay head type</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter Description</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveData()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Pay Head List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="payHeadData.length">
                        <thead>
                            <tr>
                                <th class="align-left">PAY HEAD NAME</th>
                                <th>PAY HEAD TYPE</th>
                                <th>DESCRIPTION</th>
                                <th class="no-sort">MANAGE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of payHeadData">
                                <td class="align-left">{{p.pay_head_name}}</td>
                                <td>{{p.pay_head_type}}</td>
                                <td><p>{{p.description}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openPayHeadEditModal(p.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(p.ID, 'payhead', 'payHead ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!payHeadData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>    
                </div>
            </div>
        </div>
    </div>
        
</div>