import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminLoginComponent } from './adminlogin/adminlogin.component';
let routes: Routes = [
    {
        path: 'admin/login', component: adminLoginComponent, title: 'Admin Login - EduNationPro' , 
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AdminLoginRoutingModule { }
export const AdminLoginRoutes = [
    adminLoginComponent,
]