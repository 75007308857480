<div class="modal-header">
    <h5 class="modal-title">Form Status</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj" *ngIf="!status">
            <div class="row form-group">
            <div class="col-md-6">
                <label>Application No.</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Application_no">
                <div *ngIf="rObj.controls.Application_no.invalid && (rObj.controls.Application_no.dirty || rObj.controls.Application_no.touched)">
                    <p class="input-error-msg">Please enter application no</p>
                </div>
            </div>
            <div class="col-md-6">
                <label>Date of Birth</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="DOB">
                <div *ngIf="rObj.controls.DOB.invalid && (rObj.controls.DOB.dirty || rObj.controls.DOB.touched)">
                    <p class="input-error-msg">Please enter date of birth</p>
                </div>
            </div>
            </div>
            
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="checkStatus()">Proceed</button>
            </div>
        </form>
        <div class="instruction" *ngIf="status">
            <p class="heading">Status</p>
            <ul class="instruction-list">
                <div class="row right-heading" *ngFor="let a of admissionDetails">
                    <div class="col-md-2 heading">
                        Reference No {{a.ref_no}}
                    </div>
                    <div class="col-md-2 heading">
                        Form Status {{a.status == '1'? 'Submitted' : 'Not Submitted'}}
                    </div>
                    <div class="col-md-2 heading">
                        Payment Status <br> {{a.payment_status == '1'? 'Paid' : 'UnPaid'}}
                    </div>
                    <div class="col-md-3 heading">
                        Application Date <br> {{a.create_date}}
                    </div>
                    <div class="col-md-3 heading" *ngIf="a.payment_status == '1'">
                        TransactionID {{a.txn_id}}
                    </div>
                </div>
                <li class="list-point">
                    Application is in progress. After completing confirmation email or message will be send to respective credentials.

                </li>
            </ul>
        </div>
       
    </div>
</div>
