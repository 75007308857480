<div class="modal-header">
    <h5 class="modal-title">Edit Hotel Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Hostel Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="HostelName">
                <div *ngIf="rObj.controls.HostelName.invalid && (rObj.controls.HostelName.dirty || rObj.controls.HostelName.touched)">
                    <p class="input-error-msg">Please enter hostel name</p>
                </div>
            </div>

            <div class="form-group">
                <label>Room Type</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Room_Type">
                    <option value="">Select</option>
                    <!-- <option value="1">1</option>
                    <option value="2">2</option> -->
                <option *ngFor="let type of RoomtypeData" value="{{type.ID}}">{{type.room_type}}</option>
                </select>
                <div *ngIf="rObj.controls.Room_Type.invalid && (rObj.controls.Room_Type.dirty || rObj.controls.Room_Type.touched)">
                    <p class="input-error-msg">Please enter room type</p>
                </div>
            </div>

            <div class="form-group">
                <label>Address</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" autocomplete="off" formControlName="Address"></textarea>
                <div *ngIf="rObj.controls.Address.invalid && (rObj.controls.Address.dirty || rObj.controls.Address.touched)">
                    <p class="input-error-msg">Please enter address</p>
                </div>
            </div>

            <div class="form-group">
                <label>Phone No.</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Phone_no" >
                <div *ngIf="rObj.controls.Phone_no.invalid && (rObj.controls.Phone_no.dirty || rObj.controls.Phone_no.touched)">
                    <p class="input-error-msg">Please enter phone number</p>
                </div>
            </div>

            <div class="form-group">
                <label>Capacity</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Capacity">
                <div *ngIf="rObj.controls.Capacity.invalid && (rObj.controls.Capacity.dirty || rObj.controls.Capacity.touched)">
                    <p class="input-error-msg">Please enter capacity</p>
                </div>
            </div>

            <div class="form-group">
                <label>Warden Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Warden_name">
                <div *ngIf="rObj.controls.Warden_name.invalid && (rObj.controls.Warden_name.dirty || rObj.controls.Warden_name.touched)">
                    <p class="input-error-msg">Please enter warden name</p>
                </div>
            </div>

            <div class="form-group">
                <label>Warden Address</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" formControlName="Warden_address"></textarea>
                <div *ngIf="rObj.controls.Warden_address.invalid && (rObj.controls.Warden_address.dirty || rObj.controls.Warden_address.touched)">
                    <p class="input-error-msg">Please enter warden address</p>
                </div>
            </div>

            <div class="form-group">
                <label>Warden Phone No.</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Warden_phone">
                <div *ngIf="rObj.controls.Warden_phone.invalid && (rObj.controls.Warden_phone.dirty || rObj.controls.Warden_phone.touched)">
                    <p class="input-error-msg">Please enter warden phone</p>
                </div>
            </div>

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateDetails()">Update</button>
            </div>
        </form>
    </div>
</div>
