<div class="console-content">
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-4"> Id Card Template</h1>
        <div class="link-container col-md-5">
         
        </div>
    </div>

    <div class="panel">
        <div class="filter-options">
            <form [formGroup]="rObj">    
            <div class="row">
                <div class="col-md-3 form-group">
                    <label>Class
                    <small class="req"> *</small></label>
                    <select name="class" formControlName="Class" class="form-select" style="width: 100%;">
                        <option value="">Please Select</option>
                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                        </select>
                        <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                        <p class="input-error-msg">Please select class</p>
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <label>Section
                        <small class="req"> *</small></label>
                        <select name="section" formControlName="Section" class="form-select" style="width: 100%;">
                            <option value="">Please Select</option>
                            <ng-container *ngFor="let section of classSectionData" >
                                <option [value]="section.id">{{section.name}}</option>
                            </ng-container>
                        </select>
                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                            <p class="input-error-msg">Please select section</p>
                        </div>
                </div> 
                <div class="col-md-3 form-group">
                    <label>Id Template
                        <small class="req"> *</small></label>
                        <select formControlName="Template" class="form-select" style="width: 100%;">
                            <option value="">Please Select</option>
                            <option *ngFor="let tem of idCardListData" value="{{tem.ID}}">{{tem.name}}</option> 
                        </select>
                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                            <p class="input-error-msg">Please select section</p>
                        </div>
                </div>
                <div class="col-md-3 btn-container" style="margin-top: 25px;" >
                    <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                </div> 
                <!-- <div class="col-md-2 btn-container" style="margin-top: 25px; padding-left: 39px;">
                    <button class="btn primary-btn small-btn" [disabled]="!selectedIds.length" (click)="search()">Generate</button>
                </div> -->
            </div> 
            </form>                     
        </div> 
        
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="studentListData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="openGenerateId(rObj.controls.Template.value)">Generate</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentListData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                <th class="align-left">Admission No</th>
                                <th>Student Name</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Date of Birth</th>
                                <th>Gender</th>
                                <th>Blood Group</th>
                                <th>Mobile Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentListData">
                                <td>
                                  <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                </td>
                                <td class="align-left">{{s.admission_number}} </td>
                                <td>{{s.full_name}}</td>
                                <td>{{s.class_name}}</td>
                                <td>{{s.section_name}}</td>
                                <td>{{s.dob}}</td>
                                <td>{{s.gender}}</td>
                                <td>{{s.blood_group}}</td>
                                <td>{{s.mobile}}</td>
                                <!-- <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentListData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>