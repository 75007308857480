import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: ''
})

export class RedirectComponent implements OnInit {
  public user = JSON.parse(localStorage.getItem('currentUser')!);

  constructor(
    private _router: Router,
  ) {}

  ngOnInit() {
    if (this.user) {
      this._router.navigate(['/dashboard']);
      return false;
    } else {
      this._router.navigate(['/user/login']);
      return false;
    }
  }
}
