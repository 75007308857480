import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';

@Component({
  selector: 'app-branches-add',
  templateUrl: './add.component.html'
})

export class BranchesAddComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    dtOptions: DataTables.Settings = {};

    imageSrc:any = '';
    status:boolean = false

    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _userService: UserService,
        private _instService: InstService,
        private _router: Router
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Address: ['', Validators.required],
        Pincode: ['', [Validators.required]],//Validators.pattern(/^([0-9]{6})$/)
        Country: ['', Validators.required],
        Email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        Phone: ['', [Validators.required, Validators.pattern(/^(6|7|8|9)(\d{9})$/)]],
        Fax: [''],
        Code: [''],
        IconPath: [''], 
    })

    ngOnInit(): void { }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    addInst() {
        if (this.rObj.valid) {
            // alert('yes')
            let rObj = this.rObj.value;
            let instData = {
                inst_admin_id: this.userID,
                inst_name: rObj.Name,
                inst_address: rObj.Address,
                inst_pincode: rObj.Pincode,
                inst_country: rObj.Country,
                inst_email: rObj.Email,
                inst_phone: rObj.Phone,
                inst_fax: rObj.Fax,
                inst_code: rObj.Code,
                icon_path: this.imageSrc,
            }
            this._instService.addInstData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._router.navigate(['/branches/list']);
                    this._gService.triggerSnackbar('Institution Branches Added Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
}