<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Holiday</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Holiday</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Holiday
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Title</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Title">
                            <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                                <p class="input-error-msg">Please enter Title name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control" formControlName="Date">
                            <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                <p class="input-error-msg">Please select Date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" class="form-control" formControlName="Description"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter Description</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveHoliday()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Holiday List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="holidayData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Title</th>
                                <th>Date</th>
                                <th style="width: 150px;">Description</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let h of holidayData">
                                <td class="align-left">{{h.title}}</td>
                                <td>{{getDate(h.date)}}</td>
                               <td><p>{{h.holiday_desc}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openHolidayEditModal(h.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(h.ID, 'holidays', 'holiday')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!holidayData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>