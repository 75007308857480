import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class ModulesService {
  constructor(
    private _http: HttpClient,
  ) { }

  private saveModulesAccess = `${environment.APIURL}/modules/saveUpdate/`;
  
  saveModulesAccessData(rObj: object): Observable<any> {
    return this._http.post(this.saveModulesAccess, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}