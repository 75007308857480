import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { HostelService } from 'src/app/services/hostel.service';
  
@Component({
    selector: 'app-hostelDetailsmodal',
    templateUrl: './hostelDetailsmodal.component.html',
    styleUrls: ['./hostelDetailsmodal.component.css'],
})

export class HostelDetailsmodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public hostelDetailID: number;
    public RoomtypeData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: HostelService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        HostelName: ['', Validators.required],
        Room_Type: ['', Validators.required],
        Address: ['', Validators.required],
        Phone_no: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
        Capacity: ['', Validators.required],
        Warden_name: ['', Validators.required],
        Warden_address: ['', Validators.required],
        Warden_phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room_type').subscribe(rRes => {
            console.log(rRes)
            if (rRes.status === true) {
                this.RoomtypeData = JSON.parse(rRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getDataByID(this.hostelDetailID, 'hostel').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    HostelName: sData[0].name,
                    Room_Type: sData[0].type,
                    Address: sData[0].address,
                    Phone_no: sData[0].phone_no,
                    Capacity: sData[0].capacity,
                    Warden_name: sData[0].warden_name,
                    Warden_address: sData[0].warden_address,
                    Warden_phone: sData[0].warden_phone_no,
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateDetails() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.hostelDetailID,
                name: rObj.HostelName,
                type: rObj.Room_Type,
                address: rObj.Address,
                phone_no: rObj.Phone_no,
                capacity: rObj.Capacity,
                warden_name: rObj.Warden_name,
                warden_address: rObj.Warden_address,
                warden_phone_no: rObj.Warden_phone
            }
            console.log(lData)
            this._aService.hosteldetailsData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Hostel Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}