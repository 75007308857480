import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class EventService {
  constructor(
    private _http: HttpClient,
  ) { }

  private event = `${environment.APIURL}/events/eventType/`;
  private addevents = `${environment.APIURL}/events/event/`;

 
  eventData(data: object): Observable<any> {
    return this._http.post(this.event, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  addEventData(data: object): Observable<any> {
    return this._http.post(this.addevents, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}