<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Assign Class Subject</h1>

        <div class="link-container col-md-6">
            <a href="../academic/subjects" class="content-header-link">Add Subject</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Class Subject
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Class Name</label>
                            <small class="req"> *</small>
                            <!-- <input type="" class="form-control" formControlName="Class"> -->
                            <select class="form-select" formControlName="Class">
                                <option value="">Select</option>
                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter class name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Sections</label>
                            <small class="req"> *</small>
                            <div class="list">
                                <label *ngFor="let section of classSectionData " formArrayName="Section" style="margin-right: 15px;">
                                    <input type="checkbox" [value]="section.id" (change)="onSectionSelect($event)">
                                    {{section.name}}
                                </label>
                            </div>    
                            <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                                <p class="input-error-msg">Please select a section</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Subjects</label>
                            <small class="req"> *</small>
                            <div class="list">
                                <label *ngFor="let subject of subjectData.slice().reverse();" formArrayName="Subject" style="margin-right: 15px;" >
                                    <input type="checkbox" [value]="subject.ID" (change)="onSubjectSelect($event)">
                                    {{subject.name}}		                        
                                </label>
                            </div>    
                            <div *ngIf="rObj.controls.Subject.errors?.['required'] && rObj.controls.Subject.touched">
                                <p class="input-error-msg">Please select a subject</p>
                            </div>
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveAssignsub()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>   
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Class List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="assignSubData.length">
                        <thead>
                            <tr>
                                <!-- <th class="align-left" *ngIf="isVernacular == 'yes'">Medium</th> -->
                                <th class="align-left">Class Name</th>
                                <th>Sections</th>
                                <th>Subject</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let assignsub of assignSubData.slice().reverse()">
                            
                            <!-- <td  class="align-left" *ngIf="isVernacular == 'yes'">{{assignsub.medium}}</td> -->
                            <td>
                                <ng-container *ngFor="let class of classData">
                                    <ng-container *ngIf="class.ID == assignsub.class">
                                        {{class.name}}
                                    </ng-container>
                                </ng-container>
                                </td>
                            <td>
                                <ng-container *ngFor="let section of stringToArray(assignsub.sections)">
                                    <ng-container *ngFor="let s of sectionData">
                                        <p class="td-section-list" *ngIf="section == s.ID">{{s.name}}</p>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let subject of stringToArray(assignsub.subjects)">
                                    <ng-container *ngFor="let s of subjectData">
                                        <p class="td-section-list" *ngIf="subject == s.ID">{{s.name}}</p>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openAssignSubjectEditModal(assignsub.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(assignsub.ID, 'assign_subject', 'assigned subject')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!assignSubData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
        
</div>