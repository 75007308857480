import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClassModalComponent } from 'src/app/components/modal/classmodal/classmodal.component';
import { AssignsubjectModalComponent } from 'src/app/components/modal/assign-subjectmodal/assign-subjectmodal.component';

@Component({
    selector: 'app-academic-assign-subject',
    templateUrl: './assign-subject.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class assignComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public medium = this.userData.activeMedium;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectData: any[] = [];
    public assignSubData: any[] = [];
    public mediumData: any[] = [];
    
    public sectionValue: '';
    public subjectValue: '';

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: this._fBuilder.array([], Validators.required),
        Subject: this._fBuilder.array([], Validators.required),
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                this.subjectData = JSON.parse(aRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assign_subject').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.assignSubData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        // this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        //     this._gService.showLoader(true);
        //     this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             this.classSectionData = JSON.parse(result[0].sections)
        //             console.log(this.classSectionData)

        //         }
        //     })
        //     this._gService.showLoader(false);
        // })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this._gService.showLoader(false);
        })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    // getSectionName(sectionID: number) {
    //     this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
    //         if (sRes.status === true) {
    //             let sData = JSON.parse(sRes.result)[0];
    //             return sData
    //         }
    //     })
    // }
    openAssignSubjectEditModal(assignSubID: number ) {
        this.modalRef = this.modalService.show(AssignsubjectModalComponent, {
            class: 'modal-dialog-centered assign_subject-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                assignSubID: assignSubID
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    onSectionSelect(e: any) {
        const selectedSections = <FormArray>this.rObj.controls.Section;

        selectedSections?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSections.push(new FormControl(e.target.value));
        } else {
          let index = selectedSections.controls.findIndex(x => x.value == e.target.value)
          selectedSections.removeAt(index);
        }

        this.sectionValue = selectedSections.value;

        console.log(selectedSections.value)
    }

    onSubjectSelect(e: any) {
        const selectedSubjects = <FormArray>this.rObj.controls.Subject;

        selectedSubjects?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSubjects.push(new FormControl(e.target.value));
        } else {
          let index = selectedSubjects.controls.findIndex(x => x.value == e.target.value)
          selectedSubjects.removeAt(index);
        }

        this.subjectValue = selectedSubjects.value;

        console.log(selectedSubjects.value)
    }

    saveAssignsub() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                class: rObj.Class,
                section: JSON.stringify(this.sectionValue),
                subject: JSON.stringify(this.subjectValue),
                medium: this.medium
            }
            this._aService.assignsubData(ayData).subscribe(asRes => {
                console.log(asRes)
                if (asRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Class Subject Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}