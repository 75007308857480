import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { InventoryPurchaseModalComponent } from 'src/app/components/modal/inventoryPurchasemodal/inventoryPurchasemodal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-vendor-inventoryPurchase',
  templateUrl: './inventoryPurchase.component.html',
})

export class InventoryPurchaseComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public inventoryPurchaseData: any[] = [];
    public inventoryItemData: any[] = [];
    public vendorData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    remove:boolean = false;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Item_name :['', Validators.required],
        Vendors: ['', Validators.required],
        Qty: ['', Validators.required],
        Cost: ['', Validators.required],
        Date: ['', Validators.required],
        Mode: ['', Validators.required],
        Status: ['', Validators.required],
        Doc: [''],
        Description: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_purchase').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryPurchaseData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_item').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryItemData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vendor').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.vendorData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.remove = true;
        this.imageSrc = '';
    }
    
    openPurchaseEditModal(purchaseID: number) {
        this.modalRef = this.modalService.show(InventoryPurchaseModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                purchaseID: purchaseID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    savePurchase() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                item_id: rObj.Item_name,
                vendor: rObj.Vendors,
                cost: rObj.Cost,
                date: rObj.Date,
                status: rObj.Status,
                mode: rObj.Mode,
                qty: rObj.Qty,
                description: rObj.Description,
                purchase_doc: this.imageSrc,
                remove_document:this.remove
            }
            console.log(mData)
            this._aService.inventoryPurchaseData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Inventory Category Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}