<div class="console-content">
    <form [formGroup]="rObj">   
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link" [ngClass]="bookIssue ? 'active' : ''">
                        <a (click)="setTabActive('bookIssue')">Book Issue Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="bookDueDate ? 'active' : ''">
                        <a (click)="setTabActive('bookDueDate')">Book Due Date Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="bookInventory ? 'active' : ''">
                        <a (click)="setTabActive('bookInventory')">Book Inventory Report</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="issueItemReport ? 'active' : ''">
                        <a (click)="setTabActive('issueItemReport')">Book Issue Return Report</a>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <!-- <div id="staff-details" class="panel panel-margin-top" [ngClass]="staffReport ? 'active' : ''"> -->
                <div id="bookIssue-details" class="panel panel-margin-top" [ngClass]="bookIssue ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Book Issue Report</h1>
                    </div>            
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4"> 
                                    <label>Issue Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-4" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                                <div class="col-md-4">
                                    <label>User Type<small class="req"> *</small></label>
                                    <select formControlName="User_type" class="form-control">
                                        <option value="">Select Category</option>
                                        <option value="staff">Staff</option>
                                        <option value="student">Student</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchBookIssue()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">

                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="issueBookData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Book Title</th>
                                    <th>Book Number</th>
                                    <th>Issue to</th>
                                    <th>Issue Date</th>
                                    <th>Due Date</th>
                                    <th>Return Date</th>
                                    <th>library card Number</th>
                                    <th>Members Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of issueBookData">
                                    <td class="align-left">{{s.book_title}}</td>                        
                                    <td>{{s.book_id}}</td>
                                    <td>{{rObj.controls.User_type.value == 'student' ?  s.student_name : s.staff_name}}</td>
                                    <td>{{s.issue_date}}</td>
                                    <td>{{s.due_date}}</td>
                                    <td>{{s.return_date}}</td>
                                    <td>{{s.library_card_no}}</td>
                                    <td>{{s.user_type}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!issueBookData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>                  
                </div>
                <div id="bookDueDate-details" class="panel panel-margin-top" [ngClass]="bookDueDate ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Book Due Date Report</h1>
                    </div>            
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4"> 
                                    <label>Issue Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Period" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 

                                <div  class="col-md-4" *ngIf="rObj.controls.Period.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                                
                                <div class="col-md-4">
                                    <label>User Type<small class="req"> *</small></label>
                                    <select formControlName="User_type" class="form-control">
                                        <option value="">Select Category</option>
                                        <option value="staff">Staff</option>
                                        <option value="student">Student</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchBookDue()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">

                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="dueBookData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Book Title</th>
                                    <th>Book Number</th>
                                    <th>Issue to</th>
                                    <th>Issue Date</th>
                                    <th>Due Date</th>
                                    <th>Return Date</th>
                                    <th>library card Number</th>
                                    <th>Members Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of dueBookData">
                                    <td class="align-left">{{s.book_title}}</td>                        
                                    <td>{{s.book_id}}</td>
                                    <td>{{rObj.controls.User_type.value == 'student' ?  s.student_name : s.staff_name}}</td>
                                    <td>{{s.issue_date}}</td>
                                    <td>{{s.due_date}}</td>
                                    <td>{{s.return_date}}</td>
                                    <td>{{s.library_card_no}}</td>
                                    <td>{{s.user_type}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!dueBookData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="bookInventory-details" class="panel panel-margin-top" [ngClass]="bookInventory ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-5">Book Inventory Report</h1>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryBookData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Book Title</th>
                                    <th>Book Number</th>
                                    <th>ISBN Number</th>
                                    <th>Publisher</th>
                                    <th>Author</th>
                                    <th>Subject</th>
                                    <th>Rack Number</th>
                                    <th>Qty</th>
                                    <th>Available</th>
                                    <th>Issued</th>
                                    <th>Book Price</th>
                                    <th>Post Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of inventoryBookData">
                                    <td class="align-left">{{s.title}}</td>                        
                                    <td>{{s.book_no}}</td>
                                    <!-- <td>{{rObj.controls.User_type.value == 'student' ?  s.student_name : s.staff_name}}</td> -->
                                    <td>{{s.isbn_no}}</td>
                                    <td>{{s.publisher}}</td>
                                    <td>{{s.author}}</td>
                                    <td>{{s.subject}}</td>
                                    <td>{{s.rack_no}}</td>
                                    <td>{{s.qty}}</td>
                                    <td>{{s.initial_qty}}</td>
                                    <td>{{s.issued_qty}}</td>
                                    <td>{{s.cost}}</td>
                                    <td>{{s.added_date}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!inventoryBookData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="issueItem-details" class="panel panel-margin-top" [ngClass]="issueItemReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Book Issue Return Report</h1>
                    </div>            
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4"> 
                                    <label>Issue Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Search" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-4" *ngIf="rObj.controls.Search.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>User Type<small class="req"> *</small></label>
                                    <select formControlName="User_type" class="form-control">
                                        <option value="">Select Category</option>
                                        <option value="staff">Staff</option>
                                        <option value="student">Student</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchIssueBookReturn()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">

                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="issueReturnData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Book Title</th>
                                    <th>Book Number</th>
                                    <th>Issue to</th>
                                    <th>Issue Date</th>
                                    <th>Due Date</th>
                                    <th>Return Date</th>
                                    <th>library card Number</th>
                                    <th>Members Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of issueReturnData">
                                    <td class="align-left">{{s.book_title}}</td>                        
                                    <td>{{s.book_id}}</td>
                                    <td>{{rObj.controls.User_type.value == 'student' ?  s.student_name : s.staff_name}}</td>
                                    <td>{{s.issue_date}}</td>
                                    <td>{{s.due_date}}</td>
                                    <td>{{s.return_date}}</td>
                                    <td>{{s.library_card_no}}</td>
                                    <td>{{s.user_type}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!issueReturnData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>    
    </form>
</div>