import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StaffService } from 'src/app/services/staff.service';
import { LeaveModalComponent } from 'src/app/components/modal/leavemodal/leavemodal.component';
import { ApplyLeavemodalModalComponent } from 'src/app/components/modal/applyLeavemodal/applyLeavemodal.component';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
import { AdminService } from 'src/app/services/admin.service';
import { JsonPipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-staff-applyLeave',
  templateUrl: './applyLeave.component.html',
})

export class applyLeaveComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userRole = this.userData.UserRole;
    public staffId: any;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public applyLeaveData: any[] = [];
    public leaveData: any[] = [];
    public userArray: any[] = [];
    public selectedIds : any[] = [];
    
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _staffService: StaffService,
        private _aService: AdminService,
        private _uService: UserService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({

        Reason: ['', Validators.required],
        Apply_date: ['', Validators.required],
        Leave_from_date: ['', Validators.required],
        Leave_to_date: ['', Validators.required],
        Leave_type:['',Validators.required],
        Leave_document: [''],

    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._staffService.getStaffDataByUserIdData(this.userID, 'staff').subscribe(res => {
            console.log(res)
            if(res.status == true) {
                let data = JSON.parse(res.result)
                console.log(data)
                this.staffId = data[0].staff_id
                console.log(this.staffId)
                alert(this.staffId)
                this._staffService.getapplyLeaveData(this.instID, this.activeAcademicID, this.staffId).subscribe(lRes => {
                    console.log(lRes)
                    if (lRes.status === true) {
                        this.applyLeaveData = JSON.parse(lRes.result);
                    } else {
                        this._gService.triggerSnackbar('No Data Found!', 'error', true);
                    }
                    this._gService.showLoader(false);
                })
            }
        })
        console.log(this.staffId)
        

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leave_type').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.leaveData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.applyLeaveData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.applyLeaveData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {

        this.imageSrc = '';
    }

    openApplyLeaveEditModal(leaveID: number) {
        this.modalRef = this.modalService.show(ApplyLeavemodalModalComponent, {
            class: 'modal-dialog-centered leave-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                leaveID: leaveID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    saveLeave() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                role : this.userRole,
                staff_id: this.staffId,
                reason: rObj.Reason,
                apply_date: rObj.Apply_date,
                leave_from_date: rObj.Leave_from_date,
                leave_to_date:rObj.Leave_to_date,
                leave_type:rObj.Leave_type,
                leave_document: this.imageSrc

            }
            console.log(lData)
            this._staffService.applyLeaveData(lData).subscribe(lRes => {
                console.log(lRes)
                if (lRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Leave Applied Successfully', 'success', true);
                        this._uService.getUserIdsDetailsData(this.instID, '2').subscribe(res => {
                            console.log(res.result)
                            let userIds = JSON.parse(res.result)
                            
                        if(this.userRole == 3) {
                            alert('yes')
                            let ndata = {
                                inst_id: this.instID,
                                academic_id: this.activeAcademicID,
                                user_role: '2',
                                user_ids: JSON.stringify(userIds),
                                title: 'Applied For Leave',
                                msg: 'optional',
                                link: 'staff/leave/approve/add',
                                screen: 'ApproveLeave'
                            }
                            console.log(ndata)
                            this._nService.sendNotificationData(ndata).subscribe(res => {
                                console.log(res)
                                if (res.status === true) {
                                    this._reRoute.shouldReuseRoute = () => false;
                                    this._router.navigate([this._router.url]);
                                    this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                                }
                            }) 
                        } 
                    })  
                    // })           

                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}