import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { ExaminationService } from 'src/app/services/examination.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StudentService } from 'src/app/services/student.service';
import { markRegistermodalComponent } from 'src/app/components/modal/markRegistermodal/markRegistermodal.component';
@Component({
  selector: 'app-examination-markregister',
  templateUrl: './markregister.component.html'
})

export class MarkRegisterComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public examtimetableData: any[] = [];
    public examData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectsData: any[] = [];
    public teacherData: any[] = [];
    public teachersData: any[] = [];
    public ExaminationData: any[] = [];
    // public designationId: any;
    public mediumData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    
    public classId : any;
    public sectionId : any;
    public examTypeId : any;
    public mediumId = this.userData.activeMedium;
    public day: any = 1;
    openpanel:boolean = false;
    dtOptions: any = {};

    public StudentListData: any[] = [];
    public etDeleteIds: any[] = [];

    public etPatchDataArray: FormGroup;
    modalRef: BsModalRef;
    constructor(
        private _gService: GlobalService,
        private _aService: ExaminationService,
        private _sService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        public modalService: BsModalService
    ) {}

   public rObj = this._fBuilder.group({
        Exam:['',Validators.required],
        Class:['',Validators.required],
        Section:['',Validators.required],
        Medium:[''],
        etData: this._fBuilder.array([]),
    })


    ngOnInit(): void {

        // this.classId = this._aRoute.snapshot.paramMap.get('class');
        // this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        // this.examTypeId = this._aRoute.snapshot.paramMap.get('examType')
        // if(this.classId && this.sectionId && this.examTypeId) {
        //     this.openpanel = true
        //     this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             console.log(result)
        //             let sectionsArray = JSON.parse(result[0].sections)
        //             let sectionNamesArray = result[0].section_names.split(',')
        //             console.log(sectionsArray, sectionNamesArray)

        //             this.classSectionData = JSON.parse(result[0].sections)

        //             this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
        //                 return {'id': section_id, 'name': sectionNamesArray[index]}
        //             })
        //             console.log(this.classSectionData)
        //         }
        //     })
        //     this.rObj.patchValue({
        //         Class : this.classId,
        //         Section : this.sectionId,
        //         Exam :this.examTypeId,
        //     })
        //     this.gotoSearch()
        
        // }

        // this._aService.timetableByExamTypeClassSectionData(this.instID, this.activeAcademicID, this.rObj.controls.Exam.value, this.rObj.controls.Class.value, this.rObj.controls.Section.value, this.mediumId).subscribe(res => {
        //     console.log(res)
        //     if (res.status == true) {
        //         let data = JSON.parse(res.result)
        //         console.log(data)
        //     }
        // })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'exam').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.examData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
        //     // console.log(cRes)
        //     if (cRes.status === true) {
        //         this.subjectsData = JSON.parse(cRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        // })
        // this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this.teacherData = JSON.parse(sRes.result);
        //     }
        //     this._gService.showLoader(false);
        // })

         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
    
        this.dtOptions = {
                pagingType: 'numbers',
                order: [],
                columnDefs: [{
                    targets: 'no-sort',
                    orderable: false,
                }],
                dom: 'fBrtip',
                buttons: [
                    'copy', 'excel', 'csv', 'pdf', 'print',
                ],
                language: { search: '', searchPlaceholder: "Search..." },
            };
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/examination/exam-Schedule/list/'+robj.Exam+'/'+robj.Class+'/'+robj.Section]);
        
    }
       

    openAddMarksModal(ExamId: number, SubMarks: number, Sub_name: any) {
        this.modalRef = this.modalService.show(markRegistermodalComponent, {
            class: 'modal-dialog-centered marks-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                subject_name: Sub_name,
                examID: ExamId,
                classID: this.rObj.controls.Class.value,
                sectionID: this.rObj.controls.Section.value,
                subjectMarks: SubMarks,
                exam_type: this.rObj.controls.Exam.value
            } 
        });
    }

    search(){
        let robj = this.rObj.value;
        if (robj.Class && robj.Section && robj.Exam) {
            this._aService.timetableByExamClassSectionData(this.instID, this.activeAcademicID, robj.Exam, robj.Class, robj.Section).subscribe(res => {
                console.log(res.status)
                if (res.status === true) {
                   this.ExaminationData = JSON.parse(res.result)
                } else {
                   this.ExaminationData = [];
                }
            })
        }
    }
    
    
}