<div class="modal-header">
    <!-- <h5 class="modal-title">Preview</h5> -->
    <button type="submit" class="btn primary-btn small-btn" style="margin-left: 315px !important;" (click)="print()">Print</button>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper" id="content">
        <div class="template-priview-wrapper row">
            <!-- <img class="template-preview" src="{{template.preview_path}}"> -->
            <div class="col-md-12">
                <div id="bg-logo"></div>
                <ng-container *ngFor="let student_id of studentIDs">
                    <div [id]="'htmlPreview-'+student_id" [innerHTML]="getPopulatedHTML(student_id)"></div>
                </ng-container>
                <!-- <div id="htmlPreview" [innerHTML]="templateHtml"></div> -->
            </div>
        </div>
    </div>
</div>
