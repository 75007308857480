import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';

@Component({
  selector: 'app-reports-userLogReport',
  templateUrl: './userLogReport.component.html',
})

export class UserLogReportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public userRoleData: any[] = [];
    public classSectionData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public routeData: any[] = [];
    public userLogData: any[] = [];




    public genderRatio: any;
    public absentPercentage: any;
    
    public staffReport: boolean = true;
    public payrollReport: boolean = false;

    
    public imgURL = this._gService.getImgPath();
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: [''],
        Route: [''],
        Section: [''],
        User_type: [''],
        
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        
        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })


        

        // this._rService.getStudentTeacherRatioData(this.instID, this.activeAcademicID).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentTeacherData = JSON.parse(mRes.result);
            
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._rService.getStudentgenderRatioData(this.instID, this.activeAcademicID).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentGenderData = JSON.parse(mRes.result);
            
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })


        // this._rService.getStudentCountData(this.instID, this.activeAcademicID).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentCountData = JSON.parse(mRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.allStudentData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.routeData = JSON.parse(cRes.result);
                console.log(this.routeData)
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.designationData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)

                }
            })
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }
   

   

   

    

    search() {
        if (this.rObj.valid) {
            this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'user_log').subscribe(mRes => {
                console.log(mRes)
                if (mRes.status === true) {
                    let userLogData = JSON.parse(mRes.result);
                    console.log(userLogData)
                    if (this.rObj.controls.User_type) {
                        if (this.rObj.controls.User_type.value == '5') {
                            this.userLogData = userLogData.filter((u : any)=> u.role == 'Student')
                        } else if (this.rObj.controls.User_type.value == '4') {
                            this.userLogData = userLogData.filter((u : any)=> u.role == 'Parent')
                            console.log(userLogData)
                        } else {
                            this.userLogData = userLogData
                        }
                    }
                } else {
                    this._gService.triggerSnackbar('No Data Found!', 'error', true);
                }
                this._gService.showLoader(false);
            })

           
            
       }
    }

}