<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-8">Certificates List</h1>

        <div class="link-container col-md-4">
            <a href="certificate/add" class="content-header-link">Add Certificate</a>
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <!-- <div class="panel-header">
                    <div class="panel-title">
                       Select Template
                    </div>
                </div> -->
                <div class="panel-body" >
                    <div class="template-wrapper row"  >
                        <!-- <div class="col-md-3" *ngFor="let template of certificateListData">
                            <img class="template-preview" src="{{template.preview_path}}" (click)="selectImage(templateID)" >
                            <div class="template-preview">
                                <div [innerHTML]="template.html"></div>
                            </div>
                            <h5 class="template-preview-name">{{template.name}}</h5>
                            <div class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openTemplateEdit(template.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteTemplate(template.ID, 'certificates', ' certificate')"><i class="far fa-trash-alt"></i></a>
                            </div>
                        </div> -->

                        <ng-container *ngIf="certificateListData.length">
                            <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                                <span class="rows_selected">{{selectedIds.length}} Selected</span>
                                <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('certificates', 'certificates')">Delete</button>
                            </div>
                        </ng-container>
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="certificateListData.length">
                            <thead>
                                <tr>
                                    <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                    <th class="align-left">Template Name</th>
                                    <th>Certificate Title</th>
                                    <th class="no-sort">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of certificateListData">
                                    <td>
                                        <input type="checkbox" class="list-checkbox" [checked]="c.isSelected" (change)="onChecked(c.ID, $event)">
                                    </td>
                                    <td class="align-left">{{c.name}}</td>
                                    <td>{{c.title}}</td>
                                    <td class="action-btn">
                                        <a tooltip="View" class="edit-btn" (click)="viewTemplate(c.ID)" ><i class="far fa-eye"></i></a>
                                        <a tooltip="Edit" class="edit-btn" (click)="openTemplateEdit(c.ID)"><i class="far fa-edit"></i></a>
                                        <a tooltip="Delete" class="del-btn" (click)="deleteTemplate(c.ID, 'certificates', ' certificate')"><i class="far fa-trash-alt"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                        <div *ngIf="!certificateListData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
      </div>  
</div>