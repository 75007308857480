<div class="modal-header">
    <h5 class="modal-title">Collection List</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <div>
            <label style="font-weight: 700; font-size: 15px;">Collection Date:</label>{{date}}
        </div>
        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="dailyDateCollectionData.length">
            <thead>
                <tr>
                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                    <th class="align-left">Admission No.</th>
                    <th>Name</th>
                    <th>Class(Section)</th>
                    <th>Payment Mode</th>
                    <th>Payment ID</th>
                    <th>Fine</th>
                    <th>Total</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of dailyDateCollectionData">   
                    <td class="align-left">{{s.admission_number}}</td>  
                    <td>{{s.student_name}}</td>
                    <td>{{s.class_name}}/{{s.section_name}}</td>
                    <td>{{s.payment_mode}}</td>
                    <td>{{s.txn_id}}</td>
                    <td>{{s.fine_amount}}</td>
                    <td>{{s.amount}}</td>
                </tr>
            </tbody>
        </table>   
        <div *ngIf="!dailyDateCollectionData.length">
            <div class="noDataWrapper">
                <img src="/assets/img/No-data-found.png">
            </div>
            <p style="text-align: center;">Data Not Found</p>
        </div>
    </div>
</div>
