<website-default-header *ngIf="theme == 'default'"></website-default-header>
<div class="container website-container">
    <router-outlet></router-outlet>
    <!-- <app-website-page *ngIf="instID" [instID]="instID"></app-website-page> -->
</div>
<website-default-footer *ngIf="theme == 'default'"></website-default-footer>

<website-greenOpaque-header *ngIf="theme == 'greenOpaque'"></website-greenOpaque-header>
<div class="container website-container">
    <router-outlet></router-outlet>
    <!-- <app-website-page *ngIf="instID" [instID]="instID"></app-website-page> -->
</div>
<website-greenOpaque-footer *ngIf="theme == 'greenOpaque'"></website-greenOpaque-footer> 

<website-monochromeBlue-header *ngIf="theme == 'monochromeBlue'"></website-monochromeBlue-header>
<div class="container website-container">
    <router-outlet></router-outlet>
    <!-- <app-website-page *ngIf="instID" [instID]="instID"></app-website-page> -->
</div>
<website-monochromeBlue-footer *ngIf="theme == 'monochromeBlue'"></website-monochromeBlue-footer> 

<website-pinkGrey-header *ngIf="theme == 'pinkGrey'"></website-pinkGrey-header>
<div class="container website-container">
    <router-outlet></router-outlet>
    <!-- <app-website-page *ngIf="instID" [instID]="instID"></app-website-page> -->
</div>
<website-pinkGrey-footer *ngIf="theme == 'pinkGrey'"></website-pinkGrey-footer>   

<website-violet-header *ngIf="theme == 'violet'"></website-violet-header>
<div class="container website-container">
    <router-outlet></router-outlet>
    <!-- <app-website-page *ngIf="instID" [instID]="instID"></app-website-page> -->
</div>
<website-violet-footer *ngIf="theme == 'violet'"></website-violet-footer>