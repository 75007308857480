<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6"> Hostel</h1>
        <div class="link-container col-md-6">
           <a href="../hostel/hostel-rooms/add" class="content-header-link">Add Hostel Room </a>
           <a href="../hostel/hostel-room-type/add" class="content-header-link">Add Hostel Room Type</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Hostel Detail
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">

                        <div class="form-group">
                            <label>Hostel Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="HostelName">
                            <div *ngIf="rObj.controls.HostelName.invalid && (rObj.controls.HostelName.dirty || rObj.controls.HostelName.touched)">
                                <p class="input-error-msg">Please enter hostel name</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Room Type</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Room_Type">
                                <option value="">Select</option>
                                <!-- <option value="1">1</option>
                                <option value="2">2</option> -->
                            <option *ngFor="let type of RoomtypeData" value="{{type.ID}}">{{type.room_type}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Room_Type.invalid && (rObj.controls.Room_Type.dirty || rObj.controls.Room_Type.touched)">
                                <p class="input-error-msg">Please enter room type</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Address</label>
                            <small class="req"> *</small>
                            <textarea type="text" class="form-control" autocomplete="off" formControlName="Address"></textarea>
                            <div *ngIf="rObj.controls.Address.invalid && (rObj.controls.Address.dirty || rObj.controls.Address.touched)">
                                <p class="input-error-msg">Please enter address</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Phone No.</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="Phone_no" >
                            <div *ngIf="rObj.controls.Phone_no.invalid && (rObj.controls.Phone_no.dirty || rObj.controls.Phone_no.touched)">
                                <p class="input-error-msg">Please enter phone number</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Capacity</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="Capacity">
                            <div *ngIf="rObj.controls.Capacity.invalid && (rObj.controls.Capacity.dirty || rObj.controls.Capacity.touched)">
                                <p class="input-error-msg">Please enter capacity</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Warden Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Warden_name">
                            <div *ngIf="rObj.controls.Warden_name.invalid && (rObj.controls.Warden_name.dirty || rObj.controls.Warden_name.touched)">
                                <p class="input-error-msg">Please enter warden name</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Warden Address</label>
                            <small class="req"> *</small>
                            <textarea type="text" class="form-control" formControlName="Warden_address"></textarea>
                            <div *ngIf="rObj.controls.Warden_address.invalid && (rObj.controls.Warden_address.dirty || rObj.controls.Warden_address.touched)">
                                <p class="input-error-msg">Please enter warden address</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Warden Phone No.</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="Warden_phone">
                            <div *ngIf="rObj.controls.Warden_phone.invalid && (rObj.controls.Warden_phone.dirty || rObj.controls.Warden_phone.touched)">
                                <p class="input-error-msg">Please enter warden phone</p>
                            </div>
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveDetails()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                       Hostel Details List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="hosteldetailsData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">HOSTEL NAME</th>
                                    <th>ROOM TYPE</th>
                                    <th>ADDRESS</th>
                                    <th>WARDEN NAME</th>
                                    <th>WARDEN ADDRESS</th>
                                    <th>WARDEN PHONE_NO</th>
                                    <th class="no-sort">MANAGE</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let hosteldetails of hosteldetailsData">                             
                                <td>{{hosteldetails.name}}</td>
                                <td>
                                    <ng-container *ngFor="let type of RoomtypeData" >
                                        <ng-container *ngIf="type.ID == hosteldetails.type">
                                            {{type.room_type}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                               
                                <td>{{hosteldetails.address}}</td>
                                <td>{{hosteldetails.warden_name}}</td>
                                <td>{{hosteldetails.warden_address}}</td>
                                <td>{{hosteldetails.warden_phone_no}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openHostelDetailsEditModal(hosteldetails.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(hosteldetails.ID, 'hostel', 'hostel details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                        <div *ngIf="!hosteldetailsData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div>
                </div>
            </div>
    </div>  
</div>