<div class="modal-header">
    <h5 class="modal-title">Edit Inventory Item</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Category</label>
                <small class="req"> *</small>
                <select formControlName="Category_name" class="form-control" required="">
                    <option value="">Select</option>      
                    <option *ngFor="let c of inventoryCategoryData" value="{{c.ID}}">{{c.category_name}}</option>                                          
                </select>	
                <div *ngIf="rObj.controls.Category_name.invalid && (rObj.controls.Category_name.dirty || rObj.controls.Category_name.touched)">
                    <p class="input-error-msg">Please enter Category </p>
                </div>	                    
            </div>
            <div class="form-group">
                <label>Inventory Item Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Item_name" value="">
                <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                    <p class="input-error-msg">Please enter Item name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Quantity</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Qty">
                <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                    <p class="input-error-msg">Please enter Qty </p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea class="form-control" formControlName="Description" value="" rows="3"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description </p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateItem()">Update</button>
            </div>
        </form> 
    </div>
</div>
