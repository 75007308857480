import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class FinanceService {
  constructor(
    private _http: HttpClient,
  ) { }

  private incomeHead = `${environment.APIURL}/finance/incomeHead/`;
  private income = `${environment.APIURL}/finance/income/`;
  private expenseHead = `${environment.APIURL}/finance/expenseHead/`;
  private expense = `${environment.APIURL}/finance/expense/`;
 
  incomeHeadData(data: object): Observable<any> {
    return this._http.post(this.incomeHead, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  incomeData(data: object): Observable<any> {
    return this._http.post(this.income, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  expenseHeadData(data: object): Observable<any> {
    return this._http.post(this.expenseHead, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  expenseData(data: object): Observable<any> {
    return this._http.post(this.expense, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}