import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { LibraryService } from 'src/app/services/library.service';
  
@Component({
    selector: 'app-addbookmodal',
    templateUrl: './addbookmodal.component.html',
    styleUrls: [],
})

export class AddbookmodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public bookID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    
    public bookCategoryData: any[] = [];
    public bookData : any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    removeCover: boolean = false;

    // removeImgStatus: boolean = false;
     
    // public imgURL = 'https://apiuat.edunationpro.com/uploads/';
    public imgURL = this._gService.getImgPath();

    constructor(
        private _gService: GlobalService,
        private _aService: LibraryService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Title: ['', Validators.required],
        Book_no: ['', Validators.required],
        Isbn_no: ['', Validators.required],
        Author: ['', Validators.required],
        Publisher: ['', Validators.required],
        Qty: ['', Validators.required],
        Category: ['', Validators.required],
        Subject: ['', Validators.required],
        Language: ['', Validators.required],
        Cost: ['', Validators.required],
        Rack_no: ['', Validators.required],
        Book_image: [''],
      
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'book_category').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.bookCategoryData = JSON.parse(mRes.result);
                // console.log(this.bookData)
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.bookID, 'books').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                this.bookData = JSON.parse(dataRes.result)
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Title: sData[0].title,
                    Book_no: sData[0].book_no,
                    Isbn_no: sData[0].isbn_no,
                    Author: sData[0].author,
                    Publisher: sData[0].publisher,
                    Qty: sData[0].qty,
                    Category: sData[0].category,
                    Subject: sData[0].subject,
                    Language: sData[0].language,
                    Cost: sData[0].cost,
                    Rack_no: sData[0].rack_no,
                 
                    //Book_image:sData[0].book_img,

                }); this.imageSrc = sData[0].cover_path ? this.imgURL+sData[0].cover_path : '';
               
            }
            this._gService.showLoader(false);
        })
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
        this.removeCover = true;
    }

    updateBook() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.bookID,
                title: rObj.Title,
                book_no: rObj.Book_no,
                isbn_no: rObj.Isbn_no,
                author: rObj.Author,
                publisher: rObj.Publisher,
                qty: rObj.Qty,
                category: rObj.Category,
                subject: rObj.Subject,
                language: rObj.Language,
                cost: rObj.Cost,
                rack_no: rObj.Rack_no,
                cover_path: this.status ? this.imageSrc : this.bookData[0].cover_path,
                remove_cover: this.imageSrc ? false : this.removeCover  
            }
            console.log(mData)
            this._aService.bookData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Book details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}