import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { PickUpModalComponent } from 'src/app/components/modal/pick-upmodal/pick-upmodal.component';
import * as moment from 'moment';
import { AddRouteModalComponent } from 'src/app/components/modal/addRoutemodal/addRoutemodal.component';

@Component({
  selector: 'app-transport-pick-up-routeList',
  templateUrl: './pick-up-routeList.component.html',
})

export class PickUpRouteListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public routeId : any
    public pickupRouteData : any[] = [];
    public pickUpRouteArray: any[] = [];
    public pickUpRouteList: any[] = [];
    public pickUpDeleteData: any[] = [];
    public mediumData: any[] = [];
    public routeData: any[] = [];
    public routeDeleteIds: any[] = [];
    public pickUpData: any[] = [];
    // public mediumData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _tService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Route: ['', Validators.required],
        // PickUpPoint: [''],
        // Distance: [''],
        // PickUpTime: [''],
        // MonthlyFee: [''],
        route_Data: this._fBuilder.array([]),
    })


    route_Data(): FormArray {
        return this.rObj.get("route_Data") as FormArray
    }

    newRoute_Data(): FormGroup {
        return this._fBuilder.group({
            Id: '',
            PickUpPoint: '', 
            Distance : '',
            PickupTime : moment().format("HH:mm"),
            MonthlyFees : '',
        })
    }

    addRoute_Data() {
        this.route_Data().push(this.newRoute_Data())
        console.log(this.route_Data())
    }

    removeRoute_Data( i: number, Id: any) {
        this.route_Data().removeAt(i);
        // this.routeDeleteIds.push(Id)
        // this.ttData(dayName).controls.value[i]
        // alert(this.routeDeleteIds)
    }
    ngOnInit(): void {

        this._gService.showLoader(true);
        this.routeId = this._aRoute.snapshot.paramMap.get('id');
        this.addRoute_Data()

        if(this.routeId) {
            // this.addRoute_Data()
            this.rObj.patchValue({
                Route : this.routeId,
            })
        
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route_pickup_points').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.pickupRouteData = JSON.parse(mRes.result);
                // this.pickUpRouteList = pickupRouteData.map((p : any) => p.reduce((result : any, item : any) => { result[item.route_id].push(item)}));
                this.pickUpRouteArray = [this.groupData(this.pickupRouteData, 'route_id')]
                this.pickUpRouteList = this.groupData(this.pickupRouteData, 'route_id')
                console.log(this.pickUpRouteList)
                console.log([this.pickUpRouteList].length)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'pickup_points').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.pickUpData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.routeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    groupData(input : any, key: string) {
        return input.reduce((acc : any, currentValue: any) => {
          let groupKey = currentValue[key];
          if (!acc[groupKey]) {
            acc[groupKey] = [];
          }
          acc[groupKey].push(currentValue);
          return acc;
        }, {});
    };

    getRouteName(routeId : any) {
        let routeNameData = this.pickupRouteData.filter(p => p.route_id == routeId).map(p => p.route_name)
        return [... new Set(routeNameData)].toString()
    }

    openNewPickUpRouteAddModal() {
        this.modalRef = this.modalService.show(AddRouteModalComponent, {
            class: 'modal-dialog-centered routepoint-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
            } 
        });
    }

    openPickUpRouteAddModal(pickUpRouteID: any) {
        this.modalRef = this.modalService.show(AddRouteModalComponent, {
            class: 'modal-dialog-centered routepoint-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                routeId: pickUpRouteID,
            } 
        });
    }

    deleteData(dataID: any, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    // savePoint() {
    //     if (this.rObj.valid) {
    //         let rObj = this.rObj.value;
    //         let routeData = this.rObj.controls.route_Data.controls;
    //         for (let i = 0; i < routeData.length; i++) {
    //             this.pickUpRouteArray.push(routeData[i].value)
    //         }
    //         this._router.navigate(['/transport/route-pickup-point/'+rObj.Route]);
    //         let mData = {
    //             inst_id: this.instID,
    //             route_id: rObj.Route,
    //             pickupData: this.pickUpRouteArray,
    //             del_ids : this.pickUpDeleteData
    //             // longitude: rObj.Longitude,
    //         }
    //         console.log(mData)
    //         this._tService.pickUpRouteData(mData).subscribe(aRes => {
    //             console.log(aRes)
    //             if (aRes.status === true) {
    //                 this._reRoute.shouldReuseRoute = () => false;
    //                 this._router.navigate([this._router.url]);
    //                 this._gService.triggerSnackbar('Pick Up Route Details Saved Successfully', 'success', true);
    //             } else {
    //                 this._gService.triggerSnackbar('Something went wrong', 'error', true);
    //             }
    //         }, err => {
    //             console.log(err);
    //             this._gService.triggerSnackbar('Server Error!', 'error', true);
    //         })
    //     }
    // }

}