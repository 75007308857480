import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { FeesService } from 'src/app/services/fees.service';
@Component({
    selector: 'app-feesMastermodal',
    templateUrl: './feesMastermodal.component.html',
    styleUrls: [],
})

export class FeesMastermodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public feesMasterID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public mediumID = this.userData.activeMedium;



    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public feesTypeData: any[] = [];
    public mediumData: any[] = [];
    public feesMasterData: any[] = [];
    public feesGroupData: any[] = [];
    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['',Validators.required],
        // Medium: ['', Validators.required],
        Fees_group: ['',Validators.required],
        Fees_type: ['',Validators.required],
        Amount: ['',Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.classData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.sectionData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesTypeData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.mediumData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_master').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesMasterData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_group').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesGroupData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)

                }
            })
            this._gService.showLoader(false);
        })




        this._gService.getDataByID(this.feesMasterID, 'fees_master').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                console.log(sData)
                this.rObj.patchValue({
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Fees_type: sData[0].fees_type,
                    Fees_group: sData[0].fees_group,
                    Amount: sData[0].amount,
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateFeesGroup() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.feesMasterID,
                class: rObj.Class,
                section: rObj.Section,
                medium: this.mediumID,
                fees_group :rObj.Fees_group,
                fees_type: rObj.Fees_type,
                amount: rObj.Amount,
            }
            this._aService.feesMasterData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Fees Type details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}