import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { FeesService } from 'src/app/services/fees.service';
import { SettingsService } from 'src/app/services/settings.service';
  
@Component({
    selector: 'app-printReceiptmodal',
    templateUrl: './printReceiptmodal.component.html',
    styleUrls: ['./printReceiptmodal.component.css'],
})

export class printReceiptModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public feeCollectData: any[] = [];
    public sectionData: any[] = [];
    public feeTypeData: any[] = [];
    public studentData: any[] = [];
    public feeGroupData: any[] = [];
    public feeMasterData: any[] = [];
    public statusData: any[] = [];
    public feeTypeNames: any;
    public feeTypeAmts: any;
    public TxnID: number;
    public FeeHeader: any;
    public FeeFooter: any;
    public imgURL = this._gService.getImgPath();

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _sService: SettingsService,
        public modalRef: BsModalRef,
        private _aRoute: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._sService.getMultipleSettingsData(this.instID, 'fee_header_img,fee_footer_content').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let cData = JSON.parse(mRes.result);
                console.log(cData)
                this.FeeHeader = cData[0].fee_header_img,
                this.FeeFooter = cData[0].fee_footer_content,
                console.log(this.FeeHeader)
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._aService.getCollectedFeesDataByTxnID(this.TxnID).subscribe( res => {
            console.log(JSON.parse(res.result))
            if(res.status === true){
                this.feeCollectData = JSON.parse(res.result);
                this.feeTypeNames = this.feeCollectData[0].fees_type_names.split(',')
                this.feeTypeAmts = this.feeCollectData[0].fees_amounts.split(',')
                console.log(this.feeTypeNames)
            }
            console.log(this.feeCollectData)
            this._gService.showLoader(false);
        })
    }

    stringToArray(text: string) {
        return text.split(',')
    }

    print1(){
        const originalContents = document.body.innerHTML;
        window.print();
    }

   
    
    print(){

            //const printContents : any =  document.querySelector(".modal-content-wrapper")?.innerHTML;
            //const originalContents = document.body.innerHTML;
            //document.body.innerHTML = originalContents
            window.print();
            // document.body.innerHTML = originalContents;
            // this._router.navigate([this._router.url])
    }

    
}

