import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor.service';
  
@Component({
    selector: 'app-inventoryPurchasemodal',
    templateUrl: './inventoryPurchasemodal.component.html',
    styleUrls: [],
})

export class InventoryPurchaseModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public purchaseID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public inventoryItemData: any[] = [];
    public vendorData: any[] = [];

    imageSrc:any = '';
    status:boolean = false;
    remove:boolean = false;
    public imgURL = this._gService.getImgPath();

    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Item_name:['',Validators.required],
        Vendors: ['', Validators.required],
        Qty: ['', Validators.required],
        Cost: ['', Validators.required],
        Date: ['', Validators.required],
        Mode: ['', Validators.required],
        Status: ['', Validators.required],
        Doc: [''],
        Description: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_item').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryItemData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vendor').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.vendorData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.purchaseID, 'inventory_purchase').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Item_name:sData[0].item_id,
                    Vendors: sData[0].vendor,
                    Qty: sData[0].qty,
                    Cost: sData[0].cost,
                    Date: sData[0].date,
                    Mode: sData[0].mode,
                    Status: sData[0].status,
                    Description: sData[0].description
                });
                this.imageSrc = sData[0].document ? this.imgURL+sData[0].document : '';
            }
            this._gService.showLoader(false);
        })
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.remove = true;
        this.imageSrc = '';
    }

    updatePurchase() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.purchaseID,
                item_id:rObj.Item_name,
                vendor: rObj.Vendors,
                cost: rObj.Cost,
                date: rObj.Date,
                status: rObj.Status,
                mode: rObj.Mode,
                qty: rObj.Qty,
                description: rObj.Description,
                purchase_doc: this.status ? this.imageSrc : '',
                remove_document: this.remove
            }
            this._aService.inventoryPurchaseData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Inventory Purchase Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}