import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { InstService } from './inst.service';

@Injectable({ 
    providedIn: 'root' 
})

export class CmsService {
  constructor(
    private _http: HttpClient,
    private _instService: InstService,
  ) { }

  private pages = `${environment.APIURL}/cms/pages/`;
  private mediaUpload = `${environment.APIURL}/cms/mediaUpload/`;
  private mediaMultiUpload = `${environment.APIURL}/cms/mediaMultiUpload/`;
  private pageData = `${environment.APIURL}/cms/getPageDataBySlug/`;
  private settingData = `${environment.APIURL}/cms/updateCmsSettings/`;
  private cmsSetting = `${environment.APIURL}/cms/getCmsSettingsByInstID/`;
  private cmsSettingBySlug = `${environment.APIURL}/cms/getInstSettingDataBySlug/`;
  private cmsSettingsBySlug = `${environment.APIURL}/cms/getCmsSettingsByInstSlug/`;
  private sendEmail = `${environment.APIURL}/cms/sendContactEmail/`;
  private menu = `${environment.APIURL}/cms/menu/`;
  private menuItem = `${environment.APIURL}/cms/menuItem/`;

  menuItemData(data: object): Observable<any> {
    return this._http.post(this.menuItem, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  menuData(data: object): Observable<any> {
    return this._http.post(this.menu, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  sendMail(data: object): Observable<any> {
    return this._http.post(this.sendEmail, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
 
  pagesData(data: object): Observable<any> {
    return this._http.post(this.pages, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getPageData(inst_id: number, slug: string): Observable<any> {
    return this._http.get(this.pageData+'?inst_id='+inst_id+'&slug='+slug, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  
  mediaUploadData(data: object): Observable<any> {
    return this._http.post(this.mediaUpload, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  mediaMultiUploadData(data: object): Observable<any> {
    return this._http.post(this.mediaMultiUpload, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  getSettingData(data: object): Observable<any> {
    return this._http.post(this.settingData, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  cmsSettingsData(instID: number): Observable<any> {
    return this._http.get(this.cmsSetting+'?inst_id='+instID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getCmsSettingBySlug(inst_slug: string, setting_name: string): Observable<any> {
    return this._http.get(this.cmsSettingBySlug+'?inst_slug='+inst_slug+'&setting_name='+setting_name, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getCmsSettingsBySlug(inst_slug: string): Observable<any> {
    return this._http.get(this.cmsSettingsBySlug+'?inst_slug='+inst_slug, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getInstSlug() {
    let current_url = window.location.href;
  
    if (current_url.indexOf('localhost') > -1) {
      return 'demoschool';
    } else {
      return this.getSubdomain(current_url) == 'app' || this.getSubdomain(current_url) == 'appuat' ? 'demoschool' : this.getSubdomain(current_url);
    }
  }
  
  getInstID(instSlug: any) {
    this._instService.getInstListBySlug(instSlug).subscribe(dataRes => {
      console.log(dataRes);
      if (dataRes.status === true) {
        let instData = JSON.parse(dataRes.result);
        return instData[0].ID;
      } else {
        return 'No data found';
      }
    })
  }
  
  getSubdomain(url: any) {
    let domain = url;
    if (url.includes("://")) {
        domain = url.split('://')[1];
    }
    const subdomain = domain.split('.')[0];
    return subdomain;
  };
  
  getInstSettingsData(instSlug: any) {
    this.getCmsSettingsBySlug(instSlug).subscribe(sRes => {
      console.log(sRes);
      if (sRes.status === true) {
        console.log(JSON.parse(sRes.result))
        return JSON.parse(sRes.result)[0];
      } else {
        return 'No data found';
      }
    })
  }
  
}