import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AcademicYearModalComponent } from '../../modal/academicyearmodal/academicyearmodal.component';
import { AdminService } from 'src/app/services/admin.service';
import { ChangeAdminPasswordModalComponent } from '../../modal/changeAdminPasswordmodal/changeAdminPassword.component';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})

export class AdminHeaderComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.AdminUserID;
    public userName = this.userData.AdminUserName;
    public userRole = this.userData.AdminUserRole;
    public userRoleName : any;
    public imageSrc : any;
    public imgURL = this._gService.getImgPath();
    public userRoleData : any[] = [];
    public adminUserData : any[] = [];
    public activeAcademicID = this.userData.AcademicID;

    public activeAcademicSession: string = '';

    public showNotificationsClass: boolean = false;
    public showUserInfoClass: boolean = false;
    
    modalRef: BsModalRef;

    @Input() sidebarStatus: any = false;
    @Output() showSidebar = new EventEmitter();  

    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private modalService: BsModalService,
    ) {}

    ngOnInit(): void {
        
        console.log(this.userData);
        let userData =  {
            user_id: this.userData.UserID,
            user_role: this.userData.UserRole,
        }
        this._adminService.getUserRoleData().subscribe(res => {
            console.log(res.result)
            if(res.status == true) {
              this.userRoleData = JSON.parse(res.result)
              console.log(this.userRoleData)
              this.userRoleName = this.userRoleData.filter(r => r.ID == this.userRole).map(r => r.name)
            }
          })
          this._adminService.getDataByID(this.userID, 'admin_users').subscribe(res => {
            console.log(res.result)
            if(res.status == true) {
              this.adminUserData = JSON.parse(res.result)
              console.log(this.adminUserData)
              this.imageSrc = this.adminUserData.filter(r => r.ID == this.userID).map(r => r.photo_path)
            }
          })

    }

    openChnagePasswordmodal() {
        this.modalRef = this.modalService.show(ChangeAdminPasswordModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
            } 
        });
    }

    toggleSidebar() {
        this.sidebarStatus = !this.sidebarStatus;
        this.showSidebar.emit(this.sidebarStatus);
    }

    @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
        event.stopPropagation();
        this.showNotificationsClass = false;
        this.showUserInfoClass = false;
      }

    showNotifications(event: Event) {
        event.stopPropagation()
        this.showUserInfoClass = false;
        this.showNotificationsClass = !this.showNotificationsClass;
    }

    showUserInfo(event: Event) {
        event.stopPropagation()
        this.showNotificationsClass = false;
        this.showUserInfoClass = !this.showUserInfoClass;
    }

    openAcademicYearsModal() {
        this.modalRef = this.modalService.show(AcademicYearModalComponent, {
            class: 'modal-dialog-top academic-year-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                activeAcademicID: this.activeAcademicID,
            } 
        });
    }

    logOut() {
        this._adminService.userLogout();
    }
}