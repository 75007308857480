<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-2">Edit Task</h1>
            <div class="header-msg col-md-6">
                            </div>
            <div class="btn-container link-container col-md-4">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateTask()">Update</button>
            </div>
        </div>

    
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="basic-details" class="panel panel-margin-top active">
                    <div class="panel-header">
                        <div class="panel-title">
                            Edit Task 
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Task</label>
                                    <small class="req"> *</small>
                                    <input type="text" formControlName="Task" class="form-control" >
                                </div>
                                <div class="col-md-4">
                                    <label>Task Date</label>
                                    <small class="req"> *</small>
                                    <input type="date" formControlName="Task_date" class="form-control date-input" >
                                </div>
                                <div class="col-md-4">
                                    <label>Due Date </label>
                                    <small class="req"> *</small>
                                    <input type="date" formControlName="Due_date" class="form-control date-input" >
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                            
                                <div class="col-md-4">
                                    <label>Status</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Status" class="form-select" >
                                    <option value="">Select</option>
                                    <option value="Open">Open</option>
                                    <option value="On Hold">On Hold </option>
                                    <option value="Resolved">Resolved</option>
                                    <option value="Closed">Closed</option>
                                    </select>                                
                                </div>
                                <div class="col-md-4">
                                    <label>Priority</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Priority" class="form-select">
                                    <option value="">Select the Option</option>
                                    <option value="Highest">Highest</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Lowest">Lowest</option>
                                    </select>                                
                                </div>
                                <div class="col-md-4">
                                    <label>User Type</label>
                                    <select formControlName="User_type" class="form-select" data-next="getSection" data-type="list" required="">
                                        <option value="">Select</option>
                                        <option value="student">Student</option>
                                        <option value="staff">Staff</option>
                                    </select>    
                                    <div *ngIf="rObj.controls.User_type.invalid && (rObj.controls.User_type.dirty || rObj.controls.User_type.touched)">
                                        <p class="input-error-msg">Please select user type</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="rObj.controls.User_type.value == 'staff'">
                            <div class="row">                       
                                    <div class="col-md-4">
                                        <label>Department
                                        <small class="req"> *</small></label>
                                        <select name="class" formControlName="Department" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option *ngFor="let dept of deparmentData" value="{{dept.ID}}">
                                            {{dept.name}}
                                            </option>         
                                        </select>
                                        <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                            <p class="input-error-msg">Please select Department</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Designation
                                        <small class="req"> *</small></label>
                                        <select name="class" formControlName="Designation" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option *ngFor="let des of designationData" value="{{des.ID}}">
                                            {{des.name}}
                                            </option>         
                                        </select>
                                        <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                            <p class="input-error-msg">Please select Department</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Staff Names</label>
                                        <small class="req"> *</small>
                                            <select name="class" formControlName="User_staff" class="form-select" multiple>
                                                <option value="">Please Select</option>
                                                <option *ngFor="let s of staffData" value="{{s.ID}}">
                                                {{s.full_name}}
                                                </option>         
                                            </select>
                                            <div *ngIf="rObj.controls.User_staff.invalid && (rObj.controls.User_staff.dirty || rObj.controls.User_staff.touched)">
                                                <p class="input-error-msg">Please enter User</p>
                                            </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="rObj.controls.User_type.value == 'student'">
                            <div class="row">  
                                    <div class="col-md-4">
                                        <label>Class
                                        <small class="req"> *</small></label>
                                        <select name="class" formControlName="Class" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                        </select>
                                        <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                            <p class="input-error-msg">Please select class</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Section
                                            <small class="req"> *</small></label>
                                            <select name="section" formControlName="Section" class="form-select" >
                                                <option value="">Please Select</option>
                                                <ng-container *ngFor="let section of classSectionData">
                                                    <option [value]="section.id">{{section.name}}</option>
                                                </ng-container>
                                            </select>
                                            <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                                <p class="input-error-msg">Please select section</p>
                                            </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Students Names</label>
                                            <small class="req"> *</small>
                                                <select name="class" formControlName="User_student" class="form-select" multiple>
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let s of studentListData" value="{{s.ID}}">
                                                    {{s.full_name}}
                                                    </option>         
                                                </select>
                                                <div *ngIf="rObj.controls.User_student.invalid && (rObj.controls.User_student.dirty || rObj.controls.User_student.touched)">
                                                    <p class="input-error-msg">Please enter User</p>
                                                </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12">
                                    <label>Description</label>
                                    <textarea class="form-control"  formControlName="Description"></textarea>
                                    <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                        <p class="input-error-msg">Please enter Description</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>