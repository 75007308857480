import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';  
@Component({
    selector: 'app-addEventmodal',
    templateUrl: './addEventmodal.component.html',
    // styleUrls: ['./addEventmodal.component.css'],
})

export class AddEventModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public eventID: number;

    public departmentData: any[] = [];
    public designationData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public eventData: any[] = [];
   

    constructor(
        private _gService: GlobalService,
        private _aService: EventService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        
    Event: ['', Validators.required],
    EventType: ['',Validators.required],
    UserType: ['',Validators.required],
    Class: [''],
    Section: [''],
    Department:[''],
    Designation:[''],
    Start_date:['',Validators.required],
    End_date:['',Validators.required],
    Description:[''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.eventID, 'events').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Event: sData[0].event_name,
                    EventType: sData[0].event_type_id,
                    UserType: sData[0].user_type,
                    Class: sData[0].class_id,
                    Section: sData[0].section_id,
                    Department:sData[0].department_id,
                    Designation: sData[0].designation_id,
                    Start_date: sData[0].start_date,
                    End_date:sData[0].end_date,
                    Description:sData[0].description,                 
                });
            }
            this._gService.showLoader(false);
        })
    

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.departmentData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.designationData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'event_type').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.eventData = JSON.parse(mRes.result);
                console.log(this.eventData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })

        // this._gService.getDataByID(this.eventID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    UpdateEvent() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.eventID, 
                event_type_id: rObj.EventType,
                event_name: rObj.Event,
                user_type: rObj.UserType,
                class_id: rObj.Class,
                section_id: rObj.Section,
                department_id: rObj.Department,
                designation_id: rObj.Designation,
                description: rObj.Description,
                start_date: rObj.Start_date,
                end_date: rObj.End_date
            }
            this._aService.addEventData(mData).subscribe(eRes => {
                console.log(eRes)
                this.modalRef.hide();
                if (eRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Event Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}