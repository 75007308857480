<div class="console-content">
        
                
    <div class="console-content-header row">
        <h1 class="title col-md-3">Staff Attendance</h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-4">
            <!--<a href="../../class/add/" class="content-header-link">Add Class</a>
            <a href="../../section/add/" class="content-header-link">Add Section</a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group" >
                            <label>Department
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Department" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let dept of departmentData" value="{{dept.ID}}">
                                {{dept.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Designation
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Designation" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let des of designationData" value="{{des.ID}}">
                                {{des.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Date
                            <small class="req"> *</small></label>
                            <input type="date" formControlName="Date" class="form-control">
                            <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                <p class="input-error-msg">Please select Date</p>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 24px;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="gotoSearch()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <form [formGroup]="sObj">
                        <button *ngIf="attendanceRow().controls.length" class="btn primary-btn small-btn" [disabled]="!sObj.valid" (click)="save()"  style="position: absolute; right: 20px; z-index: 9;">Save</button>
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="attendanceRow().controls.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Staff Id</th>
                                    <th>Staff Name</th>
                                    <th>Role</th>
                                    <th class="no-sort">Status</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="attendanceRow">
                            <ng-container *ngFor="let s of attendanceRow().controls.reverse(); let i=index">
                                <tr [formGroupName]="i">
                                    <td class="align-left">{{s.value.Staff_id}}</td>
                                    <td>{{s.value.Staff_name}}</td>
                                    <td>{{s.value.Role}}</td>
                                    <td class="action-btn">
                                        <label class="radio-inline" style="margin-right: 10px;">
                                            <input type="radio" formControlName="Status" value="present" autocomplete="off"> Present                                            
                                        </label>
                                        <label class="radio-inline" style="margin-right: 10px;">
                                            <input type="radio" formControlName="Status" value="absent" autocomplete="off"> Absent                                            
                                        </label>
                                        <label class="radio-inline" style="margin-right: 10px;">
                                            <input type="radio" formControlName="Status" value="halfday" autocomplete="off"> HalfDay                                            
                                        </label>
                                        <label class="radio-inline" style="margin-right: 10px;">
                                            <input type="radio" formControlName="Status" value="late" autocomplete="off"> Late                                            
                                        </label>
                                    </td>
                                    <td><input type="text" formControlName="Remark" class="form-control"></td>
                                </tr>
                                </ng-container>
                            </tbody>
                        </table>   
                        <div *ngIf="!attendanceRow().controls.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div> 
                    </form>
                </div>            
        
                            
            </div>
        </div>
    </div>
</div>