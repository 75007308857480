<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Edit Certificate</h1>
        <div class="link-container col-md-6">
            <a href="./certificate/list" class="content-header-link"> Certificate List</a>
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Edit Template
                    </div>
                </div>
                <div class="panel-body" >
                    <form [formGroup]="rObj">

                        <div class="form-group">
                            <label>Template Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Template_Name" class="form-control" >    
                            <div *ngIf="rObj.controls.Template_Name.invalid && (rObj.controls.Template_Name.dirty || rObj.controls.Template_Name.touched)">
                                <p class="input-error-msg">Please enter Template name</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Certificate Title Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Certificate_title" class="form-control" >    
                            <div *ngIf="rObj.controls.Certificate_title.invalid && (rObj.controls.Certificate_title.dirty || rObj.controls.Certificate_title.touched)">
                                <p class="input-error-msg">Please enter Certificate title name</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Certificate Sub-Title Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Certificate_sub_title" class="form-control" >    
                            <div *ngIf="rObj.controls.Certificate_sub_title.invalid && (rObj.controls.Certificate_sub_title.dirty || rObj.controls.Certificate_sub_title.touched)">
                                <p class="input-error-msg">Please enter Certificate title name</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>School Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="School_Name" class="form-control" >    
                            <div *ngIf="rObj.controls.School_Name.invalid && (rObj.controls.School_Name.dirty || rObj.controls.School_Name.touched)">
                                <p class="input-error-msg">Please enter School name</p>
                            </div>                          
                        </div>
                        <!-- <div class="form-group">
                            <label>Student Name</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Student_Name" class="form-control" >    
                            <div *ngIf="rObj.controls.Student_Name.invalid && (rObj.controls.Student_Name.dirty || rObj.controls.Student_Name.touched)">
                                <p class="input-error-msg">Please enter Student name</p>
                            </div>                          
                        </div> -->
                        <div class="form-group">
                            <label>Text</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Text" class="form-control" >    
                            <div *ngIf="rObj.controls.Text.invalid && (rObj.controls.Text.dirty || rObj.controls.Text.touched)">
                                <p class="input-error-msg">Please enter Text</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Course Name</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="text" formControlName="Course_name" class="form-control" >    
                            <div *ngIf="rObj.controls.Course_name.invalid && (rObj.controls.Course_name.dirty || rObj.controls.Course_name.touched)">
                                <p class="input-error-msg">Please enter Course name</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Background</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeBgImg()" *ngIf="bgimageSrc">Remove Image</div>  
                                <input type="file" formControlName="Background" class="form-control" (change)="onBgChange($event)" >
                            </div>
                            <div *ngIf="rObj.controls.Background.invalid && (rObj.controls.Background.dirty || rObj.controls.Background.touched)">
                                <p class="input-error-msg">Please select Background</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Presented Date</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="date" formControlName="Presented_date" class="form-control" >    
                            <div *ngIf="rObj.controls.Presented_date.invalid && (rObj.controls.Presented_date.dirty || rObj.controls.Presented_date.touched)">
                                <p class="input-error-msg">Please enter Presented Date</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Date issued</label>
                            <!-- <small class="req"> *</small> -->
                            <input type="date" formControlName="Date_issued" class="form-control" >    
                            <div *ngIf="rObj.controls.Date_issued.invalid && (rObj.controls.Date_issued.dirty || rObj.controls.Date_issued.touched)">
                                <p class="input-error-msg">Please enter Date issued</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Seal</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeSealImg()" *ngIf="sealimageSrc">Remove Image</div>  
                                <input type="file" formControlName="Seal" class="form-control" (change)="onSealChange($event)">    
                            </div>
                            <div *ngIf="rObj.controls.Seal.invalid && (rObj.controls.Seal.dirty || rObj.controls.Seal.touched)">
                                <p class="input-error-msg">Please enter Seal</p>
                            </div>                          
                        </div>
                        <div class="form-group">
                            <label>Sign</label>
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeSignImg()" *ngIf="signimageSrc">Remove Image</div>  
                                <input type="file" formControlName="Sign" class="form-control" (change)="onSignChange($event)" >
                            </div>
                            <div *ngIf="rObj.controls.Sign.invalid && (rObj.controls.Sign.dirty || rObj.controls.Sign.touched)">
                                <p class="input-error-msg">Please enter Sign</p>
                            </div>                          
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="UpdateTemplate()">Update</button>
                        </div>
                                
                    </form>
                </div>           
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Preview
                    </div>
                </div>
                <div class="panel-body">
                    <div class="template-priview-wrapper row">
                        <!-- <img class="template-preview" src="{{template.preview_path}}"> -->
                        <div class="col-md-12">
                            <div id="bg-logo"></div>
                            <div id="htmlPreview" [innerHTML]="certificate_html"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>  
</div>