<div class="console-content">
        
                
    <div class="console-content-header row">
        <h1 class="title col-md-3">Birthday List</h1>
        <div class="header-msg col-md-5">
                      </div>
        <!-- <div class="link-container col-md-4">
            <a href="../../class/add/" class="content-header-link">Add Class</a>
            <a href="../../section/add/" class="content-header-link">Add Section</a>
        </div> -->
    </div>
            
    <div class="row">
        <div class="col-md-12">
            <div class="console-content-navbar">
                <ul class="content-links inline">
                    <li class="content-link" [ngClass]="todayBirthdayTab ? 'active' : ''" (click)="setTabActive('todayBirthdayTab')">
                        <a id="today-birthday">
                            <span>Today's Birthday</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="birthdayListTab ? 'active' : ''" (click)="setTabActive('birthdayListTab')">
                        <a id="birthday-list">
                            <span>Birthday List</span>
                        </a>
                    </li>
                </ul>
                <button id="sendwish" class="btn extra-small-btn inline float-right ">Send Wishes</button>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="console-content-tab">
                
                <div id="today-birthday" class="panel panel-margin-top" [ngClass]="todayBirthdayTab ? 'active' : ''">
                    <div class="panel-body">
                        <ng-container *ngIf="birthdayData.length">
                            <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                                <span class="rows_selected">{{selectedIds.length}} Selected</span>
                                <button data-name="students" class="btn primary-btn extra-small-btn" (click)="sendMessage(selectedIds)">Send Wishes</button>
                            </div>
                        </ng-container>
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="birthdayData.length">
                            <thead>
                                <tr>
                                    <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                    <th>Sr. No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Roll Number</th>
                                    <th>Class</th>
                                    <th>Section</th>
                                    <th>Date of Birth</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of birthdayData ; let i = index" >
                                    <td>
                                        <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                    </td>
                                    <td class="align-left">{{i+1}}</td>
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.roll_no}}</td>
                                    <td>
                                        <ng-container *ngFor="let c of classData">
                                            <ng-container *ngIf="c.ID == s.class">
                                                {{c.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let c of sectionData">
                                            <ng-container *ngIf="c.ID == s.section">
                                                {{c.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.email}}</td>
                                    <td>{{s.mobile}}</td>
                                    <!-- <td class="action-btn">
                                        <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                        <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                        <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'student', 'delete student')"><i class="far fa-trash-alt"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!birthdayData.length">
                            <p>No data found</p>
                        </div>
                    </div> 
                </div>
                <div id="birthday-list" class="panel panel-margin-top "  [ngClass]="birthdayListTab ? 'active' : ''">
                    <div class="row">
                        <div class="col-md-12">
                                <div class="filter-options">
                                    <form [formGroup]="rObj">
                                        <div class="form-group">
                                            <label>Month<small class="req"> *</small></label>
                                            <select type="text" formControlName="Month" class="form-select" required="">
                                                <option>Select Month</option>
                                                <option value="1">January</option>
                                                <option value="2">February</option>
                                                <option value="3">March</option>
                                                <option value="4">April</option>
                                                <option value="5">May</option>
                                                <option value="6">June</option>
                                                <option value="7">July</option>
                                                <option value="8">August</option>
                                                <option value="9">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>                                              
                                            </select>
                                            <div *ngIf="rObj.controls.Month.invalid && (rObj.controls.Month.dirty || rObj.controls.Month.touched)">
                                                <p class="input-error-msg">Please select month</p>
                                            </div>
                                        </div>
                                        <div class="form-group btn-container">
                                            <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="search()">Search</button>
                                        </div>
                                    </form>
                                </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentData.length">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Roll Number</th>
                                    <th>Class</th>
                                    <th>Section</th>
                                    <th>Date of Birth</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentData ; let i = index">
                                    <td class="align-left">{{i+1}}</td>
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.roll_no}}</td>
                                    <td>
                                        <ng-container *ngFor="let c of classData">
                                            <ng-container *ngIf="c.ID == s.class">
                                                {{c.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let c of sectionData">
                                            <ng-container *ngIf="c.ID == s.section">
                                                {{c.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.email}}</td>
                                    <td>{{s.mobile}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div> 
                                   
                </div>
                
            </div>

        </div>
    </div>
</div>