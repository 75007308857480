import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class AttendanceService {
  constructor(
    private _http: HttpClient,
  ) { }

//   private student = `${environment.APIURL}/student/addUpdate/`;
  private studentAttendance = `${environment.APIURL}/attendance/getStudentAttendanceByClassSectionDate/`;
  private staffAttendance = `${environment.APIURL}/attendance/getStaffAttendanceByDepartmentDesignationDate/`;
  private saveAttendance = `${environment.APIURL}/attendance/studentAttendance/`;
  private saveStaffAttendance = `${environment.APIURL}/attendance/staffAttendance/`;
  

//   studentData(data: object): Observable<any> {
//     return this._http.post(this.student, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
  
  AttendencebyClassSectionDate(instID: number, academicID: number, classID: any, section: any, date: any): Observable<any> {
    return this._http.get(this.studentAttendance+'?inst_id='+instID+'&academic_id='+academicID+'&class='+classID+'&section='+section+'&date='+date, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  AttendencebydeptDesDate(instID: number, academicID: number, deptID: any, des: any, date: any): Observable<any> {
    return this._http.get(this.staffAttendance+'?inst_id='+instID+'&academic_id='+academicID+'&department='+deptID+'&designation='+des+'&date='+date, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  saveStudentAttendanceData(data : any){
    return this._http.post(this.saveAttendance, data,{
      headers: { 'Content-Type': 'application/json' }
    })
  }

  saveStaffAttendanceData(data : any){
    return this._http.post(this.saveStaffAttendance, data,{
      headers: { 'Content-Type': 'application/json' }
    })
  }
//   birthdayData(instID: number, academicID: number, dataFor: any): Observable<any> {
//     return this._http.get(this.birthday+'?inst_id='+instID+'&academic_id='+academicID+'&dataFor='+dataFor, {
//       headers: { 'Content-Type': 'application/json' }
//     })
//   }

 
}