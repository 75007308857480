import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'website-default-footer',
  templateUrl: './default-footer.component.html',
})

export class WebsiteDefaultFooterComponent implements OnInit {
    
    public pageSlug: any;
    public pageData: any[] = [];
    
    constructor(
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _cmsService: CmsService
    ) {}

    ngOnInit(): void {

        

    }

}