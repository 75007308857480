<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Print Header Footer</h1>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="console-content-navbar">
                    <ul class="content-links">
                        <li class="content-link" [ngClass]="feeReceiptTab ? 'active' : ''" (click)="setTabActive('feeReceiptTab')">
                            <a id="basic-details">
                                <span>Fee Receipt</span>
                            </a>
                        </li>
                        <li class="content-link" [ngClass]="paySlipTab ? 'active' : ''" (click)="setTabActive('paySlipTab')">
                            <a id="address-details">
                                <span>Payslip</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="fee-details" class="panel panel-margin-top" [ngClass]="feeReceiptTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                             Header Image (2230px X 300px) 
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">                         
                            <div class="header-img-wrapper">
                                <img class="photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/header.jpg'">
                            <span class="del-btn" *ngIf="imageSrc" (click)="removeImg()"><i class="far fa-trash-alt"></i></span>

                            </div>
                            <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                <button class="upload-btn">Select Photo</button>
                                <input type="file" (change)="onFileChange($event)">
                            </div>
                                   
                        </div>
                        <div class="form-group header-content">
                            <label>Content</label>
                            <angular-editor [config]="editorConfig" formControlName="Fee_Content" class="form-control" ></angular-editor>
                            <!-- <textarea formControlName="Content" class="form-control" rows="15" required="" style="height: 10%;"></textarea> -->
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="saveFeeReceipt()">Save</button>
                        </div>
                    </div>
                </div>
                
                <div id="payslip-details" class="panel panel-margin-top" [ngClass]="paySlipTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                             Header Image (2230px X 300px) 
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">                         
                            <div class="header-img-wrapper">
                                <img class="photo-preview" [src]="image2Src ? image2Src : '/assets/img/header.jpg'">
                            <span class="del-btn" *ngIf="image2Src" (click)="remove2Img()"><i class="far fa-trash-alt"></i></span>

                            </div>
                            <div class="upload-btn-wrapper" *ngIf="!image2Src">
                                <button class="upload-btn">Select Photo</button>
                                <input type="file" (change)="onFile2Change($event)">
                            </div>
                                   
                        </div>
                        <div class="form-group header-content">
                            <label>Content</label>
                            <angular-editor [config]="editorConfig" formControlName="Payslip_Content" class="form-control"></angular-editor>
                        </div>
                        <div class="form-btn-wrapper">
                            <!-- <a href="../student/import/" class="content-header-link">Import Students</a> -->
                                <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="savePayReceipt()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </form>
        
</div>