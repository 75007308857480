import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';

@Injectable({ 
    providedIn: 'root' 
})

export class UserService {
  constructor(
    private _http: HttpClient,
    private _gService: GlobalService,
    private _router: Router
  ) { }

  private checkLogin = `${environment.APIURL}/user/login/`;
  private userData = `${environment.APIURL}/user/userData/`;
  private instData = `${environment.APIURL}/user/instData/`;
  private editData = `${environment.APIURL}/user/updateUser/`;
  private changepassword = `${environment.APIURL}/user/changePassword/`;
  private getAllUserRole = `${environment.APIURL}/user/getAllUserRole/`;
  private getDetails = `${environment.APIURL}/user/getUserDataByID/`;
  private getUserIdsDetails = `${environment.APIURL}/user/getUserIDsByUserRole/`;

  getUserIdsDetailsData(inst_id: any, user_role: any): Observable<any> {
    return this._http.get(this.getUserIdsDetails+'?inst_id='+inst_id+'&user_role='+user_role, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  getDetailsData(userID: any): Observable<any> {
    return this._http.get(this.getDetails+'?user_id='+userID, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  ChnagePasswordData(rObj: object): Observable<any> {
    return this._http.post(this.changepassword, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  EditUserData(rObj: object): Observable<any> {
    return this._http.post(this.editData, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  userLoginCheck(rObj: object): Observable<any> {
    return this._http.post(this.checkLogin, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getUserDataByID(rObj: object): Observable<any> {
    return this._http.post(this.userData, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getAllUserROleData(): Observable<any> {
    return this._http.get(this.getAllUserRole, {
      headers: { 'Content-Type': 'application/json' }
    });
  }


  private getUserTypes = `${environment.APIURL}/api/GetUserTypeList/{UserTypeID}`;
  private getGender = `${environment.APIURL}/api/GetGender_List/{GenderID}`;
  private AuthToken = `https://api.healthieu.in/api/Authenticate/AuthLogin`;
  private AuthTokenLink = `${environment.APIURL}/Authenticate/Login`;
  private registerRetailClient = `${environment.APIURL}/RegisterClient`;
  private getUserList = `${environment.APIURL}/api/GetUserMasterList/`;
  private getUserListONmail = `${environment.APIURL}/api/GetUserMasterListONEmail/`;
  private UserOTPVerify = `${environment.APIURL}/api/UserOTPVerify/`;
  private getUser = `${environment.APIURL}/api/GetUserMaster_Search/`;
  private saveUser = `${environment.APIURL}/api/SaveUser/`;
  private updateSaveUser = `${environment.APIURL}/api/PartialSaveUserMaster/`;
  private updateDependentUser = `${environment.APIURL}/api/PartialDependentUpdateUserMaster/`;
  private deleteSavedUser = `${environment.APIURL}/api/DeleteUser/`;
  private resetUserPassword = `${environment.APIURL}/api/PartialPasswordSaveUserMaster/`;
  private getOTP = `${environment.APIURL}/api/Login/GetOTP`;
  private verifyOTP = `${environment.APIURL}/api/Login/VerifyOTP`;
  
  getUserTypeList(): Observable<any> {
    return this._http.get(this.getUserTypes, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getGenderList(): Observable<any> {
    return this._http.get(this.getGender, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  userLoginCheckGet(): Observable<any> {
    return this._http.get(this.getUserList, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  userOTPVerify(rObj: object): Observable<any> {
    return this._http.post(this.UserOTPVerify, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getUserByID(rObj: object): Observable<any> {
    return this._http.post(this.getUserList, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  
  getUsersByEmpID(empID: number): Observable<any> {
    return this._http.get(this.getUser + 'Employee_Id/' + empID, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getUserMobileData(mobileNo: number): Observable<any> {
    return this._http.get(this.getUser + 'MobileNumber/' + mobileNo, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getAuthTokenForRegister(): Observable<any> {
    return this._http.post<any>(this.AuthTokenLink,
      {
        UserName: 'VvhV1oW/lnb25qf+Df+Wfg==\n',
        Password: 'aPlMSpyWul04jqsEneBDYA==\n',
      }, { headers: { 'Content-Type': 'application/json' } });
  }
  
  registerRetail(rObj: object, token: object): Observable<any> {
    return this._http.post(this.registerRetailClient, rObj, {
      headers: { 'Content-Type': 'application/json', ...token }
    });
  }

  registerUser(rObj: object): Observable<any> {
    return this._http.post(this.saveUser, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  updateUser(rObj: object): Observable<any> {
    return this._http.post(this.updateSaveUser, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  updateDependent(rObj: object): Observable<any> {
    return this._http.post(this.updateDependentUser, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  deleteUser(userID: number): Observable<any> {
    return this._http.post(this.deleteSavedUser + userID, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  resetPassword(rObj: object): Observable<any> {
    return this._http.post(this.resetUserPassword, rObj, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getOtp(rObj: object): Observable<any> {
    return this._http.post(this.getOTP, rObj, {
      headers: { 'Content-Type': 'application/json', }
    });
  }

  verifyOtp(rObj: object): Observable<any> {
    return this._http.post(this.verifyOTP, rObj, {
      headers: { 'Content-Type': 'application/json', }
    });
  }

  // getUserData() {
  //   let userRemebered = localStorage.getItem('isUserRemembered');
  //   if (userRemebered == 'yes') {
  //       return JSON.parse(localStorage.getItem('currentUser')!);    
  //   } else {
  //       return JSON.parse(sessionStorage.getItem('currentUser')!);
  //   }
  // }

  userLogout() {
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentSession');
    this._router.navigate(['/user/login']);
    this._gService.triggerSnackbar('Logout Successfully.', 'success', true);
  }

}