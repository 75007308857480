import { Component, ElementRef} from '@angular/core';

@Component({
  selector: 'adminpanel-wrapper',
  templateUrl: './adminpanel.component.html',
})

export class AdminPanelComponent {

  public sidebarStatus = '';

  constructor(
    private el: ElementRef
  ) {}

  toggleSidebar(sidebarStatus: any) {
    console.log(sidebarStatus)
    this.sidebarStatus = sidebarStatus;
    let div = this.el.nativeElement.querySelector('.console-content')
    if (this.sidebarStatus) {
      div.classList.add('expand')
    } else {
      div.classList.remove('expand')
    }
  }

}