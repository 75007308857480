<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Enroll Student</h1>
            <div class="btn-container link-container col-md-6">
                <!-- <a href="../student/import/" class="content-header-link">Import Students</a> -->
                    <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="saveStudent()">Save</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="console-content-navbar">
                    <ul class="content-links">
                        <li class="content-link" [ngClass]="basicTab ? 'active' : ''" (click)="setTabActive('basicTab')">
                            <a id="basic-details">
                                <i class="fas fa-user"></i>
                                <span>Basic Details</span>
                            </a>
                        </li>
                        <li class="content-link" [ngClass]="addressTab ? 'active' : ''" (click)="setTabActive('addressTab')">
                            <a id="address-details">
                                <i class="fas fa-map-marker-alt"></i>
                                <span>Address Details</span>
                            </a>
                        </li>
                        <li class="content-link" [ngClass]="parentTab ? 'active' : ''" (click)="setTabActive('parentTab')">
                            <a id="parent-guardian-details">
                                <i class="fas fa-user-tag"></i>
                                <span>Parent Guardian Details</span>
                            </a>
                        </li>
                        <li class="content-link" [ngClass]="documentTab ? 'active' : ''" (click)="setTabActive('documentTab')">
                            <a id="documents">
                                <i class="fas fa-file-alt"></i>
                                <span>Documents</span>
                            </a>
                        </li>
                        <li class="content-link" [ngClass]="siblingsTab ? 'active' : ''" (click)="setTabActive('siblingsTab')">
                            <a id="sibling-details">
                                <i class="fas fa-user-plus"></i>
                                <span>Sibling Details</span>
                            </a>
                        </li>
                        
                        <li class="content-link" [ngClass]="otherTab ? 'active' : ''" (click)="setTabActive('otherTab')">
                            <a id="other-details">
                                <i class="fas fa-folder-plus"></i>
                                <span>Other Details</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="basic-details" class="panel panel-margin-top" [ngClass]="basicTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Official Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Academic Year</label>
                                    <small class="req"> *</small>
                                    <input type="text" class="form-control" value={{activeAcademicSession}} disabled>
                                </div>
                                <div class="col-md-4">
                                    <label>Register Number</label>
                                    <small class="req"> *</small>
                                    <input type="text" formControlName="Register_no" class="form-control" >
                                    <div *ngIf="rObj.controls.Register_no.invalid && (rObj.controls.Register_no.dirty || rObj.controls.Register_no.touched)">
                                        <p class="input-error-msg">Please enter register number</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Admission Date</label>
                                    <small class="req"> *</small>
                                    <input type="date" formControlName="Admission_date" class="form-control" >
                                    <div *ngIf="rObj.controls.Admission_date.invalid && (rObj.controls.Admission_date.dirty || rObj.controls.Admission_date.touched)">
                                        <p class="input-error-msg">Please select admission date</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Class</label>
                                    <small class="req"> *</small>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Section</label>
                                    <small class="req"> *</small>
                                    <select name="section" formControlName="Section" class="form-select">
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let section of classSectionData">
                                            <option [value]="section.id">{{section.name}}</option>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                        <p class="input-error-msg">Please select section</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Roll No.</label>
                                    <small class="req"> *</small>
                                    <input type="number" formControlName="Roll_no" class="form-control">
                                    <div *ngIf="rObj.controls.Roll_no.invalid && (rObj.controls.Roll_no.dirty || rObj.controls.Roll_no.touched)">
                                        <p class="input-error-msg">Please enter roll number</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-header padding-top-none">
                        <div class="panel-title">
                            Personal Details:
                        </div>
                    </div>
                    <div class="panel-body row">
                        <div class="col-md-10">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>First Name</label>
                                        <small class="req"> *</small>
                                        <input type="text" formControlName="First_name" class="form-control">
                                        <div *ngIf="rObj.controls.First_name.invalid && (rObj.controls.First_name.dirty || rObj.controls.First_name.touched)">
                                            <p class="input-error-msg">Please enter first name</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Middle Name</label>
                                        <small class="req"> *</small>
                                        <input type="text" formControlName="Middle_name" class="form-control">
                                        <div *ngIf="rObj.controls.Middle_name.invalid && (rObj.controls.Middle_name.dirty || rObj.controls.Middle_name.touched)">
                                            <p class="input-error-msg">Please enter middle name</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Last Name</label>
                                        <small class="req"> *</small>
                                        <input type="text" formControlName="Last_name" class="form-control">
                                        <div *ngIf="rObj.controls.Last_name.invalid && (rObj.controls.Last_name.dirty || rObj.controls.Last_name.touched)">
                                            <p class="input-error-msg">Please enter last name</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Date of Birth</label>
                                        <small class="req"> *</small>
                                        <input type="date" formControlName="Birth_date" class="form-control">
                                        <div *ngIf="rObj.controls.Birth_date.invalid && (rObj.controls.Birth_date.dirty || rObj.controls.Birth_date.touched)">
                                            <p class="input-error-msg">Please select birth date</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Gender</label>
                                        <small class="req"> *</small>
                                        <select formControlName="Gender" class="form-select">
                                            <option value="">Please Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <div *ngIf="rObj.controls.Gender.invalid && (rObj.controls.Gender.dirty || rObj.controls.Gender.touched)">
                                            <p class="input-error-msg">Please select gender</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Blood Group</label>
                                        <small class="req"> *</small>
                                        <select formControlName="Blood_Group" class="form-select">
                                            <option value="">Please Select</option>
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>                                  
                                        </select>
                                        <div *ngIf="rObj.controls.Blood_Group.invalid && (rObj.controls.Blood_Group.dirty || rObj.controls.Blood_Group.touched)">
                                            <p class="input-error-msg">Please select blood group</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Mobile Number</label>
                                        <small class="req"> *</small>
                                        <input type="number" formControlName="Mobile_no" class="form-control">
                                        <div *ngIf="rObj.controls.Mobile_no.invalid && (rObj.controls.Mobile_no.dirty || rObj.controls.Mobile_no.touched)">
                                            <p class="input-error-msg">Please enter valid mobile number</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Email</label>
                                        <small class="req"> *</small>
                                        <input type="email" formControlName="Email" class="form-control">
                                        <div *ngIf="rObj.controls.Email.invalid && (rObj.controls.Email.dirty || rObj.controls.Email.touched)">
                                            <p class="input-error-msg">Please enter email</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Aadhaar Number</label>
                                        <input type="number"formControlName="Adhar_no" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Birth Place</label>
                                        <input type="text" formControlName="Birth_place" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                    <label>Nationality</label>
                                        <select formControlName="Nationality" class="form-select">
                                            <ng-container *ngFor="let data of countriesData">
                                                <option>
                                                    {{data.nationality}}
                                                </option>
                                            </ng-container>
                                        </select>   
                                    </div>
                                    <div class="col-md-4">
                                        <label>Mother Tongue</label>
                                        <input type="text" formControlName="Mother_tongue" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Category</label>
                                        <select formControlName="Category" class="form-select">
                                            <option value="">Select Category</option>
                                            <option value="General">General</option>
                                            <option value="OBC">OBC</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Religion</label>
                                        <input type="text" formControlName="Religion" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Caste</label>
                                        <input type="text" formControlName="Caste" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                    <label>Student Photo</label>
                                    <div class="form-img-wrapper">
                                        <img class="student-photo photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/default-user-avatar.png'">
                                    </div>
                                    <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                        <button class="upload-btn">Select Photo</button>
                                        <input type="file" formControlName="PhotoUpload" id="photoUpload" (change)="onFileChange($event)">
                                    </div>
                                    <div class="upload-btn-wrapper" *ngIf="imageSrc">
                                        <button class="upload-btn" (click)="removeImg()">Remove Photo</button>
                                    </div>
                                    
                                </div>
                        </div>
                    </div>
                </div>
                
                <div id="address-details" class="panel panel-margin-top" [ngClass]="addressTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Address Details:
                        </div>
                    </div>
                    <div class="panel-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Permanent Address</label>
                                        <textarea formControlName="Permanent_address" class="form-control"></textarea>
                                        <div *ngIf="rObj.controls.Permanent_address.invalid && (rObj.controls.Permanent_address.dirty || rObj.controls.Permanent_address.touched)">
                                            <p class="input-error-msg">Please enter </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Current Address</label>
                                        <textarea formControlName="Current_address" class="form-control"></textarea>
                                        <div *ngIf="rObj.controls.Current_address.invalid && (rObj.controls.Current_address.dirty || rObj.controls.Current_address.touched)">
                                            <p class="input-error-msg">Please enter </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>City</label>
                                        <input type="text" formControlName="City" class="form-control">
                                        <div *ngIf="rObj.controls.City.invalid && (rObj.controls.City.dirty || rObj.controls.City.touched)">
                                            <p class="input-error-msg">Please enter </p>
                                        </div>
                                </div>
                                    <div class="col-md-4">
                                        <label>Pincode</label>
                                        <input type="number" formControlName="Pincode" class="form-control">
                                        <div *ngIf="rObj.controls.Pincode.invalid && (rObj.controls.Pincode.dirty || rObj.controls.Pincode.touched)">
                                            <p class="input-error-msg">Please enter </p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>State</label>
                                        <input type="text" formControlName="State" class="form-control">
                                        <div *ngIf="rObj.controls.State.invalid && (rObj.controls.State.dirty || rObj.controls.State.touched)">
                                            <p class="input-error-msg">Please enter </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Country</label>
                                        <select formControlName="Country" class="form-select">
                                            <option value="">Select Country</option>
                                            <ng-container  *ngFor="let country of countriesData">
                                            <option [value]="country.name">
                                                {{country.name}}
                                            </option>
                                            </ng-container>
                                                <div *ngIf="rObj.controls.Country.invalid && (rObj.controls.Country.dirty || rObj.controls.Country.touched)">
                                                        <p class="input-error-msg">Please enter </p>
                                                </div>
                                        </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                
                <div id="parent-guardian-details" class="panel panel-margin-top" [ngClass]="parentTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Parent Guardian Details:
                        </div>
                    </div>
                        <div class="panel-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Father Name</label>
                                        <input type="text" formControlName="Father_name" class="form-control">
                                        <div *ngIf="rObj.controls.Father_name.invalid && (rObj.controls.Father_name.dirty || rObj.controls.Father_name.touched)">
                                            <p class="input-error-msg">Please enter name</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Father Mobile Number</label>
                                        <input type="number" formControlName="Father_mobile" class="form-control">
                                        <div *ngIf="rObj.controls.Father_mobile.invalid && (rObj.controls.Father_mobile.dirty || rObj.controls.Father_mobile.touched)">
                                            <p class="input-error-msg">Please enter valid mobile number </p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Father Occupation</label>
                                        <input type="text"formControlName="Father_occupation" class="form-control">
                                        <div *ngIf="rObj.controls.Father_occupation.invalid && (rObj.controls.Father_occupation.dirty || rObj.controls.Father_occupation.touched)">
                                            <p class="input-error-msg">Please enter occupation </p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Father Photo</label>
                                        <input type="file" formControlName="Father_photo" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Mother Name</label>
                                        <input type="text" formControlName="Mother_name" class="form-control">
                                        <div *ngIf="rObj.controls.Mother_name.invalid && (rObj.controls.Mother_name.dirty || rObj.controls.Mother_name.touched)">
                                            <p class="input-error-msg">Please enter name</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Mother Mobile Number</label>
                                        <input type="number" formControlName="Mother_mobile" class="form-control">
                                        <div *ngIf="rObj.controls.Mother_mobile.invalid && (rObj.controls.Mother_mobile.dirty || rObj.controls.Mother_mobile.touched)">
                                            <p class="input-error-msg">Please enter valid mobile number</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Mother Occupation</label>
                                        <input type="text" formControlName="Mother_occupation" class="form-control">
                                        <div *ngIf="rObj.controls.Permanent_address.invalid && (rObj.controls.Permanent_address.dirty || rObj.controls.Permanent_address.touched)">
                                            <p class="input-error-msg">Please enter occupation</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Mother Photo</label>
                                        <input type="file"formControlName="Mother_photo" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>If Guardian Is</label>
                                            <label class="radio-inline" style="margin-right: 18px;">
                                                <input type="radio" formControlName="Guardian_is" value="father" autocomplete="off"> Father                                            
                                            </label>
                                            <label class="radio-inline" style="margin-right: 18px;">
                                                <input type="radio" formControlName="Guardian_is" value="mother" autocomplete="off"> Mother                                            
                                            </label>
                                            <label class="radio-inline" style="margin-right: 18px;">
                                                <input type="radio" formControlName="Guardian_is" value="other" autocomplete="off"> Other                                            
                                            </label>
                                            <span class="text-danger"></span>
                                            <!-- <div *ngIf="rObj.controls.Permanent_address.invalid && (rObj.controls.Permanent_address.dirty || rObj.controls.Permanent_address.touched)">
                                                <p class="input-error-msg">Please enter </p>
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- remaining -->
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Guardian Name</label>
                                        <input type="text" formControlName="Guardian_name" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Relation</label>
                                        <input type="text" formControlName="Guardian_relation" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Mobile Number</label>
                                        <input type="number" formControlName="Guardian_number" class="form-control">
                                            <div *ngIf="rObj.controls.Guardian_number.invalid && (rObj.controls.Guardian_number.dirty || rObj.controls.Guardian_number.touched)">
                                                <p class="input-error-msg">Please enter name</p>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Guardian Email</label>
                                        <input type="text" formControlName="Guardian_email" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Occupation</label>
                                        <input type="text" formControlName="Guardian_occupation" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Education</label>
                                        <input type="text" formControlName="Guardian_education" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Guardian Income (Annual)</label>
                                        <input type="number" formControlName="Guardian_income" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Photo</label>
                                        <input type="file" formControlName="Guardian_photo" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Guardian Address</label>
                                        <textarea formControlName="Guardian_address" class="form-control"></textarea>
                                    </div>
                                    
                                </div>
                            </div>
                    </div>
                </div>
                
                <div id="documents" class="panel panel-margin-top" [ngClass]="documentTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Documents:
                        </div>
                    </div>
                    <div class="panel-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Document Title</label>
                                        <input id="docTitle1" type="text" formControlName="Document1_title" class="form-control docTitle" placeholder="Document 1" value="">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Upload Document</label>
                                        <div class="imgUpload-wrapper">
                                            <div class="removeImgOverlay" (click)="removeDocument1()" *ngIf="file1Src">Remove Document</div>  
                                            <input type="file" formControlName="Document1_file" class="form-control" (change)="onDoc1FileChange($event)">
                                        </div>
                                        <!-- <input id="docFile1" data-number="1" type="file" formControlName="Document1_upload" class="form-control docFile"> -->
                                    </div>
                                    <div class="col-md-3">
                                        <label>Document Title</label>
                                        <input id="docTitle2" type="text" formControlName="Document2_title" class="form-control docTitle" placeholder="Document 2" value="">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Upload Document</label>
                                        <div class="imgUpload-wrapper">
                                            <div class="removeImgOverlay" (click)="removeDocument2()" *ngIf="file2Src">Remove Document</div>  
                                            <input type="file" formControlName="Document2_file" class="form-control" (change)="onDoc2FileChange($event)">
                                        </div>
                                        <!-- <input id="docFile2" data-number="2" type="file" formControlName="Document2_upload" class="form-control docFile"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <input id="docTitle3" type="text" formControlName="Document3_title" class="form-control docTitle" placeholder="Document 3" value="">
                                    </div>
                                    <div class="col-md-3">
                                        <div class="imgUpload-wrapper">
                                            <div class="removeImgOverlay" (click)="removeDocument3()" *ngIf="file3Src">Remove Document</div>  
                                            <input type="file" formControlName="Document3_file" class="form-control" (change)="onDoc3FileChange($event)">
                                        </div>
                                        <!-- <input id="docFile3" data-number="3" type="file" formControlName="Document3_upload" class="form-control docFile"> -->
                                    </div>
                                    <div class="col-md-3">
                                        <input id="docTitle4" type="text"formControlName="Document4_title" class="form-control docTitle" placeholder="Document 4" value="">
                                    </div>
                                    <div class="col-md-3">
                                        <div class="imgUpload-wrapper">
                                            <div class="removeImgOverlay" (click)="removeDocument4()" *ngIf="file4Src">Remove Document</div>  
                                            <input type="file" formControlName="Document4_file" class="form-control" (change)="onDoc4FileChange($event)">
                                        </div>
                                        <!-- <input id="docFile4" data-number="4" type="file" formControlName="Document4_upload" class="form-control docFile"> -->
                                    </div>
                                </div>
                            </div>
                            
                    </div>
                </div>
                
                <div id="sibling-details" class="panel panel-margin-top" [ngClass]="siblingsTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Siblings Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4 sibling-search-container">
                                        <ng-autocomplete 
                                            [data]="searchData"
                                            [searchKeyword]="'name'"
                                            placeholder="Search..."
                                            (selected)='selectEvent($event)'
                                            (inputChanged)='onChangeSearch($event)'
                                            (inputFocused)='onFocused($event)'
                                            [itemTemplate]="itemTemplate"
                                            [notFoundTemplate]="notFoundTemplate">                                 
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="searchItem(item)"></a>
                                        </ng-template>
                
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                        <!-- <input type="text" class="form-control"> -->
                                        <!-- <input type="text" class="form-control sibling-search tt-hint" value="" readonly="" autocomplete="off" spellcheck="false" tabindex="-1" dir="ltr" style="position: absolute; top: 0px; left: 0px; border-color: transparent; box-shadow: none; opacity: 1; background: none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255);"><input id="sibling_search" type="text" formControlName="Sibling_search" class="form-control sibling-search tt-input" placeholder="Search for Sibling" value="" autocomplete="off" spellcheck="false" dir="auto" style="position: relative; vertical-align: top; background-color: transparent;">
                                        <pre aria-hidden="true" style="position: absolute; visibility: hidden; white-space: pre; font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; word-spacing: 0px; letter-spacing: 0px; text-indent: 0px; text-rendering: auto; text-transform: none;"></pre><div class="tt-menu" style="position: absolute; top: 100%; left: 0px; z-index: 100; display: none;"><div class="tt-dataset tt-dataset-results"></div></div></span> -->
                                
                                </div>
                                <div class="col-md-8 sibling-data-container">
                                    <ng-container *ngIf="siblingData.length">
                                        <div data-id="23" class="sibling-data" *ngFor="let sibling of siblingData">
                                            <img src="{{sibling.photo ? imgURL+sibling.photo : '/assets/img/default-user-avatar.png'}}">
                                            <h3 class="name">{{sibling.name}} - {{sibling.class_name}} ({{sibling.section_name}})</h3> 
                                            <span class="remove-link"  (click)="delSibling(sibling.ID)">
                                                <i class="fas fa-times"></i>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="other-details" class="panel panel-margin-top" [ngClass]="otherTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Previous Qualification Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>School Name</label>
                                    <input type="text" formControlName="previous_school_name" class="form-control" value="">
                                </div>
                                <div class="col-md-4">
                                    <label>School Address</label>
                                    <textarea formControlName="previous_school_address" class="form-control" value=""></textarea>
                                </div>
                                <div class="col-md-4">
                                    <label>Qualification</label>
                                    <input type="text" formControlName="previous_qualification" class="form-control" value="">
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div class="panel-header">
                        <div class="panel-title">
                            Transport Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Route List</label>
                                    <select formControlName="Route_id" class="form-select" data-next="getSection" data-type="options">
                                        <option value="">Select</option>
                                        <option *ngFor="let route of routeData" value="{{route.ID}}">{{route.route_name}}</option>                                    
                                    </select>									
                                </div>
                                <div class="col-md-4">
                                    <label>Pickup Point</label>
                                    <select formControlName="Pickup_Point" class="form-select" data-next="getSection" data-type="options">
                                        <option value="">Select Point</option>
                                        <option *ngFor="let pick of pickupPointData" value="{{pick.ID}}">{{pick.name}}</option>                                    
                                    </select>									
                                </div>
                                <div class="col-md-4">
                                    <label>Fees Month</label>
                                    <select formControlName="Fees_Month" class="form-select" data-next="getSection" data-type="options" multiple>
                                        <option value="">Select Month</option>
                                        <option *ngFor="let m of MonthData" value="{{m.month}}">{{m.monthName}}</option>                                    
                                    </select>									
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Hostel  Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Hostel Name</label>   
                                    <select formControlName="hostel_id" class="form-select">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let hostel of hosteldetailsData" value="{{hostel.ID}}">{{hostel.name}}</option>                                      
                                    </select>
                                </div>
                                <div class="col-md-4">
                                <label>Room Type</label>
                                <select formControlName="hostel_room_id" class="form-select">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let room of hostelRoomsData" value="{{room.ID}}">{{room.room_no}}</option>   
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </form>
        
</div>