import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'website-wrapper',
  templateUrl: './website.component.html',
})

export class WebsiteComponent implements OnInit {
  public theme: any;

  constructor(
    private _cmsService: CmsService
) {}

ngOnInit(): void {

  let instSlug = this._cmsService.getInstSlug();
  console.log(instSlug)
  this.theme = this._cmsService.getCmsSettingBySlug(instSlug, 'theme').subscribe(settingRes => {
    console.log(settingRes)
    if (settingRes.status === true) {
      this.theme = settingRes.result;
    } else {
      this.theme = 'default';
    }
  })

}

//   let current_url = window.location.href;

//   if (current_url.indexOf('localhost') > -1) {
//     this.instSlug = 'demoschool';
//   } else {
//     this.instSlug = this.getSubdomain(current_url) == 'app' || this.getSubdomain(current_url) == 'appuat' ? 'demoschool' : this.getSubdomain(current_url);
//   }

//   this._instService.getInstListBySlug(this.instSlug).subscribe(dataRes => {
//     console.log(dataRes);
//     if (dataRes.status === true) {
//       let instData = JSON.parse(dataRes.result);
//       this.instID = instData[0].ID;
//       this._cmsService.getCmsSetting(this.instID, 'theme').subscribe(settingRes => {
//         console.log(settingRes)
//         if (settingRes.status === true) {
//             this.theme = settingRes.result;
//             console.log(this.theme)
//         }
//       })
//     }
//   })

// }

// getSubdomain(url: any) {
//   let domain = url;
//   if (url.includes("://")) {
//       domain = url.split('://')[1];
//   }
//   const subdomain = domain.split('.')[0];
//   return subdomain;
// };

}