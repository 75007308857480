import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { CircularService } from 'src/app/services/circular.service';
  
@Component({
    selector: 'app-circularmodal',
    templateUrl: './circularmodal.component.html',
    styleUrls: [],
})

export class CircularmodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public circularID: number;
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();

    constructor(
        private _gService: GlobalService,
        private _aService: CircularService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Title: ['', Validators.required],
       Description: ['', Validators.required],
       Date: ['', Validators.required],
       Send_to: ['', Validators.required],
       Attachment: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.circularID, 'circulars').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Title:sData[0].title,
                    Description:sData[0].description,
                    Date:sData[0].date,
                    Send_to:sData[0].sent_to,
                });
                this.imageSrc = sData[0].attachment ? this.imgURL+sData[0].attachment : '';
            }
            this._gService.showLoader(false);
        })
    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    updateCircular() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.circularID,
                title: rObj.Title,
                description: rObj.Description,
                date: rObj.Date,
                sent_to: rObj.Send_to,
                attachment: this.imageSrc,
            }
            this._aService.circularData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Circular Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}