import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'app-components-contactUsForm',
  templateUrl: './contactUsForm.component.html',
  styleUrls:['./contactUsForm.component.css']
})

export class ContactUsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public mediumData: any[] = [];

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _cmsService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
    ) {}

    public rObj = this._fBuilder.group({
        Name: [''],
        Email: [''],
        Message: [''],
    })

    ngOnInit(): void {}

    submit() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {    
                sender_name: rObj.Name,
                sender_email: rObj.Email,
                sender_msg: rObj.Message,
                receiver_email:'amir@zeiore.com',
                subject:'Random Email Subject'
            }
            console.log(mData)
            this._cmsService.sendMail(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/website/a/contactUs-form']);
                    this._gService.triggerSnackbar('Message Send Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}