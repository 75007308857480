<div class="modal-header">
    <h5 class="modal-title">Pay Fees</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Date"  required="">
            </div>
            <div class="form-group">
                <label>Amount</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Amount" id="amount" readonly="">
            </div>
           
            <div class="form-group" *ngIf="isDue">
                <label>Fine Type</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Fine_type" id="fine_type" readonly="">
            </div>
            <div class="form-group" *ngIf="isDue">
                <label>Fine Amount</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Fine_amount" id="fine_amount" readonly="">
            </div>
            <div class="form-group">
                    <label>Discount</label>
                    <small class="req"> *</small>
                    <select formControlName="Discount_code" class="form-select" (change)="getdiscountType($event)">
                        <option value="0">Please Select</option>
                        <option *ngFor="let type of feeDiscountData" value="{{type.ID}}">{{type.discount_code}}</option>                       
                    </select>
                <div *ngIf="discount_applied">
                    Discount Type {{typeId}} is applied to Payment Amount
                </div>
            </div>


            <!-- <div class="form-group discount_amount" id="discount_amount_holder" style="display:none;">
                <label>Discount Amount</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Discount_amount" id="discount_amount" readonly="">
            </div> -->
            <div class="form-group">
                <label>Payment Mode</label>
                <br>
                <div class="form-check inline" style="margin-right:20px;">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cash">
                    <label class="form-check-label" for="flexCheckDefault">Cash</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cheque">
                    <label class="form-check-label" for="flexCheckDefault">Cheque</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="dd">
                    <label class="form-check-label" for="flexCheckDefault">DD</label>
                </div>   
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="bank_transfer">
                    <label class="form-check-label" for="flexCheckDefault">Bank Transfer</label>
                </div>  
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="upi">
                    <label class="form-check-label" for="flexCheckDefault">UPI</label>
                </div>  
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="card">
                    <label class="form-check-label" for="flexCheckDefault">Card</label>
                </div>  
            </div>
            <div class="form-group">
                <label>Note</label>
                <textarea type="text" class="form-control" formControlName="Note"></textarea>
            </div>
            <!-- <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="Pay()">Pay</button>
            </div> -->
            
        </form>
    </div>
</div>
<div class="modal-footer" style="justify-content: space-between;">
    <div class="total-fees">{{totalAmount}}</div>
    <div class="row">
        <div class="col-md-4">
        <button type="submit" class="btn primary-btn small-btn" (click)="Pay()">Pay</button>
        </div>
        <div class="col-md-8">
        <button type="submit" class="btn primary-btn small-btn" (click)="PayAndPrint()">Pay &amp; Print</button>
        </div>
    </div>
      <!-- <button type="submit" class="btn btn-primary payprint_btn" name="payprint_btn" data-whatever="@mdo" data-toggle="modal1" data-target="#viewModal">Pay &amp; Print </button>  -->
  </div>
