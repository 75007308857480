import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { PayrollService } from 'src/app/services/payroll.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeesService } from 'src/app/services/fees.service';
import { FeesTypemodalComponent } from 'src/app/components/modal/feesTypemodal/feesTypemodal.component';
import { FeeGroupmodalModalComponent } from 'src/app/components/modal/feeGroupmodal/feeGroupmodal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-fees-feeGroup',
  templateUrl: './feeGroup.component.html',
})

export class feeGroupComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public feesGroupData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Fees_name: ['', Validators.required],
        Description: [''],  
        Due_date: ['',Validators.required],
        Fine_type: ['none'],
        Percentage: [''],
        Fine_amount: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_group').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesGroupData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openFeesGroupEditModal(feesGroupID: number) {
        this.modalRef = this.modalService.show(FeeGroupmodalModalComponent, {
            class: 'modal-dialog-centered group-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                feeGroupID : feesGroupID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    saveData() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                name: rObj.Fees_name,
                description: rObj.Description,
                due_date: rObj.Due_date,
                fine_type: rObj.Fine_type,
                percentage: rObj.Percentage,
                fine_amount: rObj.Fine_amount,
            }
            console.log(mData)
            this._aService.feesGroupData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Fees Group Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}