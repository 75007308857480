<div class="modal-header">
    <h5 class="modal-title">Edit Fees Master</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            
            <div class="form-group">
                <label>Class <small class="req"> *</small></label>
                <select formControlName="Class" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                 </select>
                 <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please select class</p>
                </div>
            </div>
            <div class="form-group">
                <label>Section <small class="req"> *</small></label>
                <select formControlName="Section" class="form-select" >
                    <option value="">Please Select</option>
                    <ng-container *ngFor="let section of classSectionData" >
                        <ng-container *ngFor="let s of sectionData">
                            <ng-container *ngIf="s.ID == section">
                                <option [value]="section">{{s.name}}</option>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </select>
                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                    <p class="input-error-msg">Please select section</p>
                </div>
            </div>
            <div class="form-group">
                <label>Fees Group <small class="req"> *</small></label>
                <select formControlName="Fees_group" class="form-select">
                    <option value="">Please Select</option>
                    <option *ngFor="let m of feesGroupData" value="{{m.ID}}">{{m.name}}</option>               
                 </select>
                 <div *ngIf="rObj.controls.Fees_group.invalid && (rObj.controls.Fees_group.dirty || rObj.controls.Fees_group.touched)">
                    <p class="input-error-msg">Please select Fees group</p>
                </div>
            </div>
            
            <div class="form-group">
                <label>Fees Type</label>
                <small class="req"> *</small>
                <select formControlName="Fees_type" class="form-select">
                    <option value="">Please Select</option>
                    <option *ngFor="let type of feesTypeData" value="{{type.ID}}">{{type.name}}</option>                                 
                </select>
                <div *ngIf="rObj.controls.Fees_type.invalid && (rObj.controls.Fees_type.dirty || rObj.controls.Fees_type.touched)">
                    <p class="input-error-msg">Please select Fees type</p>
                </div>
            </div>
            <div class="form-group amount">
                <label>Amount</label>
                <small class="req amount"> *</small>
                <input type="number" class="form-control amount" formControlName="Amount"  id="amount">
                <div *ngIf="rObj.controls.Amount.invalid && (rObj.controls.Amount.dirty || rObj.controls.Amount.touched)">
                    <p class="input-error-msg">Please select Amount</p>
                </div>
            </div>
            
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateFeesGroup()">Update</button>
            </div>
        </form>
    </div>
</div>
