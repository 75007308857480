import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Route, Router, RouteReuseStrategy ,ActivatedRoute} from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { IdService } from 'src/app/services/id.service';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-staff-idCard-editStaffIdCard',
  templateUrl: './editStaffIdCard.component.html',
})

export class EditStaffIdCardComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public templateData: any[] = [];
    public templateID: any;
    public templatePath: any;
    public templateHtml : any;
    public htmlPreview : any;
    public previewImg : any;
    public idCard_html : any;
    
    checked :boolean = false;
    checked1 :boolean = false;
    checked2 :boolean = false;
    checked3 :boolean = false;

    bgimageSrc:any = '';
    bgstatus:boolean = false;

    imageSrc:any = '';
    status:boolean = false;

    public imgURL = 'https://apiuat.edunationpro.com/uploads/';

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _iService: IdService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _aroute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
        private sanitizer: DomSanitizer,
    ) {}

    public rObj = this._fBuilder.group({
        School_Name: [''],
        School_Name_Text: [''],
        School_address: [''],
        School_address_Text: [''],
        School_phone: [''],
        School_phone_Text: [''],
        School_email: [''],
        School_email_Text: [''],
        Template_Name:[''],
        Background: [''],
    })

    ngOnInit(): void {
       
        this.templateID =this._aroute.snapshot.paramMap.get('id');
        //alert(this.templateID)
        // this._gService.showLoader(true);
        this._aService.getIdCardTemplateData().subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.templateData = JSON.parse(pRes.result);
                if (this.templateID) {
                    this.templatePath = this.templateData.filter(t => {
                        return t.ID == this.templateID  
                    })
                    console.log(this.templatePath)
                    this.templatePath = this.templatePath[0].path
                    console.log(this.templatePath)
                    this._gService.getHtmlData(this.templatePath).subscribe((data : any)=>{
                        this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data)
                    })
                }
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.templateID,'idcards').subscribe(tRes =>{
            console.log(tRes)
            if(tRes.status == true){
                let tData = JSON.parse(tRes.result);
                this.rObj.patchValue({
                    Template_Name : tData[0].name,
                    School_Name_Text: tData[0].school_name,
                    School_address_Text: tData[0].school_address,
                    School_email_Text: tData[0].school_email,
                    School_phone_Text: tData[0].school_phone
                });
                // this.imageSrc = sData[0].photo_path ? this.imgURL+sData[0].photo_path : '';
                this.imageSrc = tData[0].school_logo ? this.imgURL+tData[0].school_logo : '';
                this.idCard_html = this.sanitizer.bypassSecurityTrustHtml(tData[0].html)

            }
        })
       
        console.log(this.templatePath)

        //this.schoolName.nativeElement.innerHTML = "Hello Angular";

        this.rObj.controls['School_Name'].valueChanges.subscribe((value: any) => {
            let name : any = document.querySelector("#schoolname");
            if (value) {
                // alert('yes')
                this.checked = true
                if (name) { name.removeAttribute("hidden", false) }
            } else {
                this.checked = false
                if (name) { name.setAttribute("hidden", true) }
            }
        })

        this.rObj.controls['School_Name_Text'].valueChanges.subscribe((value: any) => {
            let name : any = document.querySelector("#schoolname");
            if (value) {
                if (name) { name.textContent = value }
            } else {
                if (name) { name.textContent = name.getAttribute('data-name') }
            }
        })

      

        this.rObj.controls['School_address'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schooladdress");
            if (value) {
                // alert('yes')
                this.checked1 = true
                if (name) { name.removeAttribute("hidden", false)}
            } else {
                this.checked1 = false
                if (name) { name.setAttribute("hidden", true) }
            }
        })

        this.rObj.controls['School_address_Text'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schooladdress");
            if (value) {
                if (name) { name.textContent = value }
            } else {
                if (name) { name.textContent = name.getAttribute('data-name') }
            }
        })

        this.rObj.controls['School_phone'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schoolphone");
            if (value) {
                // alert('yes')
                this.checked2 = true
                if (name) { name.removeAttribute("hidden", false) }
            } else {
                this.checked2 = false
                if (name) { name.setAttribute("hidden", true) }
            }
        })
        
        this.rObj.controls['School_phone_Text'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schoolphone");
            if (value) {
                if (name) { name.textContent = value }
            } else {
                if (name) { name.textContent = name.getAttribute('data-name') }
            }
        })

        this.rObj.controls['School_email'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schoolemail");
            if (value) {
                // alert('yes')
                this.checked3 = true
                if (name) { name.removeAttribute("hidden", false) }
            } else {
                this.checked3 = false
                if (name) { name.setAttribute("hidden", true) }
            }
        })
        
        this.rObj.controls['School_email_Text'].valueChanges.subscribe((value: any) => {
            let name : any= document.querySelector("#schoolemail");
            if (value) {
                if (name) { name.textContent = value }
            } else {
                if (name) { name.textContent = name.getAttribute('data-name') }
            }
        })

    }

    onBgChange(event:any) {
        this.bgstatus = false
        const file = event.target.files[0];
        // alert(file)
        this.bgstatus = event.target.files.length > 0 ? true : false;
        if (this.bgstatus == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
                this.bgimageSrc = reader.result;
                // alert(this.bgimageSrc)
            
                let bg = document.querySelector('#bg-logo')
                if (bg) { bg.setAttribute('src', this.bgimageSrc) }
                else { 
                    if (bg) { bg.setAttribute('src', bg.getAttribute('data-src'))}
                }
            }
  
        }
    }

    removeBgImg() {
        let bg = document.querySelector("#bg-logo");
        // if(sign){ sign.setAttribute('src', this.imageSrc) }
        this.bgimageSrc = '';
        this.bgstatus = false;
        let defaultImg : any = bg?.getAttribute('data-src')
        // alert(defaultImg)
        if (bg) { bg.setAttribute('src', defaultImg) }
    }



    onImgChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        // alert(file)
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
            let seal = document.querySelector("#student_img");
              this.imageSrc = reader.result;
              if (seal) { seal.setAttribute('src', this.imageSrc) }
             
           }
        }
    }

    removeImg() {
        let img = document.querySelector("#student_img");
        // if(sign){ sign.setAttribute('src', this.imageSrc) }
        this.imageSrc = '';
        this.status = false;
        let defaultImg : any = img?.getAttribute('data-src')
        // alert(defaultImg)
        if (img) { img.setAttribute('src', defaultImg) }
    }

   
    UpdateTemplate(){
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let htmlPreview = document.querySelector("#htmlPreview");
            if (htmlPreview) { this.htmlPreview = htmlPreview.innerHTML }
            this.previewImg = htmlToImage.toJpeg(document.getElementById('htmlPreview')!).then(function(dataUrl) {
                if (dataUrl) {
                    return dataUrl;
                }
            }).catch(function (error){
                return 'something went wrong !'
            });

            console.log(this.htmlPreview)
            let mData = {
                action: 'update',
                id: this.templateID,
                name: rObj.Template_Name,
                school_name: rObj.School_Name_Text,
                school_address: rObj.School_address_Text,
                school_email: rObj.School_email_Text,
                school_phone: rObj.School_phone_Text,
                school_logo: this.imageSrc,
                html: this.htmlPreview
            }
            
            console.log(mData)
            this._iService.templateSaveData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['student/generate-id/list']);
                    this._gService.triggerSnackbar('Template Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }


}