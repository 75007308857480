<div class="modal-header">
    <h5 class="modal-title">Edit Inventory Issue</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>User Type</label>
                <select formControlName="User_type" class="form-select" data-next="getSection" data-type="list" required="">
                    <option value="">Select</option>
                    <option value="student">Student</option>
                    <option value="staff">Staff</option>
                 </select>    
                <div *ngIf="rObj.controls.User_type.invalid && (rObj.controls.User_type.dirty || rObj.controls.User_type.touched)">
                    <p class="input-error-msg">Please select user type</p>
                </div>           
            </div>

            <div class="form-group" *ngIf="rObj.controls.User_type.value == 'staff'" >
                <div class="form-group" >
                    <label>Department
                    <small class="req"> *</small></label>
                    <select name="class" formControlName="Department" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let dept of deparmentData" value="{{dept.ID}}">
                        {{dept.name}}
                        </option>         
                    </select>
                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                        <p class="input-error-msg">Please select Department</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Designation
                    <small class="req"> *</small></label>
                    <select name="class" formControlName="Designation" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let des of designationData" value="{{des.ID}}">
                        {{des.name}}
                        </option>         
                    </select>
                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                        <p class="input-error-msg">Please select Department</p>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="rObj.controls.User_type.value == 'student'">
                <div class="form-group">
                    <label>Class
                    <small class="req"> *</small></label>
                    <select name="class" formControlName="Class" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                    </select>
                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                        <p class="input-error-msg">Please select class</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>Section
                        <small class="req"> *</small></label>
                        <select name="section" formControlName="Section" class="form-select" >
                            <option value="">Please Select</option>
                            <ng-container *ngFor="let section of classSectionData" >
                                <ng-container *ngFor="let s of sectionData">
                                    <ng-container *ngIf="s.ID == section">
                                        <option [value]="section">{{s.name}}</option>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </select>
                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                            <p class="input-error-msg">Please select section</p>
                        </div>
                </div>
            </div>

            <div class="form-group" *ngIf="rObj.controls.User_type.value == 'staff'">
                <label>Issue To</label>
                <small class="req"> *</small>
                    <select name="class" formControlName="Issue_to" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let s of staffListData" value="{{s.ID}}">
                        {{s.full_name}}
                        </option>         
                    </select>
                    <div *ngIf="rObj.controls.Issue_to.invalid && (rObj.controls.Issue_to.dirty || rObj.controls.Issue_to.touched)">
                        <p class="input-error-msg">Please enter issue to</p>
                    </div>
            </div>
            <div class="form-group" *ngIf="rObj.controls.User_type.value == 'student'">
                <label>Issue To</label>
                <small class="req"> *</small>
                    <select name="class" formControlName="Issue_to" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let s of studentListData" value="{{s.ID}}">
                        {{s.full_name}}
                        </option>         
                    </select>
                    <div *ngIf="rObj.controls.Issue_to.invalid && (rObj.controls.Issue_to.dirty || rObj.controls.Issue_to.touched)">
                        <p class="input-error-msg">Please enter issue to</p>
                    </div>
            </div>

            <div class="form-group">
                <label>Issue By</label> 
                <small class="req"> *</small>
                <!-- <input type="text" formControlName="Issue_by" class="form-control number-input" autocomplete="off" value="" required=""> -->
                <select formControlName="Issue_by" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let staff of staffData" value="{{staff.ID}}">
                    {{staff.full_name}}
                    </option>         
                </select>
                <div *ngIf="rObj.controls.Issue_by.invalid && (rObj.controls.Issue_by.dirty || rObj.controls.Issue_by.touched)">
                    <p class="input-error-msg">Please enter issue by</p>
                </div>
            </div>
            <div class="form-group">
                <label>Issue Date</label>
                <small class="req"> *</small>
                <input type="date" formControlName="Date" class="form-control date-input" required="">
                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                    <p class="input-error-msg">Please enter issue date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Return Date </label>
                <small class="req"> *</small>
                <input type="date" formControlName="Return_date" class="form-control date-input" required="">
                <div *ngIf="rObj.controls.Return_date.invalid && (rObj.controls.Return_date.dirty || rObj.controls.Return_date.touched)">
                    <p class="input-error-msg">Please enter return date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Item Name</label>
                    <small class="req"> *</small>
                    <select formControlName="Item_name" class="form-control" data-next="getSection" data-type="list" required="">
                    <option value="">Select</option>
                    <option *ngFor="let i of inventoryItemData" value="{{i.ID}}">
                        {{i.item_name}}
                        </option>     
                    </select>
                    <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                        <p class="input-error-msg">Please enter iten name</p>
                    </div>
            </div>
            <div class="form-group">
                <label>Quantity </label>
                <small class="req"> *</small>
                <input type="text" formControlName="Qty" class="form-control" required="">
                <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                    <p class="input-error-msg">Please enter qty</p>
                </div>

            </div>
            <div class="form-group">
                <label>Note</label>
                <textarea formControlName="Note" class="form-control" required=""></textarea>
                <div *ngIf="rObj.controls.Note.invalid && (rObj.controls.Note.dirty || rObj.controls.Note.touched)">
                    <p class="input-error-msg">Please enter note</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateIssue()">Update</button>
            </div>
        </form> 
    </div>
</div>
