import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy , ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { MenuModalComponent } from 'src/app/components/modal/menumodal/menumodal.component';
import { MenuItemModalComponent } from 'src/app/components/modal/menuItemmodal/menuItemmodal.component';

@Component({
  selector: 'app-enpcms-menu-menuItems',
  templateUrl: './menuItems.component.html',
  styleUrls: ['./menuItems.component.css']
})

export class MenuItemsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public menuItemData: any[] = [];
    public PagesData: any[] = [];
    public menuID : any;
    public menuName : any;
    public selectedIds : any[] = [];
    public menuList : any[] = [];
    // public reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    public reg = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _cmsService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Menu_item: ['', Validators.required],
        Ex_url_address: ['',Validators.pattern(this.reg)],
        Pages: [''],
        Type: ['external'],
        Target: ['yes']
    })

    ngOnInit(): void {

        this.menuID = this._aRoute.snapshot.paramMap.get('id');

        this._gService.showLoader(true);

        
        this._cmsService.cmsSettingsData(this.instID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let cData = JSON.parse(mRes.result);
                console.log(cData)  
                console.log(cData[0].menu_list_data)
                let menuListData = JSON.parse(cData[0].menu_list_data)
                console.log(menuListData)
                let menuList = menuListData.filter((m : any)  => m.menu_id == this.menuID).map((m : any) => m.menu_list)
                if (menuList.length) {
                    this.menuList = menuList[0]
                } else {
                    this.menuList = []
                }
              } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.menuID, 'cms_menu').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let data = JSON.parse(mRes.result);
                this.menuName = data[0].name
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'cms_menu_item').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.menuItemData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'cms_pages').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.PagesData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'ftip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.menuItemData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.menuItemData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
        console.log(this.menuList)
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    MultiMenuData() {
        // this.menuList = this.selectedIds
        if (this.menuList.length) {
            // this.menuList.push(this.selectedIds)
            this.menuList = [...this.menuList, ...this.selectedIds]
        } else {
            this.menuList = this.selectedIds
        }
        this.selectedIds = []
        console.log(this.selectedIds)
        console.log(this.menuList)
    }

    getName(id : any) {
        return this.PagesData.filter(r => r.ID == id).map(r => r.title)
    }

    openMenuItemEditModal(menuItemID: number) {
        this.modalRef = this.modalService.show(MenuItemModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                menuItemID: menuItemID,
            } 
        });
    }



    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    draggingIndex: any;
    
    private _reorderItem(fromIndex: number, toIndex: number): void {
      const itemToBeReordered = this.menuList.splice(fromIndex, 1)[0];
      this.menuList.splice(toIndex, 0, itemToBeReordered);
      this.draggingIndex = toIndex;
    }
  
    onDragStart(index: number): void {
      this.draggingIndex = index;
    }
  
    onDragEnter(index: number): void {
      if (this.draggingIndex !== index) {
        this._reorderItem(this.draggingIndex, index);
      }
    }
  
    onDragEnd(): void {
      this.draggingIndex = undefined;
    }

      onDragOver(e: Event): void {
          e.preventDefault();
      }

    removeMenuItem(i : any) {
      this.menuList.splice(i, 1)
    }

    saveMenuList() {
        let settingData = {
            inst_id : this.instID,
            settings : {
                menu_list_data : JSON.stringify([{
                    menu_id : this.menuID,
                    menu_list : this.menuList
                }])
            }
        }
        this._cmsService.getSettingData(settingData).subscribe(cmsRes => {
            console.log(cmsRes)
            if (cmsRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Menu Updated Successfully', 'success', true);
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })

    }

    saveMenu() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                menu_id: this.menuID,
                name: rObj.Menu_item,
                type: rObj.Type,
                target: rObj.Target,
                url: rObj.Ex_url_address,
                page_id: rObj.Pages ? rObj.Pages : 0,
            }

            console.log(mData)
            this._cmsService.menuItemData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Menu Item Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}