import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { FinanceService } from 'src/app/services/finance.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExpenseModalComponent } from 'src/app/components/modal/expensemodal/expensemodal.component';
import * as moment from 'moment';

// import { expenseHeadModalComponent } from 'src/app/components/modal/expenseHeadmodal/expenseHeadmodal.component';
// import { expenseModalComponent } from 'src/app/components/modal/expensemodal/expensemodal.component';
@Component({
  selector: 'app-finance-expense',
  templateUrl: './expense.component.html',
})

export class ExpenseComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public expenseData: any[] = [];
    public expenseHeadData: any[] = [];
    fileSrc:any = '';
    status:boolean = false;
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FinanceService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Expense_head: ['', Validators.required],
        Expense_name: ['', Validators.required],
        Invoice_no: ['', Validators.required],
        Expense_date: ['', Validators.required],
        Description: ['', Validators.required],
        Amount: ['', Validators.required],
        Document: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'expensehead').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.expenseHeadData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'expense').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.expenseData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.fileSrc = reader.result;          
           }
        }
    }

    openExpenseEditModal(expenseID: number) {
        this.modalRef = this.modalService.show(ExpenseModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                expenseID: expenseID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    getDate(date : any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    saveExpense() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                expense_head: rObj.Expense_head,
                expense_name: rObj.Expense_name,
                invoice_no: rObj.Invoice_no,
                expense_date: rObj.Expense_date,
                amount: rObj.Amount,
                description: rObj.Description,
                document: this.fileSrc,

            }
            console.log(mData)
            this._aService.expenseData(mData).subscribe(iRes => {
                console.log(iRes)
                if (iRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Expense Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}