<div class="modal-header">
    Media Information
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-media-container row" >
    <div class="col-md-8 modal-content-wrapper">
            <div class="media-img-wrapper">
                <img [src]="imgSrc ? imgSrc : '/assets/img/no-preview-available.png'">
            </div>
    </div>
    <div class="col-md-4 modal-content-wrapper info" *ngFor="let info of infoData">
        <div class="media-information-wrapper">
            <div class="details">
                <div class="info">
                    <div class="title">File title:</div>
                    <div class="data">{{info.filetitle}}</div>
                </div>
                <div class="info">
                    <div class="title">File name:</div>
                    <div class="data">{{info.filename}}</div>
                </div>
                <div class="info">
                    <div class="title">File type:</div>
                    <div class="data">{{info.filetype}}</div>
                </div>
                <div class="info">
                    <div class="title">File size:</div>
                    <div class="data">{{info.filesize}} KB</div>
                </div>
                
                <div class="info fileurl">
                    <div class="title">File URL:</div>
                    <div class="data">
                        <input #fileURL class="form-control" type="text" value="{{imgURL}}{{info.filename}}" readonly="true">
                        <span (click)="copyFileURL()">Copy File URL</span>
                    </div>
                </div>
            </div>
            <div class="del-btn-wrapper">
                <button class="btn primary-btn extra-small-btn" (click)="delete(info.ID,'cms_media', 'media')">Delete</button>
            </div>
        </div>
    </div>
</div>
