import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { StaffService } from 'src/app/services/staff.service';
import { LibraryService } from 'src/app/services/library.service';
import { Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddMemberModalComponent } from 'src/app/components/modal/addMembermodal/addMembermodal.component';
import { ReturnbookmodalModalComponent } from 'src/app/components/modal/returnbookmodal/returnbookmodal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-library-issueBook',
  templateUrl: './issueBook.component.html'
})

export class issueBookComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListDatas: any[] = [];
    public deparmentData: any[] = [];
    public designationData:any[] = [];
    public staffListData: any[] = [];
    public memberData: any[] = [];
    public searchBookData: any[] = [];
    public searchMemberData: any[] = [];
    public StudentSData: any[] = [];
    public StaffSData: any[] = [];
    public selectedIds : any[] = [];
    public mediumData : any[] = [];


    public bookIds: any[] = [];
    public bookData: any[] = [];
    public issueBookData: any[] = [];
    public allBooksData: any[] = [];
    public allBooksDataOption: any[] = [];
    public memberDataStudentOptions: any[] = [];
    public memberDataStaffOptions: any[] = [];

    public type:any;
    public dept:any = 0;
    public des:any = 0;
    public class:any = 0;
    public section:any = 0;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    dtOptions: any = {};

    modalRef: BsModalRef;


    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _lService: LibraryService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private _aRoute: ActivatedRoute,
    ) {}

    public rObj = this._fBuilder.group({
        User_type: ['', Validators.required], 
        Student_ID:[''],
        Staff_ID:[''],
        Student_name: [''],
        Staff_name:[''],
        Medium:[''],
        Class: [''],//''
        Section: [''],
        Department: [''],
        Designation:[''],
        Due_date:['',Validators.required],
        Issue_date:['',Validators.required],
        Book_Name:['', Validators.required],
        Library_member:['',Validators.required]
    })

    ngOnInit(): void {

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'books').subscribe(res => {
            console.log(res)
            if (res.status === true) {
                this.allBooksData = JSON.parse(res.result)
                this.allBooksDataOption = this.allBooksData.map(m =>({ value: m.ID, label: m.title}))
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(res => {
            console.log(res)
            if (res.status === true) {
                this.mediumData = JSON.parse(res.result)
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'book_issue_return').subscribe(res => {
            console.log(res)
            if (res.status === true) {
                this.issueBookData = JSON.parse(res.result)
            }
            console.log(this.issueBookData)
        }) 

        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.deparmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'library_members').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.memberData = JSON.parse(cRes.result);
                this.memberDataStudentOptions = this.memberData.filter(m => m.user_type == 'student' ).map(m => ({ value: m.ID, label: m.card_no }))
                this.memberDataStaffOptions = this.memberData.filter(m => m.user_type == 'staff' ).map(m => ({ value: m.ID, label: m.card_no }))
                // console.log(this.memberDataOptions)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'library_members').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.memberData = JSON.parse(cRes.result);
        //         this.studentMembers = this.memberData.filter(function(m) {
        //             return m.user_type == 'student'
        //         }).map(function(m){
        //             return m.user_id 
        //         }) 
        //         this.staffMembers = this.memberData.filter(function(m) {
        //             return m.user_type == 'staff'
        //         }).map(function(m){
        //             return m.user_id
        //         }) 
        //         console.log(this.studentMembers)
        //         console.log(this.staffMembers)
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        // })

        this.rObj.controls['Section'].valueChanges.subscribe((value : any) =>{
            this._gService.showLoader(true);
            this._aService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.StudentSData = JSON.parse(sRes.result)
                } else {
                    this.StudentSData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false);
        })

        this.rObj.controls['Designation'].valueChanges.subscribe((value : any)=>{
            this._gService.showLoader(true); 
            this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.StaffSData = JSON.parse(sRes.result)
                } else {
                    this.StaffSData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false); 
        })



        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        
    this.rObj.controls['Library_member'].valueChanges.subscribe((value: any) => {
        //alert(value)
        this._gService.showLoader(true);
        console.log(this.memberData)
                let result : any = this.memberData.filter(l => l.ID == value).map(l => l.user_id)
                //alert(result)
                console.log(this.issueBookData)
                let data = this.issueBookData.filter(i => i.user_type == this.rObj.controls.User_type.value) 
                let databyID = this.issueBookData.filter(i => i.student_id == result ) //|| i.staff_id == result
                console.log(data)
                console.log(databyID)
                // //alert(result)
                if(this.rObj.controls.User_type.value == 'student') {
                    //alert('1')
                    let id: number = result.toString()
                    console.log(id)

                    this._gService.getDataByID(id, 'students').subscribe(dataRes => {  
                    console.log(dataRes)
                    let data = JSON.parse(dataRes.result)
                    console.log(data)
                        //alert('2')
                        this.rObj.patchValue({
                            Class : data[0].class,
                            Section : data[0].section,
                            Student_ID: result.toString(),
                            Student_name : data[0].full_name
                        })
                    })
                } else {
                    //alert('3')
                    this._gService.getDataByID(result, 'staff').subscribe(dataRes => {  
                        let data = JSON.parse(dataRes.result)
                        console.log(data)
                        //alert('4')
                            this.rObj.patchValue({
                                Department : data[0].department, 
                                Designation : data[0].designation,
                                Staff_ID: result.toString(),
                                Staff_name : data[0].full_name 
                            })
                        })
                }
                // this._gService.getDataByID(result, 'student').subscribe(dataRes => {  
                //     let data = JSON.parse(dataRes.result)
                // this.rObj.patchValue({
                        

                //     Class : data[0].class_id,
                //     Section : data[0].section_id,
                //     Student_ID: data[0].student_id,
                //     Student_name : data[0].student_name,
                //     Department : data[0].department_id, 
                //     Designation : data[0].designation_id,
                //     Staff_ID: data[0].staff_id,
                //     Staff_name : data[0].staff_name 
                //    })
            // })
        this._gService.showLoader(false);
    })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.issueBookData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.issueBookData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }
    checkMemberData(memberType: any, memberId: number) {{
        let memberData = this.memberData.filter(x => x.user_type == memberType && x.user_id == memberId)
        if (memberData.length) {
            return true 
        } else {                                                    
            return false
        }
    }}
    
    getMemberId(memberType: any, memberId: number) {{
        let memberData : any = this.memberData.filter(x => x.user_type == memberType && x.user_id == memberId).map(x => x.ID)
        return parseInt(memberData)
    }}
    getMemberCardNo(memberType: any, memberId: number) {{
        let memberCardNo : any = this.memberData.filter(x => x.user_type == memberType && x.user_id == memberId).map(x => x.card_no)
        return parseInt(memberCardNo)
    }}

    gotoEditLink(dataID: number){
        this._router.navigate(['/student/edit/'+dataID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    // revertMember(memberID: number){
    //     console.log(memberID)
    //     this._lService.revertmember(memberID, 'library_members').subscribe(res =>{
    //         console.log(res)
    //         this.deleteData(memberID,'library_members','member')
    //     })
    // }

    openReturnModal(issueID : number){
        this.modalRef = this.modalService.show(ReturnbookmodalModalComponent, {
            class: 'modal-dialog-centered addMember-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                // userType: ,
                issueId: issueID,
            } 
        });
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    search() {

        if(this.rObj.valid){
            let robj = this.rObj.value;
            let bData = {
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                book_id: robj.Book_Name,
                user_type: robj.User_type,
                student_id: robj.Student_ID,
                class_id: robj.Class,
                section_id: robj.Section,
                staff_id: robj.Staff_ID,
                department_id: robj.Department,
                designation_id: robj.Designation,
                issue_date: robj.Issue_date,
                due_date: robj.Due_date
            }
            console.log(bData)
          
            this._lService.issueBookData(bData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Book issued Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
       
    }
}