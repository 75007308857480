<header class="header-main">       
    <div class="menu-bar">
        <div class="container" style="display: flex; justify-content: space-between;">
            <a href="homepage.html">
                <div class="logo-wrapper">
                    <img class="main-logo" src="assets/logos/school-logo.png">
                </div>
            </a>
            <ul class="menu-list-wrapper">
                <li class="menu-list-opt">
                    <a class="menu-opt" href="homepage.html">Home</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="about-us.html">About Us</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="admission.html">Online Admission</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="collection.html">Gallery</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="events.html">Events</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="news.html">News</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="contact-us.html">Contact us</a>
                </li>
            </ul>
        </div>
    </div>   
</header>