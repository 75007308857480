<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Circulars</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="communication/circular/add" class="content-header-link">Add Circular</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Circular List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="circularData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Title</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Send To</th>
                                <th>Attachment</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of circularData">
                                <td class="align-left">{{c.title}}</td>
                                <td><p>{{c.description}}</p></td>
                                <td>{{c.date}}</td>
                                <td>{{c.sent_to == 0 ? 'All' : (c.sent_to == 4 ? 'Teachers' : 'Students')}}</td>
                                <td>
                                    <ng-container *ngIf="c.attachment">
                                        <a href="{{imgURL}}{{c.attachment}}" target="_blank">view document</a>
                                    </ng-container>
                                </td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openCircularEditModal(c.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(c.ID, 'circulars', 'Circular')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!circularData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>