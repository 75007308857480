import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { OnlineApplicationService } from './onlineApplication.service';
import { Router, RouteReuseStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RazorpayPG {

    public showConfirmation : boolean = false;
    public admissionDetails : any[] = [];

    constructor(
        private _winRef: WindowRefService,
        private _http: HttpClient,
        private _gService: GlobalService,
        private _admissionService: OnlineApplicationService,
        private _router: Router,
        private _reRoute: RouteReuseStrategy

    ) {}

    private updatePaymentStatusAPI = `${environment.APIURL}/onlineAdmission/updatePaymentStatus/`;

    updatePaymentStatus(application_id: number, ref_no: number, txn_id: any): Observable<any> {
        return this._http.get(this.updatePaymentStatusAPI+'?application_id='+application_id+'&ref_no='+ref_no+'&txn_id='+txn_id, {
          headers: { 'Content-Type': 'application/json' }
        });
    }

    payWithRazor(payData: any) {
        console.log(payData)
        console.log(payData.inst_name)
        let options: any = {
        key: 'rzp_test_N1t90hp9wRDuDg',
        // key: 'rzp_live_tz6cRtTUyInzAO',
        amount: payData.amt*100, // amount should be in paise format to display Rs 1255 without decimal point
        currency: 'INR',
        name: payData.inst_name, // company name or product name
        description: payData.payDesc,  // product description
        image: payData.inst_logo, // company logo or product image
        application_id : payData.application_id,
        id: payData.ref_no, // order_id created by you in backend
        modal: {
            // We should prevent closing of the form when esc key is pressed.
            escape: false,
        },
        notes: {
            // include notes if any
        },
        theme: {
            color: '#1DCA7F'
        }
        };
        options.handler = ((response : any, error :any) => {
            if (response) {
                let application_id = options.application_id, ref_no = options.id, txn_id = response.razorpay_payment_id;
                this.updatePaymentStatus(application_id, ref_no, txn_id).subscribe(res => {
                    console.log(res)
                    if (res.status === true) {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url]);
                        this._gService.getDataByID(application_id, 'online_admissions').subscribe(res => {
                            console.log(res)
                            if(res.status === true) {
                                this.admissionDetails = JSON.parse(res.result)
                                console.log(this.admissionDetails)
                            }
                        })   
                        
                        this.showConfirmation = true
                        // this._gService.triggerSnackbar('Payment Success', 'success', true);
                    }else {
                        this._gService.triggerSnackbar('Something went wrong', 'error', true);
                    }
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
            }
            options.response = response;
            console.log(response);
            console.log(options);
            console.log(error)
            // call your backend api to verify payment signature & capture transaction
        });
        options.modal.ondismiss = (() => {
        // handle the case when user closes the form while transaction is in progress
            console.log('Transaction cancelled.');
        });
        const rzp = new this._winRef.nativeWindow.Razorpay(options);
        rzp.open();
    }
}