import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransportService } from 'src/app/services/transport.service';
import { DesignationModalComponent } from 'src/app/components/modal/designationmodal/designationmodal.component';
import { DestinationModalComponent } from 'src/app/components/modal/destinationmodal/destinationmodal.component';

@Component({
    selector: 'app-transport-destination',
    templateUrl: './destination.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class destinationComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public destinationData: any[] = [];
    public routeData: any[] = [];
    // public mediumData: any[] = [];

    // public sectionValue: '';

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Route: ['', Validators.required],
        Dest_name: ['', Validators.required],
        Time: ['', Validators.required],
        Fare: ['', Validators.required],
        
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'destination').subscribe(dRes => {
            console.log(dRes)
            if (dRes.status === true) {
                this.destinationData = JSON.parse(dRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.routeData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.showLoader(false);

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room_type').subscribe(rRes => {
        //     console.log(rRes)
        //     if (rRes.status === true) {
        //         this.RoomtypeData = JSON.parse(rRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        // })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    // getSectionName(sectionID: number) {
    //     this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
    //         if (sRes.status === true) {
    //             let sData = JSON.parse(sRes.result)[0];
    //             return sData
    //         }
    //     })
    // }

    openDestinationEditModal(destID: number ) {
        this.modalRef = this.modalService.show(DestinationModalComponent, {
            class: 'modal-dialog-centered route-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                destinationID: destID
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveDestination() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                route: rObj.Route,
                destination_name: rObj.Dest_name,
                stop_time: rObj.Time,
                fare: rObj.Fare,
                
                
            }
            this._aService.destinationData(ayData).subscribe(rRes => {
                console.log(rRes)
                if (rRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Destination details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}