import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { SelectModalComponent } from 'src/app/components/modal/selectmodal/selectmodal.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-enpcms-pages',
  templateUrl: './pages.component.html',
})

export class PagesComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageGallerySrc: any = '';
    selectedGalleryImgs: any[] = [];
    imageSrc: any = '';
    selectedImgSrc: any = '';
    featuredImgType: string = '';
    galleryStatus:boolean = false;
    status:boolean = false;
    remove:boolean = false;
    removeGallery:boolean = false;
    public imgURL = this._gService.getImgPath();
    public pageData: any[] = [];
    public selectedImages!: FileList;
    public galleryImages: any;

    dtOptions: any = {};

    modalRef: BsModalRef;

    editorConfig: AngularEditorConfig = {
        editable: true,
        height: '500px',
        placeholder: 'Enter text here...',
    }

    constructor(
        private _gService: GlobalService,
        private _aService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,

        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Title: ['',Validators.required],
        Content: ['',Validators.required],
        Meta_title: [''],
        Meta_desc:[''],
        Meta_keyword:[''],
        Status:['1'],
        Type:['standard'],
        Template_img:[''],
        Venue:[''],
        Start_date:[''],
        End_date:[''],
        News_date:[''],
        Gallery_img:[''],
    })

    ngOnInit(): void {

        

    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
              this.featuredImgType = 'upload'       
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.remove = true;
        this.featuredImgType = '';   
        this.imageSrc = '';
    }

    removeSelectedImg() {
        this.featuredImgType = '';   
        this.selectedImgSrc = '';
    }

    removeSelectedGalleryImg() {
        this.removeGallery = true;
        this.featuredImgType = '';   
        this.selectedGalleryImgs = [];
    }

    delGalleryImg(fileName: any) {
        this.selectedGalleryImgs.splice(this.selectedGalleryImgs.indexOf(fileName), 1)
    }

    onImageSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
          if (inputElement?.files && inputElement.files.length > 0) {
            this.selectedImages = inputElement.files;
          }
        this.galleryImages = this.getImagesArray(this.selectedImages)
        console.log(this.galleryImages)
    }

    async getImagesArray(images: FileList) {

        let promises = [];
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            promises[index] = new Promise(resolve => {
                let reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = (e) => {
                    resolve(e.target!.result);
                };
            });
        }
        await Promise.all(promises).then(data => {
            console.log("data: ", data)
            this.galleryImages = data
        })
        return this.galleryImages;
    }
        
      upload(): void {
        if (this.selectedImages) {
          this.uploadFiles(this.selectedImages);
        }
      }

      private uploadFiles(images: FileList): void {
        let promises = [];
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            promises[index] = new Promise(resolve => {
                let reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = (e) => {
                    resolve(e.target!.result);
                };
            });
            console.log(images)
        
        //   const element = images[index];
        //   console.log(element)
        //   const reader = new FileReader();
        //    reader.readAsDataURL(element);
        //    reader.onload = () => {
        //       this.galleryImages = reader.result;          
        //    }
        //    reader.onerror = function (error) {
        //     console.log('Error: ', error);
        //   };
          console.log(this.galleryImages)
          let uploadData= {
            inst_id: this.instID,
            img_file: this.galleryImages
          }
          console.log(uploadData)
          this._aService.mediaUploadData(uploadData).subscribe(res => {
            console.log(res)
          }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        //   this.fakeImageUploadService.uploadImage(element).subscribe((p) => {
        //     console.log(p)
        //   });
        }
        // Promise.all(promises).then(console.log)
        Promise.all(promises).then(data => {
            console.log(data)
        })
        console.log(promises)

      }
    openMediaModal(type: string){
        this.modalRef = this.modalService.show(SelectModalComponent, {
            class: 'modal-dialog-centered media-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                type: type,
            } 
        });

        this.modalRef.content.selectedMedias.subscribe((data: any) => {
            if (data.type == 'gallery') {
                this.selectedGalleryImgs = data.filename;
                console.log(this.selectedGalleryImgs)
                
            } else {
                this.selectedImgSrc = data.filename;
            }
            this.featuredImgType = 'select'   
          });
      }

      
  

    savePages() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                // academic_id: this.activeAcademicID,
                title: rObj.Title,
                content: rObj.Content,
                status: rObj.Status,
                meta_title:rObj.Meta_title,
                meta_desc: rObj.Meta_desc,
                meta_keywords: rObj.Meta_keyword,
                featured_img: this.imageSrc,
                remove_featured_img: this.remove,
                type: rObj.Type,
                venue: rObj.Venue,
                start_date: rObj.Start_date,
                end_date: rObj.End_date,
                news_date: rObj.News_date,
                gallery_images: JSON.stringify(this.selectedGalleryImgs),
                remove_gallery_images: this.removeGallery,
                featured_img_type: this.featuredImgType,
                featured_img_file: this.selectedImgSrc
            }
            console.log(mData)
            this._aService.pagesData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['cms/pages']);
                    this._gService.triggerSnackbar('Pages Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}