import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-edit-profile.component.html',
//   styleUrls: ['userProfile.component.css']
})

export class AdminEditProfileComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.AdminUserID;
    public userName = this.userData.AdminUserName;
    public userRole = this.userData.AdminUserRole;
    public instID = this.userData.InstID;
    public instData = [];
    public AdminData = [];
    dtOptions: DataTables.Settings = {};

    imageSrc: any = '';
    status: boolean = false;
    
    removeImgStatus: boolean = false;

    public imageURL = 'https://apiuat.edunationpro.com/uploads/';

    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _userService: UserService,
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
    ) {}

    public rObj = this._fBuilder.group({
        
        First_name: [''], 
        Last_name: [''], 
        Admin_Email: [''],  
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        console.log(this.userData)
        // alert(this.userID)

        this._adminService.getDataByID(this.userID, 'admin_users').subscribe(res =>{
            console.log(res)
            if (res.status == true) {
                this.AdminData = JSON.parse(res.result)
                console.log(this.AdminData)
                let name = this.AdminData[0].name.split(' ')
                this.rObj.patchValue({
                    First_name: name[0],
                    Last_name: name[1],
                    Admin_Email: this.AdminData[0].email,

                })
                this.imageSrc = this.AdminData[0].photo_path ? this.imageURL+this.AdminData[0].photo_path : '';
            }
        })
        this._gService.showLoader(false);
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
        this.removeImgStatus = true;
    }

    updateInst() {
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let instData = {
                user_id: this.userID,
                firstname: rObj.First_name,
                lastname: rObj.Last_name,
                email: rObj.Admin_Email,
                photo: this.imageSrc,
            }
            console.log(instData);
            this._adminService.getAdminUserData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Admin Details Updated Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })

        this._gService.showLoader(false);
        }
    }
}