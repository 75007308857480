<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Fees Master</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-9">
            <!--<a href="../../hostel-detail/add/" class="content-header-link">Add Hostel</a>
            <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel"> 
                <div class="panel-header">
                    <div class="panel-title">
                        Add Fees Master
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        
                        <div class="form-group">
                            <label>Class <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section <small class="req"> *</small></label>
                            <select name="section" formControlName="Section" class="form-select" >
                                <option value="">Please Select</option>
                                <ng-container *ngFor="let section of classSectionData" >
                                    <option [value]="section.id">{{section.name}}</option>
                                </ng-container>
                            </select>
                            <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                <p class="input-error-msg">Please select section</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Fees Group <small class="req"> *</small></label>
                            <select name="class" formControlName="Fees_group" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let m of feesGroupData" value="{{m.ID}}">{{m.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Fees_group.invalid && (rObj.controls.Fees_group.dirty || rObj.controls.Fees_group.touched)">
                                <p class="input-error-msg">Please select Fees group</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Fees Type</label>
                            <small class="req"> *</small>
                            <select formControlName="Fees_type" class="form-select">
                                <option value="">Please Select</option>
                                <option *ngFor="let type of feesTypeData" value="{{type.ID}}">{{type.name}}</option>                                 
                            </select>
                            <div *ngIf="rObj.controls.Fees_type.invalid && (rObj.controls.Fees_type.dirty || rObj.controls.Fees_type.touched)">
                                <p class="input-error-msg">Please select Fees type</p>
                            </div>
                        </div>
                        <div class="form-group amount">
                            <label>Amount</label>
                            <small class="req amount"> *</small>
                            <input type="number" class="form-control amount" formControlName="Amount"  id="amount">
                            <div *ngIf="rObj.controls.Amount.invalid && (rObj.controls.Amount.dirty || rObj.controls.Amount.touched)">
                                <p class="input-error-msg">Please select Amount</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveData()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Fees Master List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feesMasterData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Class</th>
                                <th>Section</th>
                                <!-- <th *ngIf="isVernacular">Medium</th> -->
                                <th>Fee Group</th>
                                <th class="align-left">Fees Type</th>
                                <th>Total Amount</th>
                                <th class="action-btn">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of feesMasterData">
                                <td class="align-left">{{f.class_name}}</td>
                                 <td>{{f.section_name}}</td>
                                <!-- <td *ngIf="isVernacular">
                                    <ng-container *ngFor="let medium of mediumData">
                                        <ng-container *ngIf="medium.ID == f.medium">
                                            {{medium.name}}
                                        </ng-container>
                                    </ng-container>
                                </td> -->
                                <td>{{f.fees_group_name}}</td>
                                <td class="align-left">
                                    <!-- {{f.fees_type_names}}<br> -->
                                    <!-- <ng-container *ngFor="let type of feesTypeData"> -->
                                        <!-- <ng-container *ngIf="type.ID == f.fees_type"> -->
                                        <ng-container *ngFor="let type_name of stringToArray(f.fees_type_names); let i = index">
                                            <ng-container *ngFor="let amt of stringToArray(f.amounts); let j = index">
                                                <ng-container *ngFor="let fm_id of stringToArray(f.fees_master_ids); let l = index">
                                                <ng-container *ngIf="i == j && i == l && j == l">
                                                    <div>
                                                         {{type_name}} - {{amt}}  
                                                         <span class="action-btn">
                                                            <a tooltip="Edit" class="edit-btn" (click)="openFeesMasterEditModal(fm_id)"><i class="far fa-edit"></i></a>
                                                            <a tooltip="Delete" class="del-btn" (click)="deleteData(fm_id, 'fees_master', 'fees master')"><i class="far fa-trash-alt"></i></a>
                                                         </span>
                                                    </div>
                                                </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    <!-- </ng-container> -->
                                </td>
                                <td>{{f.total_amount}}</td>
                                <td class="action-btn">
                                    <!-- <a tooltip="Edit" class="edit-btn" (click)="openFeesMasterEditModal(f.ID)"><i class="far fa-edit"></i></a> -->
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(f.ID, 'fees_master', 'fees master')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!feesMasterData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>