<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Inventory Category</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../store-management/inventory-item/add/" class="content-header-link">Add Inventory</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Category
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Category Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Category_name">
                            <div *ngIf="rObj.controls.Category_name.invalid && (rObj.controls.Category_name.dirty || rObj.controls.Category_name.touched)">
                                <p class="input-error-msg">Please enter category name</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveCategory()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Category List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryCategoryData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Category Name</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let category of inventoryCategoryData">
                                <td class="align-left">{{category.category_name}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openCategoryEditModal(category.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(category.ID, 'inventory_category', 'inventory category')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!inventoryCategoryData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>    
                </div>
            </div>
        </div>
    </div>
        
</div>