import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy , ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-staff-editStaff',
    templateUrl: './editStaff.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class editStaffComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public staffID: any;

    public designationData: any[] = [];
    public departmentData: any[] = [];
    public countriesData: any[] = [];
    public staffData: any[] = [];
    public userRoleData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    remove:boolean = false;

    file1Src:any = '';
    file1status:boolean = false;
    remove1Document:boolean = false;

    file2Src:any = '';
    file2status:boolean = false;
    remove2Document:boolean = false;

    file3Src:any = '';
    file3status:boolean = false;
    remove3Document:boolean = false;

    file4Src:any = '';
    file4status:boolean = false;
    remove4Document:boolean = false;

    public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    modalRef: BsModalRef;

    public basicTab: boolean = true;
    public addressTab: boolean = false;
    public documentTab: boolean = false;
    public payrollTab: boolean = false;
    public otherTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Staff_id: ['', Validators.required],
        Role: ['',Validators.required],
        Joining_date: ['',Validators.required], 
        Department: ['', Validators.required],
        Designation: ['', Validators.required],
        Pan_number: [''],
        First_name: ['', Validators.required],
        Last_name: ['', Validators.required],
        Dob: ['', Validators.required],
        Gender: ['', Validators.required],
        Alt_mobile: [''],
        Mobile: ['', [Validators.required,Validators.pattern("[0-9 ]{10}")]],
        Email: ['', [Validators.required, Validators.email]],
        Father_name: [''],
        Mother_name: [''],
        Marital_status: [''],
        Aadhaar_number: [''],
        Qualification: [''],
        Work_experience:  [''],
        Permanent_address: [''],
        Current_address: [''],
        City: [''],
        Pincode: [''],
        State: [''],
        Country: [''],
        Epf_number:[''],
        Basic_salary:['',],
        Contract_type:[''],
        Bank_account_name: [''],
        Bank_account_number: [''],
        Bank_name: ['',],
        Bank_branch_name:[''],
        Bank_ifsc: [''],
        Casual_leave: [''],
        Medical_leave: [''],
        Maternity_paternity_leave: [''],
        Document1_title:[''],
        Document1_file: [''],
        Document2_title: [''],
        Document2_file: [''],
        Document3_title: [''],
        Document3_file: [''],
        Document4_title: [''],
        Document4_file:[''],
        PhotoUpload:['']
       
    })

    ngOnInit(): void {

        this.staffID = this._aRoute.snapshot.paramMap.get('id');

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.designationData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.staffData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.staffID, 'staff').subscribe(dataRes => {
            console.log(this.staffID)
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Staff_id: sData[0].staff_id,
                    Role: sData[0].role,
                    Joining_date: sData[0].joining_date, 
                    Department: sData[0].department,
                    Designation: sData[0].designation,
                    Pan_number: sData[0].pan_number,
                    First_name: sData[0].first_name,
                    Last_name: sData[0].last_name,
                    Dob: sData[0].dob,
                    Gender: sData[0].gender,
                    Father_name: sData[0].father_name,
                    Mother_name: sData[0].mother_name ,
                    Email: sData[0].email,
                    Aadhaar_number: sData[0].aadhaar_number != 0 ? sData[0].aadhaar_number : '',
                    Alt_mobile: sData[0].alt_mobile != 0 ? sData[0].alt_mobile : '',
                    Mobile: sData[0].mobile != 0 ? sData[0].mobile : '',
                    Marital_status: sData[0].marital_status,
                    Qualification: sData[0].qualification,
                    Work_experience:  sData[0].work_experience,
                    Permanent_address: sData[0].permanent_address,
                    Current_address: sData[0].current_address,
                    City: sData[0].city,
                    Pincode: sData[0].pincode,
                    State: sData[0].state,
                    Country: sData[0].country,
                    Epf_number:sData[0].epf_number,
                    Basic_salary:sData[0].basic_salary,
                    Contract_type:sData[0].contract_type,
                    Bank_account_name: sData[0].bank_account_name,
                    Bank_account_number:sData[0].bank_account_number,
                    Bank_name: sData[0].bank_name,
                    Bank_branch_name:sData[0].bank_branch_name,
                    Bank_ifsc: sData[0].bank_ifsc,
                    Casual_leave: sData[0].casual_leave,
                    Medical_leave:sData[0].Medical_leave,
                    Maternity_paternity_leave: sData[0].maternity_paternity_leave,
                });
                this.imageSrc = sData[0].photo_path ? this.imgURL+sData[0].photo_path : '';

                this._aService.getStaffDocumentData(this.staffID).subscribe( res =>{
                    console.log(res)
                    if(res.status === true){
                        let dData = JSON.parse(res.result); 
                        let d1Data = dData.filter((t : any) => t.doc_no == 1).map((t : any) => t.path)
                        this.file1Src = d1Data.length ? this.imgURL+d1Data : '';
                        let d2Data = dData.filter((t : any) => t.doc_no == 2).map((t : any) => t.path)
                        this.file2Src = d2Data.length ? this.imgURL+d2Data : '';
                        let d3Data = dData.filter((t : any) => t.doc_no == 3).map((t : any) => t.path)
                        this.file3Src = d3Data.length ? this.imgURL+d3Data : '';
                        let d4Data = dData.filter((t : any) => t.doc_no == 4).map((t : any) => t.path)
                        this.file4Src = d4Data.length ? this.imgURL+d4Data : '';
                        this.rObj.patchValue({
                           Document1_title: dData.filter((t : any) => t.doc_no == 1).map((t : any) => t.name),  
                           Document2_title: dData.filter((t : any) => t.doc_no == 2).map((t : any) => t.name),
                           Document3_title: dData.filter((t : any) => t.doc_no == 3).map((t : any) => t.name),
                           Document4_title: dData.filter((t : any) => t.doc_no == 4).map((t : any) => t.name),
                        })
                    }
                })
            }
            this._gService.showLoader(false);
        })


        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

       
    }
    
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.remove = true;
        this.imageSrc = '';
    }

    onDoc1FileChange(event:any) {
        this.file1status = false
        const file = event.target.files[0];
        this.file1status = event.target.files.length > 0 ? true : false;
        if (this.file1status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file1Src = reader.result;   
                 
           }
        }
        console.log(this.file1Src)   
        
    }

    removeDocument1() {
        this.file1Src = '';
        this.remove1Document = true;
    }
    onDoc2FileChange(event:any) {
        this.file2status = false
        const file = event.target.files[0];
        this.file2status = event.target.files.length > 0 ? true : false;
        if (this.file2status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file2Src = reader.result;   
                 
           }
        }
        console.log(this.file2Src)   
        
    }

    removeDocument2() {
        this.file2Src = '';
        this.remove2Document = true
    }
    onDoc3FileChange(event:any) {
        this.file3status = false
        const file = event.target.files[0];
        this.file3status = event.target.files.length > 0 ? true : false;
        if (this.file3status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file3Src = reader.result;   
                 
           }
        }
        console.log(this.file3Src)   
        
    }

    removeDocument3() {
        this.file3Src = '';
        this.remove3Document = true
    }
    onDoc4FileChange(event:any) {
        this.file4status = false
        const file = event.target.files[0];
        this.file4status = event.target.files.length > 0 ? true : false;
        if (this.file4status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file4Src = reader.result;   
                 
           }
        }
        console.log(this.file4Src)   
        
    }

    removeDocument4() {
        this.file4Src = '';
        this.remove4Document = true
    }
    setTabActive(tabName: string) {
        if (tabName == 'basicTab') {
            this.basicTab = true
        } else {
            this.basicTab = false
        }
        if (tabName == 'addressTab') {
            this.addressTab = true
        } else {
            this.addressTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'payrollTab') {
            this.payrollTab = true
        } else {
            this.payrollTab = false
        }
        if (tabName == 'otherTab') {
            this.otherTab = true
        } else {
            this.otherTab = false
        }
    }


    updateStaff() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'update',
                id: this.staffID,
                staff_id: rObj.Staff_id,
                role: rObj.Role,
                joining_date: rObj.Joining_date,
                department: rObj.Department,
                designation: rObj.Designation,
                pan_number: rObj.Pan_number,
                first_name: rObj.First_name,
                last_name: rObj.Last_name,
                dob: rObj.Dob,
                gender: rObj.Gender,
                alt_mobile: rObj.Alt_mobile  ? rObj.Alt_mobile : '',
                mobile: rObj.Mobile ? rObj.Mobile : '',
                email: rObj.Email,
                father_name: rObj.Father_name,
                mother_name: rObj.Mother_name,
                category: rObj.Marital_status,
                aadhaar_number: rObj.Aadhaar_number  ? rObj.Aadhaar_number : '',
                marital_status: rObj.Marital_status,
                qualification: rObj.Qualification,
                work_experience: rObj.Work_experience,
                permanent_address: rObj.Permanent_address,
                current_address: rObj.Current_address,
                city: rObj.City,
                pincode: rObj.Pincode ? rObj.Pincode : '', 
                state: rObj.State,
                country: rObj.Country,
                epf_number: rObj.Epf_number,
                basic_salary: rObj.Basic_salary,
                contract_type: rObj.Contract_type,
                bank_account_name: rObj.Bank_account_name,
                bank_account_number: rObj.Bank_account_number,
                bank_name: rObj.Bank_name,
                bank_branch_name: rObj.Bank_branch_name,
                bank_ifsc: rObj.Bank_ifsc,
                casual_leave: rObj.Casual_leave,
                medical_leave: rObj.Medical_leave,
                maternity_paternity_leave: rObj.Maternity_paternity_leave,
                photo_path:  this.status ? this.imageSrc : '',
                remove_photo:this.remove,
                document1_title: rObj.Document1_title,
                document1_file: this.file1status ? this.file1Src : '',
                document2_title: rObj.Document2_title,
                document2_file: this.file2status ? this.file2Src : '',
                document3_title: rObj.Document3_title,
                document3_file: this.file3status ? this.file3Src : '',
                document4_title: rObj.Document4_title,
                document4_file: this.file4status ? this.file4Src : '',
                remove_document1: this.remove1Document,
                remove_document2: this.remove2Document,
                remove_document3: this.remove3Document,
                remove_document4: this.remove4Document,
              }
            console.log(sData)
            this._aService.staffData(sData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Staff Details Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}