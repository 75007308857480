import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class SyllabusService {
  constructor(
    private _http: HttpClient,
  ) { }

 
  private syllabus = `${environment.APIURL}/syllabus/addUpdate/`;
  private topics = `${environment.APIURL}/syllabus/topic/`;
  private gettopics = `${environment.APIURL}/syllabus/getTopicsByLessionID/`;
  private getSyllabusStatus = `${environment.APIURL}/syllabus/getSyllabusByClassSectionSubject/`;
  private updateTopicStatus = `${environment.APIURL}/syllabus/updateTopicStatus/`;

  updateTopicsStatusData(rObj: object): Observable<any> {
    return this._http.post(this.updateTopicStatus, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  syllabusTitleData(rObj: object): Observable<any> {
      return this._http.post(this.syllabus, rObj, {
        headers: { 'Content-Type': 'application/json'}
      });
    }
  topicData(rObj: object): Observable<any> {
    return this._http.post(this.topics, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  gettopicData(lesson_id: any): Observable<any> {
    return this._http.get(this.gettopics+'?lesson_id='+lesson_id, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  getSyllabusStatusData(inst_id: any, academic_id: any, class_id: any, section_id: any, subject_id: any): Observable<any> {
    return this._http.get(this.getSyllabusStatus+'?inst_id='+inst_id+'&academic_id='+academic_id+'&class_id='+class_id+'&section_id='+section_id+'&subject_id='+subject_id, {
      headers: { 'Content-Type': 'application/json'}
    });
  }
}