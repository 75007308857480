import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
  
@Component({
    selector: 'app-academicyearmodal',
    templateUrl: './academicyearmodal.component.html',
    styleUrls: ['./academicyearmodal.component.css'],
})

export class AcademicYearModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public sessionData = JSON.parse(sessionStorage.getItem('currentSession')!);
    public instID = this.userData.InstID;
    public activeAcademicID: number;
    public academicYearsData: any[] = [];

    public academicID: number;

    constructor(
        private _gService: GlobalService,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAcademicYearsDataByInstID(this.instID).subscribe(ayRes => {
            console.log(ayRes);
            if (ayRes.status === true) {
                this.academicYearsData = JSON.parse(ayRes.result);
                console.log(this.academicYearsData)
            } else {
                console.log(ayRes.msg);
            }
            this._gService.showLoader(false);
        })
    }

    onChange(e: any) {
        this.academicID = e.target.value;
    }

    updateAcademicID() {
        this.userData.AcademicID = this.academicID;
        localStorage.setItem('currentUser', JSON.stringify(this.userData));
        console.log(this.userData)
        this.modalRef.hide();
        this.modalRef.onHidden?.subscribe(() => {
            this._reRoute.shouldReuseRoute = () => false;
            this._router.navigate([this._router.url])
            this._gService.triggerSnackbar('Academic Session Updated Successfully!', 'success', true) 
        });
    }
    
}