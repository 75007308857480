import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class FeesService {
  constructor(
    private _http: HttpClient,
  ) { }

  private feestype = `${environment.APIURL}/fees/feesType/`;
  private feesMaster = `${environment.APIURL}/fees/feesMaster/`;
  private feesGroup = `${environment.APIURL}/fees/feesGroup/`;
  private feesDiscount = `${environment.APIURL}/fees/feesDiscount/`;
  private payFees = `${environment.APIURL}/fees/payFees/`;
  private  getStatus =  `${environment.APIURL}/fees/getFeesStatus/`;
  private  getDueFees =  `${environment.APIURL}/fees/getDueFees/`;
  private  getpayFees =  `${environment.APIURL}/fees/getFeesByTxnID/`;
  private getCollectedFees = `${environment.APIURL}/fees/getFeesCollectionDataByTxnID/`
  private getFeesMaster = `${environment.APIURL}/fees/getFeesMasterDataByClassSection/`
  private multifeePay = `${environment.APIURL}/fees/payMultiFees/`
 
  multiPayFeeData(data: object): Observable<any> {
    return this._http.post(this.multifeePay, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  feesTypeData(data: object): Observable<any> {
    return this._http.post(this.feestype, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  feesMasterData(data: object): Observable<any> {
    return this._http.post(this.feesMaster, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  feesGroupData(data: object): Observable<any> {
    return this._http.post(this.feesGroup, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  feesDiscountData(data: object): Observable<any> {
    return this._http.post(this.feesDiscount, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  payFeesData(data: object): Observable<any> {
    return this._http.post(this.payFees, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getStatusData(student_id:any): Observable<any> {
    return this._http.get(this.getStatus+'?student_id='+student_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getCollectedFeesDataByTxnID(txn_id:any): Observable<any> {
    return this._http.get(this.getCollectedFees+'?txn_id='+txn_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  getDueFeesData(instID:any, activeAcademicID:any, feeType:any, classId:any, sectionId:any, medium: any): Observable<any> {
    return this._http.get(this.getDueFees+'?inst_id='+instID+'&academic_id='+activeAcademicID+'&fees_type_id='+feeType+'&class_id='+classId+'&section_id='+sectionId+'&medium='+medium, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  
  getPayData(txn_id:any): Observable<any> {
    return this._http.get(this.getpayFees+'?txn_id='+txn_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  getFeesMasterData(instID:any, academicID: any, classID: any, sectionID: any): Observable<any> {
    return this._http.get(this.getFeesMaster+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classID+'&section_id='+sectionID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
}