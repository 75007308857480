import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-subjectmodal',
    templateUrl: './subjectmodal.component.html',
    styleUrls: ['./subjectmodal.component.css'],
})

export class SubjectModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public subjectID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public subjectData: any[] = [];
    

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    public rObj : any = this._fBuilder.group({
        Subject :['', Validators.required],
        subjectType: ['', Validators.required],
        subjectCode : ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.subjectID, 'subjects').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result)[0];
                this.rObj.patchValue({
                    Subject: sData.name,
                    subjectType: sData.type,
                    subjectCode: sData.code,   
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateSubject() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'update',
                id: this.subjectID,
                subject: rObj.Subject,
                subjectType: rObj.subjectType,
                subjectCode: rObj.subjectCode,
                }
            console.log(sData)
            this._aService.subjectData(sData).subscribe(sRes => {
                console.log(sRes)
                this.modalRef.hide();
                if (sRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Subject Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}