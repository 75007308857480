import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { FeesService } from 'src/app/services/fees.service';
@Component({
    selector: 'app-feesDiscountmodal',
    templateUrl: './feesDiscountmodal.component.html',
    styleUrls: [],
})

export class FeesDiscountmodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public feesDiscountID: number;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Discount_code: ['',Validators.required],
        Discount_amount: [''],
        Description: [''],
        Discount_type: [''],
        Percentage: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.feesDiscountID, 'fees_discount').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Name: sData[0].name,
                    Discount_code: sData[0].discount_code,
                    Discount_amount: sData[0].discount_amount,
                    Description: sData[0].description,
                    Percentage: sData[0].discount_percentage,
                    Discount_type: sData[0].discount_type
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateData() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.feesDiscountID,
                name: rObj.Name,
                discount_code: rObj.Discount_code,
                discount_amount: rObj.Discount_type == 'fix' ? rObj.Discount_amount : '',
                discount_percentage: rObj.Discount_type == 'percentage' ? rObj.Percentage : '',
                discount_type: rObj.Discount_type,
                description: rObj.Description,
            }
            this._aService.feesDiscountData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Fees Discount details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}