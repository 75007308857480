import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { PayrollService } from 'src/app/services/payroll.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExaminationService } from 'src/app/services/examination.service';
import { MarksGradeModalComponent } from 'src/app/components/modal/marksGrademodal/marksGrademodal.component';

@Component({
  selector: 'app-examination-marksGrade',
  templateUrl: './marksGrade.component.html',
})

export class MarksGradeComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public gradeTypeData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: ExaminationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Grade_name: ['', Validators.required],
        Percentage_from: ['', Validators.required],
        Percentage_upto: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'marks_grade').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.gradeTypeData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openPayHeadEditModal(gradetypeID: number) {
        this.modalRef = this.modalService.show(MarksGradeModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                gradetypeID: gradetypeID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveGrade() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                grade_name: rObj.Grade_name,
                per_from: rObj.Percentage_from,
                per_upto: rObj.Percentage_upto,
            }
            console.log(mData)
            this._aService.gradeTypeData(mData).subscribe(mRes => {
                console.log(mRes)
                if (mRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Grade type Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}