import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { StudentService } from 'src/app/services/student.service';
import { ExaminationService } from 'src/app/services/examination.service';
import { NotificationService } from 'src/app/services/notification.service';
  
@Component({
    selector: 'app-markRegistermodal',
    templateUrl: './markRegistermodal.component.html',
    // styleUrls: ['./mediummodal.component.css'],
})

export class markRegistermodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public examID: number;
    public subjectMarks: number;
    public classID: any;
    public subject_name: any;
    public exam_type: any;
    public sectionID: any;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public studentListData : any[] = []
    public MarksDataArray: any[] = []
    public studentMarksData: any[] = []
    public examData: any[] = []
    public ids: any[] = []

    dtOptions: any = {};
    constructor(
        private _gService: GlobalService,
        private _sService: StudentService,
        private _nService: NotificationService,
        private _eService: ExaminationService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    // public rObj = this._fBuilder.group({
    //     MarksObtained: ['', Validators.required],
    //     Remark: [''],
    // })
    public sObj = this._fBuilder.group({
        marksRow : this._fBuilder.array([], Validators.required)
    })

    marksRow(): FormArray {
        return this.sObj.get("marksRow") as FormArray
    }

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'exam').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.examData = JSON.parse(cRes.result);
                console.log(this.examData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        // alert(this.examID)
        this._sService.studentListData(this.instID, this.activeAcademicID,this.classID,this.sectionID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this.studentListData = JSON.parse(sRes.result)
                console.log(this.instID,this.activeAcademicID,this.examID,this.classID,this.sectionID)
                this._eService.getMarksData(this.instID, this.activeAcademicID, this.classID, this.sectionID, this.examID).subscribe(cRes => {
                    console.log(cRes)
                    if (cRes.status == true) {
                        this.studentMarksData = JSON.parse(cRes.result);
                            // alert('yes')
                            let marksRowArray = <FormArray>this.sObj.controls.marksRow;
                            marksRowArray.controls = this.studentMarksData.map(s => this._fBuilder.group({
                                Student_id: [s.student_id],
                                Student_name: [s.full_name],
                                Admission_no: [s.admission_number], 
                                Roll_no: [s.roll_no],
                                Marks: [s.marks], 
                                Remark: [s.remark],
                            }))
                            console.log(marksRowArray.controls)
                    } else {
                        if (this.studentListData.length) {
                        // alert('No')
                        let marksRowArray = <FormArray>this.sObj.controls.marksRow;
                        marksRowArray.controls = this.studentListData.map(s => this._fBuilder.group({
                            Student_id: [s.ID],
                            Student_name: [s.full_name],
                            Admission_no: [s.admission_number], 
                            Roll_no: [s.roll_no],            
                            Marks: [''],
                            Remark: [''],
                        }))
                    }
                    }
                })
            } else {
                this.studentListData = [];
            }
            this._gService.showLoader(false);
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    save() {
        if(this.sObj.valid) {
            this._gService.showLoader(true);
           let sObj = this.sObj.controls.marksRow.controls;
           for (let i = 0; i < sObj.length; i++) {
            this.MarksDataArray.push(sObj[i].value)
          }
           let aData = {
            inst_id: this.instID,
            academic_id: this.activeAcademicID,
            class_id: this.classID,
            section_id: this.sectionID,
            exam_id: this.examID,
            marksData: this.MarksDataArray
           }
           
           console.log(aData)
          console.log(this.MarksDataArray)
        
        this._eService.marksRegisterData(aData).subscribe((aRes : any) => {
            console.log(aRes)
            if (aRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Student Marks Saved Successfully', 'success', true);

                this._sService.studentListData(this.instID, this.activeAcademicID,this.classID,this.sectionID).subscribe(sRes => {
                    console.log(sRes)
                    if (sRes.status === true) {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url]);
                        let studentListData = JSON.parse(sRes.result)
                        studentListData.forEach(i => {
                            this.ids.push(JSON.parse(i.user_id))
                            console.log((JSON.stringify(this.ids)))
                        })
                        let ndata = {
                            inst_id: this.instID,
                            academic_id: this.activeAcademicID,
                            user_role: '4',
                            user_ids: JSON.stringify(this.ids),
                            title: 'Exam Result Available for '+ this.examData.filter(i => i.ID == this.examID).map(i => i.name),
                            msg:  'optional',
                            link: 'examination/exam-result/list',
                            screen: 'Examination'
                        }
                        console.log(ndata)
                        this._nService.sendNotificationData(ndata).subscribe(res => {
                            console.log(res)
                            if (res.status === true) {
                                this._reRoute.shouldReuseRoute = () => false;
                                this._router.navigate([this._router.url]);
                                this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                            }
                        })                                              
                    }
                })
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        this._gService.showLoader(false);
    }
    }
    
}