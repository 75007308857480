import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class HolidayService {
  constructor(
    private _http: HttpClient,
  ) { }

  private holiday = `${environment.APIURL}/holidays/addUpdate/`;
 
 
  holidayData(data: object): Observable<any> {
    return this._http.post(this.holiday, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
//   feesGroupData(data: object): Observable<any> {
//     return this._http.post(this.feesGroup, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
  
//   feesDiscountData(data: object): Observable<any> {
//     return this._http.post(this.feesDiscount, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
  
}