import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router,RouteReuseStrategy,ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PayModalComponent } from 'src/app/components/modal/paymodal/paymodal.component';
import { FeesService } from 'src/app/services/fees.service';
import { printReceiptModalComponent } from 'src/app/components/modal/printReceiptmodal/printReceiptmodal.component';
import { MultiplePayModalComponent } from 'src/app/components/modal/multiplePaymodal/multiplePaymodal.component';
@Component({
  selector: 'app-fees-collectFee',
  templateUrl: './collectFee.component.html',
  styleUrls : ['./collectFee.component.css']
})

export class CollectFeeComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public feeTypeData: any[] = [];
    public studentData: any[] = [];
    public feesMasterData: any[] = [];
    public statusData: any[] = [];
    public selectedIds : any[] = [];
    public feesDataArray : any[] = [];
    public studentId: any;
    public feeGroupId: any;
    public isDue:boolean = false;
    public imgURL = this._gService.getImgPath();

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc:any = '';
    status:boolean = false;
    // doc1Src: any = '';
    // doc2Src: any = '';
    // doc3Src: any = '';
    // doc4Src: any = '';
    dtOptions: any = {};
    modalRef: BsModalRef;
    viewId :any;

    // public profileTab: boolean = true;
    // public documentTab: boolean = false;
    // public feesTab: boolean = false;
    // public timelineTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _feeService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    

    ngOnInit(): void {
        this.viewId = this._aRoute.snapshot.paramMap.get('id');
        this._gService.showLoader(true);
        this._feeService.getStatusData(this.viewId).subscribe( res => {
            console.log(res)
            if(res.status === true){
                this.statusData = JSON.parse(res.result);
            }
            console.log(this.statusData)
        })

        this._gService.getDataByID(this.viewId, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
                console.log(this.studentData)
                console.log(this.studentData[0].class, this.studentData[0].section)
                // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_master').subscribe(cRes => {
                this._feeService.getFeesMasterData(this.instID, this.activeAcademicID,this.studentData[0].class, this.studentData[0].section).subscribe(cRes => {
                    console.log(cRes)
                    if (cRes.status === true) {
                        this.feesMasterData = JSON.parse(cRes.result);
                        // this.feesMasterData =  this.feesMasterData.filter(r => r.class == this.studentData[0].class && r.section == this.studentData[0].section)
                        console.log(this.feesMasterData)
                    
                    } else {
                        this._gService.triggerSnackbar('No Data Found!', 'error', true);
                    }
                })
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.feeTypeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
        //  this.imageSrc = this.studentData[0].photo_path ? this.imgURL+this.studentData[0].photo_path : '';
        // console.log(this.imageSrc)
        this._gService.showLoader(false);

    }
    stringToArray(text: string) {
        return text.split(',')
    }
    
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.feesMasterData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).disabled ===  false ? (<HTMLInputElement>evt.target).checked : null
            // s.isSelected = (<HTMLInputElement>evt.target).checked 
            if((<HTMLInputElement>evt.target).disabled ===  false  && (<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.feesMasterData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    dueCheck(date: any){
        let now = new Date()
        let  today = moment(now).format('YYYY-MM-DD')
        if(today > date) {
            this.isDue = true
        } else {
            this.isDue = false
        }
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    getTotalAmount(amount : any, fine_type: any,fine_amt: any, percentage_amt: any) {
        let total_amt;
        if(this.isDue) {
            total_amt = fine_type == 'fix' ? parseInt(amount) + parseInt(fine_amt) : parseInt(amount) + (parseInt(amount) * parseInt(percentage_amt)) / 100
        } else {
            total_amt = amount
        }
        return total_amt
    }

    openPayModal(feeID: any, amount: any, due_date:any,fmIDs: any, fine_type: any, fine_amount: any, total_amt:any, fee_group:any) {
        this.modalRef = this.modalService.show(PayModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                feeID: feeID,
                due_date: due_date,
                fmIds: fmIDs,
                totalAmount: total_amt,
                studentId : this.viewId,
                amount : amount,
                fee_group : fee_group,
                fine_type : fine_type,
                fine_amount : fine_amount,
            }             
        });
        console.log(this.isDue)
    }

    getData(selectedIds: any) {
        this.feesDataArray = []
        selectedIds.forEach(i => {
            let data =  this.feesMasterData.filter(f => f.ID == i)
            this.feesDataArray.push(data)
            // console.log(this.feesDataArray)
        })
        return this.feesDataArray
    }

    openMultiplePayModal(selectedIDs: any) {
        this.modalRef = this.modalService.show(MultiplePayModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                studentId: this.viewId,
                fmIds: selectedIDs,
                feeDataArray: this.getData(selectedIDs)
                // totalAmount: this.getTotalAmount(amount,fine_type,)
            }             
        });
        console.log(this.isDue)
    }

    openReceiptModal(TxnID : any) {
        this.modalRef = this.modalService.show(printReceiptModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                TxnID: TxnID,
            } 
        });
    }


    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_ids.split(',').includes(feesId)).map(s => s.txn_id)
    }
        

}