<div class="modal-header">
    <h5 class="modal-title">Edit Menu Items</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Menu Item</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Menu_item">
                <div *ngIf="rObj.controls.Menu_item.invalid && (rObj.controls.Menu_item.dirty || rObj.controls.Menu_item.touched)">
                    <p class="input-error-msg">Please Menu item Menu</p>
                </div>
            </div>

            <div class="form-group">
                <label>Type</label>
                <br>
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Type"  value="external">
                    <label class="form-check-label" for="flexCheckDefault">External</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Type"  value="internal">
                    <label class="form-check-label" for="flexCheckDefault">Internal</label>
                </div>   
            </div>
            <div class="form-group percentage" *ngIf="rObj.controls.Type.value == 'external'">
                <label>External URL Address</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Ex_url_address">
            </div>
            <div class="form-group"  *ngIf="rObj.controls.Type.value == 'internal'">
                <label>Pages</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="Pages">
                    <option value="">Select</option>
                    <option *ngFor="let p of PagesData" value="{{p.ID}}">{{p.title}}</option>
                </select>
                <div *ngIf="rObj.controls.Pages.invalid && (rObj.controls.Pages.dirty || rObj.controls.Pages.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-group">
                <label>Open In New Tab</label>
                <br>
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Target"  value="yes">
                    <label class="form-check-label" for="flexCheckDefault">Yes</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Target"  value="no">
                    <label class="form-check-label" for="flexCheckDefault">No</label>
                </div>   
            </div>

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateMenuItem()">Update</button>
            </div>
        </form>
    </div>
</div>
