<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Pick Up Route Details</h1>
        <!-- <div class="header-msg col-md-6">
                      </div> -->
        <div class="link-container col-md-6">
            <a class="content-header-link" (click)="openNewPickUpRouteAddModal()">Add Pickup Route</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Pickup Point Route List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="pickUpRouteArray.length">
                        <thead>
                            <tr>
                                <th class="align-left">Route</th>
                                <th>Pickup point</th>
                                <th>Monthly Fees</th>
                                <th>Distance(km)</th>
                                <th>Pickup Time</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let p of pickUpRouteList | keyvalue">
                            <td class="align-left">
                               {{getRouteName(p.key)}}
                            </td>
                            <td>
                                <ng-container *ngFor="let val of p.value; let i = index">
                                    <div>
                                        {{i+1}} {{val.pickup_point_name}}
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let val of p.value; let i = index">
                                    <div>
                                       {{val.monthly_fees}}
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let val of p.value; let i = index">
                                    <div>
                                       {{val.distance}}
                                    </div>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let val of p.value; let i = index">
                                    <div>
                                        {{val.pickup_time}}
                                    </div>
                                </ng-container>
                            </td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openPickUpRouteAddModal(p.key)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(p.key, 'route_pickup_points', 'route pickup point')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!pickUpRouteArray.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>