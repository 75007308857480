<div class="modal-header">
    <h5 class="modal-title">Pickup Route Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <div class="panel">
            <form [formGroup]="rObj">
                <div class="form-group">
                    <label>Route</label>
                    <small class="req"> *</small>
                    <select formControlName="Route" class="form-select">
                        <option value="">Route</option>
                        <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>               
                    </select>
                    <div *ngIf="rObj.controls.Route.invalid && (rObj.controls.Route.dirty || rObj.controls.Route.touched)">
                        <p class="input-error-msg">Please enter Route</p>
                    </div>
                </div>
            
                <div class="form-btn-wrapper" style="text-align: right;">
                    <button class="btn default-btn extra-small-btn inline" (click)="addRoute_Data()">Add More</button>
                </div>

                <div class="panel-body">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-3">Pick Up point</div>
                        <div class="col-md-3">Distance(km)</div>
                        <div class="col-md-3">Pick Up Time</div>
                        <div class="col-md-2">Monthly Fees </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div formArrayName="route_Data">
                        <div class="timetable-row" *ngFor="let detail of route_Data().controls; let i = index">
                            <div class="row" [formGroupName]="i">
                                <div class="col-md-3">
                                    <select formControlName="PickUpPoint" class="form-select">
                                        <option value="">Pick Up Point</option>
                                        <option *ngFor="let p of pickUpData" value="{{p.ID}}">{{p.name}}</option>               
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <input type="number" formControlName="Distance" class="form-control" placeholder="Distance">
                                </div>
                                <div class="col-md-3">
                                    <input type="time" formControlName="PickupTime" class="form-control" placeholder="Pick up Time">
                                </div>
                                <div class="col-md-2">
                                    <input type="number" formControlName="MonthlyFees" class="form-control" placeholder="Montly Fees">
                                </div>
                                <div class="col-md-1 align-center">
                                    <button *ngIf="i != 0" class="default-btn"style="margin-top: 15px;" (click)="removeRoute_Data(i, route_Data().controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-btn-wrapper">
                        <button class="btn primary-btn small-btn" style="margin-top: 15px;"  (click)="savePoint()">Save</button>
                    </div>
                </div>
            </form> 
        </div>
    </div>
</div>
