import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { SyllabusService } from 'src/app/services/syllabus.service';
  
@Component({
    selector: 'app-syllabusStatusDatemodal',
    templateUrl: './syllabusStatusDatemodal.component.html',
    // styleUrls: ['./syllabusStatusDatemodal.component.css'],
})

export class SyllabusStatusDateModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public topicID: number;
    public status: number;
    // public mediumID: number;

    constructor(
        private _gService: GlobalService,
        private _sService: SyllabusService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,


    ) {}

    public rObj = this._fBuilder.group({
        Date: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        // this._gService.getDataByID(this.mediumID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
            this._gService.showLoader(false);
        // })
    }

    closeModal() {
        this.modalRef.hide()
        this.modalService.setDismissReason(this.topicID.toString())

    }

    updateSyllabus() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                topic_id: this.topicID,
                status: this.status,
                completion_date: rObj.Date,
            }
            console.log(mData)
            this._sService.updateTopicsStatusData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Syllabus Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}