<div class="modal-header">
    <h5 class="modal-title">Edit Vehicle Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Vehicle No.</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Vehicle_no">		                    
                <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                    <p class="input-error-msg">Please enter vehicle number</p>
                </div>
            </div>
            <div class="form-group">
                <label>Vehicle Model</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Vehicle_model" >
                <div *ngIf="rObj.controls.Vehicle_model.invalid && (rObj.controls.Vehicle_model.dirty || rObj.controls.Vehicle_model.touched)">
                    <p class="input-error-msg">Please enter vehicle model</p>
                </div>
            </div>
            
            <div class="form-group">
                <label>Year Made</label>
                <small class="req"> *</small>
                <input type="text"  class="form-control" formControlName="Vehicle_year">
                <div *ngIf="rObj.controls.Vehicle_year.invalid && (rObj.controls.Vehicle_year.dirty || rObj.controls.Vehicle_year.touched)">
                    <p class="input-error-msg">Please enter vehicle year</p>
                </div>
            </div>

            <div class="form-group">
                <label>Vehicle Type</label>
                <small class="req"> *</small>
                <select  class="form-control" formControlName="Vehicle_type">
                        <option value="">Please Select</option>
                        <option *ngFor="let type of vehicleTypeData" value="{{type.ID}}">{{type.name}}</option>
                </select>
                <div *ngIf="rObj.controls.Vehicle_type.invalid && (rObj.controls.Vehicle_type.dirty || rObj.controls.Vehicle_type.touched)">
                    <p class="input-error-msg">Please select vehicle type</p>
                </div>
            </div>
            <div class="form-group">
                <label>No.of Seats</label>
                <small class="req"> *</small>
                <input type="number"  class="form-control" formControlName="No_seats" >
                <div *ngIf="rObj.controls.Vehicle_type.invalid && (rObj.controls.Vehicle_type.dirty || rObj.controls.Vehicle_type.touched)">
                    <p class="input-error-msg">Please enter number of seats</p>
                </div>
            </div>
            <div class="form-group">
                <label>Maximum Allowed</label>
                <small class="req"> *</small>
                <input type="number"  class="form-control" formControlName="Max_allow" >
                <div *ngIf="rObj.controls.Max_allow.invalid && (rObj.controls.Max_allow.dirty || rObj.controls.Max_allow.touched)">
                    <p class="input-error-msg">Please enter maximum allowed seats</p>
                </div>
            </div>
            <div class="form-group">
                <label>Contact Person</label>
                <small class="req"> *</small>
                <input type="text"  class="form-control" formControlName="Contact_person" >
                <div *ngIf="rObj.controls.Contact_person.invalid && (rObj.controls.Contact_person.dirty || rObj.controls.Contact_person.touched)">
                    <p class="input-error-msg">Please enter contact number</p>
                </div>
            </div>
            <div class="form-group">
                <label>Insurance Renewal Date</label>
                <small class="req"> *</small>
                <input type="text" class="form-control date-input" formControlName="Renewal_date" >
                <div *ngIf="rObj.controls.Renewal_date.invalid && (rObj.controls.Renewal_date.dirty || rObj.controls.Renewal_date.touched)">
                    <p class="input-error-msg">Please select date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Tracking Id</label>
                <small class="req"> *</small>
                <input type="number"  class="form-control" formControlName="Tracking_id"  >
                <div *ngIf="rObj.controls.Tracking_id.invalid && (rObj.controls.Tracking_id.dirty || rObj.controls.Tracking_id.touched)">
                    <p class="input-error-msg">Please enter tracking id</p>
                </div>
            </div>
           
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateVehicle()">Update</button>
            </div>
        </form> 
    </div>
</div>
