import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { MessageService } from 'src/app/services/message.service';
  
@Component({
    selector: 'app-messagemodal',
    templateUrl: './messagemodal.component.html',
    // styleUrls: ['./messagemodal.component.css'],
})

export class MessageModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userRole = this.userData.UserRole;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public ids: any;

    constructor(
        private _gService: GlobalService,
        private _mService: MessageService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Message: ['', Validators.required],
    })

    ngOnInit(): void {
        // this._gService.showLoader(true);

        // this._gService.getDataByID(this.mediumID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Message: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    sendMessage() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                from_id: this.userID,
                from_user_role: this.userRole,
                to_user_role: 4,
                to_ids: this.ids,
                msg: rObj.Message
            }
            console.log(mData)
            this._mService.sendMessageData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Message Send Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}