<div class="console-content">
    <form [formGroup]="rObj">
    <div class="console-content-header row">
        <h1 class="title col-md-2">Add Vendor</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="btn-container link-container col-md-4">
            <!--<a href="../import/" class="content-header-link">Import Book</a>-->
            <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="saveVendor()">Save</button>
       
        </div>
    </div>

    
    <div class="row">
        
        <div class="col-md-12 console-content-tab">
            <div id="basic-details" class="panel panel-margin-top active">
                <div class="panel-header">
                    <div class="panel-title">
                        Vendors Details:
                    </div>
                </div>
                <div class="panel-body">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Company Name</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Company_name" class="form-control" required="">
                                <div *ngIf="rObj.controls.Company_name.invalid && (rObj.controls.Company_name.dirty || rObj.controls.Company_name.touched)">
                                    <p class="input-error-msg">Please enter company name</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Company Phone</label>
                                <small class="req"> *</small>
                                <input type="number" formControlName="Company_phone" class="form-control" required="">
                                <div *ngIf="rObj.controls.Company_phone.invalid && (rObj.controls.Company_phone.dirty || rObj.controls.Company_phone.touched)">
                                    <p class="input-error-msg">Please enter  phone </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Company Email</label>
                                <small class="req"> *</small>
                                <input type="email" formControlName="Company_email" class="form-control " autocomplete="off" value="" required="">
                                <div *ngIf="rObj.controls.Company_email.invalid && (rObj.controls.Company_email.dirty || rObj.controls.Company_email.touched)">
                                    <p class="input-error-msg">Please enter  email</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Company Address</label>
                                <small class="req"> *</small>
                                <textarea type="textarea" formControlName="Company_address" class="form-control" required=""></textarea>
                                <div *ngIf="rObj.controls.Company_address.invalid && (rObj.controls.Company_address.dirty || rObj.controls.Company_address.touched)">
                                    <p class="input-error-msg">Please enter address</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Country</label>
                                <small class="req"> *</small>
                                <select formControlName="Country" class="form-select">
                                    <option value="">Select Country</option>
                                    <ng-container  *ngFor="let country of countriesData">
                                      <option [value]="country.name">
                                          {{country.name}}
                                      </option>
                                    </ng-container>
                                  </select>
                                <div *ngIf="rObj.controls.Country.invalid && (rObj.controls.Country.dirty || rObj.controls.Country.touched)">
                                    <p class="input-error-msg">Please select country</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>State</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="State" class="form-control" value="">
                                <div *ngIf="rObj.controls.State.invalid && (rObj.controls.State.dirty || rObj.controls.State.touched)">
                                    <p class="input-error-msg">Please enter state</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>City</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="City" class="form-control" required="">
                                <div *ngIf="rObj.controls.City.invalid && (rObj.controls.City.dirty || rObj.controls.City.touched)">
                                    <p class="input-error-msg">Please enter city</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Contact Person Name</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Contact_person_name" class="form-control" required="">
                                <div *ngIf="rObj.controls.Contact_person_name.invalid && (rObj.controls.Contact_person_name.dirty || rObj.controls.Contact_person_name.touched)">
                                    <p class="input-error-msg">Please enter contact person anme</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Contact Person Phone</label>
                                <small class="req"> *</small>
                                <input type="number" formControlName="Contact_person_phone" class="form-control" value="">
                                <div *ngIf="rObj.controls.Contact_person_phone.invalid && (rObj.controls.Contact_person_phone.dirty || rObj.controls.Contact_person_phone.touched)">
                                    <p class="input-error-msg">Please enter contact person number</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Bank Name</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Bank_name" class="form-control" value="">
                                <div *ngIf="rObj.controls.Bank_name.invalid && (rObj.controls.Bank_name.dirty || rObj.controls.Bank_name.touched)">
                                    <p class="input-error-msg">Please enter bank name</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Branch Name</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Branch_name" class="form-control" value="">
                                <div *ngIf="rObj.controls.Branch_name.invalid && (rObj.controls.Branch_name.dirty || rObj.controls.Branch_name.touched)">
                                    <p class="input-error-msg">Please enter branch name</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Bank Account Number</label>
                                <small class="req"> *</small>
                                <input type="number" formControlName="Account_number" class="form-control" value="">
                                <div *ngIf="rObj.controls.Account_number.invalid && (rObj.controls.Account_number.dirty || rObj.controls.Account_number.touched)">
                                    <p class="input-error-msg">Please enter account number</p>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>IFSC Code</label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Ifsc" class="form-control" required="" autocomplete="off">
                                <div *ngIf="rObj.controls.Ifsc.invalid && (rObj.controls.Ifsc.dirty || rObj.controls.Ifsc.touched)">
                                    <p class="input-error-msg">Please enter ifsc <code></code></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </form>
</div>