import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';

@Component({
  selector: 'app-reports-inventoryReport',
  templateUrl: './inventoryReport.component.html',
})

export class InventoryReportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public userRoleData: any[] = [];
    // public classSectionData: any[] = [];
    public inventoryPurchaseData: any[] = [];
    public inventoryIssueData: any[] = [];
    public staffData: any[] = [];
    public stockData: any[] = [];
    // public designationData: any[] = [];
    // public studentData: any[] = [];
    // public studentListData: any[] = [];
    // public staffAttendanceData: any[] = [];
    // public studentTeacherData: any[] = [];
    // public classSubjectData: any[] = [];
    // public siblingsData: any[] = [];
    // public allStudentData: any[] = [];
    // public getAdmissionData: any[] = [];
    // public studentAttendance: any[] = [];
    // public studentAttendancedaywise: any[] = [];
    // public staffAttendancedaywise: any[] = [];
    // public dailystudentAttendance: any[] = [];
    // public dailystaffAttendance: any[] = [];
    // public studentAttendanceType: any[] = [];
    // public staffAttendanceType: any[] = [];
    public genderRatio: any;
    public absentPercentage: any;
    public inventoryPurchaseReport: boolean = true;
    public inventoryReport: boolean = false;
    public stockReport: boolean = false;
    // public dailystudentAttendanceReport: boolean = false;
    // public dailystaffAttendanceReport: boolean = false;
    // public staffAttendanceReport: boolean = false;
    // public studentAttendanceDaywiseReport: boolean = false;
    // public staffAttendanceDaywiseReport: boolean = false;

    
    public imgURL = this._gService.getImgPath();
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Role: [''],
        Month: [''],
        Year: [''],
        Search_type: [''],
        Date_from:[''],
        Date_to:[''],
        // Section: [''],
        Period:[''],
        // Date: [''],
        // Designation: [''],
        // Department: [''],
        // Attendence_type:[''],
        
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        
        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

        
        this._rService.stockData(this.instID, this.activeAcademicID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.stockData = JSON.parse(mRes.result);
                console.log(this.stockData)
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        // this._rService.getStudentgenderRatioData(this.instID, this.activeAcademicID).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentGenderData = JSON.parse(mRes.result);
            
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })


        // this._rService.getStudentCountData(this.instID, this.activeAcademicID).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentCountData = JSON.parse(mRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.allStudentData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })


        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.classData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.departmentData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.designationData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
        //     console.log(cRes)
        //     if (cRes.status === true) {
        //         this.sectionData = JSON.parse(cRes.result);
        //     } else {
        //         // this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        // this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        //     this._gService.showLoader(true);
        //     this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             this.classSectionData = JSON.parse(result[0].sections)
        //             console.log(this.classSectionData)

        //         }
        //     })
        //     this._gService.showLoader(false);
        // })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }
    setTabActive(tabName: string) {
        if (tabName == 'inventoryPurchaseReport') {
            this.inventoryPurchaseReport = true
        } else {
            this.inventoryPurchaseReport = false
        }
        if (tabName == 'inventoryReport') {
            this.inventoryReport = true
        } else {
            this.inventoryReport = false
        }
        if (tabName == 'stockReport') {
            this.stockReport = true
        } else {
            this.stockReport = false
        }
        // if (tabName == 'dailystudentAttendanceReport') {
        //     this.dailystudentAttendanceReport = true
        // } else {
        //     this.dailystudentAttendanceReport = false
        // }
        // if (tabName == 'dailystaffAttendanceReport') {
        //     this.dailystaffAttendanceReport = true
        // } else {
        //     this.dailystaffAttendanceReport = false
        // }
        // if (tabName == 'staffAttendanceReport') {
        //     this.staffAttendanceReport = true
        // } else {
        //     this.staffAttendanceReport = false
        // }
        // if (tabName == 'studentAttendanceDaywiseReport') {
        //     this.studentAttendanceDaywiseReport = true
        // } else {
        //     this.studentAttendanceDaywiseReport = false
        // }
        // if (tabName == 'staffAttendanceDaywiseReport') {
        //     this.staffAttendanceDaywiseReport = true
        // } else {
        //     this.staffAttendanceDaywiseReport = false
        // }

        // this.rObj.controls.Period.valueChanges.subscribe(value => {
        //     console.log(value)
        // })
        
    }

    // stringToArray(data: string) {
    //     return JSON.parse(data)
    // }

    // getStudentData(studentId: number, data: string){
    //     return this.allStudentData.filter(s => s.ID == studentId).map(s => s[data])
    // }

    // getRatio(count1: number, count2: number) {
    //     return this.genderRatio = count1 / count2;
    // }
    
    // gotoViewLink(dataID: number){
    //     this._router.navigate(['/student/view/'+dataID])
    // }

    // gotoEditLink(dataID: number){
    //     this._router.navigate(['/student/edit/'+dataID])
    // }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }



    openMediumEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }
    getTotal(qty1 : any, qty2 : any) {
        // let = this.earningAmts.reduce((a,b) => a + b)
        let total = parseInt(qty1) + parseInt(qty2);
        // console.log(total)
        return total;
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    searchAdmission(){
        let rObj = this.rObj.value;
        // this._rService.getAdmissionData(this.instID, this.activeAcademicID, rObj.Search_type, rObj.Date_From, rObj.Date_to).subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.getAdmissionData = JSON.parse(mRes.result);
        //         console.log(this.getAdmissionData)  
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    getPercentage(present: any, halfday: any, late: any){
        let month:any = this.rObj.value.Month;
        let year: any = this.rObj.value.Year;
        
        // console.log(month)
        // console.log(year)

        let days = this.daysInMonth(month, year)
        // console.log(days)
        let totalPresent = parseInt(present) + parseInt(halfday) + parseInt(late);
        // console.log(totalPresent)
        return (totalPresent*days)/100;
    }

    getAbsentPercentage(absent: any) {
        let month:any = this.rObj.value.Month;
        let year: any = this.rObj.value.Year; 
        let days = this.daysInMonth(month, year)
        return this.absentPercentage = (absent * days)/100;       
    }


    daysInMonth (month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    
    // searchstudentdaywise(){
    //     let rObj = this.rObj.value;
    //     this._rService.studentDaywiseAttendance(this.instID, this.activeAcademicID, rObj.Class, rObj.Section, rObj.Date).subscribe(mRes => {
    //         console.log(mRes)
    //         if (mRes.status === true) {
    //             this.studentAttendancedaywise = JSON.parse(mRes.result);
    //             console.log(this.studentAttendancedaywise) 
    //         }
    //         this._gService.showLoader(false);
    //     })
    // }

    // searchstaffdaywise(){
    //     let rObj = this.rObj.value;
    //     this._rService.staffDaywiseAttendance(this.instID, this.activeAcademicID, rObj.Department, rObj.Designation, rObj.Date).subscribe(mRes => {
    //         console.log(mRes)
    //         if (mRes.status === true) {
    //             this.staffAttendancedaywise = JSON.parse(mRes.result);
    //             console.log(this.staffAttendancedaywise) 
    //         }
    //         this._gService.showLoader(false);
    //     })
    // }

    // searchstudentAttendanceType(){
    //     let rObj = this.rObj.value;
    //     this._rService.studentAttendanceTypeData(this.instID, this.activeAcademicID, rObj.Period, rObj.Attendence_type).subscribe(mRes => {
    //         console.log(mRes)
    //         alert(rObj.Period)
    //         if (mRes.status === true) {
    //             this.staffAttendanceType = JSON.parse(mRes.result);
    //             console.log(this.staffAttendanceType)  
    //         }
    //         this._gService.showLoader(false);
    //     })
    // }

    // searchstaffAttendanceType(){
    //     let rObj = this.rObj.value;
    //     this._rService.staffAttendanceTypeData(this.instID, this.activeAcademicID, rObj.Period, rObj.Attendence_type).subscribe(mRes => {
    //         console.log(mRes)
    //         alert(rObj.Period)
    //         if (mRes.status === true) {
    //             this.studentAttendanceType = JSON.parse(mRes.result);
    //             console.log(this.studentAttendanceType)  
    //         }
    //         this._gService.showLoader(false);
    //     })
    // }


    search() {
        // if (this.rObj.valid) {
            let rObj = this.rObj.value;
            
            this._rService.inventoryPurchaseData(this.instID, this.activeAcademicID, rObj.Search_type).subscribe(mRes => {
                console.log(mRes)
                if (mRes.status === true) {
                    this.inventoryPurchaseData = JSON.parse(mRes.result);
                  console.log(this.inventoryPurchaseData)
                } else {
                    this._gService.triggerSnackbar('No Data Found!', 'error', true);
                }
                this._gService.showLoader(false);
            })

            this._rService.inventoryIssueData(this.instID, this.activeAcademicID, rObj.Period).subscribe(mRes => {
                console.log(mRes)
                if (mRes.status === true) {
                    this.inventoryIssueData = JSON.parse(mRes.result);
                  console.log(this.inventoryIssueData)
                } else {
                    this._gService.triggerSnackbar('No Data Found!', 'error', true);
                }
                this._gService.showLoader(false);
            })
    
            // this._rService.classSubjectData(this.instID, this.activeAcademicID,rObj.Class,rObj.Section).subscribe(mRes => {
            //     console.log(mRes)
            //     if (mRes.status === true) {
            //         this.classSubjectData = JSON.parse(mRes.result);
            //         console.log(this.classSubjectData)
                
            //     }
            //     this._gService.showLoader(false);
            // })

            // this._rService.getStudentAttendanceData(this.instID, this.activeAcademicID, rObj.Class, rObj.Section, rObj.Month, rObj.Year).subscribe(mRes => {
            //     console.log(mRes)
            //     if (mRes.status === true) {
            //         this.studentAttendance = JSON.parse(mRes.result);
            //         console.log(this.studentAttendance)
                
            //     }
            //     this._gService.showLoader(false);
            // })

            // this._rService.getStaffAttendanceData(this.instID, this.activeAcademicID, rObj.Department, rObj.Designation, rObj.Month, rObj.Year).subscribe(mRes => {
            //     console.log(mRes)
            //     if (mRes.status === true) {
            //         this.staffAttendanceData = JSON.parse(mRes.result);
            //         console.log(this.staffAttendanceData)
                
            //     }
            //     this._gService.showLoader(false);
            // })

            // this._rService.dailyStudentAttendance(this.instID, this.activeAcademicID, rObj.Date).subscribe(mRes => {
            //     console.log(mRes)
            //     if (mRes.status === true) {
            //         this.dailystudentAttendance = JSON.parse(mRes.result);
            //         console.log(this.dailystudentAttendance) 
                   
            //     }
            //     this._gService.showLoader(false);
            // })

            
            // this._rService.dailyStaffAttendance(this.instID, this.activeAcademicID, rObj.Date).subscribe(mRes => {
            //     console.log(mRes)
            //     if (mRes.status === true) {
            //         this.dailystaffAttendance = JSON.parse(mRes.result);
            //         console.log(this.dailystaffAttendance)  
            //     }
            //     this._gService.showLoader(false);
            // })

            

        // }
    }

}