<div class="modal-header">
    <h5 class="modal-title">Edit Fee Group</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Fees_name">
                <div *ngIf="rObj.controls.Fees_name.invalid && (rObj.controls.Fees_name.dirty || rObj.controls.Fees_name.touched)">
                    <p class="input-error-msg">Please enter fees name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-group">
                <label>Due Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control input-date" formControlName="Due_date">
                <div *ngIf="rObj.controls.Due_date.invalid && (rObj.controls.Due_date.dirty || rObj.controls.Due_date.touched)">
                    <p class="input-error-msg">Please select Due date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Fine Type</label>
                <br>
                <div class="form-check inline" style="margin-right:20px;">
                    <input class="form-check-input" type="radio"  formControlName="Fine_type" value="none" >
                    <label class="form-check-label" for="flexCheckDefault">None</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Fine_type"  value="percentage">
                    <label class="form-check-label" for="flexCheckDefault">Percentage</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Fine_type"  value="fix">
                    <label class="form-check-label" for="flexCheckDefault">Fix Amount</label>
                </div>   
            </div>
            <div *ngIf="rObj.controls.Fine_type.value != 'none'">
                <div class="form-group percentage" *ngIf="rObj.controls.Fine_type.value == 'percentage'">
                    <label>Percentage</label>
                    <small class="req percentage"> *</small>
                    <input type="number" class="form-control percentage " formControlName="Percentage" value="" id="percentage">
                </div>
                <div class="form-group fineamount" *ngIf="rObj.controls.Fine_type.value == 'fix'" >
                    <label>Fix Amount</label>
                    <small class="req fineamount"> *</small>
                    <input type="number" class="form-control fineamount" formControlName="Fine_amount" value=""  id="fineamount" step="any">
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateFeeGroup()">Update</button>
            </div>
        </form> 
    </div>
</div>
