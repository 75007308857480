<div class="modal-header">
    <h5 class="modal-title">Change Academic Session</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <div class="session-selector" *ngIf="academicYearsData.length">
            <label>Academic Sessions</label>
            <select (change)="onChange($event)">
                <option *ngFor="let ay of academicYearsData" [value]="ay.ID" [selected]="ay.ID == activeAcademicID">{{ay.start_year}} - {{+ay.end_year}}</option>
            </select>
        </div>
        <!-- <ul class="branch-list" *ngIf="academicYearsData.length">
            <li class="branch-item" *ngFor="let branch of academicYearsData">
                <input type="radio" [id]="'branch_'+branch.ID" [value]="branch.ID" name="branchList" (change)="onChange($event)" [checked]="branch.ID == activeAcademicID" /> 
                <label [for]="'branch_'+branch.ID">{{branch.inst_name}}, {{branch.inst_branch}}</label> 
            </li>
        </ul> -->
        <p class="modal-msg" *ngIf="!academicYearsData.length">
            No Academic Session Found!
        </p>
        <div class="modal-btn-wrapper" *ngIf="academicYearsData.length">
            <button type="button" class="btn primary-btn continue-btn" (click)="updateAcademicID()" [disabled]="!academicID">Update</button>
        </div>
    </div>
</div>
