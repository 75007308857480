import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment.service';
  
@Component({
    selector: 'app-assignmentmodal',
    templateUrl: './assignmentmodal.component.html',
    styleUrls: [],
})

export class AssignmentModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public assignmentID: number;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectData: any[] = [];
    // public assignmentData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();
    constructor(
        private _gService: GlobalService,
        private _aService: AssignmentService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Subject: ['', Validators.required],
        Homework_date: ['', Validators.required],
        Submission_date: ['', Validators.required],
        Document: [''],
        Description: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.subjectData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    // console.log(this.classSectionData)

                }
            })
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.assignmentID, 'assignment').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Subject: sData[0].subject,
                    Homework_date: sData[0].assignment_date,
                    Submission_date: sData[0].submission_date,
                    Description: sData[0].description,
                    // Document: sData[0].attachment,
                }); this.imageSrc = sData[0].attachment ? this.imgURL+sData[0].attachment : '';
            }
            this._gService.showLoader(false);
        })
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    updateAssignment() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.assignmentID,
                assignment_date: rObj.Homework_date,
                submission_date: rObj.Submission_date,
                class: rObj.Class,
                section: rObj.Section,
                subject: rObj.Subject,
                description: rObj.Description,
                attachment: this.status ? this.imageSrc : '',
            }
            console.log(mData)
            this._aService.AssignmentData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Assignment Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}