<div class="login-page container-fluid">
    <div class="row">
        <!-- <div class="col-md-7 side-screen">
            <div class="side-screen-content">
                <div class="login-logo">
                    <a href="https://edunationpro.com/">
                        <img src="/assets/img/enp-logo.png" alt="EduNationPro Logo">
                    </a>
                </div>
                <h3 class="side-screen-text">
                    An all-in solution to manage any school with ease. Log in to access all the advanced features to reduce the effort of managing the day-to-day activities of the school.
                </h3>
            </div>
        </div> -->
        <div class="col-md-5 form-screen">
            <div class="console-logo">
                <div class="logo"><img src="/assets/img/enp-logo.png"></div>
            </div>
            
            <!-- <div class="inst-title-wrapper"><img class="inst-icon" src="{{instIcon ? instIcon : '/assets/img/default-institution-icon.png'}}"> <h1 class="inst-title">{{instName}}, {{instBranch}}</h1></div> -->
            <h4 class="login-title">Login to your account</h4>
            <form name="login-form">
                <div class="form-error" *ngIf="showError">Incorrect email address or password</div>
                <div class="form-group">
                    <input type="text" class="form-control login-input" [formControl]="UserName" [ngClass]="UserName.valid ? 'has-val' : ''">
                    <span>Username</span>
                </div>
                <div class="form-group">
                    <input [type]="showPasswordText ? 'text' : 'password'" class="form-control login-input hide-password" [formControl]="Password" [ngClass]="Password.valid ? 'has-val' : ''">
                    <span>Password</span>
                    <i class="far show-hide-password-btn" [ngClass]="showPasswordText ? 'fa-eye-slash' : 'fa-eye'" (click)="showPassword()"></i>
                </div>
                  <div class="form-group">
                      <label class="login_as">Login as</label>
                      <select [formControl]="UserRole" class="form-select">
                        <option *ngFor="let role of userRoleData" value="{{role.ID}}">{{role.name}}</option>
                      </select>
                      <!-- <select name="class" formControlName="Class" class="form-select" >
                        <option value="">Please Select</option>
                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                     </select> -->
                  </div>
                  <div class="form-group" style="text-align: center">
                      <input class="btn primary-btn" type="submit" value=" Log In " (click)="submit()" [disabled]="!UserName.valid || !Password.valid">
                </div>
            </form>
        </div>
    </div>
</div>