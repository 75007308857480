<div class="modal-header">
    <h5 class="modal-title">Edit Expense</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label> Expense Head </label>
                <small class="req"> *</small>
                <select formControlName="Expense_head" class="form-select">
                    <option value="">Select</option>
                    <option *ngFor="let i of expenseHeadData" value="{{i.ID}}">{{i.expense_head}}</option>  
                </select>
                <div *ngIf="rObj.controls.Expense_head.invalid && (rObj.controls.Expense_head.dirty || rObj.controls.Expense_head.touched)">
                    <p class="input-error-msg">Please enter Expense head</p>
                </div>
            </div>
            <div class="form-group">
                <label> Expense Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Expense_name" >
                <div *ngIf="rObj.controls.Expense_name.invalid && (rObj.controls.Expense_name.dirty || rObj.controls.Expense_name.touched)">
                    <p class="input-error-msg">Please enter Expense name</p>
                </div>
            </div>
            <div class="form-group">
                <label> Invoice Number</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Invoice_no" class="form-control " required="">
                <div *ngIf="rObj.controls.Invoice_no.invalid && (rObj.controls.Invoice_no.dirty || rObj.controls.Invoice_no.touched)">
                    <p class="input-error-msg">Please enter Invoice no</p>
                </div>
            </div>
            <div class="form-group">
                <label> Date</label>
                <small class="req"> *</small>
                <input type="date" formControlName="Expense_date" class="form-control date-input" required="">
                <div *ngIf="rObj.controls.Expense_date.invalid && (rObj.controls.Expense_date.dirty || rObj.controls.Expense_date.touched)">
                    <p class="input-error-msg">Please enter Expense date</p>
                </div>
            </div>
            <div class="form-group">
                <label> Description</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Description" class="form-control " required="">
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-group">
                <label> Amount</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Amount" class="form-control " required="">
                <div *ngIf="rObj.controls.Amount.invalid && (rObj.controls.Amount.dirty || rObj.controls.Amount.touched)">
                    <p class="input-error-msg">Please enter Amount</p>
                </div>
            </div>
            <div class="form-group">
                <label> Attachment Document</label>
                <!-- <small class="req"> *</small> -->
                <input type="file" formControlName="Document" class="form-control" (change)="onFileChange($event)">
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateexpense()">Update</button>
            </div>
        </form> 
    </div>
</div>
