<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-12">Modules Access</h1>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Allow Modules Access
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Institution</label>
                                    <small class="req"> *</small>
                                    <select class="form-select" formControlName="InstID">
                                        <option value="">Select Institution</option>
                                        <option *ngFor="let inst of instData" [value]="inst.ID">{{inst.inst_name}}</option>
                                    </select>
                                    <div *ngIf="rObj.controls['InstID'].invalid && (rObj.controls['InstID'].dirty || rObj.controls['InstID'].touched)">
                                        <p class="input-error-msg">Please select an institution</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Modules</label>
                                    <small class="req">*</small>
                                    <div class="modules-selection-wrapper">
                                        <ul class="modules-selection">
                                            <ng-container *ngFor="let module of modulesData">
                                                <li class="module-item" [ngClass]="module.has_child == '1' ? 'has-sub-nav' : ''">
                                                    <label class="item-title">{{module.name}}</label>
                                                    
                                                    <label class="switch" formArrayName="Modules">
                                                        <input type="checkbox" [value]="module.ID" [checked]="selectedModules.includes(module.ID)" (change)="onModuleSelect($event)">
                                                        <span class="slider round"></span>		                        
                                                    </label>
                                                    <ul class="sub-modules" *ngIf="module.has_child == '1'">
                                                        <ng-container *ngFor="let subModule of subModulesData">
                                                            <li class="sub-module-item" *ngIf="module.ID == subModule.parent" [style.display]="isActive(module.ID) ? 'block' : 'none'">
                                                                <label formArrayName="SubModules">
                                                                    <!-- <input type="checkbox" [value]="subModule.ID" (change)="onSubModuleSelect($event)" [checked]="isChecked(module.ID, subModule.ID)"> -->
                                                                    <input type="checkbox" [value]="subModule.ID" (change)="onSubModuleSelect($event)" [checked]="selectedSubModules.includes(subModule.ID)">
                                                                    {{subModule.name}}		                        
                                                                </label>
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-btn-wrapper">
                                    <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveModulesAccess()">Save</button>
                                </div>
                            </div>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
        
</div>