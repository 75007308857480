import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserLoginComponent } from './userlogin.component';
import { UserLoginRoutingModule, UserLoginRoutes } from './userlogin-routing.module';

@NgModule({
    declarations: [
        UserLoginComponent,
        UserLoginRoutes
    ],
    imports: [
        CommonModule,
        UserLoginRoutingModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: []
})
export class UserLoginModule { }