import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { AttendanceService } from 'src/app/services/attendance.service';
import { Router, ActivatedRoute, RouteReuseStrategy } from '@angular/router';
@Component({
  selector: 'app-attendance-staffAttendance',
  templateUrl: './staffAttendance.component.html'
})

export class StaffAttendanceComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public departmentData: any[] = [];
    public designationData: any[] = [];
    public staffListData: any[] = [];
    public staffAttendanceData: any[] = [];
    public attendanceDataArray: any[] = [];
    public departmentId : any;
    public designationId : any;
    public searchDate : any;
    

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _sService: StaffService,
        private _aService: AttendanceService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Department: ['', Validators.required], 
        Designation: ['', Validators.required],
        Date: ['',Validators.required],
       
    })

    public sObj = this._fBuilder.group({
        attendanceRow : this._fBuilder.array([])
    })
    
    attendanceRow(): FormArray {
        return this.sObj.get("attendanceRow") as FormArray
    }
      
    ngOnInit(): void {
        this.departmentId = this._aRoute.snapshot.paramMap.get('dept');
        this.designationId = this._aRoute.snapshot.paramMap.get('des');
        this.searchDate = this._aRoute.snapshot.paramMap.get('date');


        if(this.departmentId && this.designationId && this.searchDate){
            this.rObj.patchValue({
                Department : this.departmentId,
                Designation : this.designationId,
                Date : this.searchDate
            })
            this.search()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        // this.rObj.controls['Department'].valueChanges.subscribe((value: any) => {
        //     this._gService.showLoader(true);
        //     this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             this.classSectionData = JSON.parse(result[0].sections)
        //             console.log(this.classSectionData)

        //         }
        //     })
        //     this._gService.showLoader(false);
        // })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'ftip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    // gotoViewLink(dataID: number){
    //     this._router.navigate(['/student/view/'+dataID])
    // }

    // gotoEditLink(dataID: number){
    //     this._router.navigate(['/student/edit/'+dataID])
    // }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/attendance/staff/search/'+robj.Department+'/'+robj.Designation+'/'+robj.Date]);
    }

    save(){
        // if(this.sObj.valid) {
            this._gService.showLoader(true);
           let sObj = this.sObj.controls.attendanceRow.controls;
           let robj = this.rObj.value;
           for (let i = 0; i < sObj.length; i++) {
            this.attendanceDataArray.push(sObj[i].value)
          }
           let aData = {
            inst_id: this.instID,
            academic_id: this.activeAcademicID,
            department: robj.Department,
            designation: robj.Designation,
            date: robj.Date,
            attendanceData: this.attendanceDataArray
           }
           console.log(aData)
          
        // }
        this._aService.saveStaffAttendanceData(aData).subscribe((aRes : any) => {
            console.log(aRes)
            if (aRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Staff Attendance Saved Successfully', 'success', true);
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        this._gService.showLoader(false);
    // }
    }


    search() {

        if(this.rObj.valid){
            this._gService.showLoader(true);
            let robj = this.rObj.value;
            console.log(robj.Department)
            console.log(robj.Designation)
            this._sService.staffListData(this.instID, this.activeAcademicID, robj.Department, robj.Designation).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.staffListData = JSON.parse(sRes.result)
                    this._aService.AttendencebydeptDesDate(this.instID, this.activeAcademicID, robj.Department, robj.Designation , robj.Date).subscribe(cRes => {
                        console.log(cRes)
                        if (cRes.status === true) {
                            this.staffAttendanceData = JSON.parse(cRes.result);
                                console.log('yes')
                                let attendanceRowArray = <FormArray>this.sObj.controls.attendanceRow;
                                attendanceRowArray.controls = this.staffAttendanceData.map(s => this._fBuilder.group({
                                    Staff_id: [s.staff_id],
                                    Staff_name: [s.full_name],
                                    Role: [s.role_name],
                                    Status: [s.status], 
                                    Remark: [s.remark],
                                }))
                        } else {
                            if (this.staffListData.length) {
                            console.log('No')
                            let attendanceRowArray = <FormArray>this.sObj.controls.attendanceRow;
                            attendanceRowArray.controls = this.staffListData.map(s => this._fBuilder.group({
                                Staff_id: [s.ID],
                                Staff_name: [s.full_name],
                                Role: [s.role_name],                               
                                Status: [''],
                                Remark: [''],
                            }))
                        }
                    }
                    })
                } else {
                    this.staffListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            
            this._gService.showLoader(false);
        }
       
    }
}