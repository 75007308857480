import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { WebsiteRoutingModule, WebsiteRoutes } from './website-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  declarations: [
    WebsiteRoutes,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    WebsiteRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    LightboxModule
  ],
  exports: []
})
export class WebsiteModule { }