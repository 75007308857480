<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-3">Pages</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="../cms/pages/add" class="content-header-link">Add New Page</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="pageListData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('cms_pages', 'page')">Delete</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="pageListData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                <th class="align-left">Title</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of pageListData">
                                <td >
                                    <input type="checkbox" class="list-checkbox" [checked]="p.isSelected" (change)="onChecked(p.ID, $event)">
                                  </td>
                                <td  class="align-left">{{p.title}}</td>
                                <!-- <td class="align-left">
                                    <img [src]="s.photo_path ? imgURL+s.photo_path : '/assets/img/default-user-avatar.png'" style="width: 40px; border: 1px solid #dcdcdc;">
                                    {{s.full_name}}
                                </td> -->
                                <td>{{p.type}}</td>
                                <td>{{p.status == 1 ? 'Visible' : 'Hidden'}}</td>
                                <td>{{convertDate(p.date_created)}}</td>
                                <td>{{convertDate(p.date_modified)}}</td>
                                <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(instSlug, p.slug)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(p.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(p.ID, 'cms_pages', 'page')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!pageListData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>            
                            
            </div>
        </div>
    </div>
</div>