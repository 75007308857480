import { Component, Output, EventEmitter } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-selectmodal',
    templateUrl: './selectmodal.component.html',
    styleUrls: [],
})

export class SelectModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public type: string;
    public selectedMedia: String;
    public selectedMediaArray: any[] = [];
    public mediaData: any[] = [];
    public imgURL = this._gService.getImgPath();

    @Output() selectedMedias = new EventEmitter<any>();

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    // public rObj = this._fBuilder.group({
    //     Medium: ['', Validators.required],
    // })

    ngOnInit(): void {
        this._gService.showLoader(true);
        
        this._gService.getAllDataByInstID(this.instID, 0, 'cms_media').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediaData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    
    }

    selectMedia(fileName: any){
        if (this.type == 'gallery') {
            if (!this.selectedMediaArray.includes(fileName)){
                this.selectedMediaArray.push(fileName) 
            } else {
                this.selectedMediaArray.splice(this.selectedMediaArray.indexOf(fileName), 1)
            }
            console.log(this.selectedMediaArray)
        } else {
            this.selectedMedia = fileName
        }
    }

    submitSelectedMedias(){
        this.selectedMedias.emit({type: this.type, filename: this.type == 'gallery' ? this.selectedMediaArray : this.selectedMedia});
        this.modalRef.hide()
    }
}