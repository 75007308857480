<div class="console-content">
    <form [formGroup]="rObj">
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link" [ngClass]="studentAttendanceReport ? 'active' : ''">
                        <a (click)="setTabActive('studentAttendanceReport')">Student Attendance Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="studentAttendanceTypeReport ? 'active' : ''">
                        <a (click)="setTabActive('studentAttendanceTypeReport')">Students Attendance Type Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="staffAttendanceTypeReport ? 'active' : ''">
                        <a (click)="setTabActive('staffAttendanceTypeReport')">Staff Attendance Type Reports</a>
                    </div>

                    <div class="col-md-4 panel-link" [ngClass]="dailystudentAttendanceReport ? 'active' : ''">
                        <a (click)="setTabActive('dailystudentAttendanceReport')">Daily Student Attendance Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="dailystaffAttendanceReport ? 'active' : ''">
                        <a (click)="setTabActive('dailystaffAttendanceReport')">Daily Staff Attendance Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="staffAttendanceReport ? 'active' : ''">
                        <a (click)="setTabActive('staffAttendanceReport')">Staff Attendance Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="studentAttendanceDaywiseReport ? 'active' : ''">
                        <a (click)="setTabActive('studentAttendanceDaywiseReport')">Student Daywise Attendance Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="staffAttendanceDaywiseReport ? 'active' : ''">
                        <a (click)="setTabActive('staffAttendanceDaywiseReport')">Staff Daywise Attendance Reports</a>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="studentAttendance-details" class="panel panel-margin-top" [ngClass]="studentAttendanceReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Student Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">   
                               <div class="col-md-2">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Month
                                    <small class="req"> *</small></label>
                                    <select name="section" formControlName="Month" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let m of MonthData" value="{{m.month}}">{{m.monthName}}</option>   
                                    </select>
                                    <div *ngIf="rObj.controls.Month.invalid && (rObj.controls.Month.dirty || rObj.controls.Month.touched)">
                                        <p class="input-error-msg">Please select Month</p>
                                    </div>
                                </div>                                
                                <div class="col-md-2">
                                    <label>Year
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Year" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <!-- <option *ngFor="let des of designationData" value="{{des.ID}}">{{des.name}}</option>    -->
                                        </select>
                                        <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                            <p class="input-error-msg">Please select Year</p>
                                        </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group btn-container" style="margin-top:24px">
                                        <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                               
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentAttendance.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Student Name</th>
                                    <th>Present Count</th>
                                    <th>Absent Count</th>
                                    <th>Halfday Count</th>
                                    <th>Late Count</th>
                                    <th>Gross Present Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentAttendance">
                                    <td class="align-left">{{s.student_name}}</td>                        
                                    <td>{{s.present_count}}</td>
                                    <td>{{s.absent_count}}</td>
                                    <td>{{s.halfday_count}}</td>
                                    <td>{{s.late_count}}</td>
                                    <td>{{getPercentage(s.present_count, s.halfday_count, s.late_count)}}%</td>
                                    
                                    <!-- <td class="action-btn">
                                        <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                        <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                        <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentAttendance.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="studentAttendanceType-details" class="panel panel-margin-top" [ngClass]="studentAttendanceTypeReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-5">Student Attendance Type Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">                                
                                <div class="col-md-2">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>  
                                <div class="col-md-2">
                                    <label>Attendence Type</label>
                                        <small class="req">*</small>
                                    <select formControlName="Attendence_type" class="form-select" data-next="getSection" data-type="options" required="">
                                        <option value="">Please Select</option>
                                        <option value="present">Present</option>
                                        <option value="absent">Absent</option>
                                        <option value="half-day-late">Half Day, Late</option>                                
                                    </select>
                                    <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                        <p class="input-error-msg">Please select Year</p>
                                    </div>
                                </div>
                                <div class="col-md-2"> 
                                    <label>Period</label>   
                                    <small class="req">*</small>
                                    <select class="form-select" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div>
                                <div  class="col-md-4" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_from" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                               
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchstudentAttendanceType()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentAttendanceType.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Admission No.</th>
                                    <th>Student Name</th>
                                    <th>Class</th>
                                    <th>Section</th>
                                    <th>Admission Date</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Mobile Number</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentAttendanceType">
                                    <td class="align-left">{{s.admission_number}}</td>   
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.class_name}}</td>
                                    <td>{{s.section_name}}</td>
                                    <td>{{s.admission_date}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{s.attendance_count}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentAttendanceType.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="staffAttendanceType-details" class="panel panel-margin-top" [ngClass]="staffAttendanceTypeReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-5">Staff Attendance Type Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-2">
                                    <label>Department
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Department" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let dept of departmentData" value="{{dept.ID}}">{{dept.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select department</p>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>Designation
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Designation" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let ds of designationData" value="{{ds.ID}}">{{ds.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                                        <p class="input-error-msg">Please select Designation</p>
                                    </div>
                                </div>
                                
                                <div class="col-md-2"> 
                                    <label>Period</label>   
                                    <small class="req">*</small>
                                    <select class="form-control" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-4" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_from" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-md-2">
                                    <label>Attendence Type</label>
                                    <small class="req">*</small>
                                    <select formControlName="Attendence_type" class="form-control" data-next="getSection" data-type="options" required="">
                                        <option value="">Please Select</option>
                                        <option value="present">Present</option>
                                        <option value="absent">Absent</option>
                                        <option value="half-day-late">Half Day, Late</option>                                
                                    </select>
                                    <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                        <p class="input-error-msg">Please select Year</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchstaffAttendanceType()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffAttendanceType.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Staff Id.</th>
                                    <th>Staff Name</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Mobile Number</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of staffAttendanceType">
                                    <td class="align-left">{{s.staff_id}}</td>   
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.department_name}}</td>
                                    <td>{{s.designation_name}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>{{s.gender}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{s.attendance_count}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!staffAttendanceType.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
                <div id="dailystudentAttendance-details" class="panel panel-margin-top" [ngClass]="dailystudentAttendanceReport? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-6">Daily Student Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">                      
                                <div class="col-md-6" >
                                    <label>Date</label>
                                    <small class="req">*</small>
                                    <input type="date" class="form-control date-input" formControlName="Date" >
                                    <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                        <p class="input-error-msg">Please select date </p>
                                    </div>
                                </div>  
                                <div class="col-md-6 btn-container" style="margin-top: 23px;">
                                    <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                                </div>                             
                            </div>
                        </div>
                               
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="dailystudentAttendance.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Class(Section)</th>
                                    <th>Total Present</th>
                                    <th>Total Late</th>
                                    <th>Total Halfday</th>
                                    <th>Total Absent</th>
                                    <th>Present Percentage</th>
                                    <th>Absent Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of dailystudentAttendance">
                                    <td class="align-left">  {{s.class_name}}({{s.section_name}}) </td>     
                                    <td>{{s.present_count}}</td>
                                    <td>{{s.late_count}}</td>
                                    <td>{{s.halfday_count}}</td>
                                    <td>{{s.absent_count}}</td>
                                    <td>{{getPercentage(s.present_count,s.late_count,s.halfday_count)}}%</td>
                                    <td>{{getAbsentPercentage(s.absent_count)}}%</td>      
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!dailystudentAttendance.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="dailystaffAttendance-details" class="panel panel-margin-top" [ngClass]="dailystaffAttendanceReport? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-6">Daily Staff Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">                      
                                <div class="col-md-6" >
                                    <label>Date</label>
                                    <small class="req">*</small>
                                    <input type="date" class="form-control date-input" formControlName="Date" >
                                    <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                        <p class="input-error-msg">Please select date </p>
                                    </div>
                                </div>  
                                <div class="col-md-6 btn-container" style="margin-top: 23px;">
                                    <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                                </div>                             
                            </div>
                        </div>
                               
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="dailystaffAttendance.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Department</th>
                                    <th>Designation</th>
                                    <th>Total Present</th>
                                    <th>Total Late</th>
                                    <th>Total Halfday</th>
                                    <th>Total Absent</th>
                                    <th>Present Percentage</th>
                                    <th>Absent Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of dailystaffAttendance">
                                    <td class="align-left">{{s.department_name}}</td>   
                                    <td>{{s.designation_name}}</td>  
                                    <td>{{s.present_count}}</td>
                                    <td>{{s.late_count}}</td>
                                    <td>{{s.halfday_count}}</td>
                                    <td>{{s.absent_count}}</td>
                                    <td>{{getPercentage(s.present_count,s.late_count,s.halfday_count)}}%</td>
                                    <td>{{getAbsentPercentage(s.absent_count)}}%</td>      
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!dailystaffAttendance.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="staffAttendance-details" class="panel panel-margin-top" [ngClass]="staffAttendanceReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Staff Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-3">
                                    <label>Department
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Department" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let d of departmentData" value="{{d.ID}}">{{d.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                        <p class="input-error-msg">Please select Department</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Designation
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Designation" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let ds of designationData" value="{{ds.ID}}">{{ds.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                                        <p class="input-error-msg">Please select Designation</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Month
                                    <small class="req"> *</small></label>
                                    <select name="section" formControlName="Month" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let m of MonthData" value="{{m.month}}">{{m.monthName}}</option>   
                                    </select>
                                    <div *ngIf="rObj.controls.Month.invalid && (rObj.controls.Month.dirty || rObj.controls.Month.touched)">
                                        <p class="input-error-msg">Please select Month</p>
                                    </div>
                                </div>                                
                                <div class="col-md-3">
                                    <label>Year
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Year" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <!-- <option *ngFor="let des of designationData" value="{{des.ID}}">{{des.name}}</option>    -->
                                        </select>
                                        <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                            <p class="input-error-msg">Please select Year</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffAttendanceData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Staff Name</th>
                                    <th>Present Count</th>
                                    <th>Absent Count</th>
                                    <th>Halfday Count</th>
                                    <th>Late Count</th>
                                    <th>Gross Present Percentage</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of staffAttendanceData">                
                                    <td class="align-left">{{s.staff_name}}</td>
                                    <td>{{s.present_count}}</td>
                                    <td>{{s.absent_count}}</td>
                                    <td>{{s.halfday_count}}</td>
                                    <td>{{s.late_count}}</td>
                                    <td>{{getPercentage(s.present_count, s.halfday_count, s.late_count)}}%</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!staffAttendanceData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="studentAttendancedaywise-details" class="panel panel-margin-top" [ngClass]="studentAttendanceDaywiseReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-6">Student Daywise Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>              
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row"> 
                                <div class="col-md-3">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-3" >
                                    <label>Date</label>
                                    <small class="req">*</small>
                                    <input type="date" class="form-control date-input" formControlName="Date" >
                                    <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                        <p class="input-error-msg">Please select date </p>
                                    </div>
                                </div>  
                                <div class="col-md-3 btn-container" style="margin-top: 23px;">
                                    <button class="submit-btn primary-btn" type="submit" (click)="searchstudentdaywise()">Search</button>
                                </div>  

                            </div>
                        </div>
                        <!-- <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>        -->
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentAttendancedaywise.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Date</th>
                                    <th>Admission No.</th>
                                    <th>Roll No.</th>
                                    <th>Student Name</th>
                                    <th>Attendance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of studentAttendancedaywise">                
                                    <td class="align-left">{{s.date}}</td>
                                    <td>{{s.admission_number}}</td>
                                    <td>{{s.roll_no}}</td>
                                    <td>{{s.student_name}}</td>
                                    <td>{{s.status}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!studentAttendancedaywise.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
                <div id="staffAttendancedaywise-details" class="panel panel-margin-top" [ngClass]="staffAttendanceDaywiseReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Staff Daywise Attendance Report</h1>
                        <!-- <div class="header-msg col-md-5">
                                        </div> -->
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-3">
                                    <label>Department
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Department" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let d of departmentData" value="{{d.ID}}">{{d.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                        <p class="input-error-msg">Please select Department</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Designation
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Designation" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let ds of designationData" value="{{ds.ID}}">{{ds.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                                        <p class="input-error-msg">Please select Designation</p>
                                    </div>
                                </div>
                                <div class="col-md-3" >
                                    <label>Date</label>
                                    <small class="req">*</small>
                                    <input type="date" class="form-control date-input" formControlName="Date" >
                                    <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                        <p class="input-error-msg">Please select date </p>
                                    </div>
                                </div>  
                                <div class="col-md-3 btn-container" style="margin-top: 23px;">
                                    <button class="submit-btn primary-btn" type="submit" (click)="searchstaffdaywise()">Search</button>
                                </div>  
                            </div>
                        </div>
                              
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffAttendancedaywise.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Date</th>
                                    <th>Staff Id</th>
                                    <th>Staff Name</th>
                                    <th>Attendance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of staffAttendancedaywise">                
                                    <td class="align-left">{{s.date}}</td>
                                    <td>{{s.staff_id}}</td>
                                    <td>{{s.staff_name}}</td>
                                    <td>{{s.status}}</td>
                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!staffAttendancedaywise.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>