<div class="console-content">
        
                
    <div class="console-content-header row">
        <h1 class="title col-md-3">Syllabus</h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-4">
            <!--<a href="../../class/add/" class="content-header-link">Add Class</a>
            <a href="../../section/add/" class="content-header-link">Add Section</a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-select" >
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData" >
                                        <option [value]="section.id">{{section.name}}</option>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                        <div class="form-group">
                            <label>Subject
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Subject" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let sub of subjectData" value="{{sub.ID}}">{{sub.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                                <p class="input-error-msg">Please select subject</p>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 24px;">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="gotoSearch()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header title">
                    <h4>Syllabus for {{subjectName}}</h4>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <!-- <button *ngIf="attendanceRow().controls.length" class="btn primary-btn small-btn" (click)="save()" style="position: absolute; right: 20px; z-index: 9;">Save</button> -->
                        <table id="list" class="list" *ngIf="syllabusStatus.length">
                        <!-- <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="syllabusStatus.length"> -->
                        <thead>
                            <div class="row">
		                        <div class="col-md-3" style="text-align: center;">Lessons</div>
			                    <div class="col-md-3" style="text-align: center;">Topic Completion date</div>
                                <div class="col-md-3" style="text-align: center;">Status</div>
                                <div class="col-md-3" style="text-align: center;"> Action</div>
                            </div>
                        </thead>
                        <tbody *ngFor="let s of syllabusStatus">
                            <tr>
                                <td class="align-left" colspan="4">
                                     <ng-container *ngFor="let lesson of stringToArray(s.lessons); let i = index">
                                        <ng-container *ngFor="let lesson_id of stringToArray(s.lesson_ids); let j = index">
                                            <ng-container *ngIf="i == j">
                                                <ul>
                                                    <li> 
                                                        <div class="row">
                                                            <h5>{{lesson}}</h5>
                                                            <ng-container *ngFor="let topic of getToicData(lesson_id); let k = index">
                                                            <div class="col-md-3" style="text-align: center;">
                                                               {{topic.name}}
                                                            </div>
                                                            <div class="col-md-3" style="text-align: center;">
                                                                {{topic.completion_date ? topic.completion_date : '-'}}                                                              
                                                            </div>
                                                            <div class="col-md-3 action-btn" style="text-align: center;">
                                                                <span [ngClass]="topic.status == 1 ? 'result-btn-pass' : 'result-btn-fail'">{{topic.status == 1 ? 'Completed' : "Incompleted"}}</span>                                                          
                                                            </div>
                                                            <div class="col-md-3" style="margin-bottom:4px; display: grid; justify-content: center;">
                                                                <label class="switch" formControlName="isCompleted" style="margin-bottom: 5px;">
                                                                    <input type="checkbox" value="1" [checked]="topic.status == 1 ? true : false" (change)="checkStatus($event, topic.ID)">
                                                                    <span class="slider round"></span>		                        
                                                                </label><br>
                                                            </div>
                            
                                                        </ng-container>
                                                        </div>
                                                    </li>                                                  
                                                </ul> 
                                            </ng-container>
                                        </ng-container>                   
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                   
                    <div *ngIf="!syllabusStatus.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </form>
                </div>               
            </div>
        </div>
    </div>
</div>