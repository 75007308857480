import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-applications-applications',
  templateUrl: './applications.component.html'
})

export class ApplicationsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public ApplicationData: any[] = [];
    public userRoleData: any[] = [];
    public  departmentID: any;
    public designationId: any;
    public selectedIds : any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc: any;
    public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {

    //    this.departmentID = this._aRoute.snapshot.paramMap.get('department');
    //    this.designationId = this._aRoute.snapshot.paramMap.get('designation');
    //    if(this.departmentID && this.designationId ){
        // this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
        //     console.log(dataRes)
        //     if(dataRes.status === true) {
        //         let result = JSON.parse(dataRes.result)
        //         this.classSectionData = JSON.parse(result[0].sections)
        //     }
        // })
    //     this.rObj.patchValue({
    //         Class : this.classId,
    //         Section : this.sectionId,  
    //     })
    //     this.search()
    // }
       


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'online_admissions').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.ApplicationData = JSON.parse(cRes.result);
                console.log(this.ApplicationData)
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
                console.log(this.classData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.ApplicationData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.ApplicationData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    getUserRoleName(roleID : any){
        return this.userRoleData.filter(r => r.ID == roleID).map(r => r.name)
    }

    gotoViewLink(studentId: number){
        this._router.navigate(['/student/view/'+studentId])
    }
    getClass(classID: number) {
       return this.classData.filter(c => c.ID == classID).map(c => c.name)
    }

    gotoEditLink(studentID: number){
        this._router.navigate(['/online-admission/enroll/'+studentID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    
}