<div class="modal-header">
    <h5 class="modal-title">Edit Transport Allocation Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form method="post" [formGroup]="rObj">
            <div class="form-group">
                <label>Vehicle No.</label>
                    <small class="req"> *</small>
                    <select formControlName="Vehicle_no" class="form-control" >
                        <option value="">Please Select</option>
                        <option *ngFor="let No of vehicleData" value="{{No.ID}}">{{No.vehicle_no}}</option>
    
                     </select>
                     <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                        <p class="input-error-msg">Please select vehicle number</p>
                    </div>
            </div>

            <div class="form-group">
                <label>Route </label>
                <small class="req"> *</small>
                <select formControlName="Route_name" class="form-control">
                    <option value="">Please Select</option>
                    <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>
                </select>
                <div *ngIf="rObj.controls.Route_name.invalid && (rObj.controls.Route_name.dirty || rObj.controls.Route_name.touched)">
                    <p class="input-error-msg">Please enter route name</p>
                </div>
            </div>
           

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="update()">Update</button>
            </div>
        </form>
    </div>
</div>
