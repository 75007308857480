import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class CircularService {
  constructor(
    private _http: HttpClient,
  ) { }

  private circular = `${environment.APIURL}/communication/circulars/`;
//   private marksGrade = `${environment.APIURL}/examination/marksGrade/`;

 
  circularData(data: object): Observable<any> {
    return this._http.post(this.circular, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
//   gradeTypeData(data: object): Observable<any> {
//     return this._http.post(this.marksGrade, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }

  
  

  
}