<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-3">Add Page</h1>
            <div class="header-msg col-md-6"></div>
            <div class="btn-container link-container col-md-3">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="savePages()">Save Pages</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            Page Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <label>Title <small class="req"> *</small></label>
                            <input type="text" formControlName="Title" class="form-control">
                        </div>
                        <div class="form-group">
                            <div class="form-group">
                                <label style="margin-right:20px; font-weight: 700;">Type:</label>
                                <div class="form-check inline">
                                    <input class="form-check-input" type="radio"  formControlName="Type" value="standard" >
                                    <label class="form-check-label" for="flexCheckDefault">Standard</label>
                                </div> 
                                <div class="form-check inline">
                                    <input class="form-check-input" type="radio" formControlName="Type"  value="gallery">
                                    <label class="form-check-label" for="flexCheckDefault">Gallery</label>
                                </div> 
                                <div class="form-check inline">
                                    <input class="form-check-input" type="radio" formControlName="Type"  value="event">
                                    <label class="form-check-label" for="flexCheckDefault">Event </label>
                                </div>   
                                <div class="form-check inline">
                                    <input class="form-check-input" type="radio" formControlName="Type"  value="news">
                                    <label class="form-check-label" for="flexCheckDefault">News</label>
                                </div>  
                            </div> 
                            <div class="panel"  *ngIf="rObj.controls.Type.value == 'event'">
                                <div class="panel-header">
                                    <div class="panel-title">
                                        Event:
                                    </div>
                                </div>
                                <div class="panel-body row">
                                    <div class="col-md-4">
                                        <label>Event Venue</label>
                                        <input type="text" formControlName="Venue" class="form-control seo-title" value="">
                                    </div>
                                    <div class="col-md-4">
                                        <label>Event Start Date</label>
                                        <input type="date" formControlName="Start_date" class="form-control seo-title" value="">
                                    </div>     
                                    <div class="col-md-4">
                                        <label>Event End Date</label>
                                        <input type="date" formControlName="End_date" class="form-control seo-title" value="">
                                    </div>                  
                                </div>
                            </div>
                            <div class="panel"  *ngIf="rObj.controls.Type.value == 'gallery'">
                                <div class="panel-header">
                                    <div class="panel-title">
                                        Gallery:
                                    </div>
                                </div>
                                <div class="panel-body">
                                    <div class="form-group">
                                        <div class="select-btn-wrapper" *ngIf="!selectedGalleryImgs.length">
                                            <button class="btn default-btn small-btn select-btn" [disabled]="imageGallerySrc" (click)="openMediaModal('gallery')">Select Media</button>
                                        </div>
                                        <div class="select-btn-wrapper" *ngIf="selectedGalleryImgs.length">
                                            <button class="btn default-btn small-btn select-btn" (click)="removeSelectedGalleryImg()">Remove All</button>
                                        </div>
                                        <div class="gallery-img-wrapper">
                                            <div class="gallery-img-item" *ngFor="let img of selectedGalleryImgs">
                                                <img class="photo-preview" *ngIf="img" [src]="imgURL+img">
                                                <span class="del-btn" (click)="delGalleryImg(img)"><i class="far fa-trash-alt"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel"  *ngIf="rObj.controls.Type.value == 'news'">
                                <div class="panel-header">
                                    <div class="panel-title">
                                        News:
                                    </div>
                                </div>
                                <div class="panel-body">
                                    <div class="col-md-4">
                                        <label>select date</label>
                                        <input type="date" formControlName="News_date" class="form-control seo-title" value="">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Content</label>
                                <angular-editor [config]="editorConfig" formControlName="Content" class="form-control"></angular-editor>
                                <!-- <textarea formControlName="Content" class="form-control" rows="15" required=""></textarea> -->
                            </div>
                        </div>
                </div>

               
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            SEO Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <label>SEO Title</label>
                            <input type="text" formControlName="Meta_title" class="form-control seo-title"  >
                        </div>
                        <div class="form-group">
                            <label>Meta Description</label>
                            <textarea formControlName="Meta_desc" class="form-control meta-desc"></textarea>
                        </div>      
                        <div class="form-group">
                            <label>Meta Keyword</label>
                            <input type="text" formControlName="Meta_keyword" class="form-control meta-keyword">
                        </div>                
                    </div>
                </div>
            </div>
            </div>
            <div class="col-md-3">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            Visibility:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="Status" value="1" >
                                <label>Visible</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" formControlName="Status" value="0">
                                <label>Hidden</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title form-group">
                            <label> Featured Image: <small class="req" *ngIf="rObj.controls.Type.value == 'gallery'"> *</small></label>                         
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="form-img-wrapper">
                                <img class="photo-preview" *ngIf="!selectedImgSrc" [src]="imageSrc ? imageSrc : '/assets/img/default-featured-img.png'">
                                <img class="photo-preview" *ngIf="selectedImgSrc" [src]="imgURL+selectedImgSrc">
                            </div>
                           <div class="upload-btn-wrapper" *ngIf="!selectedImgSrc">
                                <button class="upload-btn" [disabled]="imageSrc" (click)="openMediaModal('featured_img')">Select</button>
                            </div>
                            <div class="upload-btn-wrapper" *ngIf="selectedImgSrc">
                                <button class="upload-btn" (click)="removeSelectedImg()">Remove</button>
                            </div>
                            <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                <button class="upload-btn">Upload</button>
                                <input type="file" (change)="onFileChange($event)" [disabled]="selectedImgSrc">
                            </div>
                            <div class="upload-btn-wrapper" *ngIf="imageSrc">
                                <button class="upload-btn" (click)="removeImg()">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
    </form>
</div>