import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { CertificateService } from 'src/app/services/certificates.service';
import { StudentService } from 'src/app/services/student.service';
@Component({
    selector: 'app-idmodal',
    templateUrl: './idmodal.component.html',
    styleUrls: ['./idmodal.component.css'],
})

    export class IdModalComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public templateID: number;
    public templateData: any[] = [];
    public studentIDs: any;
    public studentData: any[] = [];
    public staffData: any[] = [];
    public idCardTemplateData: any[] = [];
    
    public templateHtml : any;
    public templatePath : any;
    public type : any;
    
    public printcontent : any;
    public imgURL = 'https://apiuat.edunationpro.com/uploads/'; 

    @ViewChild('idTemplate') idHtml : ElementRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StudentService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);
        // alert(this.studentIDs)

        this._gService.getDataByID(this.templateID, 'idcards').subscribe(c => {
            console.log(c)
            let data = JSON.parse(c.result)
           if (c.status == true) {
                this.templateHtml = data[0].html
            console.log(this.templateHtml)
           }
        this._gService.showLoader(false);

        })

        // this._sService.getIdCardTemplateData().subscribe(pRes => {
        //     console.log(pRes)
        //     if (pRes.status === true) {
        //         this.idCardTemplateData = JSON.parse(pRes.result);
        //         // console.log(this.idCardTemplateData)
        //         if (this.templateID) {
        //             this.templatePath = this.idCardTemplateData.filter(t => {
        //                 return t.ID == this.templateID  
        //             })
        //             console.log(this.templatePath)
        //             this.templatePath = this.templatePath[0].path
        //             console.log(this.templatePath)
        //             this._gService.getHtmlData(this.templatePath).subscribe((data : any)=>{
        //                 this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data)
        //             })
        //         }
                
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })


        this._gService.showLoader(false);

    }

    getPopulatedHTML(ID: number) {
        if (this.type == 'student') {
        let student_data = this.studentData.filter(s => s.ID == ID).map(s => ({'Student Name': s.full_name, 'Class': s.class_name, 'Section': s.section_name, 'roll No': s.roll_no, '01/A ABC CHS, ABV ABC': s.permanent_address, 'phone No' : s.mobile, 'abv@gmail.com': s.email, '/assets/img/default-user-avatar.png': s.photo_path ? this.imgURL+s.photo_path : '/assets/img/default-user-avatar.png'}))
        console.log(student_data)
        let html = this.templateHtml;
        // console.log(html)
        for (const [search, replace] of Object.entries(student_data[0])) {
            html = html.replace(new RegExp('{{'+search+'}}', "g"), replace);
        }
        this.printcontent = this.sanitizer.bypassSecurityTrustHtml(html)
        console.log(this.printcontent)
        return this.printcontent
    } else {
        if (this.type == 'staff') {
            let staff_data = this.staffData.filter(s => s.ID == ID).map(s => ({'Staff Name': s.full_name, 'Department': s.department_name, 'Designation': s.designation_name, 'staffID': s.staff_id, '01/A ABC CHS, ABV ABC': s.permanent_address, 'phone No' : s.mobile, 'abv@gmail.com': s.email, '/assets/img/default-user-avatar.png': s.photo_path ? this.imgURL+s.photo_path : '/assets/img/default-user-avatar.png' }))
        console.log(staff_data)
        let html = this.templateHtml;
        // console.log(html)
        for (const [search, replace] of Object.entries(staff_data[0])) {
            html = html.replace(new RegExp('{{'+search+'}}', "g"), replace);
        }
        this.printcontent = this.sanitizer.bypassSecurityTrustHtml(html)
        console.log(this.printcontent)
        return this.printcontent
        }
    }
    }

    print() {
        // console.log(this.printcontent)
        // this.printcontent.window.print();
        window.print();    
      }

    // print() {
    //     var elem = document.getElementById("content");
    //     var domClone = elem.cloneNode(true)
    //     var $printSection = document.getElementById("printSection");
    
    //     if (!$printSection) {
    //       let $printSection = document.createElement("div");
    //       $printSection.id = "printSection";
    //       document.body.appendChild($printSection);
    //       $printSection.innerHTML = "";
    //       $printSection.appendChild(domClone);
    //       window.print();    // only modal content should get print
    //     }
    //   }

//     print(){

//         // const printContents : any =  document.querySelector("content").innerHTML;
//         // const originalContents = document.body.innerHTML;
//         // document.body.innerHTML = originalContents
        
//         // let p = print();
//        window.print();
//         // // document.body.innerHTML = originalContents;
//         // this._router.navigate([this._router.url])
// }
    
}