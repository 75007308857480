<div class="modal-header">
    <h5 class="modal-title">Edit Subjects</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Subject Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Subject">
                <div *ngIf="rObj.controls['Subject'].invalid && (rObj.controls['Subject'].dirty || rObj.controls['Subject'].touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Subject Type</label>
                <small class="req"> *</small>
                <div class="radio required">
                    <label class="radio-inline">
                        <input type="radio" value="theory" formControlName="subjectType">Theory 
                    </label>
                    <label class="radio-inline">
                        <input type="radio" value="practical" formControlName="subjectType">Practical
                    </label>
                    <div *ngIf="rObj.controls.subjectType.invalid && (rObj.controls.subjectType.dirty || rObj.controls.subjectType.touched)">
                        <p class="input-error-msg">Please select subject type</p>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Subject Code</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="subjectCode">
                <div *ngIf="rObj.controls.subjectCode.invalid && (rObj.controls.subjectCode.dirty || rObj.controls.subjectCode.touched)">
                    <p class="input-error-msg">Please select subject code</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateSubject()">Update</button>
            </div>
        </form> 
    </div>
</div>
