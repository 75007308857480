import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class ExaminationService {
  constructor(
    private _http: HttpClient,
  ) { }

  private exam = `${environment.APIURL}/examination/exam/`;
  private marksGrade = `${environment.APIURL}/examination/marksGrade/`;
  private examTimetable = `${environment.APIURL}/examination/examTimetable/`;
  private getExamTimetable = `${environment.APIURL}/examination/getExamByIDClassSection/`;
  private marksRegister = `${environment.APIURL}/examination/marksRegister/`;
  private getMarks = `${environment.APIURL}/examination/getExamDataByClassSectionExamID/`;
  private getAllMarks = `${environment.APIURL}/examination/getExamMarksByMultiExamIDs/`;
  
 
  examData(data: object): Observable<any> {
    return this._http.post(this.exam, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  gradeTypeData(data: object): Observable<any> {
    return this._http.post(this.marksGrade, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  examTimeTableData(data: object): Observable<any> {
    return this._http.post(this.examTimetable, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  timetableByExamClassSectionData(instID: number, academicID: number, exam_Id: any, classID: any, sectionID: any): Observable<any> {
    return this._http.get(this.getExamTimetable+'?inst_id='+instID+'&academic_id='+academicID+'&exam='+exam_Id+'&class='+classID+'&section='+sectionID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  marksRegisterData(data: object): Observable<any> {
    return this._http.post(this.marksRegister, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getMarksData(instID: number, academicID: number, classID: any, sectionID: any, examID: any): Observable<any> {
    return this._http.get(this.getMarks+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classID+'&section_id='+sectionID+'&exam_id='+examID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getAllMarksData(instID: number, academicID: number, classID: any, sectionID: any, examID: any): Observable<any> {
    return this._http.get(this.getAllMarks+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classID+'&section_id='+sectionID+'&exam_ids='+examID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
}