<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-4">Hostel Room</h1>
        <div class="link-container col-md-8">
            <a href="../hostel/hostel-details/add" class="content-header-link">Add Hostel</a>
            <a href="../../hostel/hostel-room-type/add" class="content-header-link">Add Hostel Room Type </a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Hostel Room Details
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Room Name/No.</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Room_no" >
                            <div *ngIf="rObj.controls.Room_no.invalid && (rObj.controls.Room_no.dirty || rObj.controls.Room_no.touched)">
                                <p class="input-error-msg">Please enter room number or name</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Hostels</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Hostels">
                                <option value="">Select</option>
                            <option *ngFor="let name of HostelData" value="{{name.ID}}">{{name.name}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Hostels.invalid && (rObj.controls.Hostels.dirty || rObj.controls.Hostels.touched)">
                                <p class="input-error-msg">Please select hostel</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Room Type</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Room_Type">
                                <option value="">Select</option>
                                <!-- <option value="1">1</option>
                                <option value="2">2</option> -->
                            <option *ngFor="let type of RoomtypeData" value="{{type.ID}}">{{type.room_type}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Room_Type.invalid && (rObj.controls.Room_Type.dirty || rObj.controls.Room_Type.touched)">
                                <p class="input-error-msg">Please enter room type</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>No.of Beds</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="No_beds" >
                            <div *ngIf="rObj.controls.No_beds.invalid && (rObj.controls.No_beds.dirty || rObj.controls.No_beds.touched)">
                                <p class="input-error-msg">Please enter number of beds</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Cost Per Bed</label>
                            <small class="req"> *</small>
                            <input type="number"class="form-control" formControlName="Cost">
                            <div *ngIf="rObj.controls.Cost.invalid && (rObj.controls.Cost.dirty || rObj.controls.Cost.touched)">
                                <p class="input-error-msg">Please enter cost</p>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <label>Description</label>
                            <small class="req"> *</small>
                            <textarea type="text" class="form-control" autocomplete="off" formControlName="Description"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter description</p>
                            </div>
                        </div>

                         <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveDetails()">Save</button>
                        </div>
                    </form>
                </div>
            </div>   
        </div>
        
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                       Hostel Rooms List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="hostelroomsData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">ROOM NO</th>
                                    <th>HOSTEL</th>
                                    <th>ROOM TYPE</th>
                                    <th>NO.OF BEDS</th>
                                    <th>COST PER BED</th>
                                    <th>DESCRIPTION</th>
                                    <th class="no-sort">MANAGE</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let hostelrooms of hostelroomsData">
                                <td>{{hostelrooms.room_no}}</td>
                                <td class="align-left">
                                <ng-container *ngFor="let hostel of HostelData">
                                    <ng-container *ngIf="hostel.ID == hostelrooms.hostel">
                                        {{hostel.name}}
                                    </ng-container>
                                </ng-container>
                                </td>
                                <td>
                                        <ng-container *ngFor="let room of RoomtypeData">
                                            <ng-container *ngIf="room.ID == hostelrooms.room_type">
                                            {{room.room_type}}
                                            </ng-container>  
                                        </ng-container>
                                </td>
                               
                                <!-- <td>{{hosteldetails.phone_no}}</td>
                                <td>{{hosteldetails.capacity}}</td> -->
                                <td>{{hostelrooms.no_beds}}</td>
                                <td>{{hostelrooms.cost_bed}}</td>
                                <td><p>{{hostelrooms.description}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openHostelRoomsEditModal(hostelrooms.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(hostelrooms.ID, 'hostel_room', 'hostel rooms details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div *ngIf="!hostelroomsData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>    
                    </div>
                </div>
            </div>
    </div>   
</div>