import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './snackbar.service';
import { LoaderService } from './loader.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../components/modal/deletemodal/deletemodal.component';
import { DeleteModalMultiComponent } from '../components/modal/deletemultimodal/deletemultimodal.component';
import { RevertModalComponent } from '../components/modal/revertmodal/revertmodal.component';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  modalRef: BsModalRef;

  constructor(
    private _http: HttpClient,
    private _snackbarService: SnackbarService,
    private _loaderService: LoaderService,
    private modalService: BsModalService,
  ) { }

  private getNotifications = `${environment.APIURL}/api/GetNotificationMaster`;
  private markNotifications = `${environment.APIURL}/api/Partial_SaveNotificationMaster`;
  private getData = `${environment.APIURL}/common/getDataByID/`;
  private getAllData = `${environment.APIURL}/common/getAllDataByInstID/`;
  private academicYearsData = `${environment.APIURL}/common/getAcademicYearsData/`;
  private settingData = `${environment.APIURL}/settings/getSettingData/`;
  private updateSettings = `${environment.APIURL}/settings/addUpdate/`; 
  private deleteData = `${environment.APIURL}/common/deleteData/`;
  private deleteMultiData = `${environment.APIURL}/common/deleteMultiData/`;
  private getCountries = `${environment.APIURL}/common/getCountries/`;
  private getUserRole =  `${environment.APIURL}/user/getUserRole/?type=staff`;
  private getInstData = `${environment.APIURL}/common/getAllInstData`;
  private getModulesAccess = `${environment.APIURL}/modules/getModulesByUserRole/`;
  private searchData = `${environment.APIURL}/common/search/`;
  private search = `${environment.APIURL}/common/searchDataByUserRole/`;

  getSearchData(key: string): Observable<any> {
    return this._http.get(this.searchData+'?key='+key, {  
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getSearch(user_role: any,key: string): Observable<any> {
    return this._http.get(this.search+'?user_role='+user_role+'&key='+key, {  
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getImgPath(){
    return `${environment.IMGURL}`;
  }

  getNotificationData(userID: number): Observable<any> {
    return this._http.get(this.getNotifications+'/UserId/'+userID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  changeNotificationStatus(data: any): Observable<any> {
    return this._http.post(this.markNotifications, data, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getDataByID(dataID: number, module: string): Observable<any> {
    return this._http.get(this.getData+'?data_id='+dataID+'&module='+module, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getSettingsData(instID: number, setting_name: string): Observable<any> {
    return this._http.get(this.settingData+'?inst_id='+instID+'&setting_name='+setting_name, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  updateSettingsData(data: object): Observable<any> {
    return this._http.post(this.updateSettings, data, {
      headers: { 'Content-Type' : 'application/json' }
    })
  }

  getAllDataByInstID(instID: number, academicID: any ,module: string): Observable<any> {
    return this._http.get(this.getAllData+'?inst_id='+instID+'&academic_id='+academicID+'&module='+module, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getAllInstData(): Observable<any> {
    return this._http.get(this.getInstData, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getModulesAccessData(instID: number, user_role: number): Observable<any> {
    return this._http.get(this.getModulesAccess+'?inst_id='+instID+'&user_role='+user_role, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getAcademicYearsDataByInstID(instID: number): Observable<any> {
    return this._http.get(this.academicYearsData+'?inst_id='+instID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  deleteModuleData(dataID: number, module: string): Observable<any> {
    return this._http.get(this.deleteData+'?data_id='+dataID+'&module='+module, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  deleteModuleMultiData(rObj: object): Observable<any> {
    return this._http.post(this.deleteMultiData, rObj, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getCountriesData(): Observable<any> {
    return this._http.get(this.getCountries, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getUserRoleData(): Observable<any> {
    return this._http.get(this.getUserRole, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getHtmlData(data: any): Observable<any> {
    return this._http.get(data, { 
      responseType: 'text' 
    })
  }

  triggerSnackbar(text: string, type: string, top: boolean = false) {
    this._snackbarService.show(text, type);
  }

  showLoader(visible: boolean = false) {
    this._loaderService.show(visible);
  }

  errorHandler(err: HttpErrorResponse) {
    console.log('GOT_ERROR FROM GLOBAL SERVICE => ', err);
    console.log(err.status);
    return err;
  }

  openDeleteModal(dataID: number, moduleName: string, moduleTitle: string) {
    this.modalRef = this.modalService.show(DeleteModalComponent, {
        class: 'modal-dialog-top address-modal-dialog form-modal-dialog',
        backdrop : 'static',
        keyboard : false,
        initialState : {
            dataID: dataID,
            moduleName: moduleName,
            moduleTitle: moduleTitle
        } 
    });
  }

  openAdminDeleteModal(dataID: number, moduleName: string, moduleTitle: string, userRole: any) {
    this.modalRef = this.modalService.show(DeleteModalComponent, {
        class: 'modal-dialog-top address-modal-dialog form-modal-dialog',
        backdrop : 'static',
        keyboard : false,
        initialState : {
            dataID: dataID,
            moduleName: moduleName,
            moduleTitle: moduleTitle,
            userRole: userRole
        } 
    });
  }
  openRevertModal(dataID: number, moduleName: string, moduleTitle: string) {
    this.modalRef = this.modalService.show(RevertModalComponent, {
        class: 'modal-dialog-top address-modal-dialog form-modal-dialog',
        backdrop : 'static',
        keyboard : false,
        initialState : {
            dataID: dataID,
            moduleName: moduleName,
            moduleTitle: moduleTitle
        } 
    });
  }

  openDeleteMultiModal(dataIDs: any, dataCount: number, moduleName: string, moduleTitle: string) {
    this.modalRef = this.modalService.show(DeleteModalMultiComponent, {
        class: 'modal-dialog-top address-modal-dialog form-modal-dialog',
        backdrop : 'static',
        keyboard : false,
        initialState : {
            dataIDs: dataIDs,
            dataCount: dataCount,
            moduleName: moduleName,
            moduleTitle: moduleTitle
        } 
    });
  }

}