import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AdminService } from 'src/app/services/admin.service';
import { GlobalService } from 'src/app/services/global.service';
import { InstService } from 'src/app/services/inst.service';
import { SettingsService } from 'src/app/services/settings.service';
//import MD5 from "crypto-js/md5";

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class adminLoginComponent implements OnInit {
  public showPasswordText: boolean = false;
  public user = JSON.parse(localStorage.getItem('currentUser')!);

  public instID: number = 1;
  public instSlug: string;
  public instName: string;
  public instIcon: string;
  public instBranch: string;

  public userRoleData: any[] = [];

  public showError = false;

  constructor(
    private _router: Router,
    private _gService: GlobalService,
    private _adminService: AdminService,
    private _instService: InstService,
    private _sService: SettingsService
  ) {}
  
  UserName = new FormControl('', [Validators.required]);
  Password = new FormControl('', [Validators.required]);
  UserRole = new FormControl('1', [Validators.required]);

  ngOnInit() {
    if (this.user) {
      this._router.navigate(['/dashboard']);
      return false;
    }

    this._gService.showLoader(true);

    this.instSlug = 'demoschool';

    this._instService.getInstListBySlug(this.instSlug).subscribe(dataRes => {
      console.log(dataRes);
      if (dataRes.status === true) {
          let instData = JSON.parse(dataRes.result);
          this. instName = instData[0].inst_name;
          //this.instIcon = instData[0].icon_path;
          this.instIcon = '/assets/img/default-institution-icon.png';
          this.instBranch = instData[0].inst_branch;
      } else {
        this._gService.triggerSnackbar('Invalid URL, Redirecting to Main Website', 'error', true)
        setTimeout(() => {
          window.location.href = 'https://www.edunationpro.com';
        }, 3000); 
      }
      this._gService.showLoader(false);
    })

    this._adminService.getUserRoleData().subscribe(res => {
      console.log(res.result)
      if(res.status == true) {
        this.userRoleData = JSON.parse(res.result)
        console.log(this.userRoleData)
      }
    })

  }

  showPassword() {
    this.showPasswordText = !this.showPasswordText;
  }

  submit() {
    this._gService.showLoader(true);
    if ((this.UserName.valid || this.Password.valid)) {
      let loginData = {
          inst_id: this.instID,
          username: this.UserName.value,
          //password: MD5(this.Password.value!).toString()
          password: this.Password.value,
          user_role: this.UserRole.value
      }
      console.log(loginData)
      this.showError = false;

      this._adminService.adminLoginCheck(loginData).subscribe(res => {
        console.log(res.status)
        if (res.status === true) {
          console.log(res.result)
          let user = JSON.parse(res.result)
          let AdminUserID = user[0].ID, AdminUserRole = user[0].userRole, AdminUserName = user[0].name;
          console.log(AdminUserID, AdminUserRole, AdminUserName);
          localStorage.setItem('currentAdminUser', JSON.stringify({ AdminUserID, AdminUserRole, AdminUserName}));     
          this._router.navigate(['/admin/dashboard/']);
           this._gService.triggerSnackbar('Login Succesful!', 'success', true); 
        } else {
          // console.log(res);
          this.showError = true;
          this._gService.triggerSnackbar('Something went wrong', 'error', true); 
        }
      })
      this._gService.showLoader(false);
    }
  }

}
