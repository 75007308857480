<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Fees Discount</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-9">
            <!--<a href="../../hostel-detail/add/" class="content-header-link">Add Hostel</a>
            <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel"> 
                <div class="panel-header">
                    <div class="panel-title">
                        Add Fees Discount
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Name">
                            <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                                <p class="input-error-msg">Please enter Name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Discount Code</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Discount_code">
                            <div *ngIf="rObj.controls.Discount_code.invalid && (rObj.controls.Discount_code.dirty || rObj.controls.Discount_code.touched)">
                                <p class="input-error-msg">Please enter Discount code</p>
                            </div>
                        </div>
                       
                        <div class="form-group">
                            <label>Discount Type</label>
                            <br>
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Discount_type"  value="percentage">
                                <label class="form-check-label" for="flexCheckDefault">Percentage</label>
                            </div> 
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Discount_type"  value="fix">
                                <label class="form-check-label" for="flexCheckDefault">Fix Amount</label>
                            </div>   
                        </div>
                        <div class="form-group percentage" *ngIf="rObj.controls.Discount_type.value == 'percentage'">
                            <label>Percentage</label>
                            <small class="req percentage"> *</small>
                            <input type="number" class="form-control percentage " formControlName="Percentage" value="" id="percentage">
                            <div *ngIf="rObj.controls.Percentage.invalid && (rObj.controls.Percentage.dirty || rObj.controls.Percentage.touched)">
                                <p class="input-error-msg">Please enter Percentage</p>
                            </div>
                        </div>
                        <div class="form-group"  *ngIf="rObj.controls.Discount_type.value == 'fix'">
                            <label>Amount</label>
                            <small class="req"> *</small>
                            <input type="number" class="form-control" formControlName="Discount_amount">
                            <div *ngIf="rObj.controls.Discount_amount.invalid && (rObj.controls.Discount_amount.dirty || rObj.controls.Discount_amount.touched)">
                                <p class="input-error-msg">Please enter Discount amount</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveData()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Fees Type List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feesDiscountData.length">
                        <thead>
                            <tr>
                                <th class="name"> Name</th>
                                <th class="discount-code"> Code</th>
                                <th class="discount_type">Type</th>
                                <th class="discount_amount"> Amount</th>
                                <th class="discount_percentage"> Percentage</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of feesDiscountData">
                                <td class="align-left">{{f.name}}</td>
                                <td>{{f.discount_code}}</td>
                                <td>{{f.discount_type}}</td>
                                <td>{{f.discount_amount}}</td>
                                <td>{{f.discount_percentage}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openFeesDiscountEditModal(f.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(f.ID, 'fees_discount', 'fees discount ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!feesDiscountData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
    </div>
        
</div>