<form [formGroup]="sObj">
<div class="modal-header">
    <h5 class="modal-title">Add Marks for {{subject_name}} Subject </h5>
    <button type="submit" class="btn primary-btn small-btn" style="margin-right: 15px !important;" (click)="save()">save</button>
    <button type="button" class="btn-close" style="margin-left: 0px;" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <!-- <form [formGroup]="sObj"> -->
            <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="marksRow().controls.length">
                <thead>
                    <tr>
                        <th>Roll No</th>
                        <th>Admission number</th>
                        <th>Student Name</th>
                        <th>Full Marks</th>
                        <th>Marks Obtained</th>
                        <th>Remark</th>
                        <!-- <th class="no-sort">Manage</th> -->
                    </tr>
                </thead>
                <tbody formArrayName="marksRow">
                    <ng-container *ngFor="let s of marksRow().controls; let i=index">
                    <tr [formGroupName]="i">
                        <td>{{s.value.Roll_no}}</td>
                        <td>{{s.value.Admission_no}}</td>
                        <td>{{s.value.Student_name}}</td>
                        <td>{{subjectMarks}}</td>
                        <td><input type="number" class="form-control" formControlName="Marks"></td>
                        <td><input type="text" class="form-control" formControlName="Remark"></td>
                        <!-- <td>{{e.passing_marks}}</td>
                        <td>{{e.room_no}}</td> -->
                        <!-- <td class="action-btn">
                            <a tooltip="Add Marks" class="edit-btn" (click)="openAddMarksModal(e.ID)" ><i class="fa-solid fa-plus"></i></a>
                            <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(e.ID)" ><i class="far fa-edit"></i></a>
                            <a tooltip="Delete" class="del-btn" (click)="deleteData(e.ID, 'staff', 'delete staff')"><i class="far fa-trash-alt"></i></a>
                        </td> -->
                    </tr>
                    </ng-container>
                </tbody>
            </table>   
        <!-- </form> -->
    </div>
</div>
</form>

