import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { InventoryCategoryModalComponent } from 'src/app/components/modal/inventoryCategorymodal/inventoryCategorymodal.component';

@Component({
  selector: 'app-vendor-editVendor',
  templateUrl: './editVendor.component.html',
})

export class EditVendorsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public vendorData: any[] = [];
    public countriesData: any[] = [];

   public vendorId : any;

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
    ) {}

    public rObj = this._fBuilder.group({
        Company_name: ['', Validators.required],
        Company_phone: ['', [Validators.required,Validators.pattern("[0-9 ]{10}")]],
        Company_email: ['', [Validators.required, Validators.email]],
        Company_address: ['', Validators.required],
        Country: ['', Validators.required],
        State: ['', Validators.required],
        City: ['', Validators.required],
        Contact_person_name: ['', Validators.required],
        Contact_person_phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
        Bank_name: ['', Validators.required],
        Branch_name: ['', Validators.required],
        Account_number: ['', Validators.required],
        Ifsc: ['', Validators.required],

    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        this.vendorId = this._aRoute.snapshot.paramMap.get('id');

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vendor').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.vendorData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.vendorId, 'vendor').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let vData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Company_name: vData[0].company_name,
                    Company_phone: vData[0].company_phone,
                    Company_email: vData[0].company_email, 
                    Company_address: vData[0].company_address,
                    Country: vData[0].country,
                    State: vData[0].state,
                    City: vData[0].city,
                    Contact_person_name: vData[0].contact_person_name,
                    Contact_person_phone: vData[0].contact_person_phone,
                    Bank_name: vData[0].bank_name,
                    Branch_name: vData[0].branch_name,
                    Account_number: vData[0].account_number ,
                    Ifsc: vData[0].ifsc,
                });
            }
            this._gService.showLoader(false);
        })


        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    // openCategoryEditModal(categoryID: number) {
    //     this.modalRef = this.modalService.show(InventoryCategoryModalComponent, {
    //         class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
    //         backdrop : 'static',
    //         keyboard : false,
    //         initialState : {
    //             categoryID: categoryID,
    //         } 
    //     });
    // }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }

    UpdateVendor() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id :this.vendorId,
                company_name: rObj.Company_name,
                company_phone: rObj.Company_phone ? rObj.Company_phone : '',
                company_email: rObj.Company_email,
                company_address: rObj.Company_address,
                country: rObj.Country,
                state: rObj.State,
                city: rObj.City,
                contact_person_name: rObj.Contact_person_name ,
                contact_person_phone: rObj.Contact_person_phone ? rObj.Contact_person_phone : '',
                bank_name: rObj.Bank_name,
                branch_name: rObj.Branch_name,
                account_number: rObj.Account_number,
                ifsc: rObj.Ifsc
            }
            console.log(mData)
            this._aService.vendorData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/store-management/vendor/list']);
                    this._gService.triggerSnackbar('Vendor Details Update Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}