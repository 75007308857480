import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { CmsService } from 'src/app/services/cms.service';
import { InstService } from 'src/app/services/inst.service';
import { GlobalService } from 'src/app/services/global.service';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';

@Component({
  selector: 'app-website-page',
  templateUrl: './page.component.html',
  styleUrls: ['./../themes/default/assets/css/style.css']
})

export class WebsitePageComponent implements OnInit {
    public pageData: any[] = [];

    public imgURL = this._gService.getImgPath();

    public instSlug: string = this._cmsService.getInstSlug();

    public _album: any[] = [];
    
    constructor(
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _cmsService: CmsService,
        private _instService: InstService,
        private _gService: GlobalService,
        private _lightbox: Lightbox,
        private _lightboxConfig: LightboxConfig
    ) {}

    ngOnInit(): void {

        this._instService.getInstListBySlug(this.instSlug).subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let instData = JSON.parse(dataRes.result);
                let instID = instData[0].ID;
                let pageSlug : any = this._aRoute.snapshot.paramMap.get('slug');
                if (pageSlug == 'online-admission') {
                    this.pageData = [{
                        title : 'Admission Form',
                        content : 'show-admission-form'
                    }]
                } else if (pageSlug == 'contact-us') {
                    this.pageData = [{
                        title : 'Contact Us',
                        content : 'show-contact-form'
                    }]
                } else {
                    this._cmsService.getPageData(instID, pageSlug).subscribe(pageRes => {
                        if (pageRes.status === true) {
                            this.pageData = JSON.parse(pageRes.result);
                            console.log(this.pageData[0].title)
                            // this._cmsService.setPageTitle(this.pageData[0].title);
                            if (this.pageData[0].type == 'gallery') {
                                let gallery_images = JSON.parse(this.pageData[0].gallery_images)
                                console.log(gallery_images)
                                for (let i = 0; i <= (gallery_images.length-1); i++) {
                                    const src = this.imgURL+gallery_images[i];
                                    const caption = this.pageData[0].title;
                                    const thumb = this.imgURL+gallery_images[i];
                                    const album = {
                                    src: src,
                                    caption: caption,
                                    thumb: thumb
                                    };
                            
                                    this._album.push(album);
                                }
                            }
                        }
                    })
            }
            }
        })

        this._lightboxConfig.positionFromTop = 50;
        this._lightboxConfig.showImageNumberLabel = true;

    }

    stringToArray(array: string) {
        return JSON.parse(array)
    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    close(): void {
    // close lightbox programmatically
    this._lightbox.close();
    }

}