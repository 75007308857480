import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

let routes: Routes = [
    {
        path: 'user/login', component: LoginComponent, title: 'Login - EduNationPro' 
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class UserLoginRoutingModule { }
export const UserLoginRoutes = [
    LoginComponent,
]