<div class="modal-header">
    <h5 class="modal-title">Edit Fees Type</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Fees_name" >
                <div *ngIf="rObj.controls.Fees_name.invalid && (rObj.controls.Fees_name.dirty || rObj.controls.Fees_name.touched)">
                    <p class="input-error-msg">Please enter fees name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Fees Code</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Fees_code" >
                <div *ngIf="rObj.controls.Fees_code.invalid && (rObj.controls.Fees_code.dirty || rObj.controls.Fees_code.touched)">
                    <p class="input-error-msg">Please enter fees code</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateFeesType()">Update</button>
            </div>
        </form> 
    </div>
</div>
