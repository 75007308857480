<div class="modal-header">
    <h5 class="modal-title">Select Images</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <div class="media-modal-wrapper">
            <ng-container *ngIf="type != 'gallery'">
                <div class="media-item" *ngFor="let media of mediaData" (click)="selectMedia(media.filename)" [ngClass]="selectedMedia == media.filename ? 'active' : ''">
                    <img class="media-img" src="{{imgURL}}{{media.filename}}"  >
                    <h5 class="media-name">{{media.filename}}</h5>
                </div>
            </ng-container>
            <ng-container *ngIf="type == 'gallery'">
                <div class="media-item" *ngFor="let media of mediaData" (click)="selectMedia(media.filename)" [ngClass]="selectedMediaArray.includes(media.filename) ? 'active' : ''">
                    <img class="media-img" src="{{imgURL}}{{media.filename}}"  >
                    <h5 class="media-name">{{media.name}}</h5>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="modal-footer-btn-wrapper">
        <button class="btn primary-btn small-btn select-btn" (click)="submitSelectedMedias()" [disabled]="(type == 'gallery' && !selectedMediaArray.length) || (type != 'gallery' && !selectedMedia)">Select</button>
    </div>
</div>
