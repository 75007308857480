<div class="website-content-wrapper" *ngFor="let page of pageData">
      <div class="page-title-wrapper">
      <h2 class="page-title">{{page.title}}</h2>
      </div>
      <div class="page-content-wrapper">
            
            <section>
                  <div [innerHTML]="page.content" *ngIf="page.content != 'show-admission-form' "></div>
                  <ng-container *ngIf="page.content == 'show-admission-form'">
                        <app-components-admissionForm></app-components-admissionForm>
                  </ng-container>
                  <ng-container *ngIf="page.content == 'show-contact-form'">
                        <app-components-contactUsForm></app-components-contactUsForm>
                  </ng-container>
            </section>
            <section>
                  <div *ngIf="page.type == 'gallery'">
                        <div class="row gallery-grid-wrapper">
                              <div class="col-md-3" *ngFor="let image of _album; let i = index">
                                    <div class="grid-img-wrapper">
                                          <img class="grid-img" [src]="image.thumb" [alt]="image.caption" height="250" width="auto" (click)="open(i)">
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</div>