<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-4">Create Time Table</h1>
            <div class="header-msg col-md-4">
                        </div>
            <!-- <div class="btn-container link-container col-md-4"> -->
                <!-- <a href="../import/" class="content-header-link">Import Students</a> -->
                    <!-- <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="savetimeTable()">Save</button>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="filter-options">
                        <form [formGroup]="rObj">
                            <div class="form-group">
                                <label>Class
                                <small class="req"> *</small></label>
                                <select name="class" formControlName="Class" class="form-select" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                 </select>
                                 <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                    <p class="input-error-msg">Please select class</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Section
                                    <small class="req"> *</small></label>
                                    <select name="section" formControlName="Section" class="form-select" >
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let section of classSectionData" >
                                            <ng-container *ngFor="let s of sectionData">
                                                <ng-container *ngIf="s.ID == section">
                                                    <option [value]="section">{{s.name}}</option>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                        <p class="input-error-msg">Please select section</p>
                                    </div>
                            </div>
                            <div class="form-group btn-container">
                                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="navigate()">Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="openpanel">
            <div class="row" >
                <div class="col-md-12">
                    <div class="console-content-navbar">
                        <ul class="content-links" *ngIf="openpanel">
                            <li class="content-link" [ngClass]="mondayTab ? 'active' : ''" (click)="setTabActive('mondayTab')">
                                <a id="basic-details">
                                    <!-- <i class="fas fa-user"></i> -->
                                    <span>Monday</span>
                                </a>
                            </li>
                            <li class="content-link" [ngClass]="tuesdayTab ? 'active' : ''" (click)="setTabActive('tuesdayTab')">
                                <a id="address-details">
                                    <!-- <i class="fas fa-map-marker-alt"></i> -->
                                    <span>Tuesday</span>
                                </a>
                            </li>
                            <li class="content-link" [ngClass]="wednesdayTab ? 'active' : ''" (click)="setTabActive('wednesdayTab')">
                                <a id="parent-guardian-details">
                                    <!-- <i class="fas fa-user-tag"></i> -->
                                    <span>Wednesday</span>
                                </a>
                            </li>
                            <li class="content-link" [ngClass]="thursdayTab ? 'active' : ''" (click)="setTabActive('thursdayTab')">
                                <a id="documents">
                                    <!-- <i class="fas fa-file-alt"></i> -->
                                    <span>Thursday</span>
                                </a>
                            </li>
                            <li class="content-link" [ngClass]="fridayTab ? 'active' : ''" (click)="setTabActive('fridayTab')">
                                <a id="sibling-details">
                                    <!-- <i class="fas fa-user-plus"></i> -->
                                    <span>Friday</span>
                                </a>
                            </li>
                            
                            <li class="content-link" [ngClass]="saturdayTab ? 'active' : ''" (click)="setTabActive('saturdayTab')">
                                <a id="other-details">
                                    <!-- <i class="fas fa-folder-plus"></i> -->
                                    <span>Saturday</span>
                                </a>
                            </li>
                            <li class="content-link" [ngClass]="sundayTab ? 'active' : ''" (click)="setTabActive('sundayTab')">
                                <a id="other-details">
                                    <!-- <i class="fas fa-folder-plus"></i> -->
                                    <span>Sunday</span>
                                </a>
                            </li>
                            <button class="btn default-btn extra-small-btn inline float-right" (click)="addTTData(getWeekDay(day))">Add </button>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row" >
                <div class="col-md-12 console-content-tab">
                    <div id="monday-details" class="panel panel-margin-top" [ngClass]="mondayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Monday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataMonday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Monday').controls; let i = index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select">
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select">
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Monday', i, ttData('Monday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;"  (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="tuesday-details" class="panel panel-margin-top" [ngClass]="tuesdayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Tuesday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataTuesday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Tuesday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Tuesday', i, ttData('Tuesday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="wednesday-details" class="panel panel-margin-top" [ngClass]="wednesdayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Wednesday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataWednesday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Wednesday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Wednesday', i, ttData('Wednesday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="thursday-details" class="panel panel-margin-top" [ngClass]="thursdayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Thursday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataThursday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Thursday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Thursday', i, ttData('Thursday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="friday-details" class="panel panel-margin-top" [ngClass]="fridayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Friday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataFriday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Friday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Friday', i, ttData('Friday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="saturday-details" class="panel panel-margin-top" [ngClass]="saturdayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Saturday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataSaturday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Saturday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Saturday', i, ttData('Saturday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>

                    <div id="sunday-details" class="panel panel-margin-top" [ngClass]="sundayTab ? 'active' : ''">
                        <div class="panel-header">
                            <div class="panel-title">
                            Sunday Time Table:
                            </div>
                        </div>
                        <div class="panel-body">
                            <div formArrayName="ttDataSunday">
                                <div class="timetable-row" *ngFor="let detail of ttData('Sunday').controls; let i=index">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-2">
                                            <select formControlName="Subject" class="form-select" >
                                                <option value="">Subject</option>
                                                <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <select formControlName="Teacher" class="form-select" >
                                                <option value="">Teacher Name</option>
                                                <option *ngFor="let t of teacherData" value="{{t.ID}}">{{t.full_name}}</option>               
                                            </select>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_from" class="form-control" placeholder="Time from">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="time" formControlName="Time_to" class="form-control" placeholder="Time to">
                                        </div>
                                        <div class="col-md-2">
                                            <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                        </div>
                                        <div class="col-md-1 align-center">
                                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeTTData('Sunday', i, ttData('Sunday').controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-btn-wrapper">
                                <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="savetimeTable()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!openpanel">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>