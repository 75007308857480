import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor.service';
import { StudentService } from 'src/app/services/student.service';
import { StaffService } from 'src/app/services/staff.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { InventoryIssueModalComponent } from 'src/app/components/modal/inventoryIssuemodal/inventoryIssuemodal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-vendor-inventoryIssue',
  templateUrl: './inventoryIssue.component.html',
})

export class InventoryIssueComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public staffData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public deparmentData: any[] = [];
    public designationData:any[] = [];
    public staffListData: any[] = [];
    public inventoryIssueData: any[] = [];
    public inventoryItemData: any[] = [];
    public studentData: any[] = [];
    public mediumData: any[] = [];
    public selectedIds : any[] = [];
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _sService: StaffService,
        private _stService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        User_type: ['student', Validators.required],
        Class: [''],
        Section: [''],
        Department: [''],
        Designation:[''],
        Issue_to: ['', Validators.required],
        Issue_by: ['', Validators.required],
        Date: ['', Validators.required],
        Return_date: ['', Validators.required],
        Item_name: ['', Validators.required],
        Qty: ['', Validators.required],
        Note: [''],
        Medium: ['']
        
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_issue').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryIssueData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_item').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryItemData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.deparmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })      

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

        // this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, this.rObj.value.Designation).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this.staffListData = JSON.parse(sRes.result)
        //     } else {
        //         this.staffListData = [];
        //     }
        // }, err => {
        //     console.log(err);
        //     this._gService.triggerSnackbar('Server Error!', 'error', true);
        // })

        // this._stService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, this.rObj.value.Section).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this.studentListData = JSON.parse(sRes.result)
        //     } else {
        //         this.studentListData = [];
        //     }
        // }, err => {
        //     console.log(err);
        //     this._gService.triggerSnackbar('Server Error!', 'error', true);
        // })

       
        this.rObj.controls['Section'].valueChanges.subscribe((value : any) =>{
            this._gService.showLoader(true);
            this._stService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                } else {
                    this.studentListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false);
        })

        this.rObj.controls['Designation'].valueChanges.subscribe((value : any)=>{
            this._gService.showLoader(true); 
            this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.staffListData = JSON.parse(sRes.result)
                } else {
                    this.staffListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false); 
        })

        

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
        this._gService.showLoader(false);
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.staffListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.staffListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    openIssueEditModal(issueID: number) {
        this.modalRef = this.modalService.show(InventoryIssueModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                issueID: issueID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    getDate(date : any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    saveIssue() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                user_type: rObj.User_type,
                class: rObj.Class,
                section: rObj.Section,
                department: rObj.Department,
                designation: rObj.Designation,
                issue_to: rObj.Issue_to,
                issue_by: rObj.Issue_by,
                item_id: rObj.Item_name,
                qty: rObj.Qty,
                date: rObj.Date,
                note: rObj.Note,
                return_date: rObj.Return_date,
               
            }
            console.log(mData)
            this._aService.inventoryIssueData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Inventory Issue Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}