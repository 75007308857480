import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';  
@Component({
    selector: 'app-menuItemmodal',
    templateUrl: './menuItemmodal.component.html',
})

export class MenuItemModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public menuItemID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public PagesData: any[] = [];
   

    constructor(
        private _gService: GlobalService,
        private _cmsService: CmsService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Menu_item: ['', Validators.required],
        Ex_url_address: [''],
        Pages: [''],
        Type: ['external'],
        Target: ['yes']
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.menuItemID, 'cms_menu_item').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Menu_item: sData[0].name,
                    Type: sData[0].type,
                    Ex_url_address: sData[0].url,
                    Pages: sData[0].page_id,
                    Target: sData[0].target,
                });
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'cms_pages').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.PagesData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
    }

    updateMenuItem() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.menuItemID,
                name: rObj.Menu_item,
                type: rObj.Type,
                url: rObj.Ex_url_address,
                page_id: rObj.Pages,
                target: rObj.Target
            }
            console.log(mData)
            this._cmsService.menuItemData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Menu Item Update Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}