import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';

@Component({
  selector: 'app-reports-hostelReport',
  templateUrl: './hostelReport.component.html',
})

export class HostelReportsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public userRoleData: any[] = [];
    public classSectionData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public routeData: any[] = [];
    public hostelData: any[] = [];
    public mediumData: any[] = [];
    // public designationData: any[] = [];
    public studentData: any[] = [];
    // public studentListData: any[] = [];
    // public staffAttendanceData: any[] = [];
    // public studentTeacherData: any[] = [];
    // public classSubjectData: any[] = [];
    // public siblingsData: any[] = [];
    // public allStudentData: any[] = [];
    // public getAdmissionData: any[] = [];
    // public studentAttendance: any[] = [];
    // public studentAttendancedaywise: any[] = [];
    // public staffAttendancedaywise: any[] = [];
    // public dailystudentAttendance: any[] = [];
    // public dailystaffAttendance: any[] = [];
    // public studentAttendanceType: any[] = [];
    // public staffAttendanceType: any[] = [];




    public genderRatio: any;
    public absentPercentage: any;
    
    public staffReport: boolean = true;
    public payrollReport: boolean = false;
    // public staffAttendanceTypeReport: boolean = false;
    // public dailystudentAttendanceReport: boolean = false;
    // public dailystaffAttendanceReport: boolean = false;
    // public staffAttendanceReport: boolean = false;
    // public studentAttendanceDaywiseReport: boolean = false;
    // public staffAttendanceDaywiseReport: boolean = false;

    
    public imgURL = this._gService.getImgPath();
    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: [''],
        Hostel: [''],
        Section: [''],
        Medium: [''],
        // Search_type: [''],
        // Date_from:[''],
        // Date_to:[''],
        // Period:[''],
        // Date: [''],
        // Designation: [''],
        // Department: [''],
        // Attendence_type:[''],
        
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        
        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })


      

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.hostelData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.routeData = JSON.parse(cRes.result);
                console.log(this.routeData)
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

       

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }
  




    openMediumEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
  

 


    search() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;

            this._rService.getHostelData(this.instID, this.activeAcademicID, rObj.Class, rObj.Section, rObj.Hostel).subscribe((res : any)=> {
                console.log(res)
                if (res.status === true) {
                    this.studentData = JSON.parse(res.result)
                    console.log(this.studentData)
                } 
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })


                //     if(rObj.Hostel) {
                //         console.log(rObj.Hostel,rObj.Class,rObj.Section,)
                //         this._rService.getHostelData(this.instID, this.activeAcademicID, rObj.Class, rObj.Section, rObj.Hostel).subscribe(res => {
                //             console.log(res)
                //             // this.studentData = JSON.parse(res.result)
                //             if (res.)
                //             this.studentData = studentData.filter((h : any) => h.hostel_id == rObj.Hostel)
                //             console.log(this.studentData)
                //         })
                //     } else {
                //         this.studentData = studentData
                //     }
                // } else {
                //     this._gService.triggerSnackbar('Something went wrong', 'error', true);
                // }
        //     }, err => {
        //         console.log(err);
        //         this._gService.triggerSnackbar('Server Error!', 'error', true);
        //     })

            

        }
    }

}