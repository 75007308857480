<div class="modal-header">
    <h5 class="modal-title">Delete {{moduleTitle}}</h5>
</div>
<div class="modal-body modal-delete-container">
    <div class="modal-content-wrapper">
        <div class="remove-msg-wrapper">
            <p class="remove-msg">Are you sure that you want to delete this {{moduleTitle}}?</p>
        </div>
        <div class="modal-btn-wrapper">
            <button type="button" class="btn default-btn cancel-btn" (click)="modalRef.hide()">Cancel</button>
            <button type="button" class="btn primary-btn continue-btn" (click)="userRole == 1 ? deleteAdminData(dataID) : deleteModuleData(dataID)">Yes</button>
        </div>
    </div>
</div>
