<div class="modal-header">
    <h5 class="modal-title">Edit Academic Year</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Start Year</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="StartYear">
                    <option *ngFor="let year of years" [value]="year">{{year}}</option> 
                </select>
            </div>
            <div class="form-group">
                <label>Start Month</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="StartMonth">
                    <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option> 
                </select>
            </div>
            <div class="form-group">
                <label>End Year</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="EndYear">
                    <option *ngFor="let year of years" [value]="year">{{year}}</option> 
                </select>    
            </div>
            <div class="form-group">
                <label>End Month</label>
                <small class="req"> *</small>
                <select class="form-select" formControlName="EndMonth">
                    <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option> 
                </select>
            </div>
            <div class="form-group form-btn-wrapper">
                <button class="btn primary-btn small-btn" (click)="updateAcademicYear()">Update</button>
            </div>
        </form> 
    </div>
</div>
