import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { UserService } from 'src/app/services/user.service';
import { AdminService } from 'src/app/services/admin.service';
  
@Component({
    selector: 'app-activemodal',
    templateUrl: './activemodal.component.html',
    // styleUrls: ['./changePasswordmodal.component.css'],
})

export class ActiveModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.AdminUserID;
    public instId: any
    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        OTP: ['', Validators.required],
    })

    ngOnInit(): void {
        // alert(this.instId)
        // this._gService.showLoader(true);

        // this._gService.getDataByID(this.mediumID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    resend() {
        this._gService.showLoader(true);
        let rData = {
            inst_id: this.instId,
        }
        this._adminService.resendOTP(rData).subscribe(r => {
            console.log(r)
            if(r.status == true) {
                this._gService.triggerSnackbar('OTP Resent Sucessfully', 'success', true) 
            }else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
       
        })
        this._gService.showLoader(false);
    }

    verify() {
        this._gService.showLoader(true);

        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id: this.instId,
                otp: rObj.OTP,
            }
            console.log(mData)
            this._adminService.ActivateInst(mData).subscribe(mRes => {
                console.log(mRes)               
                if (mRes.status === true) {
                    this.modalRef.hide();
                    this.modalRef.onHidden?.subscribe(() => {
                        // this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate(['admin/institution/list'])
                        this._gService.triggerSnackbar('Institution Successfully Activated', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Invalid OTP, Try Again', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
        this._gService.showLoader(false);

    }
    
}