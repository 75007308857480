import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { EventService } from 'src/app/services/event.service';
import { EventModalComponent } from 'src/app/components/modal/eventmodal/eventmodal.component';
import { Title } from '@angular/platform-browser';
import { CircularService } from 'src/app/services/circular.service';
import { CircularmodalComponent } from 'src/app/components/modal/circularmodal/circularmodal.component';

@Component({
  selector: 'app-communication-circularList',
  templateUrl: './circularList.component.html',
})

export class CircularListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public mediumData: any[] = [];
    public circularData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: CircularService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
       Title: ['', Validators.required],
       Description: ['', Validators.required],
       Date: ['', Validators.required],
       Send_to: ['', Validators.required],
       Attachment: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.mediumData = JSON.parse(mRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'circulars').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.circularData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

       
    

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    openCircularEditModal(circularID: number) {
        this._router.navigate(['/communication/circular/edit/'+circularID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveCircular() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                title: rObj.Title,
                description: rObj.Description,
                date: rObj.Date,
                sent_to: rObj.Send_to,
                attachment: this.imageSrc,
            }
            
            console.log(mData)
            this._aService.circularData(mData).subscribe(eRes => {
                console.log(eRes)
                if (eRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Circular Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}