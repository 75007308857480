import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class ReportService {
  constructor(
    private _http: HttpClient,
  ) { }

  private getStudentCount = `${environment.APIURL}/reports/students/getStudentsCountByInstID/`;
  private genderRatio = `${environment.APIURL}/reports/students/getStudentsGenderCountByInstID/`;
  private teacherRatio = `${environment.APIURL}/reports/students/getStudentsTeachersCountByInstID/`;
  private classSubject = `${environment.APIURL}/reports/students/getClassSubjectByInstID/`;
  private admission = `${environment.APIURL}/reports/students/getAdmissionDataByPeriod/`;
  private incomeData = `${environment.APIURL}/reports/finance/getIncomeDataByPeriod/`;
  private expenseData = `${environment.APIURL}/reports/finance/getExpenseDataByPeriod/`;
  private payroll = `${environment.APIURL}/reports/finance/getPayrollDataByPeriod/`;
  private dueBalance = `${environment.APIURL}/reports/finance/getBalanceFeesData/`;
  private dailyCollection = `${environment.APIURL}/reports/finance/getDailyFeesCollectionData/`;
  private dateCollection = `${environment.APIURL}/reports/finance/getFeesCollectionDataByDate/`;
  private feeCollection = `${environment.APIURL}/reports/finance/getFeesCollectionDataByPeriod/`;
  private studentAttendance = `${environment.APIURL}/reports/attendance/getAttendanceCountByClassSectionMonthYear/`;
  private staffAttendance = `${environment.APIURL}/reports/attendance/getAttendanceCountByDeptDesMonthYear/`;
  private studentAttendancedaywise = `${environment.APIURL}/reports/attendance/getAttendanceCountByClassSectionDate/`;
  private staffAttendancedaywise = `${environment.APIURL}/reports/attendance/getAttendanceCountByDeptDesDate/`;
  private dailystudentattendance = `${environment.APIURL}/reports/attendance/getStudentAttendanceByDate/`;
  private dailystaffattendance = `${environment.APIURL}/reports/attendance/getStaffAttendanceByDate/`;
  private studentAttendanceType = `${environment.APIURL}/reports/attendance/getStudentAttendanceByTypePeriod/`;
  private staffAttendanceType = `${environment.APIURL}/reports/attendance/getStaffAttendanceByTypePeriod/`;
  private staffInfo = `${environment.APIURL}/reports/staff/getStaffDataByRoleStatusPeriod/`;
  private payrollInfo = `${environment.APIURL}/reports/staff/getPayrollDataByRoleMonthYear/`;
  private inventoryPurchase = `${environment.APIURL}/reports/inventory/getInventoryPurchaseDataByPeriod/`;
  private inventoryissue = `${environment.APIURL}/reports/inventory/getInventoryIssueDataByPeriod/`;
  private stock = `${environment.APIURL}/reports/inventory/getInventoryStockData/`;
  private getHostelDetails = `${environment.APIURL}/reports/hostel/getHostelDataByClassSectionName/`;
  private getTransportDetails = `${environment.APIURL}/reports/transport/getStudentTransportData/`;
  private getBookIssueDetails = `${environment.APIURL}/reports/library/getBookIssueDataByPeriodMemberType/`;
  private getBookDueDetails = `${environment.APIURL}/reports/library/getBookDueDataByPeriodMemberType/`;
  private getBookInventoryDetails = `${environment.APIURL}/reports/library/getBooksInventoryData/`;
  private getBookIssueReturnDetails = `${environment.APIURL}/reports/library/getBookReturnDataByPeriodMemberType/`;
  private getStudentHistory = `${environment.APIURL}/reports/students/getStudentHistoryData/`;

  getStudentHistoryData(instID: number) {
    return this._http.get(this.getStudentHistory+'?inst_id='+instID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getBookIssueReturnData(instID: number, academicID: number, period: any, memberType: any) {
    return this._http.get(this.getBookIssueReturnDetails+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&member_type='+memberType, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getBookInventoryData(instID: number, academicID: number) {
    return this._http.get(this.getBookInventoryDetails+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getBookDueData(instID: number, academicID: number, period: any, memberType: any) {
    return this._http.get(this.getBookDueDetails+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&member_type='+memberType, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getBookIssueData(instID: number, academicID: number, period: any, memberType: any) {
    return this._http.get(this.getBookIssueDetails+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&member_type='+memberType, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  getTransportData(instID: number, academicID: number, classID: any, sectionID: any, medium: any, routeID: any, routepickupID: any) {
    return this._http.get(this.getTransportDetails+'?inst_id='+instID+'&academic_id='+academicID+'&medium='+medium+'&class_id='+classID+'&section_id='+sectionID+'&route_id='+routeID+'&route_pickup_id='+routepickupID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getHostelData(instID: number, academicID: number, classID: any, sectionID: any, hostelID: any) {
    return this._http.get(this.getHostelDetails+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classID+'&section_id='+sectionID+'&hostel_id='+hostelID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  
  stockData(instID: number, academicID: number): Observable<any> {
    return this._http.get(this.stock+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }


  inventoryIssueData(instID: number, academicID: number, period: any): Observable<any> {
    return this._http.get(this.inventoryissue+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  inventoryPurchaseData(instID: number, academicID: number, period: any): Observable<any> {
    return this._http.get(this.inventoryPurchase+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 


  staffpayrollData(instID: number, academicID: number, role: any, month: any, year: any): Observable<any> {
    return this._http.get(this.payrollInfo+'?inst_id='+instID+'&academic_id='+academicID+'&role='+role+'&month='+month+'&year='+year, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 

  staffData(instID: number, academicID: number,role: any, period: any, status: any): Observable<any> {
    return this._http.get(this.staffInfo+'?inst_id='+instID+'&academic_id='+academicID+'&role='+role+'&period='+period+'&status='+status, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 

  staffAttendanceTypeData(instID: number, academicID: number,period: any, type: any): Observable<any> {
    return this._http.get(this.staffAttendanceType+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&type='+type, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 


  studentAttendanceTypeData(instID: number, academicID: number, classId: any, sectionId : any, period: any, type: any): Observable<any> {
    return this._http.get(this.studentAttendanceType+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classId+'&section_id='+sectionId+'&period='+period+'&type='+type, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 

  dailyStaffAttendance(instID: number, academicID: number, date: any): Observable<any> {
    return this._http.get(this.dailystaffattendance+'?inst_id='+instID+'&academic_id='+academicID+'&date='+date, {
      headers: { 'Content-Type': 'application/json' }
    })
  } 

  dailyStudentAttendance(instID: number, academicID: number, date: any): Observable<any> {
    return this._http.get(this.dailystudentattendance+'?inst_id='+instID+'&academic_id='+academicID+'&date='+date, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  staffDaywiseAttendance(instID: number, academicID: number, deptId: any, desId: any, date: any): Observable<any> {
    return this._http.get(this.staffAttendancedaywise+'?inst_id='+instID+'&academic_id='+academicID+'&department_id='+deptId+'&designation_id='+desId+'&date='+date, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  studentDaywiseAttendance(instID: number, academicID: number, classId: any, sectionId: any, date: any, medium: any): Observable<any> {
    return this._http.get(this.studentAttendancedaywise+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classId+'&section_id='+sectionId+'&date='+date+'&medium='+medium, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getStudentAttendanceData(instID: number, academicID: number, classId: any, sectionId: any, month: any,  year: any): Observable<any> {
    return this._http.get(this.studentAttendance+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classId+'&section_id='+sectionId+'&month='+month+'&year='+year, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  getStaffAttendanceData(instID: number, academicID: number, deptId: any, desId: any, month: any,  year: any): Observable<any> {
    return this._http.get(this.staffAttendance+'?inst_id='+instID+'&academic_id='+academicID+'&department_id='+deptId+'&designation_id='+desId+'&month='+month+'&year='+year, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  feeCollectionData(instID: number, academicID: number, period: any, classId: any, sectionId: any, fee_group: any): Observable<any> {
    return this._http.get(this.feeCollection+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&class_id='+classId+'&section_id='+sectionId+'&fees_group_id='+fee_group, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  incomereportData(instID: number, academicID: number, period: any, date_from: any, date_to: any): Observable<any> {
    return this._http.get(this.incomeData+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&date_from='+date_from+'&date_to='+date_to, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  expensereportData(instID: number, academicID: number, period: any, date_from: any, date_to: any): Observable<any> {
    return this._http.get(this.expenseData+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&date_from='+date_from+'&date_to='+date_to, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  payrollData(instID: number, academicID: number, period: any, date_from: any, date_to: any): Observable<any> {
  return this._http.get(this.payroll+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&date_from='+date_from+'&date_to='+date_to, {
    headers: { 'Content-Type': 'application/json' }
  })
}

balanceFeeData(instID: number, academicID: number, classId: any, sectionId: any, medium: any ): Observable<any> {
  return this._http.get(this.dueBalance+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classId+'&section_id='+sectionId+'&medium='+medium, {
    headers: { 'Content-Type': 'application/json' }
  })
}   

dailyCollectionData(instID: number, academicID: number, date_from: any, date_to: any ): Observable<any> {
  return this._http.get(this.dailyCollection+'?inst_id='+instID+'&academic_id='+academicID+'&date_from='+date_from+'&date_to='+date_to, {
    headers: { 'Content-Type': 'application/json' }
  })
}   
dailyDateCollectionData(instID: number, academicID: number, date: any ): Observable<any> {
  return this._http.get(this.dateCollection+'?inst_id='+instID+'&academic_id='+academicID+'&date='+date, {
    headers: { 'Content-Type': 'application/json' }
  })
}   

 getStudentCountData(instID: number, academicID: number): Observable<any> {
    return this._http.get(this.getStudentCount+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getStudentgenderRatioData(instID: number, academicID: number): Observable<any> {
    return this._http.get(this.genderRatio+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getStudentTeacherRatioData(instID: number, academicID: number): Observable<any> {
    return this._http.get(this.teacherRatio+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

   classSubjectData(instID: number, academicID: number, classId: any, sectionId: any, medium:any): Observable<any> {
    return this._http.get(this.classSubject+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classId+'&section_id='+sectionId+'&medium='+medium, {
      headers: { 'Content-Type': 'application/json'}
    })
  }


  getAdmissionData(instID: number, academicID: number, period: any, date_from: any, date_to: any ): Observable<any> {
    return this._http.get(this.admission+'?inst_id='+instID+'&academic_id='+academicID+'&period='+period+'&date_from='+date_from+'&date_to='+date_to, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
}