<div class="modal-header">
    <h5 class="modal-title">Edit Fees Discount</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Name" >
            </div>
            <div class="form-group">
                <label>Discount Code</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Discount_code">
            </div>
            <div class="form-group">
                <label>Discount Type</label>
                <br>
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Discount_type"  value="percentage">
                    <label class="form-check-label" for="flexCheckDefault">Percentage</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Discount_type"  value="fix">
                    <label class="form-check-label" for="flexCheckDefault">Fix Amount</label>
                </div>   
            </div>
            <div class="form-group percentage" *ngIf="rObj.controls.Discount_type.value == 'percentage'">
                <label>Percentage</label>
                <small class="req percentage"> *</small>
                <input type="number" class="form-control percentage " formControlName="Percentage" value="" id="percentage">
            </div>
            <div class="form-group"  *ngIf="rObj.controls.Discount_type.value == 'fix'">
                <label>Amount</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Discount_amount" >
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description" ></textarea>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateData()">Update</button>
            </div>
        </form> 
    </div>
</div>
