<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Marks Grade</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-9">
            <!--<a href="../../hostel-detail/add/" class="content-header-link">Add Hostel</a>
            <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel"> 
                <div class="panel-header">
                    <div class="panel-title">
                        Add Marks Grade
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Grade Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Grade_name" >
                            <div *ngIf="rObj.controls.Grade_name.invalid && (rObj.controls.Grade_name.dirty || rObj.controls.Grade_name.touched)">
                                <p class="input-error-msg">Please enter Grade name</p>    
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Percentage From</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Percentage_from" >
                            <div *ngIf="rObj.controls.Percentage_from.invalid && (rObj.controls.Percentage_from.dirty || rObj.controls.Percentage_from.touched)">
                                <p class="input-error-msg">Please enter Percentage from</p>    
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Percentage Upto</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Percentage_upto" >
                            <div *ngIf="rObj.controls.Percentage_upto.invalid && (rObj.controls.Percentage_upto.dirty || rObj.controls.Percentage_upto.touched)">
                                <p class="input-error-msg">Please enter Percentage_upto</p>    
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveGrade()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Marks Grade List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="gradeTypeData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Marks Grade Name</th>
                                <th class="align-left">Percentage From</th>
                                <th class="align-left">Percentage Upto</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let g of gradeTypeData">
                                <td class="align-left">{{g.grade_name}}</td>
                                <td class="align-left">{{g.per_from}}</td>
                                <td class="align-left">{{g.per_upto}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openPayHeadEditModal(g.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(g.ID, 'marks_grade', ' grade ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                    <div *ngIf="!gradeTypeData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
        
</div>