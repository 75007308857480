import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Route, Router, RouteReuseStrategy ,ActivatedRoute} from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CertificateService } from 'src/app/services/certificates.service';
import * as moment from 'moment';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg,} from 'html-to-image';
import { readyException } from 'jquery';
import { CertificateModalComponent } from 'src/app/components/modal/certificatemodal/certificatemodal.component';

@Component({
  selector: 'app-certificate-addCertificate',
  templateUrl: './addCertificate.component.html'
})

export class CertificateTemplateComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public templateData: any[] = [];
    public templateID: any;
    public tempID: number;
    public templatePath: any;
    public templateHtml : any;
    public htmlPreview : String;
    public previewImg : any;
    public course_field_array: any[] = [1];
    public schoolName_field_array: any[] = [1,2,6];
    public presented_field_array: any[] = [1];
    public seal_array: number[] = [1,2,3,5,7,8];
    signimageSrc:any = '';
    signstatus:boolean = false;

    sealimageSrc:any = '';
    sealstatus:boolean = false;

    bgimageSrc:any = '';
    bgstatus:boolean = false;
    modalRef : any
    
    // htmlToImage = require('html-to-image');

    public imgURL = 'https://apiuat.edunationpro.com/uploads/';

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: CertificateService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _aroute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
        private sanitizer: DomSanitizer,
        private modalService: BsModalService
    ) {}

    public rObj = this._fBuilder.group({
        Template_Name: [''],
        Certificate_title: [''],
        Certificate_sub_title: [''],
        School_Name: [''],
        // Student_Name: [''],
        Text: [''],
        Course_name:[''],
        Background: [''],
        Presented_date: [''],
        Date_issued: [''],
        Seal: [''],
        Sign: [''],
    })

    ngOnInit(): void {
       
        this.templateID =this._aroute.snapshot.paramMap.get('id');
        this.tempID = parseInt(this.templateID)
        //alert(this.templateID)
        // this._gService.showLoader(true);
        // alert(this.seal_array)
        // alert(this.templateID)
        // alert(this.seal_array.includes(2))
        // alert(this.seal_array.includes(parseInt(this.templateID)))
        this._aService.templatesData().subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.templateData = JSON.parse(pRes.result);
                if (this.templateID) {
                    this.templatePath = this.templateData.filter(t => {
                        return t.ID == this.templateID  
                    })
                    console.log(this.templatePath)
                    this.templatePath = this.templatePath[0].path
                    console.log(this.templatePath)
                    this._gService.getHtmlData(this.templatePath).subscribe((data : any)=>{
                        this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data)
                    })
                }
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
       

       
        console.log(this.templatePath)


        this.rObj.controls['School_Name'].valueChanges.subscribe((value: any) => {
            let name = document.querySelector("#schoolname");
            if (value) {
                if (name) { name.textContent = value }
            } else {
                if (name) { name.textContent = name.getAttribute('data-name') }
            }
        })

        this.rObj.controls['Certificate_title'].valueChanges.subscribe((value: any) => {
            let CertificteName = document.querySelector("#titlename");
            if (value) {
                if (CertificteName) { CertificteName.textContent = value }
            } else {
                if (CertificteName) { CertificteName.textContent = CertificteName.getAttribute('data-name') }
            }
        })
        this.rObj.controls['Certificate_sub_title'].valueChanges.subscribe((value: any) => {
            let CertificteName = document.querySelector("#titlename2");
            if (value) {
                if (CertificteName) { CertificteName.textContent = value }
            } else {
                if (CertificteName) { CertificteName.textContent = CertificteName.getAttribute('data-name') }
            }
        })
        this.rObj.controls['Text'].valueChanges.subscribe((value: any) => {
            let text = document.querySelector("#text");
            if (value) {
                if (text) { text.textContent = value }
            } else {
                if (text) { text.textContent = text.getAttribute('data-name') }
            }
        })
        this.rObj.controls['Course_name'].valueChanges.subscribe((value: any) => {
            let coursename = document.querySelector("#coursename");
            if (value) {
                if (coursename) { coursename.textContent = value }
            } else {
                if (coursename) { coursename.textContent = coursename.getAttribute('data-name') }
            }
        })

        this.rObj.controls['Presented_date'].valueChanges.subscribe((value: any) => {
            let presenteddate = document.querySelector("#presenteddate");
            if (value) {
                if (presenteddate) { presenteddate.textContent = moment(value).format('MMMM Do YYYY'); }
            } else {
                if (presenteddate) { presenteddate.textContent = presenteddate.getAttribute('data-name') }
            }
        })

        this.rObj.controls['Date_issued'].valueChanges.subscribe((value: any) => {
            let dateissued = document.querySelector("#dateissued");
            if (value) {
                if (dateissued) { dateissued.textContent = moment(value).format('MMMM Do YYYY'); }
            } else {
                if (dateissued) { dateissued.textContent = dateissued.getAttribute('data-name') }
            }
        })

       
    }
    openTemplateEdit(templateID: number) {
        this._router.navigate(['certificate/add/'+templateID])
        
    }

    previewTemplate(templateID: any) {
        this.modalRef = this.modalService.show(CertificateModalComponent, {
            class: 'modal-dialog-centered certificate-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                templateID: templateID,
            } 
        });
    }

    onSignChange(event:any) {
        this.signstatus = false
        const file = event.target.files[0];
        // alert(file)
        this.signstatus = event.target.files.length > 0 ? true : false;
        if (this.signstatus == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
            let sign = document.querySelector("#sign");
              this.signimageSrc = reader.result;
              if (sign) { sign.setAttribute('src', this.signimageSrc) 
              } else {
            let bgSrc = sign.getAttribute('data-src')
                if(sign) { sign.setAttribute('src', bgSrc )}
            }
             
           }
        }
    }

    removeSignImg() {
        let sign = document.querySelector("#sign");
        // if(sign){ sign.setAttribute('src', this.imageSrc) }
        this.signimageSrc = '';
        this.signstatus = false;
        let defaultImg : any = sign?.getAttribute('data-src')
        // alert(defaultImg)
        if (sign) { sign.setAttribute('src', defaultImg) }
    }
    

    onSealChange(event:any) {
        this.sealstatus = false
        const file = event.target.files[0];
        // alert(file)
        this.sealstatus = event.target.files.length > 0 ? true : false;
        if (this.sealstatus == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
            let seal = document.querySelector("#seal");
              this.sealimageSrc = reader.result;
              if (seal) { seal.setAttribute('src', this.sealimageSrc) }
             
           }
        }
    }

    removeSealImg() {
        let seal = document.querySelector("#seal");
        this.sealimageSrc = '';
        this.sealstatus = false;
        let defaultImg : any = seal?.getAttribute('data-src')
        if (seal) { seal.setAttribute('src', defaultImg) }
    }


    onBgChange(event:any) {
        this.bgstatus = false
        const file = event.target.files[0];
        // alert(file)
        this.bgstatus = event.target.files.length > 0 ? true : false;
        if (this.bgstatus == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
                this.bgimageSrc = reader.result;
                // alert(this.bgimageSrc)
            
                let bg = document.querySelector('#bg-logo')
                if (bg) { bg.setAttribute('src', this.bgimageSrc) }
                else { 
                    if (bg) { bg.setAttribute('src', bg.getAttribute('data-src'))}
                }
                // if (bg) { bg.addEventListener('click', function(){
                //     document.documentElement.style.backgroundImage = ("/assets/img/golden/seal-01.png");
                //     document.documentElement.style.backgroundImage = this.bgimageSrc;
                // })}
            }
  
        }
    }

    removeBgImg() {
        let bg = document.querySelector("#bg-logo");
        // if(sign){ sign.setAttribute('src', this.imageSrc) }
        this.bgimageSrc = '';
        this.bgstatus = false;
        let defaultImg : any = bg?.getAttribute('data-src')
        // alert(defaultImg)
        if (bg) { bg.setAttribute('src', defaultImg) }
    }

getImgPreview(){
    htmlToImage.toPng(document.getElementById('htmlPreview')!).then(dataURL => {
        let img = dataURL
        
        this.previewImg =  img
    }).catch(function (error){
        return 'something went wrong !'
    });
}

    saveTemplate(){
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let htmlPreview = document.querySelector("#htmlPreview");
            if (htmlPreview) { this.htmlPreview = htmlPreview.innerHTML }
            this.previewImg = htmlToImage.toJpeg(document.getElementById('htmlPreview')!).then(function(dataUrl) {
                if (dataUrl) {
                    return dataUrl;
                }
            }).catch(function (error){
                return 'something went wrong !'
            });

            console.log(this.htmlPreview)
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                name: rObj.Template_Name,
                title: rObj.Certificate_title,
                sub_title: rObj.Certificate_sub_title,
                school_name: rObj.School_Name,
                text: rObj.Text,
                bg_path: rObj.Background,
                date_presented: rObj.Presented_date,
                date_issued: rObj.Date_issued,
                seal_path: rObj.Seal,
                sign_path: rObj.Sign,
                html: this.htmlPreview
                // preview: this.previewImg
            }
            
            console.log(mData)
            this._aService.templateSaveData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['certificate/list']);
                    this._gService.triggerSnackbar('Template Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }


}