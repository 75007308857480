import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { CertificateService } from 'src/app/services/certificates.service';
import { CertificateModalComponent } from 'src/app/components/modal/certificatemodal/certificatemodal.component';
// import { CertificateModalComponent } from 'src/app/components/modal/certificatemodal/certificatemodal.component';
@Component({
  selector: 'app-certificate-generateCertificate',
  templateUrl: './generateCertificate.component.html',
})

export class GenerateCertificateComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public selectedIds : any[] = [];
    public studentModalData : any[] = [];
    public studentListData: any[] = [];
    public classData: any[] = [];
    public classSectionData: any[] = [];
    public templateData: any[] = [];
    public certificateListData: any[] = [];
    
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private _cService: CertificateService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Template: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'certificates').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.certificateListData = JSON.parse(lRes.result);
                console.log(this.certificateListData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._cService.templatesData().subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.templateData = JSON.parse(pRes.result);
            //     if (this.templateID) {
            //         this.templatePath = this.templateData.filter(t => {
            //             return t.ID == this.templateID  
            //         })
            //         console.log(this.templatePath)
            //         this.templatePath = this.templatePath[0].path
            //         console.log(this.templatePath)
            //         this._gService.getHtmlData(this.templatePath).subscribe((data : any)=>{
            //             this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data)
            //         })
            //     }
                
            // } else {
            //     this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.studentListData = JSON.parse(mRes.result);
        //     } 
        //     this._gService.showLoader(false);
        // })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } 
            this._gService.showLoader(false);
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })

        

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openGenerateCertificate(templateID: any) {
        this.studentModalData = this.studentListData.filter(s => this.selectedIds.includes(s.ID))
        // this._router.navigate(['certificate/add/'+templateID])
            this.modalRef = this.modalService.show(CertificateModalComponent, {
                class: 'modal-dialog-centered certificate-edit-modal-dialog form-modal-dialog',
                backdrop : 'static',
                keyboard : false,
                initialState : {
                    templateID: templateID,
                    studentIDs: this.selectedIds,
                    studentData: this.studentModalData,
                } 
            });
    }

    openMediumEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.studentListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.studentListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }
    
    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }


    search() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                class: rObj.Class,
                section: rObj.Section,
            }
            console.log(mData)
            this._sService.studentListData(this.instID, this.activeAcademicID, rObj.Class, rObj.Section).subscribe(sRes => {
                console.log(JSON.parse(sRes.result))
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                } else {
                    this.studentListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}