<div class="modal-header">
    <h5 class="modal-title">Edit Holiday</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Title</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Title">
                <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                    <p class="input-error-msg">Please enter Title name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="Date">
                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                    <p class="input-error-msg">Please select Date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateHoliday()">Update</button>
            </div>
        </form>
    </div>
</div>
