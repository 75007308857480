import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router, RouteReuseStrategy,ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-mediamodal',
    templateUrl: './mediamodal.component.html',
    styleUrls: ['./mediamodal.component.css'],
})

export class MediaModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public fileID: any;
    public infoData:any[] = [];
    public imgURL = this._gService.getImgPath();
    imgSrc: any;

    @ViewChild ('fileURL') fileURLtxt : ElementRef;

    public imgTypes: any[] = ['png','jpg','jpeg'];
    
    constructor(
        private _gService: GlobalService,
        // private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _arouter: Router,

        private _reRoute: RouteReuseStrategy,

    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);
        // this.mediafile = this._aroute.snapshot.paramMap.get('name');

        this._gService.getDataByID(this.fileID, 'cms_media').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                this.infoData = JSON.parse(dataRes.result);
                this.imgSrc = this.infoData[0].filename && this.imgTypes.includes(this.infoData[0].filetype) ? this.imgURL+this.infoData[0].filename : '';
            }
            this._gService.showLoader(false);
        })
    }

    copyFileURL() {
        let fileURL = this.fileURLtxt.nativeElement.value;
        navigator.clipboard.writeText(fileURL)
        this._gService.triggerSnackbar('File URL copied to clipboard', 'success', true)
    }
    delete(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
        this.modalRef.hide();
    }


    // updateMedium() {
    //     if (this.rObj.valid) {
    //         let rObj = this.rObj.value;
    //         let mData = {
    //             action: 'update',
    //             id: this.mediumID,
    //             medium: rObj.Medium,
    //         }
    //         this._aService.mediumData(mData).subscribe(mRes => {
    //             console.log(mRes)
    //             this.modalRef.hide();
    //             if (mRes.status === true) {
    //                 this.modalRef.onHidden?.subscribe(() => {
    //                     this._reRoute.shouldReuseRoute = () => false;
    //                     this._router.navigate([this._router.url])
    //                     this._gService.triggerSnackbar('Medium Updated Successfully', 'success', true) 
    //                 });
    //             } else {
    //                 this._gService.triggerSnackbar('Something went wrong', 'error', true);
    //             }
    //         }, err => {
    //             console.log(err);
    //             this._gService.triggerSnackbar('Server Error!', 'error', true);
    //         })
    //     }
    // }
    
}
