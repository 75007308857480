import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'userlogin-wrapper',
  template: '<router-outlet></router-outlet>',
})

export class UserLoginComponent implements OnInit {

  constructor(private _aRoute: ActivatedRoute,) { }

  ngOnInit() { }
  
}