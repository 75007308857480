<div class="modal-header">
    <h5 class="modal-title">Edit Book </h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-img-wrapper">
                        <img class="inst-icon photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/bookImg.png'">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Book Title</label>
                        <small class="req"> *</small>
                        <input type="text" formControlName="Title" class="form-control" >    
                        <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                            <p class="input-error-msg">Please enter title name</p>
                        </div>                        
                    </div>
                    <div class="form-group">
                        <label>Book Image</label>
                        <!-- <small class="req"> *</small> -->
                        <div class="imgUpload-wrapper">
                            <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                            <input type="file" formControlName="Book_image" class="form-control" (change)="onFileChange($event)">
                        </div>
                        <div *ngIf="rObj.controls.Book_image.invalid && (rObj.controls.Book_image.dirty || rObj.controls.Book_image.touched)">
                            <p class="input-error-msg">Please select image </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Book No</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Book_no" class="form-control" required="">
                <div *ngIf="rObj.controls.Book_no.invalid && (rObj.controls.Book_no.dirty || rObj.controls.Book_no.touched)">
                    <p class="input-error-msg">Please enter Book_no </p>
                </div>
            </div>
            <div class="form-group">
                <label>ISBN Number</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Isbn_no" class="form-control " autocomplete="off" value="" required="">
                <div *ngIf="rObj.controls.Isbn_no.invalid && (rObj.controls.Isbn_no.dirty || rObj.controls.Isbn_no.touched)">
                    <p class="input-error-msg">Please enter Isbn_no </p>
                </div>
            </div>
            <div class="form-group">
                <label>Author</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Author" class="form-control" required="">
                <div *ngIf="rObj.controls.Author.invalid && (rObj.controls.Author.dirty || rObj.controls.Author.touched)">
                    <p class="input-error-msg">Please enter Author name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Publisher</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Publisher" class="form-control" required="">
                <div *ngIf="rObj.controls.Publisher.invalid && (rObj.controls.Publisher.dirty || rObj.controls.Publisher.touched)">
                    <p class="input-error-msg">Please enter Publisher name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Quantity</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Qty" class="form-control" value="" required="">
                <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                    <p class="input-error-msg">Please enter Qty </p>
                </div>
            </div>
            <div class="form-group">
                <label>Category</label>
                <small class="req"> *</small>
                <select formControlName="Category" class="form-select" required="">
                    <option value="">Select</option>
                    <option *ngFor="let type of bookCategoryData" value="{{type.ID}}">{{type.name}}</option>               
                </select>
                <div *ngIf="rObj.controls.Category.invalid && (rObj.controls.Category.dirty || rObj.controls.Category.touched)">
                    <p class="input-error-msg">Please enter category </p>
                </div>
        </div>

            <div class="form-group">
                <label>Subject</label>
                        <small class="req"> *</small>
                        <input type="text" formControlName="Subject" class="form-control" required="">
                        <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                            <p class="input-error-msg">Please enter Subject </p>
                        </div>
                </div>

            <div class="form-group">
            <label>Language</label>
                    <small class="req"> *</small>
                    <input type="text" formControlName="Language" class="form-control" required="">
                    <div *ngIf="rObj.controls.Language.invalid && (rObj.controls.Language.dirty || rObj.controls.Language.touched)">
                        <p class="input-error-msg">Please enter Language </p>
                    </div>
            </div>
            <div class="form-group">
            <label>Book Cost</label>
                    <small class="req"> *</small>
                    <input type="number" formControlName="Cost" class="form-control" value="" required="">
                    <div *ngIf="rObj.controls.Cost.invalid && (rObj.controls.Cost.dirty || rObj.controls.Cost.touched)">
                        <p class="input-error-msg">Please enter cost </p>
                    </div>
            </div>
            <div class="form-group">
                <label>Rack No.</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Rack_no" class="form-control" required="">
                <div *ngIf="rObj.controls.Rack_no.invalid && (rObj.controls.Rack_no.dirty || rObj.controls.Rack_no.touched)">
                    <p class="input-error-msg">Please enter rack no </p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateBook()">Update</button>
            </div>
        </form> 
    </div>
</div>
