import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
  
@Component({
    selector: 'app-editUsersmodal',
    templateUrl: './editUsersmodal.component.html',
    // styleUrls: ['./editUsersmodal.component.css'],
})

export class EditUsersModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;
    public usersID: number;
    public UserRoleData: any[] = [];
    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Name: ['',],
        UserName: [''],
        Email: [''],
        Password: [''],
        UserRole: [''],
    })


    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.usersID, 'admin_users').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Name: sData[0].name,
                    UserName: sData[0].username,
                    Email: sData[0].email,
                    Password: sData[0].password,
                    UserRole: sData[0].userRole,
                });
            }
            this._gService.showLoader(false);
        })

        this._adminService.getUserRoleData().subscribe(res =>{
            console.log(res)
            if (res.status === true) {
                this.UserRoleData = JSON.parse(res.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    }

    updateUsers() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.usersID,
                name: rObj.Name,
                username: rObj.UserName,
                email: rObj.Email,
                password: rObj.Password,
                userRole: rObj.UserRole,
            }
            console.log(mData)
            this._adminService.UserData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('User Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}