<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Room Type</h1>
        <div class="link-container col-md-6">
            <a href="../hostel/hostel-details/add/" class="content-header-link">Add Hostel</a>
            <a href="../hostel/hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Room Type
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label> Room Type</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Room_type">
                            <div *ngIf="rObj.controls.Room_type.invalid && (rObj.controls.Room_type.dirty || rObj.controls.Room_type.touched)">
                                <p class="input-error-msg">Please enter room type</p>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label>Description</label>
                            <small class="req"> *</small>
                            <textarea type="text" class="form-control" autocomplete="off" formControlName="Description"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter description</p>
                            </div>
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveRoomType()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                     Rooms Types List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="hostelRoomTypeData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">ROOM TYPE</th>
                                    <th>DESCRIPTION</th>
                                    <th class="no-sort">MANAGE</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let rooms of hostelRoomTypeData">
                                <!-- <td class="align-left">
                                <ng-container *ngFor="let class of classData">
                                    <ng-container *ngIf="class.ID == assignteacher.class">
                                        {{class.name}}
                                    </ng-container>
                                </ng-container>
                                </td> -->
                                <!-- <td>
                                        <ng-container *ngFor="let s of sectionData">
                                            <ng-container *ngIf="s.ID == assignteacher.section">
                                            {{s.name}}
                                            </ng-container>  
                                        </ng-container>
                                </td> -->
                                <td class="align-left">{{rooms.room_type}}</td>
                                <td><p>{{rooms.description}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openRoomsTypeEditModal(rooms.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(rooms.ID, 'hostel_room_type', 'hostel rooms details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!hostelRoomTypeData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div>
                </div>
            </div>
    </div>
        
</div>