import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class VendorService {
  constructor(
    private _http: HttpClient,
  ) { }

  private vendor = `${environment.APIURL}/storeManagement/vendor/`;
  private inventoryCategory = `${environment.APIURL}/storeManagement/inventoryCategory/`;
  private inventoryItem = `${environment.APIURL}/storeManagement/inventoryItem/`;
  private inventoryPurchase = `${environment.APIURL}/storeManagement/inventoryPurchase/`;
  private inventoryIssue = `${environment.APIURL}/storeManagement/inventoryIssue/`;
//   private applyLeave = `${environment.APIURL}/staff/applyLeave/`;
//   private getapplyLeave = `${environment.APIURL}/staff/getLeavesByStaffID/`;
//   private addleave = `${environment.APIURL}/staff/addLeave/`;


  vendorData(data: object): Observable<any> {
    return this._http.post(this.vendor, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  inventoryCategoryData(data: object): Observable<any> {
    return this._http.post(this.inventoryCategory, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  inventoryItemData(data: object): Observable<any> {
    return this._http.post(this.inventoryItem, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  inventoryPurchaseData(data: object): Observable<any> {
    return this._http.post(this.inventoryPurchase, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

//   staffListData(instID: number, academicID: number, departmentID: any, designationID: any): Observable<any> {
//     return this._http.get(this.staffList+'?inst_id='+instID+'&academic_id='+academicID+'&department='+departmentID+'&designation='+designationID, {
//       headers: { 'Content-Type': 'application/json' }
//     })
//   }

//   getapplyLeaveData(instID: number, academicID: number, staff_ID: any): Observable<any> {
//     return this._http.get(this.getapplyLeave+'?inst_id='+instID+'&academic_id='+academicID+'&staff_id='+ staff_ID, {
//       headers: { 'Content-Type': 'application/json' }
//     })
//   }

  inventoryIssueData(data: object): Observable<any> {
    return this._http.post(this.inventoryIssue, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

//   addLeaveData(data: object): Observable<any> {
//     return this._http.post(this.addleave, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }

}