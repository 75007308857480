<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-3">General Settings</h1>
            <div class="header-msg col-md-5">
                        </div>
            <div class="btn-container link-container col-md-4">
                <button class="btn primary-btn small-btn"  (click)="save()">Save</button>
            </div>
        </div>    
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            Institution Details:
                        </div>
                    </div>
                    <div class="panel-body row">
                        <div class="col-md-10">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Institution Name</label>
                                        <small class="req">*</small>
                                        <input type="text" formControlName ="Inst_name" class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Institution Address</label>
                                        <small class="req">*</small>
                                        <textarea formControlName ="Inst_address" class="form-control">Panchgani, Maharashtra</textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Branch Name</label>
                                        <small class="req">*</small>
                                        <input type="text" formControlName ="Inst_branch" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_branch.invalid && (rObj.controls.Inst_branch.dirty || rObj.controls.Inst_branch.touched)">
                                            <p class="input-error-msg">Please enter Inst branch</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Pincode</label>
                                        <small class="req">*</small>
                                        <input type="number" formControlName ="Inst_pincode" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_pincode.invalid && (rObj.controls.Inst_pincode.dirty || rObj.controls.Inst_pincode.touched)">
                                            <p class="input-error-msg">Please enter Inst pincode </p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Country</label>
                                        <small class="req">*</small>
                                        <select formControlName="Inst_country" class="form-select">
                                          <option value="">Select Country</option>
                                          <ng-container  *ngFor="let country of countriesData">
                                            <option [value]="country.name">
                                                {{country.name}}
                                            </option>
                                          </ng-container>
                                                 <div *ngIf="rObj.controls.Inst_country.invalid && (rObj.controls.Inst_country.dirty || rObj.controls.Inst_country.touched)">
                                                        <p class="input-error-msg">Please enter Country </p>
                                                 </div>
                                        </select>
                                      </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Institution Email</label>
                                        <small class="req">*</small>
                                        <input type="email" formControlName ="Inst_email" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_email.invalid && (rObj.controls.Inst_email.dirty || rObj.controls.Inst_email.touched)">
                                            <p class="input-error-msg">Please enter Inst email</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Institution Phone</label>
                                        <small class="req">*</small>
                                        <input type="number" formControlName ="Inst_phone" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_phone.invalid && (rObj.controls.Inst_phone.dirty || rObj.controls.Inst_phone.touched)">
                                            <p class="input-error-msg">Please enter Inst phone</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Institution Fax</label>
                                        <input type="text" formControlName ="Inst_fax" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Institution Code</label>
                                        <small class="req">*</small>
                                        <input type="text" formControlName ="Inst_code" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_code.invalid && (rObj.controls.Inst_code.dirty || rObj.controls.Inst_code.touched)">
                                            <p class="input-error-msg">Please enter Inst code</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Currency</label>
                                        <small class="req">*</small>
                                        <input type="text" formControlName ="Inst_currency" class="form-control">
                                        <div *ngIf="rObj.controls.Inst_currency.invalid && (rObj.controls.Inst_currency.dirty || rObj.controls.Inst_currency.touched)">
                                            <p class="input-error-msg">Please enter Inst currency</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Language</label>
                                        <small class="req">*</small>
                                        <select class="form-select" formControlName="Inst_language">
                                            <option value="">Select Language</option>
                                            <option value="Marathi">Marathi</option>
                                            <option value="Hindi">Hindi</option>
                                            <option value="English">English</option>
                                            <option value="Gujarati">Gujarati</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Is Vernacular<small class="req"> *</small>&nbsp;&nbsp;&nbsp;</label>
                                        <div>
                                            <label class="radio-inline" >
                                                <input type="radio" formControlName="Is_Vernacular" value="1" autocomplete="off"> Yes                                            
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" formControlName="Is_Vernacular" value="0" autocomplete="off"> No                                            
                                            </label>
                                            <span class="text-danger"></span>
                                            <div *ngIf="rObj.controls.Is_Vernacular.invalid && (rObj.controls.Is_Vernacular.dirty || rObj.controls.Is_Vernacular.touched)">
                                                <p class="input-error-msg">Please select </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="rObj.controls.Is_Vernacular.value == '1'">
                                        <label>Select Medium</label>
                                        <small class="req"> *</small>
                                        <select class="form-select" formControlName="Medium">
                                            <option value="">Select</option>
                                            <option *ngFor="let m of mediumData" value="{{m.ID}}">{{m.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <!-- <div class="form-group">
                                <label>Institution Logo</label>
                                <div class="inst-icon-container">
                                    <div class="img-wrapper">
                                        <img class="inst-icon photo-preview" src="/uploads/institutions/11/new-era-icon.jpeg">
                                    </div>
                                    <div class="upload-btn-wrapper">
                                        <button class="upload-btn">Change Photo</button>
                                        <input type="file">
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label>Institution Logo</label>
                                <div class="form-img-wrapper">
                                  <img class="student-photo photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/default-institution-icon.png'">
                              </div>
                              <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                  <button class="upload-btn">Select Photo</button>
                                  <input type="file" id="photoUpload" (change)="onFileChange($event)">
                              </div>
                              <div class="upload-btn-wrapper" *ngIf="imageSrc">
                                  <button class="upload-btn" (click)="removeImg()">Remove Photo</button>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="panel-header padding-top-none">
                        <div class="panel-title">
                            Academic Year:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Select Academic Session</label>
                                        <small class="req">*</small>
                                        <select class="form-control" formControlName="Current_session_id">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let ay of academicYearsData" [value]="ay.ID" [selected]="ay.ID == activeAcademicID">{{ay.start_year}} - {{+ay.end_year}}</option>    
                                        </select>   
                                        <div *ngIf="rObj.controls.Current_session_id.invalid && (rObj.controls.Current_session_id.dirty || rObj.controls.Current_session_id.touched)">
                                            <p class="input-error-msg">Please enter Current_session_id</p>
                                        </div>                      
                                    </div>
                                    <div class="col-md-4">
                                        <label>Start Month</label>
                                        <input type="text" formControlName ="Start_month" [value]="startMonth" class="form-control" >
                                    </div>
                                    <div class="col-md-4">
                                        <label>End Month</label>
                                        <input type="text" formControlName ="End_month" [value]="endMonth" class="form-control">
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                
                <div class="panel" *ngIf="userRole == 1">
                    <div class="panel-header">
                        <div class="panel-title">
                            Subscription Details:
                        </div>
                    </div>
                    <div class="panel-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Start Date</label>
                                        <small class="req">*</small>
                                        <input type="date" formControlName ="Start_subscription_date" class="form-control date-input">
                                        <div *ngIf="rObj.controls.Start_subscription_date.invalid && (rObj.controls.Start_subscription_date.dirty || rObj.controls.Start_subscription_date.touched)">
                                            <p class="input-error-msg">Please enter Start_subscription_date</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Renewal Date</label>
                                        <small class="req">*</small>
                                        <input type="date" formControlName ="Renewal_date" class="form-control date-input">
                                        <div *ngIf="rObj.controls.Renewal_date.invalid && (rObj.controls.Renewal_date.dirty || rObj.controls.Renewal_date.touched)">
                                            <p class="input-error-msg">Please enter Renewal date</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Update Date</label>
                                        <input type="date" formControlName ="Update_date" class="form-control date-input">
                                        <div *ngIf="rObj.controls.Update_date.invalid && (rObj.controls.Update_date.dirty || rObj.controls.Update_date.touched)">
                                            <p class="input-error-msg">Please enter Update date</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-8">
                                        <label>Secret Key</label>
                                        <small class="req"> *</small>
                                        <input type="text" formControlName ="Inst_key" class="form-control">
                                        <span class="input-edit-btn" (click)="copyInputMessage(rObj.controls.Inst_key.value)"><i class="fa fa-clipboard"></i></span>
                                        <!-- <span class="input-edit-btn"><i class="far fa-edit"></i></span> -->
                                        <div *ngIf="rObj.controls.Inst_key.invalid && (rObj.controls.Inst_key.dirty || rObj.controls.Inst_key.touched)">
                                            <p class="input-error-msg">Please enter Inst_key</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Subscription Period</label>
                                        <small class="req"> *</small>
                                        <small class="float-right">Months</small>
                                        <input type="number" formControlName ="Subscription_period" class="form-control">
                                        <div *ngIf="rObj.controls.Subscription_period.invalid && (rObj.controls.Subscription_period.dirty || rObj.controls.Subscription_period.touched)">
                                            <p class="input-error-msg">Please enter Subscription_period</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Max. Allowed Users</label>
                                        <small class="req"> *</small>
                                        <input type="number" formControlName ="Allowed_users" class="form-control">
                                        <div *ngIf="rObj.controls.Allowed_users.invalid && (rObj.controls.Allowed_users.dirty || rObj.controls.Allowed_users.touched)">
                                            <p class="input-error-msg">Please enter Allowed_users</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Subscription Cost</label>
                                        <small class="req"> *</small>
                                        <small class="float-right">INR/User</small>
                                        <input type="number" formControlName="Per_user_cost" class="form-control">
                                        <div *ngIf="rObj.controls.Per_user_cost.invalid && (rObj.controls.Per_user_cost.dirty || rObj.controls.Per_user_cost.touched)">
                                            <p class="input-error-msg">Please enter Per_user_cost</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Total Cost</label>
                                        <small class="req"> *</small>
                                        <small class="float-right">INR</small>
                                        <input type="number" formControlName ="Total_cost" class="form-control">
                                        <div *ngIf="rObj.controls.Total_cost.invalid && (rObj.controls.Total_cost.dirty || rObj.controls.Total_cost.touched)">
                                            <p class="input-error-msg">Please enter Total cost</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    </form>
</div>