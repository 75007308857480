import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { PayrollService } from 'src/app/services/payroll.service';
import * as moment from 'moment';
import { filter } from 'rxjs';
import { PrintPaymodalModalComponent } from '../printPaymodal/printPaymodal.component';
@Component({
    selector: 'app-paymentmodal',
    templateUrl: './paymentmodal.component.html',
    styleUrls: [],
})

export class PaymentModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public payId: any;
    public staff_name: any;
    public month: number;
    public nameMonth : any
    public year: number;
    public payment_amt: number;
    public date: number;
    public staffId : any;
    public payrollData : any[] = [];
    public staffData : any[] = [];
    public department : any;
    public designation : any;
    private disabledStatus : boolean;
    public rObj : any;
    public staffPayrollData: any[] = [];
    public now = new Date()
    public  today = moment(this.now).format('YYYY-MM-DD')
   

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _pService: PayrollService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,

    ) {}

    


    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];
    

    ngOnInit(): void {
    
        this._gService.showLoader(true);

        this._pService.getStaffpayrollData(this.instID, this.activeAcademicID, this.month, this.year).subscribe(cRes => {
            if (cRes.status === true) {    
                this.staffPayrollData = JSON.parse(cRes.result);
                console.log(this.staffPayrollData)
                // this._router.navigate(['/payroll/list/'])
            }
        })

        this.disabledStatus = true; 
        this.rObj = this._fBuilder.group({
            Staff_name: [{value: this.staff_name, disabled: this.disabledStatus}],
            Month_year: [{value: this.MonthData[this.month].monthName+'-'+this.year, disabled: this.disabledStatus}],
            Date: [{value: this.today, disabled: this.disabledStatus} ],
            Payment_amount:[{value: this.payment_amt, disabled: this.disabledStatus} ],
            Note: [''],
            Payment_mode: ['cash', Validators.required],
        }) 
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'payroll').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.payrollData = JSON.parse(lRes.result);

            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
  
    }
    
    // }

    

    openPaymentReceiptModal(staffId:any,staff_name: string, month: any, year:any, departmentId: any, designationId: any){
        this.modalRef = this.modalService.show(PrintPaymodalModalComponent, {
            class: 'modal-dialog-centered payreceipt-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                payId:this.payId,
                staff_name: staff_name,
                month: month,
                year : year,
                department : departmentId,
                designation : designationId,
            } 
        });
    }
    
    PayAndPrint(){
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                id:JSON.parse(this.payId),
                payment_mode: rObj.Payment_mode,
                payment_date: this.today,
                note : rObj.Note,
            }
            console.log(mData)
            this._pService.payData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._gService.triggerSnackbar('Payment Saved  Successfully', 'success', true) 
                        this.openPaymentReceiptModal(this.payId,this.staff_name,this.month,this.year,this.department,this.designation)
                         // this._router.navigate([this._router.url])
                        this._router.navigate(['/payroll/list/'+this.department+'/'+this.designation+'/'+this.month+'/'+this.year])
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

    Pay() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                id:JSON.parse(this.payId),
                payment_mode: rObj.Payment_mode,
                payment_date: this.today,
                note : rObj.Note,
            }
            console.log(mData)
            this._pService.payData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate(['/payroll/list/'+this.department+'/'+this.designation+'/'+this.month+'/'+this.year])
                        this._gService.triggerSnackbar('Payment Saved  Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}