<div class="modal-header">
    <h5 class="modal-title">Edit Pick up point</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Pick Up Point</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="PickUpPoint">
                <div *ngIf="rObj.controls.PickUpPoint.invalid && (rObj.controls.PickUpPoint.dirty || rObj.controls.PickUpPoint.touched)">
                    <p class="input-error-msg">Please enter Pick Up Point</p>
                </div>
            </div>
            <div class="form-group">
                <label>Latitude</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Latitude">
                <div *ngIf="rObj.controls.Latitude.invalid && (rObj.controls.Latitude.dirty || rObj.controls.Latitude.touched)">
                    <p class="input-error-msg">Please select latitude</p>
                </div>
            </div>
            <div class="form-group">
                <label>Longitude</label>
                <small class="req"> *</small>
                <input type="number" class="form-control" formControlName="Longitude">
                <div *ngIf="rObj.controls.Longitude.invalid && (rObj.controls.Longitude.dirty || rObj.controls.Longitude.touched)">
                    <p class="input-error-msg">Please select Longitude</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="update()">Update</button>
            </div>
        </form> 
    </div>
</div>
