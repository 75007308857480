import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-settings-printHeaderFooter',
    templateUrl: './printHeaderFooter.component.html',
    styleUrls: ['./printHeaderFooter.component.css']
})

export class PrintHeaderFooterComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    
    public imgURL = this._gService.getImgPath();


    image2Src: any = '';
    imageSrc:any = '';
    status:boolean = false;
    remove:boolean = false;
    status2:boolean = false;
    remove2:boolean = false;
   
    dtOptions: any = {};

    modalRef: BsModalRef;

    editorConfig: AngularEditorConfig = {
        editable: true,
        height: '200px',
        placeholder: 'Enter text here...',
    }

    public feeReceiptTab: boolean = true;
    public paySlipTab: boolean = false;
   
    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    

    public rObj = this._fBuilder.group({
    //    Header_img: [''],
       Fee_Content: [''],
       Payslip_Content: ['']
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._sService.getSettingsData(this.instID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let cData = JSON.parse(mRes.result);
                console.log(cData)
                this.rObj.patchValue({
                    Fee_Content: cData[0].fee_footer_content,
                    Payslip_Content: cData[0].payslip_footer_content,                  
                });
                this.imageSrc = cData[0].fee_header_img ? this.imgURL+cData[0].fee_header_img : '';
                this.image2Src = cData[0].payslip_header_img ? this.imgURL+cData[0].payslip_header_img : '';
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    

        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }

    
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
            //   this.featuredImgType = 'upload'       
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.remove = true;
        // this.featuredImgType = '';   
        this.imageSrc = '';
    }
    onFile2Change(event:any) {
        this.status2 = false
        const file = event.target.files[0];
        this.status2 = event.target.files.length > 0 ? true : false;
        if (this.status2 == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.image2Src = reader.result;   
            //   this.featuredImgType = 'upload'       
           }
           console.log(this.image2Src)
        }
    }

    remove2Img() {
        this.remove = true;
        // this.featuredImgType = '';   
        this.image2Src = '';
    }

    
    setTabActive(tabName: string) {
        if (tabName == 'feeReceiptTab') {
            this.feeReceiptTab = true
        } else {
            this.feeReceiptTab = false
        }
        if (tabName == 'paySlipTab') {
            this.paySlipTab = true
        } else {
            this.paySlipTab = false
        }
    }


    saveFeeReceipt(){
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let settingData = {
                inst_id : this.instID,
                settings : {
                    fee_header_img : this.imageSrc,
                    fee_footer_content : rObj.Fee_Content,
                }
            }
            console.log(settingData)
            this._sService.saveSettingsData(settingData).subscribe(cmsRes => {

                console.log(cmsRes)
                if (cmsRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Fee Receipt Header Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
        this._gService.showLoader(false);
    }

    savePayReceipt(){
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let settingData = {
                inst_id : this.instID,
                settings : {
                    payslip_header_img : this.image2Src,
                    payslip_footer_content : rObj.Payslip_Content,
                }
            }
            console.log(settingData)
            this._sService.saveSettingsData(settingData).subscribe(cmsRes => {

                console.log(cmsRes)
                if (cmsRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Payslip Header Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
        this._gService.showLoader(false);
    }
   
}