<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-6">Payroll Details</h1>
        <div class="header-msg col-md-5"></div>
        <div class="link-container col-md-5">
            <!--<a href="../add/" class="content-header-link">Add Hostel</a>--> 
        </div>
    </div>
    <div class="row" style="justify-content: space-between;">
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-body row" *ngFor="let staff of staffData">
                    <div class="col-md-3">
                        <div class="student-photo">
                            <img [src]="staff.photo_path ? imgURL+staff.photo_path : (staff.gender == 'male' ? '/assets/img/male-worker.png' : '/assets/img/female-worker.png')" width="100%">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row details" style="width: 150%;">
                            <div class="col-md-3"  style="margin-bottom: 15px;">Name</div>
                            <div class="col-md-3 head" style="margin-bottom: 15px;">{{staff.full_name}}</div>
                            <div class="col-md-3" style="margin-bottom: 15px;">Staff ID</div>
                            <div class="col-md-3 head" style="margin-bottom: 15px;">{{staff.staff_id}}</div>
                        </div>
                    
                        <div class="row details" style="width: 150%;">
                        
                            <div class="col-md-3" style="margin-bottom: 15px;"> Role</div>
                            <div class="col-md-3 head" style="margin-bottom: 15px;">{{getUserRoleName(staff.role)}}</div>
                            <div class="col-md-3" style="margin-bottom: 15px;">Phone No.</div>
                            <div class="col-md-3 head" style="margin-bottom: 15px;">{{staff.mobile}}</div> 
                        </div>
                        <div class="row details" style="width: 150%;">
                            <div class="col-md-3" style="margin-bottom: 15px;">Department</div>
                            <div class="col-md-3 head" style="margin-bottom: 15px;">
                                <ng-container *ngFor="let dept of departmentData">
                                    <ng-container *ngIf="dept.ID == staff.department">
                                        {{dept.name}}
                                    </ng-container>
                                </ng-container>   
                            </div>
                            <div class=" col-md-3" style="margin-bottom: 15px;">Designation</div>
                            <div class=" col-md-3 head" style="margin-bottom: 15px;">
                                <ng-container *ngFor="let des of designationData">
                                    <ng-container *ngIf="des.ID == staff.designation">
                                        {{des.name}}
                                    </ng-container>
                                </ng-container>    
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        <div class="panel col-md-5">
            <div class="attendance-wrapper">
            <div class="vertical-heading">Attendance</div>
            <table>

                <tr>
                    <th class="heading">Month</th>
                    <th class="heading">P</th>
                    <th class="heading">L</th>
                    <th class="heading">A</th>
                    <th class="heading">F</th>
                    <th class="heading">H</th>
                    <th class="heading">V</th>
                </tr>
                <tr>
                    <td class="data">May</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                </tr>
                <tr>
                    <td class="data">April</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                </tr>
                <tr>
                    <td class="data">March</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                    <td class="data">4</td>
                    <td class="data">6</td>
                </tr>
            </table>
            <!-- <div class="vertical heading">Attendance</div> -->
        </div>
        </div>

    </div>
    <div  *ngFor="let staff of staffData" style="justify-content: space-between;">
        <form [formGroup]="rObj" >
            <div class="row panel-wrapper" >
                <div class="panel col-md-3" formArrayName="eData">
                    <div class="panel_title"  style="display: flex;font-size: large; " >
                        <div class="panel-header">Earnings</div>
                        <button type="button" (click)="addEData()" style="background:transparent; border: none; margin-left: 55%;"><i class="fa-solid fa-square-plus" style="font-size: 25px;"></i></button>
                    </div>
                    <div class="panel-body" style="display: flex;padding-right: 5px;" *ngFor="let detail of eData().controls; let i=index" >
                        <div class="row pay-head-row" [formGroupName]="i">
                            <div class="col-md-7">
                                <select aria-controls="list" class="option" formControlName="Earning_name">
                                    <option value="">Type..</option>
                                    <option *ngFor="let e of earningsData" value="{{e.ID}}">
                                        {{e.pay_head_name}}
                                    </option>  
                                </select>
                            </div>
                            <div class="col-md-5">
                                <!--  && (!rObj.controls.eData.dirty || !rObj.controls.eData.touched)" -->
                                <input type="number"  formControlName="Earning_price" [disabled]="!rObj.controls.eData.valid" class="price" aria-controls="list">
                                <span  class="default-btn absolute-btn remove-btn" (click)="removeEData(i)"><i class="fa-solid fa-xmark"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel col-md-3" formArrayName="dData">  
                    <div class="panel_title" style="display: flex;font-size: large; ">
                        <div class="panel-header"> Deduction</div>
                        <button type="button" (click)="addDData()" style="background:transparent; border: none; margin-left: 50%;"><i class="fa-solid fa-square-plus" style="font-size: 25px;"></i></button>
                    </div>
                    <div class="panel-body" style="display: flex;padding-right: 5px;" *ngFor="let detail of dData().controls; let i=index">
                        <div class="row pay-head-row" [formGroupName]="i">
                            <div class="col-md-7">
                                <select aria-controls="list" class="option" formControlName="Deduction_name">
                                    <option value="">Type..</option>
                                    <option *ngFor="let d of deductionsData" value="{{d.ID}}">
                                        {{d.pay_head_name}}
                                    </option>  
                                </select>
                            </div>
                            <div class="col-md-5">
                                <input type="number" formControlName="Deduction_price"   class="price" aria-controls="list">
                                <span  class="default-btn absolute-btn remove-btn" (click)="removeDData(i)"><i class="fa-solid fa-xmark"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel col-md-5">
                    <div class="panel_title row" style="display: flex;font-size: large; ">
                        <div class="panel-header col-md-6"> Payroll Summary (INR)</div>
                        <button type="button" class="btn primary-btn small-btn" style="margin-top: 15px !important; margin-left: 128px !important;" (click)="calculatePayroll()">Calculate</button>
                    </div>
                    <div class="panel-body">
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Basic Salary</div>
                                <input type="number" formControlName="Basic_salary" [value]="staff.basic_salary"  style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Earning</div>
                                <input type="number" formControlName="Earning" [value]="earningPrice" style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Deduction</div>
                                <input type="number" formControlName="Deduction" [value]="deductionPrice" style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Gross Salary</div>
                                <input type="number" formControlName="Gross_salary" [value]="gross" style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Tax</div>
                                <input type="number" formControlName="Tax" style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                        <div class="detail" style="display: flex;">
                            <div class="" style="margin-bottom: 10px;width: 100%;font-size: 18px;text-align: right;">Net Salary</div>
                                <input type="number" formControlName="Net_salary" [value]="tax" style="margin-bottom: 10px; border: none;border-bottom: 1px solid #dcdcdc ;width: 180%;text-align: right;">
                        </div>
                    
                    </div>
                </div>  
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
            </div>
        </form>
    </div>
    
        
</div>