<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Syllabus</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="/academic/section" class="content-header-link"></a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Syllabus Details
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Syllabus For</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Syllabus"> 
                            <div *ngIf="rObj.controls.Syllabus.invalid && (rObj.controls.Syllabus.dirty || rObj.controls.Syllabus.touched)">
                                <p class="input-error-msg">Please enter syllabus name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Class</label>
                            <small class="req"> *</small>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>    
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter class name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Sections</label>
                            <small class="req"> *</small>
                            <div class="list">
                                <label *ngFor="let section of classSectionData " formArrayName="Section" style="margin-right: 15px;">
                                    <input type="checkbox" [value]="section.id" (change)="onSectionSelect($event)">
                                    {{section.name}}
                                </label>
                            </div>    
                            <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                                <p class="input-error-msg">Please select a section</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <small class="req"> *</small>
                            <select name="class" formControlName="Subject" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let sub of subjectData" value="{{sub.ID}}">{{sub.name}}</option>               
                             </select>    
                             <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                                <p class="input-error-msg">Please enter class name</p>
                            </div>
                        </div>
                        <div style="margin-left: 86% !important;">
                            <button class="btn default-btn extra-small-btn inline" (click)="addlData()">Add </button>
                        </div>
                        <div formArrayName="Lessons">
                            <div *ngFor="let detail of lData().controls; let i=index" style="margin-bottom: 15px ;">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label>Lesson Name</label>
                                            <small class="req"> *</small>
                                            <input type="text" class="form-control" formControlName="Name">
                                        </div>
                                    </div>
                                    <div class="col-md-1" >
                                        <button *ngIf="i != 0" class="default-btn"style="margin-top: 300%;" (click)="removeLData(i, lData().controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveClass()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Syllabus List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="syllabusData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Syllabus Name</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Subject</th>
                                <th>lesson Name</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let s of syllabusData">
                            <td class="align-left">{{s.syllabus_for}}</td>
                            <td>{{s.class_name}}</td>
                            <td>{{s.section_name}}
                                <!-- <ng-container *ngFor="let section of stringToArray(s.sections)">
                                    <ng-container *ngFor="let s of sectionData">
                                        <p class="td-section-list" *ngIf="section == s.ID">{{s.name}}</p>
                                    </ng-container>
                                </ng-container> -->
                            </td>
                            <td>{{s.subject_name}}</td>
                            <td>
                                <ng-container *ngFor="let l of stringToArray(s.lessons); let i = index">
                                    <ng-container *ngFor="let lesson_id of stringToArray(s.lesson_ids); let j = index">
                                        <ng-container *ngIf="i == j">
                                            <div>{{l}}
                                                <a tooltip="Add Topics" class="edit-btn" (click)="openAddSyllabuModal(lesson_id,l)" style="margin-left: 7px;"><i class="fa-solid fa-plus"></i></a>
                                            </div> 
                                        </ng-container>
                                    </ng-container>                   
                                </ng-container>
                            </td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openEditModal(s.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'syllabus', 'syllabus')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!classData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>