import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class NotificationService {
  constructor(
    private _http: HttpClient,
  ) { }

  private getNotification = `${environment.APIURL}/notifications/getNotifications/`;
  private notification = `${environment.APIURL}/notifications/sendNotificationWeb/`;
//   private hostelRoomsType = `${environment.APIURL}/hostel/hostelRoomType/`;
 
  
getNotificationData(inst_id: any, academic_id: any, user_role: any, user_id: any): Observable<any> {
    return this._http.get(this.getNotification+'?inst_id='+inst_id+'&academic_id='+academic_id+'&user_role='+user_role+'&user_id='+user_id, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

sendNotificationData(data: object): Observable<any> {
  return this._http.post(this.notification, data, {
    headers: { 'Content-Type': 'application/json' }
  });
}
  
}