<div class="console-content">

    <div class="console-content-header row">
        <h1 class="title col-md-3">User Log Report</h1>     
    </div>
    <div class="row">
        <div class="col-md-12 ">
            <div class="panel">
                <!-- <div class="panel-header">
                    <h1 id="panel_title" class="title col-md-4">Transport Report</h1>
                </div> -->
            
                <div class="panel-body">   
                    <form [formGroup]="rObj">
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4">
                                    <label>User Type<small class="req"> *</small></label>
                                    <select formControlName="User_type" class="form-select">
                                        <option value="">Select User</option>
                                        <option value="all">All Users</option>
                                        <option value="5">Student</option>
                                        <option value="staff">Staff</option>
                                        <option value="4">Parents</option>
                                    </select>
                                    <div *ngIf="rObj.controls.User_type.invalid && (rObj.controls.User_type.dirty || rObj.controls.User_type.touched)">
                                        <p class="input-error-msg">Please select user type</p>
                                    </div>
                                </div> 
                                <div class="col-md-3 btn-container" style="margin-top: 28px;">
                                    <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                                </div>  
                            </div> 
                        </div>
                    </form>     
                </div>
                <div class="panel-body">

                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="userLogData.length">
                        <thead>
                            <tr>
                                <th class="align-left">User ID</th>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Ip address</th>
                                <th>Login datetime</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let u of userLogData">
                                <td class="align-left">{{u.user_id}}</td>   
                                <td>{{u.username}}</td>
                                <td>{{u.name}}</td>
                                <td>{{u.role}}</td>                     
                                <td>{{u.ip_address}}</td>
                                <td>{{u.login_datetime}}</td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!userLogData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>  
            
            </div>
        </div>
    </div>
</div>