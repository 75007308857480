import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-staff-addStaff',
    templateUrl: './addStaff.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class addStaffComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public designationData: any[] = [];
    public departmentData: any[] = [];
    public countriesData: any[] = [];
    public staffData: any[] = [];
    public userRoleData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    file1Src:any = '';
    file1status:boolean = false;
    file2Src:any = '';
    file2status:boolean = false;
    file3Src:any = '';
    file3status:boolean = false;
    file4Src:any = '';
    file4status:boolean = false;


    dtOptions: any = {};

    modalRef: BsModalRef;

    public basicTab: boolean = true;
    public addressTab: boolean = false;
    public documentTab: boolean = false;
    public payrollTab: boolean = false;
    public otherTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Staff_id: ['', Validators.required],
        Role: ['',Validators.required],
        Joining_date: ['',Validators.required], 
        Department: ['', Validators.required],
        Designation: ['', Validators.required],
        Pan_number: [''],
        First_name: ['', Validators.required],
        Last_name: ['', Validators.required],
        Dob: ['', Validators.required],
        Gender: ['', Validators.required],
        Alt_mobile: ['',Validators.pattern("[0-9 ]{10}")],
        Mobile: ['', [Validators.required,Validators.pattern("[0-9 ]{10}")]],
        Email: ['', [Validators.required, Validators.email]],
        Father_name: [''],
        Mother_name: [''],
        Marital_status: [''],
        Aadhaar_number: [''],
        Qualification: [''],
        Work_experience:  [''],
        Permanent_address: [''],
        Current_address: [''],
        City: [''],
        Pincode: [''],
        State: [''],
        Country: [''],
        Epf_number:[''],
        Basic_salary:['',],
        Contract_type:[''],
        Bank_account_name: [''],
        Bank_account_number: [''],
        Bank_name: ['',],
        Bank_branch_name:[''],
        Bank_ifsc: [''],
        Casual_leave: [''],
        Medical_leave: [''],
        Maternity_paternity_leave: [''],
        PhotoUpload: [''],
        Document1_title:[''],
        Document1_file: [''],
        Document2_title: [''],
        Document2_file: [''],
        Document3_title: [''],
        Document3_file: [''],
        Document4_title: [''],
        Document4_file:[''],
       
       
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.designationData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.staffData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getUserRoleData().subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.userRoleData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getCountriesData().subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.countriesData = JSON.parse(cRes.result);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    setTabActive(tabName: string) {
        if (tabName == 'basicTab') {
            this.basicTab = true
        } else {
            this.basicTab = false
        }
        if (tabName == 'addressTab') {
            this.addressTab = true
        } else {
            this.addressTab = false
        }
        if (tabName == 'documentTab') {
            this.documentTab = true
        } else {
            this.documentTab = false
        }
        if (tabName == 'payrollTab') {
            this.payrollTab = true
        } else {
            this.payrollTab = false
        }
        if (tabName == 'otherTab') {
            this.otherTab = true
        } else {
            this.otherTab = false
        }
    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.imageSrc = '';
    }
    onDoc1FileChange(event:any) {
        this.file1Src = false
        const file = event.target.files[0];
        this.file1Src = event.target.files.length > 0 ? true : false;
        if (this.file1Src == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file1Src = reader.result;   
                 
           }
        }
        console.log(this.file1Src)   
        
    }

    removeDocument1() {
        this.file1Src = '';
    }
    onDoc2FileChange(event:any) {
        this.file2Src = false
        const file = event.target.files[0];
        this.file2Src = event.target.files.length > 0 ? true : false;
        if (this.file2Src == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file2Src = reader.result;   
                 
           }
        }
        console.log(this.file2Src)   
        
    }

    removeDocument2() {
        this.file2Src = '';
    }
    onDoc3FileChange(event:any) {
        this.file3Src = false
        const file = event.target.files[0];
        this.file3Src = event.target.files.length > 0 ? true : false;
        if (this.file3Src == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file3Src = reader.result;   
                 
           }
        }
        console.log(this.file3Src)   
        
    }

    removeDocument3() {
        this.file3Src = '';
    }
    onDoc4FileChange(event:any) {
        this.file4Src = false
        const file = event.target.files[0];
        this.file4Src = event.target.files.length > 0 ? true : false;
        if (this.file4Src == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.file4Src = reader.result;   
                 
           }
        }
        console.log(this.file4Src)   
        
    }

    removeDocument4() {
        this.file4Src = '';
    }


    saveStaff() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                // medium: medium
                staff_id: rObj.Staff_id,
                role: rObj.Role,
                joining_date: rObj.Joining_date,
                department: rObj.Department,
                designation: rObj.Designation,
                pan_number: rObj.Pan_number,
                first_name: rObj.First_name,
                last_name: rObj.Last_name,
                dob: rObj.Dob,
                gender: rObj.Gender,
                alt_mobile: rObj.Alt_mobile  ? rObj.Alt_mobile : '',
                mobile: rObj.Mobile ? rObj.Mobile : '',
                email: rObj.Email,
                father_name: rObj.Father_name,
                mother_name: rObj.Mother_name,
                category: rObj.Marital_status,
                aadhaar_number: rObj.Aadhaar_number  ? rObj.Aadhaar_number : '',
                marital_status: rObj.Marital_status,
                qualification: rObj.Qualification,
                work_experience: rObj.Work_experience,
                permanent_address: rObj.Permanent_address,
                current_address: rObj.Current_address,
                city: rObj.City,
                pincode: rObj.Pincode ? rObj.Pincode : '', 
                state: rObj.State,
                country: rObj.Country,
                epf_number: rObj.Epf_number,
                basic_salary: rObj.Basic_salary,
                contract_type: rObj.Contract_type,
                bank_account_name: rObj.Bank_account_name,
                bank_account_number: rObj.Bank_account_number,
                bank_name: rObj.Bank_name,
                bank_branch_name: rObj.Bank_branch_name,
                bank_ifsc: rObj.Bank_ifsc,
                casual_leave: rObj.Casual_leave,
                medical_leave: rObj.Medical_leave,
                maternity_paternity_leave: rObj.Maternity_paternity_leave,
                photo_path: this.imageSrc,
                document1_title: rObj.Document1_title,
                document1_file: this.file1Src,
                document2_title: rObj.Document2_title,
                document2_file: this.file2Src,
                document3_title: rObj.Document3_title,
                document3_file: this.file3Src,
                document4_title: rObj.Document4_title,
                document4_file: this.file4Src,
              }
            console.log(sData)
            this._aService.staffData(sData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Staff Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}