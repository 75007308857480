import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import * as moment from 'moment'
@Component({
  selector: 'app-academic-classTimeTable',
  templateUrl: './classTimeTable.component.html'
})

export class ClassTimeTableComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classtimetableData: any[] = [];

    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public teacherData: any[] = [];
    public subjectsData: any[] = [];
    public mediumData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    
    public classId : any;
    public sectionId : any;
    public mediumId : any;
    dtOptions: any = {};

    public weekData: any[] = [{day:'1', dayName:'Monday'}, {day:'2', dayName:'Tuesday'}, {day:'3', dayName:'Wednesday'}, {day:'4', dayName:'Thursday'}, {day:'5', dayName:'Friday'}, {day:'6', dayName:'Saturday'}, {day:'7', dayName:'Sunday'}];

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required], 
        Section: ['', Validators.required],
        Medium: [''],
    })

    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        // this.mediumId = this._aRoute.snapshot.paramMap.get('medium');

    if(this.classId && this.sectionId){
        this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
            console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                this.classSectionData = JSON.parse(result[0].sections)
            }
        })
        this.rObj.patchValue({
            Class : this.classId,
            Section : this.sectionId,
            // Medium : this.mediumId,
        })
        this.search()
    }

    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
        console.log(cRes)
        if (cRes.status === true) {
            this.mediumData = JSON.parse(cRes.result);
        } else {
            this._gService.triggerSnackbar('No Data Found!', 'error', true);
        }
    })

    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
        // console.log(cRes)
        if (cRes.status === true) {
            this.classData = JSON.parse(cRes.result);
        } else {
            this._gService.triggerSnackbar('No Data Found!', 'error', true);
        }
    })

    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
        // console.log(cRes)
        if (cRes.status === true) {
            this.sectionData = JSON.parse(cRes.result);
        } else {
            this._gService.triggerSnackbar('No Data Found!', 'error', true);
        }
    })

    this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
        console.log(sRes)
        if (sRes.status === true) {
            this.teacherData = JSON.parse(sRes.result);
        }
        this._gService.showLoader(false);
    })
    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
        console.log(cRes)
        if (cRes.status === true) {
            this.subjectsData = JSON.parse(cRes.result);
        } else {
            this._gService.triggerSnackbar('No Data Found!', 'error', true);
        }

        this._gService.showLoader(false);
    })

    this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/academic/class-timetable/'+robj.Class+'/'+robj.Section]);
    }
    search(){
        let robj = this.rObj.value;
        this._aService.timetableByClassData(this.instID, this.activeAcademicID, robj.Class, robj.Section, robj.Medium).subscribe(Res => {
            console.log(Res)
            if (Res.status === true) {
                this.classtimetableData = JSON.parse(Res.result);
                // this._reRoute.shouldReuseRoute = () => false;
                // this._router.navigate([this._router.url]);
                // this._gService.triggerSnackbar('Class Saved Successfully', 'success', true);
            } else {
                this._gService.triggerSnackbar('Data Not Found', 'error', true);
            }
            console.log(this.classtimetableData)
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
    }
     timeFormat(time: any){
        return moment(time, 'HH:mm:ss').format('hh:mm A')
    }
}