<div class="modal-header">
    <h5 class="modal-title">Edit Assignment</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">

            <div class="form-group">
                <label>Class
                <small class="req"> *</small></label>
                <select name="class" formControlName="Class" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                 </select>
                 <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please select class</p>
                </div>
            </div>
            <div class="form-group">
                <label>Section
                    <small class="req"> *</small></label>
                    <select name="section" formControlName="Section" class="form-select" >
                        <option value="">Please Select</option>
                        <ng-container *ngFor="let section of classSectionData" >
                            <ng-container *ngFor="let s of sectionData">
                                <ng-container *ngIf="s.ID == section">
                                    <option [value]="section">{{s.name}}</option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </select>
                    <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                        <p class="input-error-msg">Please select section</p>
                    </div>
            </div>

            <div class="form-group">
                <label>Subject</label>
                <small class="req"> *</small>
                <select formControlName="Subject" class="form-control" >
                    <option value="">Please Select</option>
                    <option *ngFor="let sub of subjectData" value="{{sub.ID}}">{{sub.name}}</option> 
                 </select>
                 <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                    <p class="input-error-msg">Please select Subject</p>
                </div>
            </div>
            
            <div class="form-group">
                <label>Homework Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Homework_date" >
                <div *ngIf="rObj.controls.Homework_date.invalid && (rObj.controls.Homework_date.dirty || rObj.controls.Homework_date.touched)">
                    <p class="input-error-msg">Please select Homework date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Submission Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Submission_date" >
                <div *ngIf="rObj.controls.Submission_date.invalid && (rObj.controls.Submission_date.dirty || rObj.controls.Submission_date.touched)">
                    <p class="input-error-msg">Please select Submission date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Attachment </label>
                <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;">View Document</a>
                <div class="imgUpload-wrapper">
                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Document</div>  
                    <input type="file" formControlName="Document" class="form-control" (change)="onFileChange($event)">
                </div>
                <div *ngIf="rObj.controls.Document.invalid && (rObj.controls.Document.dirty || rObj.controls.Document.touched)">
                    <p class="input-error-msg">Please select Document</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" formControlName="Description" rows="3" class="form-control"></textarea>
            </div>   
            
            <div class="form-group btn-container">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateAssignment()">Update</button>
            </div>
        </form> 
    </div>
</div>
