import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { InstService } from 'src/app/services/inst.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/services/admin.service';
  
@Component({
    selector: 'app-deletemodal',
    templateUrl: './deletemodal.component.html'
})

export class DeleteModalComponent {
    
    public dataID: number;
    public moduleName: string;
    public moduleTitle: string;
    public userRole: any;

    constructor(
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _instService: InstService,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    ngOnInit(): void {}

    deleteModuleData(dataID: number) {
        //this._instService.deleteInstData(dataID).subscribe(delRes => {
            // alert('delete')
            this._gService.deleteModuleData(dataID, this.moduleName).subscribe(delRes => {
            this.modalRef.hide();
            console.log(delRes)
            if (delRes.status === true) {
                this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url])
                    this._gService.triggerSnackbar('Data Deleted Successfully!', 'success', true) 
                });
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        })
    }

    deleteAdminData(dataID: number) {
        // alert('admin delete')
        //this._instService.deleteInstData(dataID).subscribe(delRes => {
            this._adminService.deleteData(dataID, this.moduleName).subscribe(delRes => {
            this.modalRef.hide();
            console.log(delRes)
            if (delRes.status === true) {
                this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url])
                    this._gService.triggerSnackbar('Data Deleted Successfully!', 'success', true) 
                });
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        })
    }
    
}