<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Vehicle</h1>
        <div class="link-container col-md-6">
            <a href="../transport/driver/add" class="content-header-link">Add Driver</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                    Add Vehicle
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Vehicle No.</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Vehicle_no">		                    
                            <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                                <p class="input-error-msg">Please enter vehicle number</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Vehicle Model</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Vehicle_model" >
                            <div *ngIf="rObj.controls.Vehicle_model.invalid && (rObj.controls.Vehicle_model.dirty || rObj.controls.Vehicle_model.touched)">
                                <p class="input-error-msg">Please enter vehicle model</p>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label>Year Made</label>
                            <small class="req"> *</small>
                            <input type="text"  class="form-control" formControlName="Vehicle_year">
                            <div *ngIf="rObj.controls.Vehicle_year.invalid && (rObj.controls.Vehicle_year.dirty || rObj.controls.Vehicle_year.touched)">
                                <p class="input-error-msg">Please enter vehicle year</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Vehicle Type</label>
                            <small class="req"> *</small>
                            <select  class="form-control" formControlName="Vehicle_type">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let type of vehicleTypeData" value="{{type.ID}}">{{type.name}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Vehicle_type.invalid && (rObj.controls.Vehicle_type.dirty || rObj.controls.Vehicle_type.touched)">
                                <p class="input-error-msg">Please select vehicle type</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>No.of Seats</label>
                            <small class="req"> *</small>
                            <input type="number"  class="form-control" formControlName="No_seats" >
                            <div *ngIf="rObj.controls.Vehicle_type.invalid && (rObj.controls.Vehicle_type.dirty || rObj.controls.Vehicle_type.touched)">
                                <p class="input-error-msg">Please enter number of seats</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Maximum Allowed</label>
                            <small class="req"> *</small>
                            <input type="number"  class="form-control" formControlName="Max_allow" >
                            <div *ngIf="rObj.controls.Max_allow.invalid && (rObj.controls.Max_allow.dirty || rObj.controls.Max_allow.touched)">
                                <p class="input-error-msg">Please enter maximum allowed seats</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Contact Person</label>
                            <small class="req"> *</small>
                            <input type="text"  class="form-control" formControlName="Contact_person" >
                            <div *ngIf="rObj.controls.Contact_person.invalid && (rObj.controls.Contact_person.dirty || rObj.controls.Contact_person.touched)">
                                <p class="input-error-msg">Please enter contact number</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Insurance Renewal Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control date-input" formControlName="Renewal_date" >
                            <div *ngIf="rObj.controls.Renewal_date.invalid && (rObj.controls.Renewal_date.dirty || rObj.controls.Renewal_date.touched)">
                                <p class="input-error-msg">Please select date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Tracking Id</label>
                            <small class="req"> *</small>
                            <input type="number"  class="form-control" formControlName="Tracking_id"  >
                            <div *ngIf="rObj.controls.Tracking_id.invalid && (rObj.controls.Tracking_id.dirty || rObj.controls.Tracking_id.touched)">
                                <p class="input-error-msg">Please enter tracking id</p>
                            </div>
                        </div>
                       
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveVehicle()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                    Vehicles List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="vehicleData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">VEHICLE NO</th>
                                    <th class="align-left">VEHICLE MODEL</th>
                                    <th class="align-left">YEAR MADE </th>
                                    <th class="align-left">NO OF SEATS </th>
                                    <th class="align-left">Vehicle Type</th>
    
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let vehicle of vehicleData">
                                <!-- <td class="align-left">
                                <ng-container *ngFor="let class of classData">
                                    <ng-container *ngIf="class.ID == assignteacher.class">
                                        {{class.name}}
                                    </ng-container>
                                </ng-container>
                                </td> -->
                                <!-- <td class="align-left">
                                        <ng-container *ngFor="let v of vehicleData">
                                            <ng-container *ngIf="v.ID == vehicle.vehicle_no">
                                            {{v.name}}
                                            </ng-container>  
                                        </ng-container>
                                </td> -->
                                <td class="align-left">{{vehicle.vehicle_no}}</td>
                                <td>{{vehicle.model}}</td>
                                <td>{{vehicle.year_made}}</td>
                                <td>{{vehicle.no_seat}}</td>
                                <!-- <td>{{vehicle.type}}</td> -->
                                <td class="align-left">
                                    <ng-container *ngFor="let v of vehicleTypeData">
                                        <ng-container *ngIf="v.ID == vehicle.type">
                                        {{v.name}}
                                        </ng-container>  
                                    </ng-container>
                            </td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openVehicleEditModal(vehicle.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(vehicle.ID, 'vehicle', 'vehicle details')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                        <div *ngIf="!vehicleData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>     
                    </div>
                </div>
            </div>
    </div>
        
</div>