import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
  
@Component({
    selector: 'app-branchmodal',
    templateUrl: './branchmodal.component.html',
    styleUrls: ['./branchmodal.component.css'],
})

export class BranchModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public activeBranchID: number;
    public branchData: any[] = [];

    public branchID: number;

    constructor(
        private _gService: GlobalService,
        private _userService: UserService,
        private _instService: InstService,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._instService.getInstListByAdminID(this.userID).subscribe(instRes => {
            console.log(instRes);
            if (instRes.status === true) {
                this.branchData = JSON.parse(instRes.result);
                console.log(this.branchData)
            } else {
                console.log(instRes.msg);
            }
            this._gService.showLoader(false);
        })
    }

    onChange(e: any) {
        this.branchID = e.target.value;
    }

    updateInstID() {
        this._gService.showLoader(true);
        this._gService.getSettingsData(this.branchID, 'activeSession').subscribe(settingRes => {
            console.log(settingRes)
            if (settingRes.status === true) {
                console.log(settingRes.result)
                this.userData.InstID = this.branchID;
                this.userData.AcademicID = settingRes.result;
                localStorage.setItem('currentUser', JSON.stringify(this.userData));
                console.log(this.userData)
                this.modalRef.hide();
                this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url])
                    this._gService.triggerSnackbar('Branch Updated Successfully!', 'success', true) 
                });
            }
            this._gService.showLoader(false);
        })
    }
    
}