<div class="modal-header">
    <h5 class="modal-title">Edit Event</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Event</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Event">
                <div *ngIf="rObj.controls.Event.invalid && (rObj.controls.Event.dirty || rObj.controls.Event.touched)">
                    <p class="input-error-msg">Please enter event name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Event Type</label>
                <small class="req"> *</small>
                <select formControlName="EventType" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let e of eventData" value="{{e.ID}}">
                    {{e.name}}
                    </option>
                </select>    
                <div *ngIf="rObj.controls.EventType.invalid && (rObj.controls.EventType.dirty || rObj.controls.EventType.touched)">
                    <p class="input-error-msg">Please select Event type</p>
                </div>   
            </div>
            <div class="form-group">
                <label>Event for</label>
                <small class="req"> *</small>
                <select formControlName="UserType" class="form-select" >
                    <option value="">Select</option>
                    <option value="student">Student</option>
                    <option value="staff">Staff</option>
                </select>    
                <div *ngIf="rObj.controls.UserType.invalid && (rObj.controls.UserType.dirty || rObj.controls.UserType.touched)">
                    <p class="input-error-msg">Please select User type</p>
                </div>   
            </div>
            <div class="form-group" *ngIf="rObj.controls.UserType.value == 'staff'">
                <!-- <div class="row">                        -->
                        <div class="form-group">
                            <label>Department
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Department" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let dept of departmentData" value="{{dept.ID}}">
                                {{dept.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Designation
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Designation" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let des of designationData" value="{{des.ID}}">
                                {{des.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                    <!-- </div> -->
            </div>
            <div class="form-group" *ngIf="rObj.controls.UserType.value == 'student'">
                <!-- <div class="row">                          -->
                        <div class="form-group">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-select" >
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData" >
                                        <ng-container *ngFor="let s of sectionData">
                                            <ng-container *ngIf="s.ID == section">
                                                <option [value]="section">{{s.name}}</option>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                <!-- </div> -->
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea class="form-control"  formControlName="Description"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>  
            </div>
            <div class="form-group">
                <label>Start Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="Start_date">
                <div *ngIf="rObj.controls.Start_date.invalid && (rObj.controls.Start_date.dirty || rObj.controls.Start_date.touched)">
                    <p class="input-error-msg">Please enter Start date</p>
                </div>
            </div>
            <div class="form-group">
                <label>End Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="End_date">
                <div *ngIf="rObj.controls.End_date.invalid && (rObj.controls.End_date.dirty || rObj.controls.End_date.touched)">
                    <p class="input-error-msg">Please enter End date</p>
                </div>
            </div>
            

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="UpdateEvent()">Update</button>
            </div>
        </form>
    </div>
</div>
