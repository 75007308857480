<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Inventory Purchase</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
        <a href="../store-management/inventory-item/add/" class="content-header-link">Add Inventory Item</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Inventory Purchase
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Item Name</label>
                                <small class="req"> *</small>
                                <select formControlName="Item_name" class="form-select" data-next="getSection" data-type="list" required="">
                                    <option value="">Select</option>
                                    <option *ngFor="let i of inventoryItemData" value="{{i.ID}}">{{i.item_name}}</option>                                                
                                </select>
                                <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                                    <p class="input-error-msg">Please select item name</p>
                                </div>
                        </div>
                        <div class="form-group">
                            <label>Vendors</label>
                            <small class="req"> *</small>
                            <!-- <input type="text" formControlName="Vendors" class="form-control " required=""> -->
                            <select formControlName="Vendors" class="form-select" data-next="getSection" data-type="list" required="">
                                <option value="">Select</option>
                                <option *ngFor="let i of vendorData" value="{{i.ID}}">{{i.company_name}}</option>                                                
                            </select>
                            <div *ngIf="rObj.controls.Vendors.invalid && (rObj.controls.Vendors.dirty || rObj.controls.Vendors.touched)">
                                <p class="input-error-msg">Please select vendor</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Quantity</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="Qty" class="form-control number-input" autocomplete="off" value="" required="">
                            <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                                <p class="input-error-msg">Please enter quantity</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Purchase Price</label>
                            <small class="req"> *</small>
                            <input type="number" formControlName="Cost" class="form-control" required="">
                            <div *ngIf="rObj.controls.Cost.invalid && (rObj.controls.Cost.dirty || rObj.controls.Cost.touched)">
                                <p class="input-error-msg">Please enter purchase price</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Purchase Date </label>
                            <small class="req"> *</small>
                            <input type="date" formControlName="Date" class="form-control date-input" required="">
                            <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                <p class="input-error-msg">Please enter purchase date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Payment Mode </label>
                            <small class="req"> *</small>
                            <select type="text" formControlName="Mode" class="form-select">
                                <option value="">Select</option>
                                <option value="online">Online</option>
                                <option value="cash">Cash</option>                                             
                            </select>
                            <div *ngIf="rObj.controls.Mode.invalid && (rObj.controls.Mode.dirty || rObj.controls.Mode.touched)">
                                <p class="input-error-msg">Please enter medium name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Payment Status</label>
                            <small class="req"> *</small>
                            <input type="text" formControlName="Status" class="form-control" required="">
                            <div *ngIf="rObj.controls.Status.invalid && (rObj.controls.Status.dirty || rObj.controls.Status.touched)">
                                <p class="input-error-msg">Please enter payment status</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Attach Document </label>
                            <!-- <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;" *ngIf="imageSrc">View Document</a> -->
                            <div class="imgUpload-wrapper">
                                <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                                <input type="file" formControlName="Doc" class="form-control" (change)="onFileChange($event)">
                            </div>
                            <div *ngIf="rObj.controls.Doc.invalid && (rObj.controls.Doc.dirty || rObj.controls.Doc.touched)">
                                <p class="input-error-msg">Please attach Document</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description  </label>
                            <textarea type="text" formControlName="Description" rows="3" class="form-control"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter description</p>
                            </div>
                        </div>   
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="savePurchase()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Inventory Purchase List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryPurchaseData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Search Item</th>
                                <th>Vendor</th>
                                <th>Quantity</th>
                                <th>Purchase Price</th>
                                <th>Purchase Date</th>
                                <th>Attach Document</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of inventoryPurchaseData">
                                <td class="align-left">
                                    <ng-container *ngFor="let c of inventoryItemData">
                                        <ng-container *ngIf="c.ID == p.item_id">
                                            {{c.item_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let v of vendorData">
                                        <ng-container *ngIf="v.ID == p.vendor">
                                            {{v.company_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{p.qty}}</td>
                                <td>{{p.cost}}</td>
                                <td>{{getDate(p.date)}}</td>
                                <td>
                                    <ng-container *ngIf="p.document">
                                        <a href="{{imgURL}}{{p.document}}" target="_blank">view document</a>
                                    </ng-container>
                                </td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openPurchaseEditModal(p.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(p.ID, 'inventory_purchase', 'inventory purchase')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!inventoryPurchaseData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>      
                </div>
            </div>
        </div>
    </div>
</div>