import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import { InstService } from 'src/app/services/inst.service';
import moment from 'moment';
@Component({
  selector: 'app-enpcms-pageList',
  templateUrl: './pageList.component.html'
})

export class PageListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public selectedIds : any[] = [];
    public pageListData: any[] = [];
    // public userRoleData: any[] = [];
    // public  departmentID: any;
    // public designationId: any;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public instSlug : any 
    // imageSrc: any;
    // public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private _instService: InstService
    ) {}

    ngOnInit(): void {

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'cms_pages').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.pageListData = JSON.parse(cRes.result);
                // this._gService.getUserRoleData().subscribe(res =>{
                //     if (res.status === true) {
                //         this.userRoleData = JSON.parse(res.result)
                //         console.log(this.userRoleData)
                //     }
                // })
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

        this._instService.getInstDataByID(this.instID).subscribe(instRes => {
            console.log(instRes)
            if (instRes.status === true) {
                this.instSlug = JSON.parse(instRes.result)[0].inst_slug
                console.log(this.instSlug)
            }
        })

    }
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.pageListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.pageListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }
    
    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    gotoViewLink(instSlug: string, pageSlug: string){ 
        window.open('https://'+instSlug+'.edunationpro.com/website/'+pageSlug, '_blank');
    }


    gotoEditLink(pageID: number){
        this._router.navigate(['/cms/edit/pages/'+pageID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    convertDate(date : any){
        return moment(date).format('LLL')
    }

}