import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy,ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-taskManagement-taskList',
  templateUrl: './taskList.component.html'
})

export class TaskListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public departmentData: any[] = [];
    public designationData: any[] = [];
    public taskData: any[] = [];
    public studentData: any[] = [];
    public staffData: any[] = [];
    public selectedIds : any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public userType: string;
    public TaskAlloted: any[] = [];
    public taskID: any;
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
    ) {}



    ngOnInit(): void {

        

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'task').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.taskData = JSON.parse(cRes.result);
                // this.userType = this.taskData[0].user_type;
                // this.TaskAlloted = JSON.parse(this.taskData[0].user_id).length;
                // console.log(this.TaskAlloted)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
               
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
               
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
               
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
            
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.taskData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.taskData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    getUserCount(users: any) {
       return JSON.parse(users).length 
    }

    getAllUsersCount(classId: number, sectionId: number) {
        return this.studentData.filter(s => s.class == classId && s.section == sectionId).map(s => s.ID).length
    }

    getdtId(dtID : Number) {
        let dtData = this.departmentData.filter(function(m) {
             return m.ID == dtID
         }).map(function(m){
             return m.name
         })
         return dtData
     }
     getdsId(dsID : Number) {
        let dsData = this.designationData.filter(function(m) {
             return m.ID == dsID
         }).map(function(m){
             return m.name
         })
         return dsData
     }
     getcId(cID : Number) {
        let cData = this.classData.filter(function(m) {
             return m.ID == cID
         }).map(function(m){
             return m.name
         })
         return cData
     }
     getsId(sID : Number) {
        let sData = this.sectionData.filter(function(m) {
             return m.ID == sID
         }).map(function(m){
             return m.name
         })
         return sData
     }

    gotoViewLink(staffID: number){
        this._router.navigate(['/staff/view/'+staffID])
    }


    gotoEditLink(taskID: number){
        this._router.navigate(['/task/edit/'+taskID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }



}