<div class="modal-header">
    <h5 class="modal-title">Return Book</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Return Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control" formControlName="Return_date">
                <div *ngIf="rObj.controls.Return_date.invalid && (rObj.controls.Return_date.dirty || rObj.controls.Return_date.touched)">
                    <p class="input-error-msg">Please enter Return date</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="returnDate()">Update</button>
            </div>
        </form>
    </div>
</div>
