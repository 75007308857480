<header class="header-main" *ngFor="let inst of instData">
    <div class="container">
        <div class="header-wrapper">
            <a href="homepage.html">
                <div class="logo-wrapper">
                    <img class="main-logo" [src]="imgURL+inst.website_logo_img">
                </div>
            </a>
            <div class="contact-wrapper">
                <div class="contact-detail">
                    <a class="small-text" href="#"><i class="fas fa-phone"></i> +91 1234567890</a>
                </div>
                <div class="email-wrapper">
                    <a class="small-text" href="#"><i class="fas fa-envelope"></i> info@edunationpro.com</a>
                </div>
                <div class="social-media-wrapper">
                    <p class="small-text">Follow us: </p>
                    <a class="social-icon" href="#">
                        <i class="fab fa-whatsapp"></i>
                    </a>
                    <a class="social-icon" href="#">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a class="social-icon" href="#">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a class="social-icon" href="#">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="menu-bar">
        <div class="container">
            <ul class="menu-list-wrapper">
                <li class="menu-list-opt">
                    <a class="menu-opt" href="homepage.html">Home</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="about-us.html">About Us</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="admission.html">Online Admission</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="collection.html">Gallery</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="events.html">Events</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="news.html">News</a>
                </li>
                <li class="menu-list-opt">
                    <a class="menu-opt" href="contact-us.html">Contact us</a>
                </li>
            </ul>
        </div>
    </div>
</header>