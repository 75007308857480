<div class="modal-header">
    <h5 class="modal-title">Edit Driver Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
            <label>Vehicle No.</label>
                <small class="req"> *</small>
                <select formControlName="Vehicle_no" class="form-control" >
                    <option value="">Please Select</option>
                    <option *ngFor="let No of vehicleData" value="{{No.ID}}" >{{No.vehicle_no}}</option>
            
                 </select>
                 <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                    <p class="input-error-msg">Please select vehicle number</p>
                </div>
            </div>
            <div class="form-group">
                <label>Driver Name</label>
                <small class="req"> *</small>
                <input type="text" formControlName="D_name" class="form-control" >
                <div *ngIf="rObj.controls.D_name.invalid && (rObj.controls.D_name.dirty || rObj.controls.D_name.touched)">
                    <p class="input-error-msg">Please enter name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Driver Phone No.</label>
                <small class="req"> *</small>
                <input type="number" formControlName="D_phone" class="form-control" >
                <div *ngIf="rObj.controls.D_phone.invalid && (rObj.controls.D_phone.dirty || rObj.controls.D_phone.touched)">
                    <p class="input-error-msg">Please enter phone number</p>
                </div>
            </div>
            <div class="form-group">
                <label>Alternate Contact No.</label>
                <small class="req"> *</small>
                <input type="number" formControlName="D_altPhone" class="form-control" >
                <div *ngIf="rObj.controls.D_altPhone.invalid && (rObj.controls.D_altPhone.dirty || rObj.controls.D_altPhone.touched)">
                    <p class="input-error-msg">Please enter alternate phone number</p>
                </div>
            </div>
            <div class="form-group">
                <label>Present Address</label>
                <small class="req"> *</small>
                <textarea type="text" formControlName="D_address" class="form-control"></textarea>
                <div *ngIf="rObj.controls.D_address.invalid && (rObj.controls.D_address.dirty || rObj.controls.D_address.touched)">
                    <p class="input-error-msg">Please enter present address </p>
                </div>
            </div>
            <div class="form-group">
                <label>Perment Address</label>
                <small class="req"> *</small>
                <textarea type="text" formControlName="D_Confirmaddress" class="form-control" ></textarea>
                <div *ngIf="rObj.controls.D_Confirmaddress.invalid && (rObj.controls.D_Confirmaddress.dirty || rObj.controls.D_Confirmaddress.touched)">
                    <p class="input-error-msg">Please enter permanent address</p>
                </div>
            </div>
            <div class="form-group">
                <label>Driving License No.</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Driving_licence" class="form-control" >
                <div *ngIf="rObj.controls.Driving_licence.invalid && (rObj.controls.Driving_licence.dirty || rObj.controls.Driving_licence.touched)">
                    <p class="input-error-msg">Please enter license number</p>
                </div>
            </div>
            
           
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateDriver()">Update</button>
            </div>
        </form> 
    </div>
</div>
