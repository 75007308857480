import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Route, Router, RouteReuseStrategy ,ActivatedRoute} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CertificateService } from 'src/app/services/certificates.service';
import * as moment from 'moment';
import { CertificateModalComponent } from 'src/app/components/modal/certificatemodal/certificatemodal.component';
import { ViewCertificatemodalModalComponent } from 'src/app/components/modal/viewCertificatemodal/viewCertificatemodal.component';
import { IdModalComponent } from 'src/app/components/modal/idmodal/idmodal.component';
import { ViewIdModalComponent } from 'src/app/components/modal/viewIdmodal/viewIdmodal.component';

@Component({
  selector: 'app-staff-staffidList',
  templateUrl: './staffidList.component.html',
})

export class StaffIdListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public IdListData: any[] = [];
    public templateData: any[] = [];
    public templateID: any;
    public templatePath: any;
    public templateHtml : any;    
    public selectedIds : any[] = [];

    // status:boolean = false;
    // public imgURL = 'https://apiuat.edunationpro.com/uploads/';
    modalRef: BsModalRef;
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: CertificateService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _aroute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
        private sanitizer: DomSanitizer,
        private modalService: BsModalService,
    ) {}


    ngOnInit(): void {

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'idcards').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
           let idList = JSON.parse(lRes.result);
                this.IdListData = idList.filter(i => i.type == 'staff')
            console.log(this.IdListData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
       
        this.templateID =this._aroute.snapshot.paramMap.get('id');
        // alert(this.templateID)
        this._gService.showLoader(true);
        this._aService.templatesData().subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.templateData = JSON.parse(pRes.result);
                if (this.templateID) {
                    this.templatePath = this.templateData.filter(t => {
                        return t.ID == this.templateID                                  
                    })
                    console.log(this.templatePath)
                    this.templatePath = this.templatePath[0].path
                    console.log(this.templatePath)
                    this._gService.getHtmlData(this.templatePath).subscribe((data : any)=>{
                        this.templateHtml = this.sanitizer.bypassSecurityTrustHtml(data)
                    })
                }
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
       

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort', 
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.IdListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.IdListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    viewTemplate(templateId : any) {
        this.modalRef = this.modalService.show(ViewIdModalComponent, {
            class: 'modal-dialog-centered id-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                templateID: templateId,
            } 
        });
    }

    // onFileChange(event:any) {
    //     this.status = false
    //     const file = event.target.files[0];
    //     this.status = event.target.files.length > 0 ? true : false;
    //     if (this.status == true) {
    //        const reader = new FileReader();
    //        reader.readAsDataURL(file);
    //        reader.onload = () => {
    //           this.imageSrc = reader.result;          
    //        }
    //     }
    // }

    selectImage(ImgID : number){
        this._router.navigate(['certificate/certificate-template/'+ImgID])
    }

    openTemplateEdit(templateID: number) {
        this._router.navigate(['id-card/'+templateID])
    }

    deleteTemplate(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    // saveTemplate(){
    //     if (this.rObj.valid) {
    //         let rObj = this.rObj.value;
    //         let mData = {
    //             action: 'save',
    //             inst_id: this.instID,
    //             academic_id: this.activeAcademicID,
    //             name: rObj.Certificate_title,
    //             options: rObj.Template_Name,
    //             html:"",
    //         }
    //         console.log(mData)
    //         this._aService.templateSaveData(mData).subscribe(phRes => {
    //             console.log(phRes)
    //             if (phRes.status === true) {
    //                 this._reRoute.shouldReuseRoute = () => false;
    //                 this._router.navigate([this._router.url]);
    //                 this._gService.triggerSnackbar('Template Saved Successfully', 'success', true);
    //             } else {
    //                 this._gService.triggerSnackbar('Something went wrong', 'error', true);
    //             }
    //         }, err => {
    //             console.log(err);
    //             this._gService.triggerSnackbar('Server Error!', 'error', true);
    //         })
    //     }
    // }


}