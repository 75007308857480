<footer class="footer-main">
    <div class="container">
        <div class="row footer-main-wrapper">
            <div class="col-md-3">
                <div class="footer-link-wrapper">
                    <ul class="link-list-wrapper">
                        <li class="link-list">
                            <a class="link" href="homepage.html">
                                Home
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="about-us.html">
                                About us
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="events.html">
                                Events
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="collection.html">
                                Gallery
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-link-wrapper">
                    <ul class="link-list-wrapper">
                        <li class="link-list">
                            <a class="link" href="contact-us.html">
                                Contact us
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="news.html">
                                News
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="privacy-policy.html">
                                Privacy Policy
                            </a>
                        </li>
                        <li class="link-list">
                            <a class="link" href="terms.html">
                                Terms and Condition
                            </a>
                        </li>
                    </ul>   
                </div>
            </div>
            <div class="col-md-3">
                <div class="newsletter-wrapper">
                    <div class="newsletter-title">
                        <h2 class="section-title">Subscribe for Newsletter</h2>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" value="Email" placeholder="Enter your email">
                        <a class="button-wrapper" href="#">
                            <button class="cta-btn">
                                Subscribe
                            </button>
                        </a>
                    </form>
                </div>
            </div>
            <div class="col-md-3">
                <div class="container">
                    <div class="header-wrapper">
                        <div class="contact-wrapper">
                            <div class="contact-detail">
                                <a class="small-text" href="#"><i class="fas fa-phone"></i> +91 1234567890</a>
                            </div>
                            <div class="email-wrapper">
                                <a class="small-text" href="#"><i class="fas fa-envelope"></i> info@edunationpro.com</a>
                            </div>
                            <div class="social-media-wrapper">
                                <p class="small-text">Follow us: </p>
                                <a class="social-icon" href="#">
                                    <i class="fab fa-whatsapp"></i>
                                </a>
                                <a class="social-icon" href="#">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a class="social-icon" href="#">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a class="social-icon" href="#">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</footer>
<div class="footer-text">
    <div class="container">
        <div class="footer-text-wrapper">
            <p class="text">
                © Ginyard School 2023 All rights reserved
            </p>
        </div>
    </div>
</div>