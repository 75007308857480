<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-7">Fees Collection</h1>
        <!-- <div class="header-msg col-md-5">
                    </div> -->
        <!-- <div class="link-container col-md-5">
            <a href="../add/" class="content-header-link">Add New Student</a>
            <a href="../import/" class="content-header-link">Import Students</a>
        </div> -->
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-select" >
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData" >
                                         <option [value]="section.id">{{section.name}}</option>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <!-- <ng-container *ngIf="studentListData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                        </div>
                    </ng-container> -->
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentListData.length">
                        <thead>
                            <tr>
                                <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
		                        <th>Register Number</th>
			                    <th>Student Name</th>
			                    <th>Class</th>
                                <th>Section</th>
			                    <th>Roll Number</th>
		                        <th>Date of Birth</th>
			                    <th>Gender</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of studentListData">
                                <!-- <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                </td> -->
                                <td>{{s.admission_number}}</td>
                                <td>{{s.full_name}}</td>
                                <td> 
                                    <ng-container *ngFor="let class of classData">
                                        <ng-container *ngIf="class.ID == s.class">
                                            {{class.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{s.section_name}}</td>
                                <td>{{s.roll_no}}</td>
                                <td>{{s.dob}}</td>
                                <td>{{s.gender}}</td>
                                <td class="action-btn">
                                    <a tooltip="Collect Fee" class="edit-btn" (click)="gotoPayLink(s.ID)" ><i class="fas fa-money-bill-wave-alt"></i></a>
                                    <!-- <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'student', 'delete student')"><i class="far fa-trash-alt"></i></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!studentListData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>            
        
                            
            </div>
        </div>
    </div>
</div>