<div class="modal-header">
    <h5 class="modal-title">Edit Class Subject</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Class Name</label>
                <small class="req"> *</small>
                <!-- <input type="" class="form-control" formControlName="Class"> -->
                <select class="form-select" formControlName="Class">
                    <option value="">Select</option>
                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>
                </select>
                <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>

            <div class="form-group">
                <label>Sections</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <!-- <label *ngFor="let section of classSectionData; let i = index" formArrayName="Section">
                        <input type="checkbox" [checked]="rObj.controls['Section'].value[i].isSelected" [value]="rObj.controls['Section'].value[i].value" [formControlName]="i" (change)="onSectionSelect(i, section.ID, $event)">
                        {{section.name}}           
                    </label> -->
                    <label *ngFor="let section of classSectionData; let i = index" formArrayName="Section">
                        <input type="checkbox" [checked]="rObj.controls['Section'].value[i].isSelected == 'true'" [value]="section.id" [formControlName]="i" (change)="onSectionSelect($event)">
                        {{rObj.controls['Section'].value[i].isSelected}}
                        {{section.name}}
                    </label>
                </div>    
                <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                    <p class="input-error-msg">Please select a section</p>
                </div>
            </div>  

            <div class="form-group">
                <label>Subjects</label>
                <small class="req"> *</small>
                <div class="section-list">
                    <label *ngFor="let subject of subjectData.slice().reverse(); let i = index" formArrayName="Subject">
                        <input type="checkbox" [checked]="rObj.controls['Subject'].value[i].isSelected == 'true'" [value]="rObj.controls['Subject'].value[i].value" [formControlName]="i" (change)="onSubjectSelect($event)">
                        {{subject.name}}		                        
                    </label>
                </div>    
                <div *ngIf="rObj.controls.Subject.errors?.['required'] && rObj.controls.Subject.touched">
                    <p class="input-error-msg">Please select a subject</p>
                </div>
            </div>

            <div class="modal-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateClass()">Update</button>
            </div>
        </form> 
    </div>
</div>
