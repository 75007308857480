import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class StudentService {
  constructor(
    private _http: HttpClient,
  ) { }

  private student = `${environment.APIURL}/student/addUpdate/`;
  private studentList = `${environment.APIURL}/student/getStudentsByClassSection/`;
  private birthday = `${environment.APIURL}/student/getStudentsByBirthday/`;
  private bdayByMonth = `${environment.APIURL}/student/getStudentsByBirthday/`;
  private bulkimport = `${environment.APIURL}/student/importStudents/`;
  private getIdCardTemplate = `${environment.APIURL}/idCard/getTemplates/?type=student`;
  private getstudentbyName = `${environment.APIURL}/student/getStudentsByName/`;
  private getStudentDocument = `${environment.APIURL}/student/getStudentDocumentByID/`;
  private promoteStudent = `${environment.APIURL}/student/promoteStudents/`;
  private studentsToPromote = `${environment.APIURL}/student/getStudentsByClassSectionToPromote/`;

  getStudentToPromote(instID: number, academicID: number, classID: any, section: any): Observable<any> {
    return this._http.get(this.studentsToPromote+'?inst_id='+instID+'&academic_id='+academicID+'&class='+classID+'&section='+section, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  promoteStudentData(data: object): Observable<any> {
    return this._http.post(this.promoteStudent, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  

  studentData(data: object): Observable<any> {
    return this._http.post(this.student, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  studentListData(instID: number, academicID: number, classID: any, section: any): Observable<any> {
    return this._http.get(this.studentList+'?inst_id='+instID+'&academic_id='+academicID+'&class='+classID+'&section='+section, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  birthdayData(instID: number, academicID: number, dataFor: any): Observable<any> {
    return this._http.get(this.birthday+'?inst_id='+instID+'&academic_id='+academicID+'&dataFor='+dataFor, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  monthBirthdayData(instID: number, academicID: number, dataFor: any, month: any): Observable<any> {
    return this._http.get(this.bdayByMonth+'?inst_id='+instID+'&academic_id='+academicID+'&dataFor='+dataFor+'&month='+month, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  bulkImportData(data: object): Observable<any> {
    return this._http.post(this.bulkimport, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getIdCardTemplateData(): Observable<any> {
    return this._http.get(this.getIdCardTemplate, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  studentByName(instID: number, academicID: number, name : any): Observable<any> {
    return this._http.get(this.getstudentbyName+'?inst_id='+instID+'&academic_id='+academicID+'&name='+name, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getStudentDocumentData(studentID: number): Observable<any> {
    return this._http.get(this.getStudentDocument+'?student_id='+studentID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
}