import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { FinanceService } from 'src/app/services/finance.service';
  
@Component({
    selector: 'app-incomemodal',
    templateUrl: './incomemodal.component.html',
    styleUrls: [],
})

export class IncomeModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public incomeID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public incomeHeadData: any[] = [];

    constructor(
        private _gService: GlobalService,
        private _aService: FinanceService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Income_head: ['', Validators.required],
        Income_name: ['', Validators.required],
        Invoice_no: ['', Validators.required],
        Income_date: ['', Validators.required],
        Amount: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'incomehead').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.incomeHeadData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getDataByID(this.incomeID, 'income').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Income_head: sData[0].income_head,
                    Income_name: sData[0].income_name,
                    Invoice_no: sData[0].invoice_no,
                    Income_date: sData[0].income_date,
                    Amount: sData[0].amount

                });
            }
            this._gService.showLoader(false);
        })
    }

    updateIncome() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.incomeID,
                income_head: rObj.Income_head,
                income_name: rObj.Income_name,
                invoice_no: rObj.Invoice_no,
                income_date: rObj.Income_date,
                amount: rObj.Amount,
            }
            this._aService.incomeData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Income Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}