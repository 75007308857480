<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Menu Item ({{menuName}})</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="../../class/add/" class="content-header-link">Add Medium</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-3">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Menu Item
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Menu Item</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Menu_item">
                            <div *ngIf="rObj.controls.Menu_item.invalid && (rObj.controls.Menu_item.dirty || rObj.controls.Menu_item.touched)">
                                <p class="input-error-msg">Please Menu item Menu</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Type</label>
                            <div class="radio">
                                <label class="radio-inline">
                                    <input class="form-check-input" type="radio" formControlName="Type" value="external">External
                                </label>
                                <label class="radio-inline">
                                    <input class="form-check-input" type="radio" formControlName="Type" value="internal">Internal
                                </label>
                            </div>
                            <div *ngIf="rObj.controls.Type.invalid && (rObj.controls.Type.dirty || rObj.controls.Type.touched)">
                                <p class="input-error-msg">Please select Type</p>
                            </div>  
                        </div>
                        <div class="form-group percentage" *ngIf="rObj.controls.Type.value == 'external'">
                            <label>External URL Address</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Ex_url_address">
                            <div *ngIf="rObj.controls.Ex_url_address.invalid && (rObj.controls.Ex_url_address.dirty || rObj.controls.Ex_url_address.touched)">
                                <p class="input-error-msg">Please enter External url address</p>
                            </div>
                        </div>
                        <div class="form-group"  *ngIf="rObj.controls.Type.value == 'internal'">
                            <label>Pages</label>
                            <small class="req"> *</small>
                            <select class="form-select" formControlName="Pages">
                                <option value="">Select</option>
                                <option *ngFor="let p of PagesData" value="{{p.ID}}">{{p.title}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Pages.invalid && (rObj.controls.Pages.dirty || rObj.controls.Pages.touched)">
                                <p class="input-error-msg">Please enter Description</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Open In New Tab</label>
                            <br>
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Target"  value="yes">
                                <label class="form-check-label" for="flexCheckDefault">Yes</label>
                            </div> 
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Target"  value="no">
                                <label class="form-check-label" for="flexCheckDefault">No</label>
                            </div> 
                            <div *ngIf="rObj.controls.Target.invalid && (rObj.controls.Target.dirty || rObj.controls.Target.touched)">
                                <p class="input-error-msg">Please select Target</p>
                            </div>  
                        </div>

                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveMenu()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Menu Items 
                    </div>
                </div>
                <div class="panel-body">
                    
                    <table  id="list" class="list" *ngIf="menuItemData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" [checked]="selectedIds.length == menuItemData.length" (change)="checkUncheckAll($event)"></th>
                                <th class="align-left">Menu Item</th>
                                <th>External URL Address</th>
                                <th>Internal Pages</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let m of menuItemData">
                                <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="selectedIds.includes(m.ID)" (change)="onChecked(m.ID, $event)">
                                </td>
                                <td class="align-left">{{m.name}}</td>
                                <td>{{m.url ? m.url : '-'}}</td>
                                <td>{{getName(m.page_id) ? getName(m.page_id) : '-'}}</td>
                                <td class="action-btn">
                                    <!-- <a tooltip="Edit" class="edit-btn" (click)="openMediumEditModal(m.ID)"><i class="fa-solid fa-plus"></i></a> -->
                                    <a tooltip="Edit" class="edit-btn" (click)="openMenuItemEditModal(m.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(m.ID, 'cms_menu_item', 'menu item')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="row" style="justify-content: space-between;">
                        <div class="col-md-5">
                            <div *ngIf="selectedIds.length">{{selectedIds.length}} Selected</div>
                        </div>
                        <div class="col-md-5">
                            <button class="btn primary-btn extra-small-btn" [disabled]="!selectedIds.length" (click)="MultiMenuData()" style="margin-top: 15px !important;float: right;">Add to Menu</button>
                        </div>
                    </div>
                    <div *ngIf="!menuItemData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel">
                <div class="row panel-header">
                    <div class="col-md-6 panel-title">
                        Menu List
                    </div>
                    <div class="col-md-6 panel-title">
                        <button class="btn primary-btn extra-small-btn" (click)="saveMenuList()" style="float: right;">Save</button>
                    </div>
                </div>
                <div class="panel-body">
                    <ng-container *ngIf="menuList.length">
                        <ul class="menu-drag-wrapper" 
                            *ngFor="let menu of menuList; let i = index" 
                            [draggable]="true" 
                            [class.dragging]="i === this.draggingIndex"
                            (dragstart)="onDragStart(i)" 
                            (dragenter)="onDragEnter(i)" 
                            (dragend)="onDragEnd()"
                            (dragover)="onDragOver($event)">
                            <li class="menu-list-item">
                                <ng-container *ngFor="let menuItem of menuItemData">
                                    <ng-container *ngIf="menuItem.ID == menu">
                                        {{menuItem.name}}
                                        <span (click)="removeMenuItem(i)">remove</span>
                                    </ng-container>
                                </ng-container>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!menuList.length">
                        <div class="message">
                            Menu List is empty please select the menu from menu item 
                        </div>

                    </ng-container>
               </div>

            </div>
        </div>
    </div>
        
</div>