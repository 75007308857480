<div class="console-content">
    <div class="console-content-header row">
        <h1 class="title col-md-3">Front CMS Settings</h1>
        <div class="header-msg col-md-6"></div>
        <div class="link-container col-md-3">
            <!-- <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn">Upload</button>
            </div> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title"> Current Theme </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="setting-wrapper">
                            <div class="setting-item-wrapper">
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme" name="Theme" id="default" value="default" hidden>
                                    <label for="default">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/default.PNG"></div>
                                        <h5 class="setting-name">Default</h5>
                                    </label>
                                </div>
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme" id="theme1" value="greenOpaque" hidden>
                                    <label for="theme1">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/greenOpaque.PNG"></div>
                                        <h5 class="setting-name">Green Opaque</h5>
                                    </label>
                                </div>
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme"id="theme2" value="aliceBlue" hidden>
                                    <label for="theme2">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/aliceBlue.PNG"></div>
                                        <h5 class="setting-name">Alice Blue</h5>
                                    </label>
                                </div>
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme"id="theme3" value="monochromeBlue" hidden>
                                    <label for="theme3">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/monochromeBlue.PNG"></div>
                                        <h5 class="setting-name">Monochrome Blue</h5>
                                    </label>
                                </div>
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme" id="theme4" value="pinkGrey" hidden>
                                    <label for="theme4">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/pinkGrey.PNG"></div>
                                        <h5 class="setting-name">Pink Grey</h5>
                                    </label>
                                </div>
                                <div class="setting-item">
                                    <input type="radio" formControlName="Theme" id="theme5" value="voilet" hidden>
                                    <label for="theme5">
                                        <div class="setting-img-wrapper"><img class="setting-img" src="/assets/img/website-Template/violet.PNG"></div>
                                        <h5 class="setting-name"> Violet</h5>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="content row col-md-12" >
                            <div class="col-md-6">
                                <div class="row content-item form-group">
                                    <label>Language:</label>
                                    <select  class="form-select" formControlName="Language">
                                        <option value="">Please select</option>
                                        <option value="english">English</option>
                                        <option value="hindi">Hindi</option>
                                        <option value="marathi">Marathi</option>
                                    </select>
                                </div>
                                <div class="row content-item form-group">
                                    <label>Logo(250px X 50px):</label>
                                    <div class="img-container">
                                        <div class="img-wrapper">
                                            <img [src]="imageSrc ? imageSrc : '/assets/img/default-user-avatar.png'">
                                        </div>
                                        <div class="upload-btn-wrapper imgBtn" *ngIf="!imageSrc">
                                            <button class="upload-btn">Select</button>
                                            <input type="file" (change)="onFileChange($event)">
                                        </div>
                                        <div class="upload-btn-wrapper imgBtn" *ngIf="imageSrc">
                                            <button class="upload-btn" (click)="removeImg()">Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row content-item form-group">
                                    <label>Favicon(32px X 32px):</label>
                                    <div class="img-container">
                                        <div class="img-wrapper">
                                            <img [src]="imageSrc2 ? imageSrc2 : '/assets/img/default-user-avatar.png'" style="width: 32px; height: 32px;">
                                        </div>
                                        <div class="upload-btn-wrapper imgBtn" *ngIf="!imageSrc2">
                                            <button class="upload-btn">Select</button>
                                            <input type="file" formControlName="PhotoUpload" id="photoUpload" (change)="onFile2Change($event)">
                                        </div>
                                        <div class="upload-btn-wrapper imgBtn" *ngIf="imageSrc2">
                                            <button class="upload-btn" (click)="remove2Img()">Remove</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row content-item form-group">
                                    <label>Footer Text:</label>
                                <input type="text" class="form-control" formControlName="Footer">
                                </div>
                                <div class="row content-item form-group">
                                    <label>Cookie consent:</label>
                                    <textarea class="form-control" formControlName="Cookie_consent"></textarea>
                                </div>
                                <div class="row content-item form-group">
                                    <label>Google Analytics:</label>
                                    <textarea class="form-control" formControlName="Google_analytics"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> WhatsApp URL:</label>
                                        <input type="text" class="form-control" formControlName="Whatsapp" placeholder="https://www.whatsapp.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Facebook URL:</label>
                                        <input type="text" class="form-control" formControlName="Facebook" placeholder="https://www.facebook.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Twitter URL:</label>
                                        <input type="text" class="form-control" formControlName="Twitter" placeholder="https://twitter.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Youtube URL:</label>
                                        <input type="text" class="form-control" formControlName="Youtube" placeholder="https://www.youtube.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Google Plus:</label>
                                        <input type="text" class="form-control" formControlName="Google" placeholder="https://plus.google.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> LinkedIn URL:</label>
                                        <input type="text" class="form-control" formControlName="Linkedin" placeholder="https://www.linkedin.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Instagram URL:</label>
                                        <input type="text" class="form-control" formControlName="Instagram" placeholder="https://www.instagram.com/a">
                                    </div>
                                </div>
                                <div class="row content2-item form-group">
                                    <div class="social-links">
                                        <label> Pinterest URL:</label>
                                        <input type="text" class="form-control" formControlName="Pinterest" placeholder="https://in.pinterest.com/a">
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn"  (click)="save()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
            
        </div>
    </div>
</div>