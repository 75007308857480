import { Component, Output, EventEmitter } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { OnlineApplicationService } from 'src/app/services/onlineApplication.service';  
@Component({
    selector: 'app-trackingmodal',
    templateUrl: './trackingmodal.component.html',
    styleUrls: ['./trackingmodal.component.css'],
})

export class TrackingModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public status: boolean = false;
    @Output() emitService = new EventEmitter();
    public admissionDetails : any[] = []

    constructor(
        private _gService: GlobalService,
        private _admissionService: OnlineApplicationService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Application_no: ['', Validators.required],
        DOB: ['', Validators.required],
    })

    ngOnInit(): void {
        // this._gService.showLoader(true);

        // this._gService.getDataByID(this.mediumID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    checkStatus(){
        // alert('yes')
        let rObj = this.rObj.controls
        console.log(rObj.Application_no.value,rObj.DOB.value)
        this._admissionService.checkApplicationStatusData(rObj.Application_no.value,rObj.DOB.value).subscribe(aRes => {
            console.log(aRes)
            if(aRes.status == true) {
                this.status = true
                // this.modalRef.hide();
                let application_no = aRes.result
                console.log(application_no)
                this._gService.getDataByID(application_no, 'online_admissions').subscribe(res => {
                    console.log(res)
                    if(res.status === true) {
                        this.admissionDetails = JSON.parse(res.result)
                        console.log(this.admissionDetails)
                    }
                }) 
                this._router.navigate([this._router.url])

            }
        })
        // alert('yes')
        // this.status = true
    }
    // updateMedium() {
    //     if (this.rObj.valid) {
    //         let rObj = this.rObj.value;
    //         let mData = {
    //             action: 'update',
    //             id: this.mediumID,
    //             medium: rObj.Medium,
    //         }
    //         this._aService.mediumData(mData).subscribe(mRes => {
    //             console.log(mRes)
    //             this.modalRef.hide();
    //             if (mRes.status === true) {
    //                 this.modalRef.onHidden?.subscribe(() => {
    //                     this._reRoute.shouldReuseRoute = () => false;
    //                     this._router.navigate([this._router.url])
    //                     this._gService.triggerSnackbar('Medium Updated Successfully', 'success', true) 
    //                 });
    //             } else {
    //                 this._gService.triggerSnackbar('Something went wrong', 'error', true);
    //             }
    //         }, err => {
    //             console.log(err);
    //             this._gService.triggerSnackbar('Server Error!', 'error', true);
    //         })
    //     }
    // }
    
}