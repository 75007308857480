<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Online Admission</h1>
        <div class="col-md-6 form-btn-wrapper">
            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
        </div>
    </div>
    <form [formGroup]="rObj">
        <div class="row">
            <div [ngClass]="rObj.controls.Online_admission.value == '1' ? 'col-md-7' : 'col-md-12'">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            Online Admission Form Settings
                        </div>
                    </div>
                    <div class="panel-body">
                        
                            <div class="row form-group">
                                <label [ngClass]="rObj.controls.Online_admission.value == '1' ? 'col-md-6' : 'col-md-3'">Online Admission</label>
                                <label class="switch" [ngClass]="rObj.controls.Online_admission.value == '1' ? 'col-md-6' : 'col-md-9'">
                                    <input type="checkbox" value="1" formControlName="Online_admission">
                                    <span class="slider round"></span>		                        
                                </label>
                            </div>
                            <div class="row form-group" *ngIf="rObj.controls.Online_admission.value == '1'">
                                <label class="col-md-6">Online Admission Payment Option</label>
                                <label class="switch col-md-6">
                                    <input type="checkbox" value="1" formControlName="Online_admission_pay">
                                    <span class="slider round"></span>		                        
                                </label>
                            </div>
                            <div class="form-group" *ngIf="rObj.controls.Online_admission_pay.value == '1'">
                                <label>Online Admission Form Fees</label>
                                <input type="text" class="form-control" formControlName="Fees">
                                <div *ngIf="rObj.controls.Fees.invalid && (rObj.controls.Fees.dirty || rObj.controls.Fees.touched)">
                                    <p class="input-error-msg">Please enter fees</p>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="rObj.controls.Online_admission.value == '1'">
                                <label>Attach Document</label>
                                <div class="imgUpload-wrapper">
                                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="fileSrc">Remove Image</div>  
                                    <input type="file" formControlName="Document" class="form-control" (change)="onFileChange($event)">
                                </div>
                            </div>

                            <div class="form-group" *ngIf="rObj.controls.Online_admission.value == '1'">
                                <label>Online Admission Instructions</label>
                                <angular-editor [config]="editorConfig" formControlName="Instructions" class="form-control"></angular-editor>
                                <!-- <textarea formControlName="Content" class="form-control" rows="15" required=""></textarea> -->
                            </div>
                            <div class="form-group" *ngIf="rObj.controls.Online_admission.value == '1'">
                                <label>Terms & Conditions </label>
                                <angular-editor [config]="editorConfig" formControlName="Terms_Condition" class="form-control"></angular-editor>
                                <!-- <textarea formControlName="Content" class="form-control" rows="15" required=""></textarea> -->
                            </div>   
                    </div>
                </div>
            </div>
            <div class="col-md-5" *ngIf="rObj.controls.Online_admission.value == '1'">
                <div class="panel">
                    <div class="panel-header">
                        <div id="panel_title" class="panel-title">
                            Online Admission Form Fields
                        </div>
                    </div>
                    <div class="panel-body">
                            <ng-container formArrayName="inputList">
                                <div class="control-box-wrapper">

                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Class</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="1" [checked]="selectedFields.includes('1')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">First name</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="2" [checked]="selectedFields.includes('2')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Last name</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="3" [checked]="selectedFields.includes('3')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Birth date</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="4" [checked]="selectedFields.includes('4')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group" style="margin-bottom: 5px">
                                        <label class="col-md-6">Gender</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="5" [checked]="selectedFields.includes('5')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Mobile no</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="6" [checked]="selectedFields.includes('6')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Email</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="7" [checked]="selectedFields.includes('7')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Permanent address</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="8" [checked]="selectedFields.includes('8')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Current address</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="9" [checked]="selectedFields.includes('9')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Father name</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="10" [checked]="selectedFields.includes('10')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Mother name</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="11" [checked]="selectedFields.includes('11')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian is</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="12" [checked]="selectedFields.includes('12')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian name</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="13" [checked]="selectedFields.includes('13')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian relation</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="14" [checked]="selectedFields.includes('14')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian number</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="15" [checked]="selectedFields.includes('15')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian email</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="16" [checked]="selectedFields.includes('16')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian occupation</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="17" [checked]="selectedFields.includes('17')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>  
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian Photo</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="18" [checked]="selectedFields.includes('18')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">Guardian address</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="19" [checked]="selectedFields.includes('19')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div> 
                            
                                    <div class="row form-group " style="margin-bottom: 5px">
                                        <label class="col-md-6">PhotoUpload</label>
                                        <label class="switch col-md-6">
                                            <input type="checkbox" [value]="20" [checked]="selectedFields.includes('20')" (change)="onFieldSelect($event)">
                                            <span class="slider round"></span>		                        
                                        </label>
                                    </div>
                                </div>
                            </ng-container>  
                    </div>
                </div>
            </div>
        </div>
    </form> 
</div>