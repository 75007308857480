import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { OnlineApplicationService } from 'src/app/services/onlineApplication.service';
import { CmsService } from 'src/app/services/cms.service';
import { InstService } from 'src/app/services/inst.service';
import { SettingsService } from 'src/app/services/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingModalComponent } from 'src/app/components/modal/trackingmodal/trackingmodal.component';
import { RazorpayPG } from 'src/app/services/razorpay-pg.service';

@Component({
    selector: 'app-components-admissionForm',
    templateUrl: './admissionForm.component.html',
    styleUrls: ['./admissionForm.component.css']
})

export class AdmissionFormComponent implements OnInit {
    public instSlug: string = this._cmsService.getInstSlug();

    public instID : any;
    public activeAcademicID : any;
    public online_pay : any;
    public fees : any;
    public application_id : number;
    public ref_no : number;
    public activeSession : number;

    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public routeData: any[] = [];
    public hosteldetailsData: any[] = [];
    public hostelRoomsTypeData: any[] = [];
    public studentData: any[] = [];
    public countriesData: any[] = [];
    public admissionDetails: any[] = this._razorPay.admissionDetails;
    
    public searchData: any[] = [];
    public imgURL = this._gService.getImgPath();

    public siblingIds : any[] = [];
    public siblingData : any[] = [];

    public allowedFields: any[] = [];

    imageSrc:any = '';
    status:boolean = false;

    image2Src:any = '';
    status2:boolean = false;

    showConfirmation = this._razorPay.showConfirmation
    showAcknowlegement: boolean = false;
    modalRef: BsModalRef;
    

    // public basicTab: boolean = true;
    // public addressTab: boolean = false;
    // public parentTab: boolean = false;
    // public documentTab: boolean = false;
    // public siblingsTab: boolean = false;
    // public otherTab: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _admissionService: OnlineApplicationService,
        private _cmsService: CmsService,
        private _instService: InstService,
        private _sService: SettingsService,
        private _razorPay: RazorpayPG,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,

    ) {}

    
    public rObj = this._fBuilder.group({
        inputList : this._fBuilder.array([
            this._fBuilder.group({
                Class: [''], 
                First_name: [''],
                Last_name: [''],
                Birth_date: [''],
                Gender: [''],
                Mobile_no: ['', [Validators.pattern("[0-9 ]{10}")]],
                Email: ['', [Validators.email]],
                Permanent_address: [''],
                Current_address: [''],
                Father_name:[''],
                Mother_name:[''],
                Guardian_is: ['father'],
                Guardian_name: [''],
                Guardian_relation: [''],
                Guardian_number:['',Validators.pattern("[0-9 ]{10}")],
                Guardian_email: [''],
                Guardian_occupation:[''],
                Guardian_photo: [''],
                Guardian_address: [''],
                PhotoUpload:['']
            })
        ])
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        console.log(this.showConfirmation)
        console.log(this.admissionDetails)

        console.log(this.instSlug)

        this._sService.getMultipleSettingsSlugData(this.instSlug, 'application_fields,online_admission_pay,fees,activeSession').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                let settingData = JSON.parse(sRes.result)[0]
                this.allowedFields = settingData.application_fields
                this.online_pay = settingData.online_admission_pay
                this.fees = settingData.fees
                this.activeSession = settingData.activeSession
                console.log(this.allowedFields)
                console.log(this.activeSession)

                this._instService.getInstListBySlug(this.instSlug).subscribe(instRes => {
                    console.log(instRes)
                    if (instRes.status === true) {
                        this.instID = JSON.parse(instRes.result)[0].ID
                        console.log(this.instID)
                        this._gService.getAllDataByInstID(this.instID, this.activeSession, 'class').subscribe(cRes => {
                            console.log(cRes)
                            if (cRes.status === true) {
                                this.classData = JSON.parse(cRes.result);
                                console.log(this.classData)
                            } else {
                                this._gService.triggerSnackbar('No Data Found!', 'error', true);
                            }
                        this._gService.showLoader(false);
                
                        })
                    }
                })
            }
        })
        console.log(this.rObj.controls.inputList.controls[0].get('Class'))


       
        console.log(this.instID)
    }

    openStatusModal() {
        this.modalRef = this.modalService.show(TrackingModalComponent, {
            class: 'modal-dialog-centered tracking-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
            } 
        });
    }


    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)       
    }

    removeImg() {
        this.imageSrc = '';
    }
    onFile2Change(event:any) {
        this.status2 = false
        const file = event.target.files[0];
        this.status2 = event.target.files.length > 0 ? true : false;
        if (this.status2 == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.image2Src = reader.result;   
                 
           }
        }
        console.log(this.image2Src)       
    }

    removeDocument1() {
        this.image2Src = '';
    }
    // setTabActive(tabName: string) {
    //     if (tabName == 'basicTab') {
    //         this.basicTab = true
    //     } else {
    //         this.basicTab = false
    //     }
    //     if (tabName == 'addressTab') {
    //         this.addressTab = true
    //     } else {
    //         this.addressTab = false
    //     }
    //     if (tabName == 'parentTab') {
    //         this.parentTab = true
    //     } else {
    //         this.parentTab = false
    //     }
    //     if (tabName == 'documentTab') {
    //         this.documentTab = true
    //     } else {
    //         this.documentTab = false
    //     }
    //     if (tabName == 'siblingsTab') {
    //         this.siblingsTab = true
    //     } else {
    //         this.siblingsTab = false
    //     }
    //     if (tabName == 'otherTab') {
    //         this.otherTab = true
    //     } else {
    //         this.otherTab = false
    //     }
    // }
    Print() {
        window.print();
    }

    SubmitApplication() {
        // alert('yes')
        if (this.rObj.valid) {
            // alert('yes')
            let rObj = this.rObj.controls.inputList.at(0).value;
            console.log(rObj)
            let sData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeSession,
                section: 0,
                roll_no: 0,
                class: rObj.Class,
                first_name: rObj.First_name,
                last_name: rObj.Last_name,
                dob: rObj.Birth_date,
                gender: rObj.Gender,
                mobile: rObj.Mobile_no,
                email: rObj.Email,
                permanent_address: rObj.Permanent_address,
                current_address: rObj.Current_address,
                father_name: rObj.Father_name,
                mother_name: rObj.Mother_name,
                guardian_is: rObj.Guardian_is,
                guardian_name: rObj.Guardian_name,
                guardian_relation: rObj.Guardian_relation,
                guardian_mobile: rObj.Guardian_number,
                guardian_email: rObj.Guardian_email,
                guardian_occupation: rObj.Guardian_occupation,
                guardian_address: rObj.Guardian_address,
                photo_path_img: this.imageSrc,
                guardian_photo_img: this.image2Src,
            }
            console.log(sData)
            this._admissionService.onlineApplicationData(sData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    let appRes = sRes.result
                    this.application_id = appRes.application_id
                    this.ref_no = appRes.ref_no
                    this._gService.getDataByID(this.application_id, 'online_admissions').subscribe(res => {
                        console.log(res)
                        if(res.status === true) {
                            this.admissionDetails = JSON.parse(res.result)
                            console.log(this.admissionDetails)
                        }
                    })   
                    if (this.online_pay && this.fees) {
                        let payData = {
                            inst_name: 'Wees English High School',
                            inst_logo: 'https://image.freepik.com/free-vector/logo-sample-text_355-558.jpg',
                            payDesc: 'Admission Form Fees',
                            amt: this.fees,
                            application_id: this.application_id,
                            ref_no: this.ref_no
                        }
                        this._razorPay.payWithRazor(payData)
                    } else {
                        // this._reRoute.shouldReuseRoute = () => false;
                        this.showConfirmation = true 
                        // this._router.navigate([this._router.url]);                                    
                    }
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}