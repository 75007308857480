<div class="modal-header">
    <h5 class="modal-title">Edit Medium</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Medium Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Medium">
                <div *ngIf="rObj.controls.Medium.invalid && (rObj.controls.Medium.dirty || rObj.controls.Medium.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateMedium()">Update</button>
            </div>
        </form>
    </div>
</div>
