import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class CertificateService {
  constructor(
    private _http: HttpClient,
  ) { }

  private template = `${environment.APIURL}/certificate/getTemplates/`;
  private saveTemplate = `${environment.APIURL}/certificate/addUpdate/`;
//   private hostelRoomsType = `${environment.APIURL}/hostel/hostelRoomType/`;
 
  
templatesData(): Observable<any> {
    return this._http.get(this.template, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
templateSaveData(data: object): Observable<any> {
  return this._http.post(this.saveTemplate, data, {
    headers: { 'Content-Type': 'application/json' }
  });
}
  
}