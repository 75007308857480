<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Media</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <!-- <a href="../../class/add/" class="content-header-link">Add Medium</a> -->
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn"  (click)="openPanel()">Upload</button>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel" *ngIf="openpanel">
                <div class="panel-header">
                    <div class="panel-title">
                       Select Images
                       <span class="closepanel-icon" (click)="closePanel()"><i class="fa-solid fa-xmark"></i></span>
                     </div>
                </div>
                <div class="panel-body">
                    <div class="media-upload-wrapper">
                        <div class="media-upload-form">
                            <input class="form-control" type="file" multiple="true" (change)="onImageSelected($event)">
                            <button class="btn primary-btn small-btn" (click)="uploadImgs()">Upload Image(s)</button>
                        </div>
                        <div class="preview-imgs-wrapper">
                            <div class="preview-img-wrapper" *ngFor="let item of allFiles[0]">
                                <ng-container *ngIf="imgTypes.includes(item.filetype.split('/').pop())">
                                    <img [src]="item.file" class="preview-img">
                                </ng-container>
                                <ng-container *ngIf="fileTypes.includes(item.filetype.split('/').pop())">
                                    <ng-container *ngFor="let fileicon of fileTypes">
                                        <img [src]="'/assets/img/icons/filetype-icons/'+fileicon+'.png'" class="preview-img" *ngIf="fileicon == item.filetype.split('/').pop()">
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!imgTypes.includes(item.filetype.split('/').pop()) && !fileTypes.includes(item.filetype.split('/').pop())">
                                    <img src="/assets/img/icons/filetype-icons/file.png" class="preview-img">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Media 
                    </div>
                </div>
                <div class="panel-body">
                    <div class="media-wrapper">
                        <div class="media-item-wrapper">
                            <div class="media-item"*ngFor="let media of mediaData" (click)="openMediaModal(media.ID)"> 
                                <div class="media-img-wrapper">
                                    <ng-container *ngIf="imgTypes.includes(media.filetype)">
                                        <img [src]="imgURL+media.filename" class="media-img">
                                    </ng-container>
                                    <ng-container *ngIf="fileTypes.includes(media.filetype)">
                                        <ng-container *ngFor="let fileicon of fileTypes">
                                            <img [src]="'/assets/img/icons/filetype-icons/'+fileicon+'.png'" class="media-img media-icon" *ngIf="fileicon == media.filetype" width="100%" height="125px">
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!imgTypes.includes(media.filetype) && !fileTypes.includes(media.filetype)">
                                        <img src="/assets/img/icons/filetype-icons/file.png" class="media-img media-icon" width="100%" height="125px">
                                    </ng-container>
                                </div>
                                <h5 class="media-name">{{media.filetitle}}</h5>
                            </div>  
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
       
    </div>
        
</div>