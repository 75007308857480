import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HostelService } from 'src/app/services/hostel.service';
import { ClassModalComponent } from 'src/app/components/modal/classmodal/classmodal.component';
import { HostelDetailsmodalComponent } from 'src/app/components/modal/hostelDetailsmodal/hostelDetailsmodal.component';

@Component({
    selector: 'app-hostel-hostelDetails',
    templateUrl: './hostelDetails.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class hostelDetailsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public hosteldetailsData: any[] = [];
    public RoomtypeData: any[] = [];
    // public mediumData: any[] = [];

    public sectionValue: '';

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: HostelService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        HostelName: ['', Validators.required],
        Room_Type: ['', Validators.required],
        Address: ['', Validators.required],
        Phone_no: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
        Capacity: ['', Validators.required],
        Warden_name: ['', Validators.required],
        Warden_address: ['', Validators.required],
        Warden_phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.hosteldetailsData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.showLoader(false);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room_type').subscribe(rRes => {
            console.log(rRes)
            if (rRes.status === true) {
                this.RoomtypeData = JSON.parse(rRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    // getSectionName(sectionID: number) {
    //     this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
    //         if (sRes.status === true) {
    //             let sData = JSON.parse(sRes.result)[0];
    //             return sData
    //         }
    //     })
    // }

    openHostelDetailsEditModal(hostelDetailID: number ) {
        this.modalRef = this.modalService.show(HostelDetailsmodalComponent, {
            class: 'modal-dialog-centered hosteldetail-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                hostelDetailID: hostelDetailID
            } 
        });
    }//remianing

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveDetails() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                name: rObj.HostelName,
                type: rObj.Room_Type,
                address: rObj.Address,
                phone_no: rObj.Phone_no,
                capacity: rObj.Capacity,
                warden_name: rObj.Warden_name,
                warden_address: rObj.Warden_address,
                warden_phone_no: rObj.Warden_phone
                
            }
            console.log(ayData)
            this._aService.hosteldetailsData(ayData).subscribe(hRes => {
                console.log(hRes)
                if (hRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Hostel details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}