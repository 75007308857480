<div class="modal-header">
    <h5 class="modal-title">Edit Book Category</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Category Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Name" value="">
                <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                    <p class="input-error-msg">Please enter category name</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateCategory()">Update</button>
            </div>
        </form> 
    </div>
</div>
