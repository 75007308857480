<div class="modal-header">
    <h5 class="modal-title">Edit Event Type</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Event Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Event">
                <div *ngIf="rObj.controls.Event.invalid && (rObj.controls.Event.dirty || rObj.controls.Event.touched)">
                    <p class="input-error-msg">Please enter Event name</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateEvent()">Update</button>
            </div>
        </form>
    </div>
</div>
