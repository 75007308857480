<div class="modal-header" #FeeReceiptWrapper1>
    <h5 class="modal-title">Pay Slip</h5>
    <button type="submit" class="btn primary-btn small-btn" style="margin-right: 0px !important;" (click)="print()">Print</button>
    <button type="button" class="btn-close" style="margin-left: 20px;" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper" #FeeReceiptWrapper>
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Freeman&family=Poetsen+One&display=swap" rel="stylesheet">
            <title>Document</title>
        </head>
        <body>
            <div class="main" id="FeeReceiptWrapper"  *ngFor="let s of payrollData">
                <div class="headers">
                    <img class="head" [src]="imgURL+PayslipHeader">
                    <!-- <div class="head">
                        <div class="logo">
                            <img id="logo" src="/assets/img/download.png" data-src="/assets/img/download.png" width="50%">
                        </div>
                        <div class="text">
                            <div class="text1 freeman-regular"><h1 id="schoolname" data-name="UVAAN MODEL SCHOOL">UVAAN MODEL SCHOOL</h1></div>
                            <div class="text2 Freeman"><h4 id="detail" data-name="(Recognized by the Govt. of T.S)">(Recognized by the Govt. of T.S)</h4></div>
                            <div class="text3 freeman-regular"><h3 id="standardmedium" data-name="Nursery to X Class | English Medium">Nursery to X Class | English Medium</h3></div> 
                        </div>
                    </div>
                    <div class="address">
                        <h4 id="address" data-name="Above Bank of Baroda, Bhagyalata Bus Stop, Mayasagar, R.R Dist, Hyderabad - 5012540">Above Bank of Baroda, Bhagyalata Bus Stop, Mayasagar, R.R Dist, Hyderabad - 5012540</h4>
                    </div> -->
                </div>
                <div class="center">
                    <div class="text4 freeman-regular"><h1>Payslip Receipt</h1></div>
                    <div class="info">
                        <ng-container>
                            <h3>No.</h3>:<h4>{{s.ID}}</h4>
                        </ng-container>
                        <h3 style="margin-left: 55%;">Date</h3>:<h4>{{s.payment_date}}</h4>
                    </div>
                    <div class="details">
                        <div class="text5">
                            <h3>Name</h3>
                            <div class="dotLine1">
                                <h3 id="student_name" data-name="">{{staff_name}}</h3>
                            </div>
                        </div>
                        <div class="class">
                            <div class="text6">
                                <h3>Class</h3>
                                <div class="dotLine2">
                                    <h4 id="student_class_section" data-name="">
                                        {{departmentName}} / {{designationName}}
                                    </h4>
                                </div>
                            </div>
                            <div class="text7">
                                <h3>Month / Year</h3>
                                <div class="dotLine2">
                                    <h4 id="term_month" data-name=""> 
                                        {{monthYear}}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead >   
                                <tr class="line">   
                                    <th>Particulars</th>
                                    <th colspan="2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let ftName of feeTypeNames;let i = index">
                                    <td >{{ftName}}</td>
                                    <td>{{feeTypeAmts[i]}}</td>
                                </tr> -->
                                <!-- <tr>
                                    <td>2.Exam Fee</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>3.Special Fee</td>
                                    <td></td>
                                </tr> -->
                                <tr class="line">
                                    <td>Payment Mode</td>
                                    <td>{{s.payment_mode}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Basic Salary</td>
                                    <td>{{s.basic}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Earnings</td>
                                    <td>{{s.earning ? s.earning : '-'}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Deduction</td>
                                    <td>{{s.deduction ? s.deduction : '-'}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Gross Salary</td>
                                    <td>{{s.gross}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Tax</td>
                                    <td>{{s.tax}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Net Salary</td>
                                    <td>{{s.net}}</td>
                                </tr>
                            </tbody>
                            
                        </table>
                         <!-- <div class="text8"> Please pay fee before 10th of every month</div> -->
                        <div class="sign">
                            <h4>{{PayslipFooter}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            
        </body>
        </html>
    </div>
</div>
