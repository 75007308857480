import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<any>();
  public loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show(visible: boolean) {
    this.loaderSubject.next({
      visible
    });
  }
}