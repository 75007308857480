import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-institution-add',
  templateUrl: './add.component.html'
})

export class institutionAddComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userID = this.userData.UserID;
    dtOptions: DataTables.Settings = {};

    imageSrc:any = '';
    status:boolean = false

    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _adminService: AdminService,
        private _instService: InstService,
        private _router: Router
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Address: ['', Validators.required],
        Branch: [''],
        Pincode: ['', [Validators.required]],
        Country: ['', Validators.required],
        Email: ['', [Validators.required, Validators.email ]],//Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        Phone: ['', [Validators.required, Validators.pattern(/^(6|7|8|9)(\d{9})$/)]],
        Fax: [''],
        Is_Vernacular: [''], 
        Is_Multi_branch: [''], 
        Currency:[{value: 'INR', disabled: true}],
        Language:[{value: 'English', disabled: true}],
        Start_date: [''], 
        Renewal_date: [''], 
        Update_date: [''], 
        Secret_Key: [{value: this.secretKeyGenerater(48), disabled: true}], 
        Subscription_period: [''], 
        Allowed_users: [''], 
        Subscription_Cost: [''], 
        Total_cost: [''], 
        First_name: [''], 
        Last_name: [''], 
        Admin_Email: [''], 
        
    })

    ngOnInit(): void {}

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    copyInputMessage(inputElement: any){
       if(navigator.clipboard.writeText(inputElement)) {
            this._gService.triggerSnackbar('Copied', 'success', true)
       }
        
    }

    secretKeyGenerater(length: number) {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; 
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    addInst() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            console.log(this.rObj.controls.Secret_Key.value)
            let instData = {
                action: 'save',
                inst_name: rObj.Name,
                isMultiBranch: rObj.Is_Multi_branch,
                isVernacular: rObj.Is_Vernacular,
                inst_address: rObj.Address,
                inst_branch: rObj.Branch,
                inst_pincode: rObj.Pincode,
                inst_country: rObj.Country,
                inst_email: rObj.Email,
                inst_phone: rObj.Phone,
                inst_fax: rObj.Fax,
                inst_currency: "INR",
                inst_language: "English",
                inst_key: this.rObj.controls.Secret_Key.value,
                start_date: rObj.Start_date,
                renewal_date: rObj.Renewal_date,
                update_date:rObj. Update_date,
                subscription_period: rObj.Subscription_period,
                allowed_users: rObj.Allowed_users,
                per_user_cost: rObj.Subscription_Cost,
                total_cost: rObj.Total_cost,
                icon_path: this.imageSrc,
                firstname: rObj.First_name,
                lastname: rObj.Last_name,
                email: rObj.Admin_Email
            }
    
            console.log(instData)
            this._adminService.addInstData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._router.navigate(['/admin/institution/list']);
                    this._gService.triggerSnackbar('Institution Added Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
}