import { Component } from '@angular/core';
import { Router, RouteReuseStrategy ,ActivatedRoute} from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
  
@Component({
    selector: 'app-academicyearsmodal',
    templateUrl: './academicyearsmodal.component.html',
    styleUrls: [],
})

export class AcademicyearsModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public academicyearsID: number;
    public academicYearsData: any[] = [];

    currentYear: number = new Date().getFullYear();
    years: number[] = [];
    currentMonth: number = new Date().getMonth() + 1;
    months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        StartYear: [this.currentYear, Validators.required],
        StartMonth: [this.currentMonth, Validators.required],
        EndYear: [this.currentYear, Validators.required],
        EndMonth: [this.currentMonth, Validators.required],
    })

    ngOnInit(): void {

        let yearsList = this.currentYear + 5;
        
        for (let year = yearsList; year >= 2020; year--) {
            this.years.push(year);
        }
        this._gService.showLoader(true);
        this._gService.getDataByID(this.academicyearsID, 'academic_years').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let instData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    StartYear: instData[0].start_year,
                    StartMonth: instData[0].start_month,
                    EndYear: instData[0].end_year,
                    EndMonth: instData[0].end_month,
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateAcademicYear() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'update',
                id: this.academicyearsID,
                start_year: rObj.StartYear,
                start_month: rObj.StartMonth,
                end_year: rObj.EndYear,
                end_month: rObj.EndMonth
            }
            this._sService.saveAcademicYearData(ayData).subscribe(ayRes => {
                console.log(ayRes)
                this.modalRef.hide();
                if (ayRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/settings/academic-years']);
                    this._gService.triggerSnackbar('Academic Year Updated Successfully', 'success', true);
                });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}