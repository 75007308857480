import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { SelectModalComponent } from 'src/app/components/modal/selectmodal/selectmodal.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-enpcms-editPage',
  templateUrl: './editPage.component.html',
})

export class EditPageComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageGallerySrc: any = '';
    selectedGalleryImgs: any = '';
    galleryStatus:boolean = false;
    imageSrc:any = '';
    selectedImgSrc: any = '';
    featuredImgType: string = '';
    status:boolean = false;
    remove:boolean = false;
    removeGallery:boolean = false;
    public imgURL = this._gService.getImgPath();
    public pageData: any[] = [];
    public pageID: any;
    dtOptions: any = {};

    modalRef: BsModalRef;

    editorConfig: AngularEditorConfig = {
        editable: true,
        height: '500px',
        placeholder: 'Enter text here...',
    }

    constructor(
        private _gService: GlobalService,
        private _aService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Title: ['',Validators.required],
        Content: ['',Validators.required],
        Meta_title: [''],
        Meta_desc:[''],
        Meta_keyword:[''],
        Status:['1'],
        Type:['standard'],
        Template_img:[''],
        Venue:[''],
        Start_date:[''],
        End_date:[''],
        News_date:[''],
        Gallery_img:['']
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        this.pageID = this._aRoute.snapshot.paramMap.get('id');

        this._gService.getDataByID(this.pageID, 'cms_pages').subscribe(dataRes => {
            // this._gService.showLoader(true);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                console.log(sData);
                this.rObj.patchValue({
                    // Medium: sData[0].name,
                    Title: sData[0].title,
                    Content: sData[0].content,
                    Meta_title: sData[0].meta_title,
                    Meta_desc: sData[0].meta_desc,
                    Meta_keyword: sData[0].meta_keywords,
                    Status: sData[0].status,
                    Type: sData[0].type,
                    // Template_img:sData[0].featured_img,
                    Venue:sData[0].venue,
                    Start_date:sData[0].start_date,
                    End_date:sData[0].end_date,
                    News_date:sData[0].news_date,
                    Gallery_img:sData[0].gallery_images, 
                });
                this.imageSrc = sData[0].featured_img ? this.imgURL+sData[0].featured_img : '';
                this.selectedImgSrc = sData[0].featured_img_file ? sData[0].featured_img_file : '';
                this.selectedGalleryImgs = sData[0].gallery_images ? JSON.parse(sData[0].gallery_images) : '';
            }
            console.log(this.selectedImgSrc)
            this._gService.showLoader(false);
        })
        // this.dtOptions = {
        //     pagingType: 'numbers',
        //     order: [],
        //     columnDefs: [{
        //         targets: 'no-sort',
        //         orderable: false,
        //     }],
        //     dom: 'fBrtip',
        //     buttons: [
        //         'copy', 'excel', 'csv', 'pdf', 'print',
        //     ],
        //     language: { search: '', searchPlaceholder: "Search..." },
        // };
        this._gService.showLoader(false);
    }

    
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
              this.featuredImgType = 'upload'       
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.remove = true;
        this.featuredImgType = '';   
        this.imageSrc = '';
    }
    removeSelectedImg() {
        this.featuredImgType = '';   
        this.selectedImgSrc = '';
    }
    removeSelectedGalleryImg() {
        this.removeGallery = true;
        this.featuredImgType = '';   
        this.selectedGalleryImgs = '';
    }

    delGalleryImg(fileName: any) {
        this.selectedGalleryImgs.splice(this.selectedGalleryImgs.indexOf(fileName), 1)
    }

    openMediaModal(type: string){
        this.modalRef = this.modalService.show(SelectModalComponent, {
            class: 'modal-dialog-centered media-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                type: type,
            } 
        });

        this.modalRef.content.selectedMedias.subscribe((data: any) => {
            if (data.type == 'gallery') {
                this.selectedGalleryImgs = data.filename;
            } else {
                this.selectedImgSrc = data.filename;
                // console.log(this.selectedImgSrc)
            }
            this.featuredImgType = 'select'   
          });
      }
    gotoUploadData(img : String){
        this.modalRef = this.modalService.show(SelectModalComponent, {
            class: 'modal-dialog-centered media-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            // initialState : {
            //     // selectID: pageID,
            // } 
        });

        this.modalRef.content.selectedMedias.subscribe((data: any) => {
            this.selectedImgSrc = data.filename;
            this.featuredImgType = 'select'   
          });
      }

    updatePages() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.pageID,
                // inst_id: this.instID,
                // academic_id: this.activeAcademicID,
                title: rObj.Title,
                content: rObj.Content,
                status: rObj.Status,
                meta_title:rObj.Meta_title,
                meta_desc: rObj.Meta_desc,
                meta_keywords: rObj.Meta_keyword,
                featured_img: this.imageSrc ,
                type: rObj.Type,
                venue: rObj.Venue,
                start_date: rObj.Start_date,
                end_date: rObj.End_date,
                news_date: rObj.News_date,
                gallery_images:JSON.stringify(this.selectedGalleryImgs),
                featured_img_type: this.featuredImgType,
                featured_img_file: this.selectedImgSrc
            }
            console.log(mData)
            this._aService.pagesData(mData).subscribe(ayRes => {
                this._gService.showLoader(true);
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['cms/pages']);
                    this._gService.triggerSnackbar('Pages Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
                this._gService.showLoader(false);
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}