<div class="modal-header">
    <h5 class="modal-title">Edit Expense Head </h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Expense Head</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Expense_name" >
                <div *ngIf="rObj.controls.Expense_name.invalid && (rObj.controls.Expense_name.dirty || rObj.controls.Expense_name.touched)">
                    <p class="input-error-msg">Please enter Expense name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateExpenseHead()">Update</button>
            </div>
        </form> 
    </div>
</div>
