import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';

@Component({
    selector: 'app-dailyCollectionModal',
    templateUrl: './dailyCollectionModal.component.html',
    // styleUrls: ['./dailyCollectionModal.component.css'],
})

export class dailyCollectionModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public date: number;
    public dailyDateCollectionData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    
    dtOptions: any = {};


    constructor(
        private _gService: GlobalService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}


    ngOnInit(): void {
        this._gService.showLoader(true);

        this._rService.dailyDateCollectionData(this.instID, this.activeAcademicID, this.date ).subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                this.dailyDateCollectionData =JSON.parse(dataRes.result)
            }
            this._gService.showLoader(false);
        })
    
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

   
}