import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { FeesService } from 'src/app/services/fees.service';
import * as moment from 'moment';
import { printReceiptModalComponent } from '../printReceiptmodal/printReceiptmodal.component';
@Component({
    selector: 'app-multiplePaymodal',
    templateUrl: './multiplePaymodal.component.html',
    styleUrls: [],
})

export class MultiplePayModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public fmIds: any[] = [];
    public feeDataArray: any[] = [];
    public feesDataArray: any[] = [];
    public due_date: any;
    public feeID: any;
    
    public totalAmount: any;
    public studentId: number;
    public amount: number;
    public fine_type: number;
    public fine_amount: number;
    public discount_id : number;
    public typeId : any;
    public fee_group : any;
    private disabledStatus : boolean;
    public rObj : any;
    public isDue : any;
    public finalAmt : number = 0; 
    public discount_amt : number = 0;
    public feeDiscountData: any[] = [];
    public statusData: any[] = [];
    public total_amt_final: number =0
    public now = new Date()
    public  today = moment(this.now).format('YYYY-MM-DD')

    public discount_applied : boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _feeService: FeesService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private modalService: BsModalService,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public sObj = this._fBuilder.group({
        feesArray : this._fBuilder.array([])
    })
    
    feesArray(): FormArray {
        return this.sObj.get("feesArray") as FormArray
    }


    ngOnInit(): void {
        this._gService.showLoader(true);
        this.getFinalAmount()
        console.log(this.feeDataArray)

        let feesDataArray = <FormArray>this.sObj.controls.feesArray;
        this.feeDataArray.forEach((f, index) => {
            // console.log
            feesDataArray.controls =  f.map(fee => this._fBuilder.group({
                        fees_master_ids: fee.fees_master_ids,
                        fees_group_id: fee.fees_group,
                        amount: fee.amount, 
                        fine_amount: fee.fine_amount,
                        fine_type: fee.fine_type, 
                    }))
                    // console.log( feesDataArray.controls)
                    // console.log( .value)
                    let sObj = this.sObj.controls.feesArray.controls;
                    for (let i = 0; i < sObj.length; i++) {
                        this.feesDataArray.push(sObj[i].value)
                    }
            })
                    console.log( this.feesDataArray)

        // let DataArray
        // DataArray =+ feesDataArray.controls
        // console.log(DataArray)

        // this.feesDataArray = this.feeDataArray.map(f => this._fBuilder.group({
        //     fees_master_ids: [f.fees_master_ids],
        //     fees_group_id: [f.fees_group_id],
        //     amount: [f.amount], 
        //     fine_amount: [f.fine_amount],
        //     fine_type: [f.fine_type], 
        // }))

        // this.feesDataArray = [this.feeDataArray[0].fees_master_ids, this.feeDataArray[0].fees_group_id, this.feeDataArray[0].amount, this.feeDataArray[0].fine_amount, this.feeDataArray[0].fine_type]
        // console.log(this.feeDataArray)

        this.disabledStatus = true;
         this.rObj = this._fBuilder.group({
            Date: ['', Validators.required],
            Payment_mode: ['cash', Validators.required],
            Note: [''],
        })

       

        // this.dueCheck(this.due_date)
        // this.totalAmount = this.isDue ? this.totalAmount : this.amount
        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_discount').subscribe(lRes => {
        //     console.log(lRes)
        //     if (lRes.status === true) {
        //         this.feeDiscountData = JSON.parse(lRes.result);
        //         this.rObj.patchValue({
        //             // Discount_code: this.feeDiscountData[0].discount_code,
        //             // Discount_amount: this.feeDiscountData[0].discount_amount,
        //             Date: this.today
        //         })
                

        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
            // this._gService.showLoader(false);
        // })
       
            this._feeService.getStatusData(this.studentId).subscribe( res => {
                console.log(res)
                if(res.status === true){
                    this.statusData = JSON.parse(res.result);
                }
                console.log(this.statusData)
            })
            this._gService.showLoader(false);
        // })
    }

    getFinalAmount() {
        let amts = this.feeDataArray.map(f => f[0].total_amount)
        let fine_types = this.feeDataArray.map(f => f[0].fine_type)
        let fine_amts = this.feeDataArray.map(f => f[0].fine_amount)
        let percentage_amts = this.feeDataArray.map(f => f[0].percentage)
        let due_date = this.feeDataArray.map(d => d[0].due_date)
        console.log(due_date)
        let total_amt = 0
        amts.forEach((amt, index) => {
            if(this.dueCheck(due_date[index]) == true) {
                // alert('due')
                total_amt = fine_types[index] == 'fix' ? parseInt(amt) + parseInt(fine_amts[index]) : parseInt(amt) + (parseInt(amt) * parseInt(percentage_amts[index])) / 100     
                console.log(total_amt)        
              } else {
                total_amt = parseInt(amt)
              }
              this.total_amt_final = this.total_amt_final + total_amt
              console.log(this.total_amt_final)
              
        })

    }

    dueCheck(date: any){
        let now = new Date()
        let  today = moment(now).format('YYYY-MM-DD')
        if(today > date) {
            return true
        } else {
            return false
        }
    }

    openReceiptModal(TxnID : any) {
        this.modalRef = this.modalService.show(printReceiptModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                TxnID: TxnID,
            } 
        });
    }
    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_ids.split(',').includes(feesId)).map(s => s.txn_id)
    }
    
    View() {}
    PayAndPrint() {
        this.Pay()
        this.openReceiptModal(this.getTxnId(this.feeID))
    }

    Pay() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            // let sObj = this.sObj.controls.feesArray.controls;
            // for (let i = 0; i < sObj.length; i++) {
            //     this.feesDataArray.push(sObj[i].value)
            //   }
            let mData = {
                inst_id : this.instID ,
                academic_id : this.activeAcademicID,
                student_id : this.studentId,
                payment_mode: rObj.Payment_mode,
                payment_date: rObj.Date,
                note: rObj.Note,
                feesData: this.feesDataArray
            }
            // alert(mData)
            console.log(mData)
            this._feeService.multiPayFeeData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Payment Saved  Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}