<div class="modal-header">
    <h5 class="modal-title">Edit Room Type</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label> Room Type</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Room_type">
                <div *ngIf="rObj.controls.Room_type.invalid && (rObj.controls.Room_type.dirty || rObj.controls.Room_type.touched)">
                    <p class="input-error-msg">Please enter room type</p>
                </div>
            </div>

            <div class="form-group">
                <label>Description</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" autocomplete="off" formControlName="Description"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter description</p>
                </div>
            </div>

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateRoomType()">Save</button>
            </div>
        </form>
    </div>
</div>
