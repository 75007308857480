import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class HostelService {
  constructor(
    private _http: HttpClient,
  ) { }

  private hostelDetails = `${environment.APIURL}/hostel/hostelDetails/`;
  private hostelRooms = `${environment.APIURL}/hostel/hostelRooms/`;
  private hostelRoomsType = `${environment.APIURL}/hostel/hostelRoomType/`;
 
  hosteldetailsData(data: object): Observable<any> {
    return this._http.post(this.hostelDetails, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  hostelRoomsData(data: object): Observable<any> {
    return this._http.post(this.hostelRooms, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  hostelRoomsTypeData(data: object): Observable<any> {
    return this._http.post(this.hostelRoomsType, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}