import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StaffService } from 'src/app/services/staff.service';
import { LeaveModalComponent } from 'src/app/components/modal/leavemodal/leavemodal.component';
import { AddLeavemodalModalComponent } from 'src/app/components/modal/addLeavemodal/addLeavemodal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-staff-approveLeave',
  templateUrl: './approveLeave.component.html',
})

export class approveLeaveComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public allLeaveData: any[] = [];
    public leaveTypeData: any[] = [];
    public userRoleData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
      Name: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leaves').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.allLeaveData = JSON.parse(lRes.result);
                console.log(this.allLeaveData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leave_type').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.leaveTypeData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getUserRoleData().subscribe(res =>{
            if (res.status === true) {
                this.userRoleData = JSON.parse(res.result)
                console.log(this.userRoleData)
            }
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openAddLeaveEditModal(){
        this.modalRef = this.modalService.show(AddLeavemodalModalComponent, {
            class: 'modal-dialog-centered leave-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false
        });
    }

    openApproveLeaveEditModal(approveleaveID: number, action: string) {
        this.modalRef = this.modalService.show(AddLeavemodalModalComponent, {
            class: 'modal-dialog-centered leave-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                action : action,
                leavesID: approveleaveID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    getUserRoleName(roleID : any){
        // console.log(roleID)
        let a =this.userRoleData.filter(r => r.ID == roleID).map(r => r.name)
        // console.log(a)
        return a
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

}