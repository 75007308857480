<div class="modal-header">
    <h5 class="modal-title">Change Password</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Password</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Password">
                <div *ngIf="rObj.controls.Password.invalid && (rObj.controls.Password.dirty || rObj.controls.Password.touched)">
                    <p class="input-error-msg">Please enter Password </p>
                </div>
            </div>
            <div class="form-group">
                <label>Confirm Password</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Confirm_Password">
                <ng-container *ngIf="rObj.controls.Confirm_Password.dirty || rObj.controls.Confirm_Password.touched">
                    <!-- <p class="input-error-msg">Please enter confirm password </p> -->
                    <div *ngIf="rObj.controls.Password.value != rObj.controls.Confirm_Password.value">
                        <p class="input-error-msg">Enter above same Password </p>
                    </div>
                </ng-container>
                <!-- <div *ngIf="rObj.controls.Confirm_Password.invalid && (rObj.controls.Confirm_Password.dirty || rObj.controls.Confirm_Password.touched) && rObj.controls.Password.value != rObj.controls.Confirm_Password.value">
                    <p class="input-error-msg">Please enter confirm password </p>
                </div> -->
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updatePassword()">Update</button>
            </div>
        </form>
    </div>
</div>
