import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
  
@Component({
    selector: 'app-applyLeavemodal',
    templateUrl: './applyLeavemodal.component.html',
    styleUrls: [],
})

export class ApplyLeavemodalModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public leaveID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();

    public leaveData: any[] = [];

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Reason: ['', Validators.required],
        Apply_date: ['', Validators.required],
        Leave_from_date: ['', Validators.required],
        Leave_to_date: ['', Validators.required],
        Leave_type:['',Validators.required],
        Leave_document: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leave_type').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.leaveData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.leaveID, 'leaves').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Reason: sData[0].reason,
                    Apply_date: sData[0].apply_date,
                    Leave_from_date: sData[0].leave_from_date,
                    Leave_to_date:sData[0].leave_to_date,
                    Leave_type:sData[0].leave_type,
                });this.imageSrc = sData[0].leave_document ? this.imgURL+sData[0].leave_document : '';
            }
            this._gService.showLoader(false);
        })
    }
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }
       
    

    updateApplyLeave() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.leaveID,
                staff_id: this.userID,
                reason: rObj.Reason,
                apply_date: rObj.Apply_date,
                leave_from_date: rObj.Leave_from_date,
                leave_to_date:rObj.Leave_to_date,
                leave_type:rObj.Leave_type,
                leave_document: this.status ? this.imageSrc : '',
            }
            this._aService.applyLeaveData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Apply Leave details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}