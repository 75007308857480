import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notifications-notifications',
  templateUrl: './notifications.component.html'
})

export class NotificationsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public userRole = this.userData.UserRole;
    public userId = this.userData.UserID;
    public staffListData: any[] = [];
    public userRoleData: any[] = [];
    public notificationData: any[] = [];
    public  departmentID: any;
    public designationId: any;
    public selectedIds : any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc: any;
    public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
       
        this._nService.getNotificationData(this.instID, this.activeAcademicID, this.userRole, this.userId).subscribe(res => {
            console.log(res)
            if(res.status === true) {
                this.notificationData = JSON.parse(res.result)
                console.log(this.notificationData)
            }

        })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    getDate(date: any){
        return moment(date).fromNow()
    }
    gotoPage(link: any) {
       return this._router.navigate([link])
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.staffListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        e.stopPropagation()
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.staffListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    // getUserRoleName(roleID : any){
    //     return this.userRoleData.filter(r => r.ID == roleID).map(r => r.name)
    // }

    // gotoViewLink(staffID: number){
    //     this._router.navigate(['/staff/view/'+staffID])
    // }


    // gotoEditLink(staffID: number){
    //     this._router.navigate(['/staff/edit/'+staffID])
    // }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }

    
}