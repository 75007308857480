import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminLoginComponent } from './adminlogin.component';
import { AdminLoginRoutingModule, AdminLoginRoutes } from './adminlogin-routing.module';

@NgModule({
    declarations: [
        AdminLoginComponent,
        AdminLoginRoutes
    ],
    imports: [
        CommonModule,
        AdminLoginRoutingModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: []
})
export class AdminLoginModule { }