<div class="modal-header">
    <h5 class="modal-title">Verify OTP</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>OTP</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="OTP">
                <div *ngIf="rObj.controls.OTP.invalid && (rObj.controls.OTP.dirty || rObj.controls.OTP.touched)">
                    <p class="input-error-msg">Please enter otp </p>
                </div>
            </div>
            <a class="" (click)="resend()">Resend OTP</a>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="verify()">Activate</button>
            </div>
        </form>
    </div>
</div>
