import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClassModalComponent } from 'src/app/components/modal/classmodal/classmodal.component';
import { EditSyllabusModalComponent } from 'src/app/components/modal/editSyllabusmodal/editSyllabusmodal.component';
import { AddSyllabusModalComponent } from 'src/app/components/modal/addSyllabusmodal/addSyllabusmodal.component';
import * as moment from 'moment';
import { SyllabusService } from 'src/app/services/syllabus.service';
@Component({
    selector: 'app-academic-syllabus',
    templateUrl: './syllabus.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class SyllabusComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public medium = this.userData.activeMedium
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = []; 
    public syllabusData: any[] = [];
    public subjectData: any[] = [];
    public lDataArray: any[] = [];
    

    public sectionValue: '';

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: SyllabusService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Syllabus: ['', Validators.required],
        Class: ['', Validators.required], 
        Section: this._fBuilder.array([], Validators.required),
        Subject: ['', Validators.required], 
        Lessons: this._fBuilder.array([]),
    })


    lData(): FormArray {
        return this.rObj.get("Lessons") as FormArray
    }

    newLData(): FormGroup {
        return this._fBuilder.group({
            Name : ''
        })
    }

    addlData(){
        this.lData().push(this.newLData())
    }
    removeLData(i:number, Id:any) {
        this.lData().removeAt(i);
        // this.etDeleteIds.push(Id)
        // alert(this.etDeleteIds)
    }



    ngOnInit(): void {

        this._gService.showLoader(true);

        this.addlData()

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'syllabus').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.syllabusData = JSON.parse(cRes.result);
                console.log(this.syllabusData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.subjectData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this._gService.showLoader(false);
        })
        

        this._gService.showLoader(false);

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    stringToArray(text: string) {
        // console.log(text)
        if(text) {
            return text.split(',')
        }
       
    }

    // stringtoArray(data: any) {
    //     return JSON.parse(data);
    // }
    // stringToArray(data: any) {
    //     // console.log(JSON.parse(data).split(','))
    //     return JSON.parse(data);
    // }

    onSectionSelect(e: any) {
        const selectedSections = <FormArray>this.rObj.controls.Section;

        selectedSections?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSections.push(new FormControl(e.target.value));
        } else {
          let index = selectedSections.controls.findIndex(x => x.value == e.target.value)
          selectedSections.removeAt(index);
        }

        this.sectionValue = selectedSections.value;

        console.log(selectedSections.value)
    }

    openEditModal(syllabusID: number) {
        this.modalRef = this.modalService.show(EditSyllabusModalComponent, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                syllabusID: syllabusID
            } 
        });
    }

    openAddSyllabuModal(lessonID: any, lesson_name: any) {
        this.modalRef = this.modalService.show(AddSyllabusModalComponent, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                lessonId: lessonID,
                lesson_name: lesson_name
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveClass() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lessonData = this.rObj.controls.Lessons.controls;
            for (let i = 0; i < lessonData.length; i++) {
                this.lDataArray.push(lessonData[i].value)
            }
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                syllabus_for: rObj.Syllabus,
                class_id: rObj.Class,
                section_ids: this.sectionValue,
                subject_id: rObj.Subject, 
                lessons: this.lDataArray
            }
            console.log(ayData)
            this._sService.syllabusTitleData(ayData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Syllabus Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}