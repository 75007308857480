import { Component, OnInit, Output, EventEmitter, Directive, HostBinding, HostListener } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaModalComponent } from 'src/app/components/modal/mediamodal/mediamodal.component';
import { ImageFile } from './image.component';
@Component({
  selector: 'app-enpcms-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})

export class MediaComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public type: string;
    public selectedMedia: String;
    public selectedMediaArray: any[] = [];
    public mediaData: any[] = [];
    imageSrc: any = '';
    status:boolean = false;
    remove:boolean = false;
    openpanel:boolean = false;
    public imgURL = this._gService.getImgPath();

    uploading = false;
    selectedImages!: FileList;
    public allFiles: any[] = [];

    dtOptions: any = {};

    @Output() selectedMedias = new EventEmitter<any>();
   
    modalRef: BsModalRef;

    public imgTypes: any[] = ['png','jpg','jpeg'];
    public fileTypes : any[] = ['pdf','doc','ppt','txt','xls','zip','xml','gif','avi','css','html','js','mp3','php','svg','tif'];

    constructor(
        private _gService: GlobalService,
        private _cmsService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,

    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);
        
        this._gService.getAllDataByInstID(this.instID, 0, 'cms_media').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediaData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    
    }

    selectMedia(fileName: any){
        if (this.type == 'gallery') {
            if (!this.selectedMediaArray.includes(fileName)){
                this.selectedMediaArray.push(fileName) 
            } else {
                this.selectedMediaArray.splice(this.selectedMediaArray.indexOf(fileName), 1)
            }
            console.log(this.selectedMediaArray)
        } else {
            this.selectedMedia = fileName
        }
    }

    submitSelectedMedias(){
        this.selectedMedias.emit({type: this.type, filename: this.type == 'gallery' ? this.selectedMediaArray : this.selectedMedia});
        this.modalRef.hide()
    }
    openMediaModal(fileID: Number){
        // alert(fileID)
        this.modalRef = this.modalService.show(MediaModalComponent, {
            class: 'modal-dialog-centered media-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                fileID : fileID,
            } 
        });
    }

    removeImg() {
        this.remove = true;
        // this.featuredImgType = '';   
        this.imageSrc = '';
    }

    openPanel() {
        this.openpanel = true;
    }

    closePanel() {
        this.openpanel = false;
    }

    onImageSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;

        if (inputElement?.files && inputElement.files.length > 0) {
        this.selectedImages = inputElement.files;
        }
        let imgs = this.getImagesArray(this.selectedImages)
        console.log(imgs)
        console.log(this.allFiles)
    }

    async getImagesArray(files: FileList) {

        let promises = [];
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            promises[index] = new Promise(resolve => {
                let reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = (e) => {
                    resolve({
                        filename: element.name,
                        filetype: element.type,
                        filesize: element.size,
                        file: e.target!.result
                    });
                };
            });
        }
        await Promise.all(promises).then(data => {
            console.log(data)
            let files = data;
            this.allFiles = []
            this.allFiles.push(files)
        })
    }

    uploadImgs(): void {
        this._gService.showLoader(true);
        if (this.allFiles) {
            // this.uploadFiles(this.selectedImages);
            let filesUploadData = {
                inst_id: this.instID,
                upload_files: this.allFiles
            }
            console.log(filesUploadData)
            this._cmsService.mediaMultiUploadData(filesUploadData).subscribe(uRes => {
                console.log(uRes)
                if (uRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Image(s) Uploaded Successfully', 'success', true);
                }else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false);
        }   
    }

}
