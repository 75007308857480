<div class="modal-header">
    <h5 class="modal-title">Edit Pay Head</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Pay Head</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Pay_head_name" >
                <div *ngIf="rObj.controls.Pay_head_name.invalid && (rObj.controls.Pay_head_name.dirty || rObj.controls.Pay_head_name.touched)">
                    <p class="input-error-msg">Please enter Pay head name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Pay Head Type</label>
                <select formControlName="Pay_head_type" class="form-select">
                    <option value="">Select Pay Head Type</option>
                    <option value="addition">Addition</option>
                    <option value="deduction">Deduction</option>
                </select>
                <div *ngIf="rObj.controls.Pay_head_type.invalid && (rObj.controls.Pay_head_type.dirty || rObj.controls.Pay_head_type.touched)">
                    <p class="input-error-msg">Please enter Pay head type</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter Description</p>
                </div>
            </div>
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updatePayhead()">Update</button>
            </div>
        </form> 
    </div>
</div>
