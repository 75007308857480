<div class="console-content">
    <form [formGroup]="rObj">
        <div class="panel-link-container">
            <div class="panel">
                <div class="row">
                    <div class="col-md-4 panel-link" [ngClass]="staffReport ? 'active' : ''">
                        <a (click)="setTabActive('staffReport')">Staff Reports</a>
                    </div>
                    <div class="col-md-4 panel-link" [ngClass]="payrollReport ? 'active' : ''">
                        <a (click)="setTabActive('payrollReport')">Payroll Reports</a>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="row">
            <div class="col-md-12 console-content-tab">
                <div id="staff-details" class="panel panel-margin-top" [ngClass]="staffReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-4">Staff Report</h1>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">   
                            <div class="row">    
                                <div class="col-md-4"> 
                                    <label>Joining Period</label>   
                                    <small class="req"> *</small>
                                    <select class="form-control" formControlName="Search_type" autocomplete="off">
                                        <option value="">Select</option>
                                        <option value="today">Today</option>
                                        <option value="this_week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="this_month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="last_3_month">Last 3 Months</option>
                                        <option value="last_6_month">Last 6 Months</option>
                                        <option value="last_12_month">Last 12 Months</option>
                                        <option value="this_year">This Year</option>
                                        <option value="last_year">Last Year</option>
                                        <option value="custom">Custom Period</option>
                                    </select>   
                                </div> 
                                <div  class="col-md-6" *ngIf="rObj.controls.Search_type.value == 'custom'" style="display: contents;">
                                    <div class="row">
                                        <div class="col-md-6" >
                                            <label>Date From</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_From" >
                                            <div *ngIf="rObj.controls.Date_from.invalid && (rObj.controls.Date_from.dirty || rObj.controls.Date_from.touched)">
                                                <p class="input-error-msg">Please select date from</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Date To</label>
                                            <!-- <small class="req">*</small> -->
                                            <input type="date" class="form-control date-input" formControlName="Date_to" >
                                            <div *ngIf="rObj.controls.Date_to.invalid && (rObj.controls.Date_to.dirty || rObj.controls.Date_to.touched)">
                                                <p class="input-error-msg">Please select Date to</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-md-4">
                                    <label>Role</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Role" class="form-select" required="">
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let role of userRoleData.reverse()">
                                            <option value="{{role.ID}}">
                                                {{role.name}}
                                            </option>
                                         </ng-container>                         
                                    </select>
                                    <div *ngIf="rObj.controls.Role.invalid && (rObj.controls.Role.dirty || rObj.controls.Role.touched)">
                                        <p class="input-error-msg">Please select role </p>
                                    </div>
                                </div>                             
                                <div class="col-md-4">
                                    <label>Status<small class="req"> *</small></label>
                                    <select formControlName="Status" class="form-control" required="">
                                        <option value="">Select Status</option>
                                        <option value="2">All</option>
                                        <option value="1">Active</option>
                                        <option value="0">Disable</option>
                                    </select>
                                        <div *ngIf="rObj.controls.Status.invalid && (rObj.controls.Status.dirty || rObj.controls.Status.touched)">
                                            <p class="input-error-msg">Please select Status</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="search()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">

                         <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="StaffData.length">
                            <thead>
                            <tr>
		                        <th>Staff Id</th>
			                    <th class="align-left">Staff Name</th>
			                    <th>Mobile</th>
			                    <th>Email </th>
		                        <th>Role</th>
			                    <th>Gender</th>
			                    <th>Qualification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of StaffData">
                               
                                <td>{{s.staff_id}}</td>
                                <td class="align-left">
                                    <img [src]="s.photo_path ? imgURL+s.photo_path : '/assets/img/default-user-avatar.png'" style="width: 40px; border: 1px solid #dcdcdc;">
                                    {{s.full_name}}
                                </td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.email}}</td>
                                <td>{{getUserRoleName(s.role)}}</td>
                                <td>{{s.gender}}</td>
                                <td>{{s.qualification}}</td>
                               
                            </tr>
                        </tbody>
                        </table>   
                        <div *ngIf="!StaffData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div> 
                    </div>  
                </div>
                <div id="payroll-details" class="panel panel-margin-top" [ngClass]="payrollReport ? 'active' : ''">
                    <div class="console-content-header">
                        <h1 id="panel_title" class="title col-md-5">Payroll Report</h1>
                    </div>
                
                    <div class="panel-body">   
                        <div class="form-group">      
                            <div class="row">    
                                <div class="col-md-4">
                                    <label>Role</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Role" class="form-select" required="">
                                        <option value="">Please Select</option>
                                        <ng-container *ngFor="let role of userRoleData.reverse()">
                                            <option value="{{role.ID}}">
                                                {{role.name}}
                                            </option>
                                            </ng-container>                         
                                    </select>
                                    <div *ngIf="rObj.controls.Role.invalid && (rObj.controls.Role.dirty || rObj.controls.Role.touched)">
                                        <p class="input-error-msg">Please select role </p>
                                    </div>
                                </div>           
                                <div class="col-md-4">
                                    <label>Month
                                    <small class="req"> *</small></label>
                                    <select name="section" formControlName="Month" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let m of MonthData" value="{{m.month}}">{{m.monthName}}</option>   
                                    </select>
                                    <div *ngIf="rObj.controls.Month.invalid && (rObj.controls.Month.dirty || rObj.controls.Month.touched)">
                                        <p class="input-error-msg">Please select Month</p>
                                    </div>
                                </div>                                
                                <div class="col-md-4">
                                    <label>Year
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Year" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <!-- <option *ngFor="let des of designationData" value="{{des.ID}}">{{des.name}}</option>    -->
                                        </select>
                                        <div *ngIf="rObj.controls.Year.invalid && (rObj.controls.Year.dirty || rObj.controls.Year.touched)">
                                            <p class="input-error-msg">Please select Year</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="submit-btn primary-btn" type="submit" (click)="searchStaffPayroll()">Search</button>
                        </div>       
                    </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffPayrollData.length">
                            <thead>
                                <tr>
                                    <th class="align-left">Staff Name</th>
                                    <th>Role</th>
                                    <th>Designation</th>
                                    <th>Department</th>
                                    <th>Month-Year</th>
                                    <th>Basic Salary</th>
                                    <th>Earnings</th>
                                    <th>Deductions</th>
                                    <th>Gross Salary</th>
                                    <th>Tax</th>
                                    <th>Net Salary</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of staffPayrollData">
                                    <td class="align-left">{{s.staff_name}}</td>   
                                    <td>{{s.role_name}}</td>
                                    <td>{{s.designation_name}}</td>
                                    <td>{{s.department_name}}</td>
                                    <td>{{getMonth(s.month)}}/{{s.year}}</td>
                                    <td>{{s.basic}}</td>
                                    <td>{{s.earning}}</td>
                                    <td>{{s.deduction}}</td>
                                    <td>{{s.gross}}</td>
                                    <td>{{s.tax}}</td>
                                    <td>{{s.net}}</td>

                                </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!staffPayrollData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </form>
</div>