<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Exam Timetable</h1>
            <!-- <div class="header-msg col-md-4">
            </div> -->
            <div class="link-container col-md-6">
                <a href="./examination/exam-schedule/list" class="content-header-link"> Exam Schedule</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="filter-options">
                        <form [formGroup]="rObj">
                            <!-- <div class="row"> -->
                                <div class="form-group">
                                    <label>Exam
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Exam" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let exam of examData" value="{{exam.ID}}">{{exam.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Exam.invalid && (rObj.controls.Exam.dirty || rObj.controls.Exam.touched)">
                                        <p class="input-error-msg">Please select exam </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData">
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div> 
                            <!-- </div>  -->
                            <div class="form-group btn-container" style="margin-top: 22px;">
                                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="navigate()">Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="openpanel">
            <div class="col-md-12 row" >
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                       Exam Details:
                        <button class="btn default-btn extra-small-btn inline float-right" (click)="addETData()">Add </button>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-2">Subject</div>
                            <div class="col-md-2">Date</div>
                            <div class="col-md-2">Start Time</div>
                            <div class="col-md-2">End Time</div>
                            <div class="col-md-1">Full Marks</div>
                            <div class="col-md-1">Passing Marks</div>
                            <div class="col-md-1">Room No.</div>
                        </div>
                        <div formArrayName="etData">
                            <div class="timetable-wrapper" *ngFor="let detail of etData().controls; let i=index" style="margin-bottom: 15px ;">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-md-2">
                                        <select formControlName="Subject" class="form-select" >
                                            <option value="">Subject</option>
                                            <option *ngFor="let sub of subjectsData" value="{{sub.ID}}">{{sub.name}}</option>               
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="date" formControlName="Date" class="form-control" placeholder="Date">
                                    </div>
                                    <div class="col-md-2">
                                        <input type="time" formControlName="Start_time" class="form-control" placeholder="Start Time">
                                    </div>
                                    <div class="col-md-2">
                                        <input type="time" formControlName="End_time" class="form-control" placeholder="End Time">
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" formControlName="Full_marks" class="form-control" placeholder="full Marks">
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" formControlName="Passing_marks" class="form-control" placeholder="Passing Marks">
                                    </div>
                                    <div class="col-md-1">
                                        <input type="text" formControlName="Room_no" class="form-control" placeholder="Room No.">
                                    </div>
                                    <div class="col-md-1" >
                                        <button *ngIf="i != 0" class="default-btn"style="margin-top: 4px;" (click)="removeETData(i, etData().controls[i].value.Id)"><i class="far fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" style="margin-top: 15px;" (click)="saveExamtimeTable()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        

  
        <div class="row" *ngIf="!openpanel">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>