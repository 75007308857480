import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { AttendanceService } from 'src/app/services/attendance.service';
import { Router, ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-attendance-studentAttendance',
  templateUrl: './studentAttendance.component.html'
})

export class StudentAttendanceComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public studentAttendanceData: any[] = [];
    public mediumData: any[] = [];
    public idsData: any[] = [];
    public statusData: any[] = [];
    
    public attendanceDataArray: any[] = []
    public classId : any;
    public sectionId : any;
    public mediumId : any;
    public searchDate : any;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public medium = this.userData.activeMedium
    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _sService: StudentService,
        private _aService: AttendanceService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required], 
        Section: ['', Validators.required],
        Date: ['', Validators.required],
    })

    public sObj = this._fBuilder.group({
        attendanceRow : this._fBuilder.array([])
    })
    
    attendanceRow(): FormArray {
        return this.sObj.get("attendanceRow") as FormArray
    }

    ngOnInit(): void {

        console.log(this.sObj.controls.attendanceRow)

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        this.searchDate = this._aRoute.snapshot.paramMap.get('date')

        if(this.classId && this.sectionId && this.searchDate){
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                Date : this.searchDate,
            })
            this.search()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
            }
        })
        this._gService.showLoader(false);
    })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'ftip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    arrayToString(array: any){
        return JSON.stringify(array)
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/attendance/student/search/'+robj.Class+'/'+robj.Section+'/'+robj.Date]);
    }

    search() {

        // if(this.rObj.valid) {
            this._gService.showLoader(true);
            console.log(this.classId,this.sectionId,this.medium)
            this._sService.studentListData(this.instID, this.activeAcademicID, this.classId, this.sectionId).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                    this._aService.AttendencebyClassSectionDate(this.instID, this.activeAcademicID, this.classId, this.sectionId, this.searchDate).subscribe(cRes => {
                        console.log(cRes)
                        if (cRes.status === true) {
                            this.studentAttendanceData = JSON.parse(cRes.result);
                                console.log('yes')
                                let attendanceRowArray = <FormArray>this.sObj.controls.attendanceRow;
                                attendanceRowArray.controls = this.studentAttendanceData.map(s => this._fBuilder.group({
                                    Student_id: [s.student_id],
                                    Student_name: [s.full_name],
                                    Admission_no: [s.admission_number], 
                                    Roll_no: [s.roll_no],
                                    Status: [s.status], 
                                    Remark: [s.remark],
                                }))
                                console.log(attendanceRowArray.controls)
                        } else {
                            if (this.studentListData.length) {
                            console.log('No')
                            let attendanceRowArray = <FormArray>this.sObj.controls.attendanceRow;
                            attendanceRowArray.controls = this.studentListData.map(s => this._fBuilder.group({
                                Student_id: [s.ID],
                                Student_name: [s.full_name],
                                Admission_no: [s.admission_number], 
                                Roll_no: [s.roll_no],            
                                Status: [''],
                                Remark: [''],
                            }))
                        }
                        }
                    })
                } else {
                    this.studentListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            
            this._gService.showLoader(false);
        // }

        console.log(this.sObj.controls.attendanceRow)
       
    }
   

    save() {
        // if(this.sObj.valid) {
            this._gService.showLoader(true);
           let sObj = this.sObj.controls.attendanceRow.controls;
           let robj = this.rObj.value;
           for (let i = 0; i < sObj.length; i++) {
            this.attendanceDataArray.push(sObj[i].value)
            let s = this.attendanceDataArray.map(s => s.Status)
            this.statusData.push(s)
          }
          console.log(this.statusData)
           let aData = {
            inst_id: this.instID,
            academic_id: this.activeAcademicID,
            class: robj.Class,
            section: robj.Section,
            date: robj.Date,
            attendanceData: this.attendanceDataArray
           }
           console.log(aData)
          console.log(this.attendanceDataArray)
        // }
        this._aService.saveStudentAttendanceData(aData).subscribe((aRes : any) => {
            console.log(aRes)
            if (aRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Student Attendance Saved Successfully', 'success', true);
                this.studentListData.forEach(i =>{
                   this.idsData.push(i.user_id)
                })
                let ndata = {
                    inst_id: this.instID,
                    academic_id: this.activeAcademicID,
                    user_role: 4,
                    user_ids: JSON.stringify(this.idsData),
                    title: 'Attendence Marked for'+' '+this.classData.filter(c =>c.ID == robj.Class).map(c => c.name)+' (' + this.sectionData.filter(s => s.ID == robj.Section).map(s => s.name)+')',
                    msg: '',
                    link: 'attendance/student',
                    screen: 'Attendance'
                }
                console.log(ndata)
                this._nService.sendNotificationData(ndata).subscribe(res => {
                    console.log(res)
                    if (res.status === true) {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url]);
                        this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                    }
                })            
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        this._gService.showLoader(false);
    // }
    }
}