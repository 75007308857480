<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6"> Transport Allocation</h1>
        <div class="link-container col-md-6">
            <a href="../transport/driver/add/" class="content-header-link">Add Driver</a>
            <a href="../transport/vehicles/add/" class="content-header-link">Add Vehicle</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                    Transport Allocation
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Vehicle No.</label>
                                <small class="req"> *</small>
                                <select formControlName="Vehicle_no" class="form-control" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let No of vehicleData" value="{{No.ID}}">{{No.vehicle_no}}</option>
                
                                 </select>
                                 <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                                    <p class="input-error-msg">Please select vehicle number</p>
                                </div>
                        </div>

                        <div class="form-group">
                            <label>Route </label>
                            <small class="req"> *</small>
                            <select formControlName="Route_name" class="form-control">
                                <option value="">Please Select</option>
                                <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>
                            </select>
                            <div *ngIf="rObj.controls.Route_name.invalid && (rObj.controls.Route_name.dirty || rObj.controls.Route_name.touched)">
                                <p class="input-error-msg">Please enter route name</p>
                            </div>
                        </div>
                       
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="save()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                    Transport Allocation List
                    </div>
                </div>
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list"  *ngIf="allocationData.length">    
                            <thead>
                                <tr>
                                    <th class="align-left">Vehicle No</th>
                                    <th class="align-left">Route Name</th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let t of allocationData">
                                <td class="align-left">
                                    <ng-container *ngFor="let v of vehicleData">
                                        <ng-container *ngIf="v.ID == t.vehicle_no">
                                        {{v.vehicle_no}}
                                        </ng-container>  
                                    </ng-container>
                                </td>
                                <td >
                                    <ng-container *ngFor="let r of routeData">
                                        <ng-container *ngIf="r.ID == t.route">
                                        {{r.route_name}}
                                        </ng-container>  
                                    </ng-container>
                                </td>
                                <!-- <td>{{t.route_name}}</td> -->
                                
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openAllocationEditModal(t.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(t.ID, 'transport_allocation', 'transport allocation ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>   
                        <div *ngIf="!allocationData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>   
                    </div>
                </div>
            </div>
    </div>
        
</div>