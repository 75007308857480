import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';

@Component({
  selector: 'app-branches-list',
  templateUrl: './list.component.html'
})

export class BranchesListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userName = this.userData.Name;
    public instList : any[] = [];
    public selectedIds : any[] = [];
    //dtOptions: DataTables.Settings = {};

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _userService: UserService,
        private _instService: InstService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._instService.getInstListByAdminID(this.userID).subscribe(instRes => {
            console.log(instRes);
            if (instRes.status === true) {
                this.instList = JSON.parse(instRes.result);
                console.log(this.instList)
            } else {
                console.log(instRes.msg);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
        
        console.log(this.userData);
        let userData =  {
            user_id: this.userData.UserID,
            user_role: this.userData.UserRole
        }
        this._userService.getUserDataByID(userData).subscribe(uRes => {
            console.log(uRes)
            if (uRes.status === true) {
                let userResponse = JSON.parse(uRes.result)
                console.log(userResponse);
            } else {
                // this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        })

    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.instList.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.instList.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }
    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }


    goToEditLink(inst_id: number) {
        if (inst_id) {
            this._router.navigate(['/branches/edit/'+inst_id])
        }
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
}