<div class="console-content">
    
    <div class="row">
        
        <div class="col-md-3">
            <div class="panel">
                <div class="view-meta student-meta"  *ngFor="let staff of staffData">
                    <div class="student-photo">
                        <img class="photo-preview" [src]="staff.photo_path ? imgURL+staff.photo_path : '/assets/img/default-user-avatar.png'">
                    </div>
                    <ng-container>
                    <h3 class="name">{{staff.full_name}}</h3>
                    <div class="view-info row">
                        <div class="col-md-6 title">Staff Id</div>
                        <div class="col-md-6 info">{{staff.staff_id}}</div>
                        <div class="col-md-6 title">Staff Role</div>
                        <div class="col-md-6 info">
                            {{staff.role}}</div> 
                        <div class="col-md-6 title">Department</div>
                        <div class="col-md-6 info">
                            <ng-container *ngFor="let dept of departmentData">
                                <ng-container *ngIf="dept.ID == staff.department">
                                    {{dept.name}}
                                </ng-container>
                            </ng-container>
                            <br>
                        </div>
                        <div class="col-md-6 title">Designation.</div>
                        <div class="col-md-6 info">
                            <ng-container *ngFor="let des of designationData">
                                <ng-container *ngIf="des.ID == staff.designation">
                                    {{des.name}}
                                </ng-container>
                            </ng-container>
                            <br>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>  
        <div class="col-md-9">
            <div class="console-content-navbar">
                <ul class="content-links">
                    <li class="content-link " [ngClass]="profileTab ? 'active' : ''" (click)="setTabActive('profileTab')">
                        <a id="personal">
                            <i class="fas fa-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="documentTab ? 'active' : ''" (click)="setTabActive('documentTab')">
                        <a id="documents">
                            <i class="fas fa-file-alt"></i>
                            <span>Documents</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="PayrollTab ? 'active' : ''" (click)="setTabActive('PayrollTab')">
                        <a id="fees">
                            <i class="far fa-money-bill-alt"></i>
                            <span>Payroll </span>
                        </a>
                    </li>                  
                    <li class="content-link" [ngClass]="timelineTab ? 'active' : ''" (click)="setTabActive('timelineTab')">
                        <a id="timeline">
                            <i class="fas fa-stream"></i>
                            <span>Timeline</span>
                        </a>
                    </li>
                    <li class="content-link" [ngClass]="detailsTab ? 'active' : ''" (click)="setTabActive('detailsTab')">
                        <a id="details">
                            <i class="fas fa-stream"></i>
                            <span>Login Details</span>
                        </a>
                    </li>
                    <a href="/../staff/edit/{{viewId}}">
                        <button class="btn default-btn extra-small-btn inline float-right ">Edit</button>
                    </a>
                </ul>
            </div>
            
            <div class="console-content-tab">       
                <div id="personal" class="panel panel-margin-top" [ngClass]="profileTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Personal Details:
                        </div>
                    </div>
                    <div class="panel-body" *ngFor="let staff of staffData">
                        <div class="row details">
                            <div class="col-md-3 ">First Name</div>
                            <div class="col-md-3 head">{{staff.first_name}}</div> 
                            <div class="col-md-3 ">Last Name</div>
                            <div class="col-md-3 head">{{staff.last_name}}</div> 
                            
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Date of Birth</div>
                            <div class="col-md-3 head">{{staff.dob}}</div>
                            <div class="col-md-3 ">Gender</div>
                            <div class="col-md-3 head">{{staff.gender}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Father Name</div>
                            <div class="col-md-3 head">{{staff.father_name}}</div> 
                            <div class="col-md-3 ">Mother Name</div>
                            <div class="col-md-3 head">{{staff.mother_name}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Email Id</div>
                            <div class="col-md-3 head">{{staff.email}}</div> 
                            <div class="col-md-3 ">Mobile</div>
                            <div class="col-md-3 head">{{staff.mobile}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">Alternate Mobile</div>
                            <div class="col-md-3 head">{{staff.alt_mobile}}</div>
                            <div class="col-md-3 ">Marital Status</div>
                            <div class="col-md-3 head">{{staff.marital_status}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Aadhar Number</div>
                            <div class="col-md-3 head">{{staff.aadhaar_number}}</div> 
                            <div class="col-md-3 ">Qualification</div>
                            <div class="col-md-3 head">{{staff.qualification}}</div>  
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Work Experience</div>
                            <div class="col-md-3 head">{{staff.work_experience}}</div> 
                        </div>
                    </div>
                    <div class="panel-header">
                        <div class="panel-title">
                            Address Details:
                        </div>
                    </div>
                    <div class="panel-body" *ngFor="let staff of staffData">
                        <div class="row details">
                            <div class="col-md-3 ">Current Address</div>
                            <div class="col-md-9 head">{{staff.current_address}}</div> 
                        </div>
                        <div class="row details">
                            <div class="col-md-3 ">Permanent Address</div>
                            <div class="col-md-9 head">{{staff.permanent_address}}</div> 
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">City</div>
                            <div class="col-md-3 head">{{staff.city}}</div> 
                            <div class="col-md-3 ">Pincode</div>
                            <div class="col-md-3 head">{{staff.pincode}}</div>
                        </div> 
                        <div class="row details">
                            <div class="col-md-3 ">State</div>
                            <div class="col-md-3 head">{{staff.state}}</div> 
                            <div class="col-md-3 ">Country</div>
                            <div class="col-md-3 head">{{staff.country}}</div>
                        </div> 
                    </div>
                    
                </div>
                <div id="documents" class="panel panel-margin-top" [ngClass]="documentTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Document
                        </div>
                    </div>
                    <div class="panel-body">
                        <br> 
                        <div class="row details" *ngFor="let doc of documentData">
                            <div class="col-md-3 title">{{doc.name}}</div>
                            <div class="col-md-3 info">
                                <a href="{{imgURL}}{{doc.path}}" target="_blank" *ngIf="doc.path">View Document</a>
                            </div>
                        </div>
                    </div>
                </div>      
                <div id="payroll" class="panel panel-margin-top" [ngClass]="PayrollTab ? 'active' : ''">
                    <div class="row panel-header">
                        <div class="panel-title col-md-6">
                            Payroll Details:
                        </div>
                        <!-- <div class="panel-title col-md-6">
                            <form [formGroup]="rObj">
                                <div class="row options" style="justify-content: end;">
                                    <div class="col-md-4">
                                        <select formControlName="Month" class="form-select" (change)="OnMonthChange($event)" style="font-size: 13px;padding: 8px 10px;line-height: 1;">
                                            <option value="">MONTH</option>
                                            <ng-container *ngFor="let month of monthData"> 
                                                <option [value]="month">{{getMonthName(month)}}</option>
                                            </ng-container>
                                        </select>
                                    </div>                                
                                    <div class="col-md-4">
                                        <select name="section" formControlName="Year" class="form-select" (change)="OnYearChange($event)" style="font-size: 13px;padding: 8px 10px;line-height: 1;">
                                            <option value="">YEAR</option>
                                            <ng-container *ngFor="let a of academicYearsData">
                                                <option [value]="a.start_year" selected>{{a.start_year}}</option>
                                                <option [value]="a.end_year">{{a.end_year}}</option>
                                            </ng-container>
                                        </select>    
                                    </div>
                                </div>
                            </form>
                        </div> -->oo
                    <div class="panel-body">  
                        <table class="list" *ngIf="staffData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="align-left"><input type="checkbox" id="select_all"></th> -->
                                    <th>Staff ID</th>
                                    <th>Staff Name</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Role</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <!-- <th class="no-sort">Manage</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of staffData">
                                    <!-- <td class="align-left">
                                      <input type="checkbox" class ="list-checkbox" value="ID">
                                        <input type='checkbox' class="list-checkbox" data-id='<?php echo $row['ID'] ; ? >' >
                                    </td> -->
                                    <td>{{s.staff_id}}</td>
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.department}}</td>
                                    <td>{{s.designation}}</td>
                                    <td>{{s.role_name}}</td>
                                    <td>{{s.mobile}}</td>
                                    <td>{{getPayrollStatus(s.ID) ? getPayrollStatus(s.ID) : 'Not Generated'}}</td>
                                    <!-- <td class="action-btn">
                                        <ng-container *ngIf="getPayrollStatus(s.ID) == 'Paid'">
                                            <a tooltip="Revert" class="edit-btn" (click)="RevertPage(s.ID, 'payroll', 'payroll')"><i class="fa-solid fa-rotate-right"></i></a>
                                            <button type="button" class="btn primary-btn small-btn" (click)="openPaymentReceiptModal(s.ID, s.full_name, monthId, yearId, departmentId, designationId)">View</button>
                                         </ng-container>
                                        <ng-container *ngIf="getPayrollStatus(s.ID) == 'Generated'">
                                            <a tooltip="Edit" class="edit-btn" (click)="openEditPage(s.ID)"><i class="far fa-edit"></i></a>
                                            <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'payroll', 'payroll')"><i class="far fa-trash-alt"></i></a>
                                            <button type="button" class="btn primary-btn small-btn" (click)="openPayModal(s.ID, s.full_name, monthId, yearId, payment_amt, departmentId, designationId)">Pay</button>
                                        </ng-container>
                                        <ng-container *ngIf="!getPayrollStatus(s.ID)?.length">
                                            <button class="btn primary-btn small-btn" (click)="goToGeneratePage(s.ID)" >Generate</button>
                                        </ng-container>
    
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                </div>        
                <div id="timeline" class="panel panel-margin-top" [ngClass]="timelineTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Timeline:
                        </div>
                    </div>
                    <div class="panel-body">
                        Documents    
                    </div>
                </div>
                </div> 
                <div id="login_details" class="panel panel-margin-top" [ngClass]="detailsTab ? 'active' : ''">
                    <div class="panel-header">
                        <div class="panel-title">
                            Details 
                        </div>
                    </div>
                    <div class="panel-body">
                        <br> 
                        <div class="row details">
                            <div class="row details">
                                <div class="col-md-3 ">Username</div>
                                <div class="col-md-9 head">{{username}}</div> 
                            </div>
                            <div class="row details">
                                <div class="col-md-3 ">Password</div>
                                <div class="col-md-9 head">{{password}}</div> 
                            </div> 
                        </div>
                    </div>
                </div> 

        </div> 
    </div>
</div>