import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class AcademicService {
  constructor(
    private _http: HttpClient,
  ) { }

  private medium = `${environment.APIURL}/academic/medium/`;
  private section = `${environment.APIURL}/academic/section/`;
  private class = `${environment.APIURL}/academic/class/`;
  private subjects = `${environment.APIURL}/academic/subjects/`;
  private assignsub = `${environment.APIURL}/academic/assignSubject/`;
  private assignteacher = `${environment.APIURL}/academic/assignTeacher/`;
  private timetable = `${environment.APIURL}/academic/getTimeTableByClassSection/`;
  private teachertimetable = `${environment.APIURL}/academic/getTimeTableByTeacher/`;
  private tt = `${environment.APIURL}/academic/timetable/`;
 

  
  mediumData(rObj: object): Observable<any> {
    return this._http.post(this.medium, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  sectionData(rObj: object): Observable<any> {
    return this._http.post(this.section, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  classData(rObj: object): Observable<any> {
    return this._http.post(this.class, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  subjectData(rObj: object): Observable<any> {
    return this._http.post(this.subjects, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  assignsubData(rObj: object): Observable<any> {
    return this._http.post(this.assignsub, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  assingteacherData(rObj: object): Observable<any> {
    return this._http.post(this.assignteacher, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }

  timetableByClassData(instID: number, academicID: number, classID: any, sectionID: any, mediumID: any): Observable<any> {
    return this._http.get(this.timetable+'?inst_id='+instID+'&academic_id='+academicID+'&class='+classID+'&section='+sectionID+'&medium='+mediumID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  teachertimetableData(instID: number, academicID: number, teacherID: any): Observable<any> {
    return this._http.get(this.teachertimetable+'?inst_id='+instID+'&academic_id='+academicID+'&teacher='+teacherID, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  DataByDay(rObj: object): Observable<any> {
    return this._http.post(this.tt, rObj, {
      headers: { 'Content-Type': 'application/json'}
    });
  }


 
}