import { Component, OnInit, Output, EventEmitter, Directive, HostBinding, HostListener } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaModalComponent } from 'src/app/components/modal/mediamodal/mediamodal.component';
// import { ImageFile } from './image.component';
@Component({
  selector: 'app-enpcms-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})


export class SettingsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public type: string;
    public selectedMedia: String;
    public selectedMediaArray: any[] = [];
    public cmsSettingData: any[] = [];
    imageSrc: any = '';
    imageSrc2: any = '';
    status:boolean = false;
    status2:boolean = false;
    remove:boolean = false;
    remove2:boolean = false;
    // openpanel:boolean = false;
    public imgURL = this._gService.getImgPath();

    uploading = false;
    selectedImages!: FileList;
    public allImages: any;
    imagesUrl: Array<string> = [];

    dtOptions: any = {};

    @Output() selectedMedias = new EventEmitter<any>();
   

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _cmsService: CmsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,

    ) {}
    public rObj = this._fBuilder.group({
        Theme: ['default'],
        Language: [''],
        Footer: [''],
        Cookie_consent: [''],
        Google_analytics: [''],
        Whatsapp: [''],
        Facebook: [''],
        Twitter: [''],
        Youtube: [''],
        Google: [''],
        Linkedin: [''],
        Instagram: [''],
        Pinterest: [''],
    })



    ngOnInit(): void {
        this._gService.showLoader(true);
        
        this._cmsService.cmsSettingsData(this.instID).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                let cData = JSON.parse(mRes.result);
                console.log(cData)
                this.rObj.patchValue({
                    Theme: cData[0].theme,
                    Language: cData[0].language,
                    Footer: cData[0].footer,
                    Cookie_consent: cData[0].cookie,
                    Google_analytics: cData[0].google_analytics,
                    Whatsapp: cData[0].whatsapp,
                    Facebook: cData[0].facebook,
                    Twitter: cData[0].twitter,
                    Youtube: cData[0].youtube,
                    Google: cData[0].google,
                    Linkedin: cData[0].linkedin,
                    Instagram: cData[0].instagram,
                    Pinterest: cData[0].pinterest,   
                });
                this.imageSrc = cData[0].website_logo_img ? this.imgURL+cData[0].website_logo_img : '';
                this.imageSrc2 = cData[0].logo_img ? this.imgURL+cData[0].logo_img : '';
                
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;   
                 
           }
        }
        console.log(this.imageSrc)   
        
    }

    removeImg() {
        this.imageSrc = '';
    }

    onFile2Change(event:any) {
        this.status2 = false
        const file = event.target.files[0];
        this.status2 = event.target.files.length > 0 ? true : false;
        if (this.status2 == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc2 = reader.result;   
                 
           }
        }
        console.log(this.imageSrc2)   
        
    }

    remove2Img() {
        this.imageSrc2 = '';
    }

    save(){
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let settingData = {
                inst_id : this.instID,
                settings : {
                    theme : rObj.Theme,
                    language : rObj.Language,
                    website_logo_img : this.imageSrc,
                    logo_img : this.imageSrc2,
                    footer : rObj.Footer,
                    cookie : rObj.Cookie_consent,
                    google_analytics : rObj.Google_analytics,
                    whatsapp : rObj.Whatsapp,
                    facebook : rObj.Facebook,
                    twitter : rObj.Twitter,
                    youtube : rObj.Youtube,
                    google : rObj.Google,
                    linkedin : rObj.Linkedin,
                    instagram : rObj.Instagram,
                    pinterest : rObj.Pinterest
                }
            }
            console.log(settingData)
            this._cmsService.getSettingData(settingData).subscribe(cmsRes => {

                console.log(cmsRes)
                if (cmsRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('CMS Settings Updated Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
        this._gService.showLoader(false);
    }
   


}
