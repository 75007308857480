import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import { StudentService } from 'src/app/services/student.service';
import { FinanceService } from 'src/app/services/finance.service';
import { FeesService } from 'src/app/services/fees.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import * as moment from 'moment';
import { dailyCollectionModalComponent } from 'src/app/components/modal/dailyCollectionModal/dailyCollectionModal.component';


@Component({
  selector: 'app-reports-financeReport',
  templateUrl: './financeReport.component.html',
  styleUrls: ['./financeReport.component.css']
})

export class financeReportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public mediumId = this.userData.activeMedium;

    public studentCountData: any[] = [];
    public classSectionData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public studentData: any[] = [];
    public studentListData: any[] = [];
    public feesMasterData: any[] = [];
    public expenseheadData: any[] = [];
    public incomeheadData: any[] = [];
    public payrollData: any[] = [];
    public expenseData: any[] = [];
    public incomeData: any[] = [];
    public statusData: any[] = [];
    public feeTypeData: any[] = [];
    public duefeeData: any[] = [];
    public dailyCollectionData: any[] = [];
    public feeGroupData: any[] = [];
    public feeCollectionData: any[] = [];
    public mediumData: any[] = [];
    public ApplicationData: any[] = [];
    
    // public genderRatio: any;



    public genderRatio: any;
    public isDue:boolean = false;

    
    public balancefeesStatement: boolean = true;
    public dailyCollectionReport: boolean = false;
    public feesStatement: boolean = false;
    public balanceFeesReport: boolean = false;
    public feesCollectionReport: boolean = false;
    public onlineFeeCollectionReport: boolean = false;
    public incomeReport: boolean = false;
    public expenseReport: boolean = false;
    public payrollReport: boolean = false;
    public onlineAdmissionFeesCollection: boolean = false;
    public incomeGroupReport: boolean = false;
    public expenseGroupReport: boolean = false;

    
    public imgURL = this._gService.getImgPath();


    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];


    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fService: FeesService,
        private _rService: ReportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Income_Head: [''],
        Expense_Head: [''],
        Search_type: [''],
        Period: [''],
        expense_Period : [''],
        Payroll_period : [''],
        IncomeGroup_period : [''],
        Date_to: [''],
        Date_From: [''],
        Student: [''],
        Fee_group: [''],
        Medium: ['']
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'online_admissions').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.ApplicationData = JSON.parse(cRes.result);
                console.log(this.ApplicationData)
                let student = this.ApplicationData[0].student_id;

                this._gService.getDataByID(student,'students').subscribe(res => {
                    let data = JSON.parse(res.result)
                    console.log(data)
                    // alert('yes')                         

                })
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'incomehead').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.incomeheadData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'expensehead').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.expenseheadData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })



        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.feeTypeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_group').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.feeGroupData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
        this.rObj.controls['Section'].valueChanges.subscribe((value : any) =>{
            this._gService.showLoader(true);
            this._aService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                } else {
                    this.studentListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false);
        })


        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    getMonth(month:any){
        return this.MonthData.filter(d => d.month == month).map(d => d.monthName)
    }
    setTabActive(tabName: string) {
        if (tabName == 'balancefeesStatement') {
            this.balancefeesStatement = true
        } else {
            this.balancefeesStatement = false
        }
        if (tabName == 'dailyCollectionReport') {
            this.dailyCollectionReport = true
        } else {
            this.dailyCollectionReport = false
        }
        if (tabName == 'feesStatement') {
            this.feesStatement = true
        } else {
            this.feesStatement = false
        }
        if (tabName == 'balanceFeesReport') {
            this.balanceFeesReport = true
        } else {
            this.balanceFeesReport = false
        }
        if (tabName == 'feesCollectionReport') {
            this.feesCollectionReport = true
        } else {
            this.feesCollectionReport = false
        }
        if (tabName == 'onlineFeeCollectionReport') {
            this.onlineFeeCollectionReport = true
        } else {
            this.onlineFeeCollectionReport = false
        }
        if (tabName == 'incomeReport') {
            this.incomeReport = true
        } else {
            this.incomeReport = false
        }
        if (tabName == 'expenseReport') {
            this.expenseReport = true
        } else {
            this.expenseReport = false
        }
        if (tabName == 'payrollReport') {
            this.payrollReport = true
        } else {
            this.payrollReport = false
        }
        if (tabName == 'onlineAdmissionFeesCollection') {
            this.onlineAdmissionFeesCollection = true
        } else {
            this.onlineAdmissionFeesCollection = false
        }
        if (tabName == 'incomeGroupReport') {
            this.incomeGroupReport = true
        } else {
            this.incomeGroupReport = false
        }
        if (tabName == 'expenseGroupReport') {
            this.expenseGroupReport = true
        } else {
            this.expenseGroupReport = false
        }
        this.rObj.controls.Search_type.valueChanges.subscribe(value => {
            console.log(value)
        })
    }
    getClass(classID: number) {
        return this.classData.filter(c => c.ID == classID).map(c => c.name)
    }
    gotoViewLink(studentId: number){
        this._router.navigate(['/student/view/'+studentId])
    }
    gotoEditLink(studentID: number){
        this._router.navigate(['/online-admission/enroll/'+studentID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }



    searchAdmission(){
        let rObj = this.rObj.value;
       console.log(rObj.Income_Head)

       this._rService.dailyCollectionData(this.instID, this.activeAcademicID, rObj.Date_From, rObj.Date_to).subscribe(res =>{
            console.log(res)
            if (res.status == true) {
                this.dailyCollectionData = JSON.parse(res.result)
            }
            console.log(this.dailyCollectionData)
        })
   
        this._rService.incomereportData(this.instID, this.activeAcademicID, rObj.Period, rObj.Date_From, rObj.Date_to).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.incomeData = JSON.parse(mRes.result);
                console.log(this.incomeData)  
                if (rObj.Income_Head) {
                    // alert('yes')
                    this.incomeData = this.incomeData.filter(i => i.income_head == rObj.Income_Head)
                    console.log(this.incomeData)
                }else{
                    this.incomeData =this.incomeData
                } 
            } else {
                this.incomeData = []
            }
            this._gService.showLoader(false);
        })

        this._rService.expensereportData(this.instID, this.activeAcademicID, rObj.expense_Period, rObj.Date_From, rObj.Date_to).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.expenseData = JSON.parse(mRes.result);
                console.log(this.expenseData)  
                if (rObj.Expense_Head) {
                    // alert('yes')
                    this.expenseData = this.expenseData.filter(i => i.expense_head == rObj.Expense_Head)
                    console.log(this.expenseData)
                }else{
                    this.expenseData
                }
            } 
            this._gService.showLoader(false);
        })

        this._rService.payrollData(this.instID, this.activeAcademicID, rObj.Payroll_period, rObj.Date_From, rObj.Date_to).subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.payrollData = JSON.parse(mRes.result);
                console.log(this.payrollData) 

            } 
            this._gService.showLoader(false);
        })
    }

    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_id == feesId).map(s => s.txn_id)
    }
    viewModal(date :any) {
        this.modalRef = this.modalService.show(dailyCollectionModalComponent, {
            class: 'modal-dialog-centered dailyCollection-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                date: date,
            } 
        });
    }

    dueCheck(date: any) {
        let now = new Date()
        let  today = moment(now).format('YYYY-MM-DD')
        if(today > date) {
            this.isDue = true

        } else {
            this.isDue = false
        }
        }
        totalAmount(amt : any, f_amt :any){  
            if(this.isDue == true) {
                return parseInt(amt)+parseInt(f_amt);
            }
        }

        searchbalance(){
            if (this.rObj.valid) {
                let rObj = this.rObj.value;
                this._rService.balanceFeeData(this.instID, this.activeAcademicID, rObj.Class, rObj.Section, rObj.Medium).subscribe(res =>{
                    console.log(res)
                    if (res.status == true) {
                        this.duefeeData = JSON.parse(res.result)
                    }
                    console.log(this.duefeeData)
                })
            }    
        }
        searchfeecollection(){
            if (this.rObj.valid) {
                let rObj = this.rObj.value;
                this._rService.feeCollectionData(this.instID, this.activeAcademicID, rObj.Search_type, rObj.Class, rObj.Section, rObj.Fee_group).subscribe(res =>{
                    console.log(res)
                    if (res.status == true) {
                        this.feeCollectionData = JSON.parse(res.result)
                    }
                    console.log(this.feeCollectionData)
                })
            }    
        }

        search() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let student: any = rObj.Student; 
           
            this._fService.getStatusData(student).subscribe( res => {
                console.log(res)
                if(res.status === true){
                    this.statusData = JSON.parse(res.result);
                }
                console.log(this.statusData)
            })
            this._gService.getDataByID(student,'students').subscribe(mRes => {
                console.log(mRes)
                if (mRes.status === true) {
                    this.studentData = JSON.parse(mRes.result);
                    console.log(this.studentData)
                    this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_master').subscribe(cRes => {
                        console.log(cRes)
                        if (cRes.status === true) {
                            this.feesMasterData = JSON.parse(cRes.result);
                            console.log(this.feesMasterData)
                            this.feesMasterData =  this.feesMasterData.filter(r => r.class == this.studentData[0].class && r.section == this.studentData[0].section )
                        } else {
                            // this._gService.triggerSnackbar('No Data Found!', 'error', true);
                        }
                    })
                
                } else {
                    // this._gService.triggerSnackbar('No Data Found!', 'error', true);
                }
                this._gService.showLoader(false);
            })
        }
    }

}