<div class="modal-header">
    <h5 class="modal-title">Edit Inventory Purchase </h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Item Name</label>
                    <small class="req"> *</small>
                    <select formControlName="Item_name" class="form-select" data-next="getSection" data-type="list" required="">
                        <option value="">Select</option>
                        <option *ngFor="let i of inventoryItemData" value="{{i.ID}}">{{i.item_name}}</option>                                                
                    </select>
                    <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                        <p class="input-error-msg">Please enter medium name</p>
                    </div>
            </div>
            <div class="form-group">
                <label>Vendors</label>
                <small class="req"> *</small>
                <select formControlName="Vendors" class="form-select" data-next="getSection" data-type="list" required="">
                    <option value="">Select</option>
                    <option *ngFor="let i of vendorData" value="{{i.ID}}">{{i.company_name}}</option>                                                
                </select>
                <div *ngIf="rObj.controls.Vendors.invalid && (rObj.controls.Vendors.dirty || rObj.controls.Vendors.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Quantity</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Qty" class="form-control number-input" autocomplete="off" value="" required="">
                <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Purchase Price</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Cost" class="form-control" required="">
                <div *ngIf="rObj.controls.Cost.invalid && (rObj.controls.Cost.dirty || rObj.controls.Cost.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Purchase Date </label>
                <small class="req"> *</small>
                <input type="date" formControlName="Date" class="form-control date-input" required="">
                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Payment Mode </label>
                <small class="req"> *</small>
                <select type="text" formControlName="Mode" class="form-select">
                    <option value="">Select</option>
                    <option value="online">Online</option>
                    <option value="cash">Cash</option>                                             
                </select>
                <div *ngIf="rObj.controls.Mode.invalid && (rObj.controls.Mode.dirty || rObj.controls.Mode.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Payment Status</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Status" class="form-control" required="">
                <div *ngIf="rObj.controls.Status.invalid && (rObj.controls.Status.dirty || rObj.controls.Status.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Attach Document</label>
                <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;" *ngIf="imageSrc">View Document</a>
                <div class="imgUpload-wrapper">
                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Image</div>  
                    <input type="file" formControlName="Doc" class="form-control" (change)="onFileChange($event)">
                </div>
                <div *ngIf="rObj.controls.Doc.invalid && (rObj.controls.Doc.dirty || rObj.controls.Doc.touched)">
                    <p class="input-error-msg">please attach document</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" formControlName="Description" rows="3" class="form-control"></textarea>
                <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter medium name</p>
                </div>
            </div>   
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updatePurchase()">Update</button>
            </div>
        </form> 
    </div>
</div>
