import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
  
@Component({
    selector: 'app-routemodal',
    templateUrl: './routemodal.component.html',
    styleUrls: [],
})

export class RoutemodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public routeID: number;
    public vehicleData: any[] = [];
    public routeData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: TransportService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Vehicle_no: ['', Validators.required],
        Route_name: ['', Validators.required],
        Route_start: ['', Validators.required],
        Route_end: ['', Validators.required],
        Route_fare: ['', Validators.required],
        
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.routeData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })



        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vehicle').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.vehicleData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       


        this._gService.getDataByID(this.routeID, 'route').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Vehicle_no: sData[0].vehicle_no,
                    Route_name: sData[0].route_name,
                    Route_start: sData[0].route_start_place,
                    Route_end: sData[0].route_end_place,
                    Route_fare: sData[0].route_fare,

                    
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateRoute() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.routeID,
                vehicle_no: rObj.Vehicle_no,
                route_name: rObj.Route_name,
                route_start_place: rObj.Route_start,
                route_end_place: rObj.Route_end,
                route_fare: rObj.Route_fare,
             }
            this._aService.routeData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Route Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}