<div class="console-sidebar" [ngClass]="sidebarStatus ? 'collapse' : ''" (mouseenter)="sidebarStatus ? expandSidebar() : ''">
    <div class="institution-info">
        <div class="institution-icon">
            <img [src]="instLogo ? instLogo : '/assets/img/default-institution-icon.png'">
        </div>
        <div class="institution-meta">
            <div class="name">{{instName}}</div>
            <div class="branch">{{instBranch}}<span class="branch-switch" *ngIf="userRole == 2 && isMultiInst == 1" tooltip="Switch Branch" (click)="openBranchModal()"><i class="fa-solid fa-repeat"></i></span></div>
        </div>
    </div>
    <div class="navigation-links">
        <ul class="nav-links">
            
            <ng-container *ngFor="let module of modulesData">
                <li class="nav-link" *ngIf="modulesAccessData.includes(module.ID)" routerLinkActive="active" [ngClass]="[module.has_child == '1' ? 'has-sub-nav' : '', isActive(module.ID) ? 'hovered' : '']" (click)="selectMenu(module.ID)">
                    <a class="top-link" *ngIf="module.has_child == '0'" [routerLink]="'/'+module.slug">
                        <i class="nav-icon" [ngClass]="module.icon_class"></i>
                        <span>{{module.name}}</span>
                    </a>
                    <a class="top-link" *ngIf="module.has_child == '1'">
                        <i class="nav-icon" [ngClass]="module.icon_class"></i>
                        <span>{{module.name}}</span>
                    </a>
                    <ul class="sub-nav" *ngIf="module.has_child == '1'" [style.display]="isActive(module.ID) ? 'block' : 'none'">
                        <ng-container *ngFor="let subModule of subModulesData">
                            <li *ngIf="subModulesAccessData.includes(subModule.ID) && module.ID == subModule.parent" routerLinkActive="active">
                                <a [routerLink]="subModule.uri">{{subModule.name}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
   
        </ul>
    </div>
</div>