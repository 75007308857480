import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class OnlineApplicationService {
  constructor(
    private _http: HttpClient,
  ) { }

  private onlineApplication = `${environment.APIURL}/onlineAdmission/application/`;
  private checkApplicationStatus = `${environment.APIURL}/onlineAdmission/checkApplicationStatus/`;
 
  onlineApplicationData(data: object): Observable<any> {
    return this._http.post(this.onlineApplication, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  checkApplicationStatusData(refNo: any, dob: any): Observable<any> {
    return this._http.get(this.checkApplicationStatus+'?ref_no='+refNo+'&dob='+dob, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
  
}