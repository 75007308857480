<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Circulars</h1>
        <div class="link-container col-md-6">
            <!-- <a href="communication/circular/list" class="content-header-link">Circular List</a> -->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Circular
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Title</label>
                                    <small class="req"> *</small>
                                    <input type="text" class="form-control" formControlName="Title">
                                    <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                                        <p class="input-error-msg">Please enter Title name</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Date</label>
                                    <small class="req"> *</small>
                                    <input type="date" class="form-control" formControlName="Date">
                                    <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                        <p class="input-error-msg">Please enter Date name</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Send to</label>
                                    <small class="req"> *</small>
                                    <div class="radio required" style="display: flex;">
                                        <div class="form-check" style="margin-left: 10px; margin-top: 0px; margin: bottom 0px;">
                                            <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="6">
                                            <label class="radio-inline" for="Send_to1">
                                                Students
                                            </label>
                                        </div>
                                        <div class="form-check" style="margin-left: 10px;">
                                            <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="4">
                                            <label class="radio-inline" for="Send_to1">
                                                Teachers
                                            </label>
                                        </div>
                                        <div class="form-check" style="margin-left: 10px;">
                                            <input class="form-check-input" type="radio" formControlName="Send_to" id="Send_to1" value="0">
                                            <label class="radio-inline" for="Send_to1">
                                                All
                                            </label>
                                        </div>
                                        <div *ngIf="rObj.controls.Send_to.invalid && (rObj.controls.Send_to.dirty || rObj.controls.Send_to.touched)">
                                            <p class="input-error-msg">Please select </p>
                                        </div>
                                    </div>
                                    <!-- <input type="checkbox" class="form-control" formControlName="Send_to">
                                    <div *ngIf="rObj.controls.Send_to.invalid && (rObj.controls.Send_to.dirty || rObj.controls.Send_to.touched)">
                                        <p class="input-error-msg">Please enter Send to name</p>
                                    </div> -->
                                </div>
                                <div class="col-md-6">
                                    <label>Attach Document</label>
                                    <!-- <input type="file" class="form-control" formControlName="Leave_document"> -->
                                    <div class="imgUpload-wrapper">
                                        <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove File</div>  
                                        <input type="file" class="form-control" formControlName="Attachment" (change)="onFileChange($event)">
                                    </div>
                                    <div *ngIf="rObj.controls.Attachment.invalid && (rObj.controls.Attachment.dirty || rObj.controls.Attachment.touched)">
                                        <p class="input-error-msg">Please enter Attachment name</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Content</label>
                            <angular-editor [config]="editorConfig" formControlName="Description" class="form-control"></angular-editor>
                            <!-- <textarea formControlName="Content" class="form-control" rows="15" required=""></textarea> -->
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveCircular()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
        
</div>