import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class SettingsService {
  constructor(
    private _http: HttpClient,
  ) { }

  private academicYear = `${environment.APIURL}/settings/academicYear/`;
  private generalSetting = `${environment.APIURL}/settings/updateSettings/`;
  private getSetting = `${environment.APIURL}/settings/getSettingData/`;
  private getSettingByInstSlug = `${environment.APIURL}/settings/getSettingDataByInstSlug/`;
  private getgeneralSetting = `${environment.APIURL}/settings/getSettingsDataByInstID/`;
  private getmultiSetting = `${environment.APIURL}/settings/getMultiSettingsDataByInstID/`;
  private getmultiSettingBySlug = `${environment.APIURL}/settings/getMultiSettingsDataByInstSlug/`;

  saveSettingsData(data: object): Observable<any> {
    return this._http.post(this.generalSetting, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  saveAcademicYearData(data: object): Observable<any> {
    return this._http.post(this.academicYear, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getSettingsData(instID: number): Observable<any> {
    return this._http.get(this.getgeneralSetting+'?inst_id='+instID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getSettingData(instID: number, setting_name: string): Observable<any> {
    return this._http.get(this.getgeneralSetting+'?inst_id='+instID+'&setting_name='+setting_name, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getSettingDataByInstSlug(inst_slug: string, setting_name: string): Observable<any> {
    return this._http.get(this.getSettingByInstSlug+'?inst_slug='+inst_slug+'&setting_name='+setting_name, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getMultipleSettingsData(instID: number, setting_names: any): Observable<any> {
    return this._http.get(this.getmultiSetting+'?inst_id='+instID+'&setting_names='+setting_names, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getMultipleSettingsSlugData(instSlug: any, setting_names: any): Observable<any> {
    return this._http.get(this.getmultiSettingBySlug+'?inst_slug='+instSlug+'&setting_names='+setting_names, {
      headers: { 'Content-Type': 'application/json' }
    })
  }
  
}