import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import $ from 'jquery';
import { BranchModalComponent } from '../../modal/branchmodal/branchmodal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})

export class AdminSidebarComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentAdminUser')!);
    public userRole = this.userData.AdminUserRole;
    public isMultiInst = this.userData.isMultiInst;
    public instID = this.userData.InstID;
    public instLogo = '';
    public instName = '';
    public instBranch = '';

    public current_url = this._router.url;

    public modulesData: any[] = [];
    public subModulesData: any[] = [];
    public modulesAccessData: any[] = [];
    public subModulesAccessData: any[] = [];

    modalRef: BsModalRef;

    @Input() sidebarStatus: any;
    @Output() hoverSidebar = new EventEmitter();

    public isSelected: any = '';

    constructor(
        private _gService: GlobalService,
        private _router: Router,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(0, 0, 'modules').subscribe(mRes => {
            console.log(mRes);
            if (mRes.status === true) {
                this.modulesData = JSON.parse(mRes.result);
                console.log(this.modulesData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(0, 0, 'sub_modules').subscribe(smRes => {
            console.log(smRes);
            if (smRes.status === true) {
                this.subModulesData = JSON.parse(smRes.result);
                console.log(this.subModulesData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        function getUrlParameter(sParam: string) {
            var sPageURL = window.location.search.substring(1);
            var sURLVariables = sPageURL.split('&');
            for (var i = 0; i < sURLVariables.length; i++) {
                var sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] == sParam) {
                    return sParameterName[1];
                }
            }
        }

        var curr_path = window.location.pathname;

        $(document).find('.nav-links li').each(function() {
            var href = $(this).find('a').attr('href');
            var name = $(this).find('a').attr('data-name');
            if (curr_path == href || curr_path.indexOf('/' + name + '/') > -1 || (curr_path.indexOf('content') > -1 && getUrlParameter('type') == name)) {
                var parentClass = $(this).parent('ul').attr('class');
                if (parentClass == 'sub-nav') {
                    $(this).addClass('active');
                    $(this).parents('.nav-links li').addClass('active');
                } else {
                    $(this).addClass('active');
                }
            }
        });

        var y = $(document).find('.sub-nav li.active').parents('.nav-link.has-sub-nav').html();
        // alert(y);
        
        $(document).on('click', '.nav-links li.has-sub-nav', function() {
            if ($(this).hasClass('hovered')) {
                $(this).removeClass('hovered');
                $(this).children('ul.sub-nav').hide();
            } else {
                $('.nav-links li').removeClass('hovered');
                $('.nav-links li').children('ul.sub-nav').hide();
                $(this).addClass('hovered');
                $(this).children('ul.sub-nav').show();
            }
        });

        setTimeout(() => {
            document.querySelector('.nav-link.active')?.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }, 500)

    }

    selectMenu(menuID: number) {
        this.isSelected = (this.isSelected === menuID ? null : menuID); 
    }

    isActive(menuID: number) {
        return this.isSelected === menuID
    }

    expandSidebar() {
        this.hoverSidebar.emit(false);
    }

    openBranchModal() {
        this.modalRef = this.modalService.show(BranchModalComponent, {
            class: 'modal-dialog-top branch-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                activeBranchID: this.instID,
            } 
        });
    }
}