import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubjectModalComponent } from 'src/app/components/modal/subjectmodal/subjectmodal.component';

@Component({
    selector: 'app-academic-subjects',
    templateUrl: './subjects.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class subjectsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public subjectsData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Subject: ['', Validators.required],
        subjectType: ['theory', Validators.required],
        subjectCode: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.subjectsData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }

            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    getSectionName(sectionID: number) {
        this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
            if (sRes.status === true) {
                let sData = JSON.parse(sRes.result)[0];
                return sData
            }
        })
    }

    openSubjectEditModal(subjectID: number ) {
        this.modalRef = this.modalService.show(SubjectModalComponent, {
            class: 'modal-dialog-centered subject-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                subjectID: subjectID
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }


    saveSubject() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let sData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                subject: rObj.Subject,
                subjectType: rObj.subjectType,
                subjectCode: rObj.subjectCode,
            }
            this._aService.subjectData(sData).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Subject Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}