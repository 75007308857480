import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class PayrollService {
  constructor(
    private _http: HttpClient,
  ) { }
  private payhead = `${environment.APIURL}/payroll/payhead/`;
  private getstaffPayroll = `${environment.APIURL}/payroll/getStaffPayrollData/`;
  private payroll = `${environment.APIURL}/payroll/payroll/`; 
  private paydata = `${environment.APIURL}/payroll/pay/`; 
  private revertdata = `${environment.APIURL}/payroll/revertPay/`; 
  private getStaffPayrollByID = `${environment.APIURL}/payroll/getPayrollDataByStaffID/`; 
  
  payHeadData(data: object): Observable<any> {
    return this._http.post(this.payhead, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  payData(data: object): Observable<any> {
    return this._http.post(this.paydata, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  revertPayData(data: object): Observable<any> {
    return this._http.post(this.revertdata, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getStaffpayrollData(instID: any, academicID: any, month:any, year:any): Observable<any> {
    return this._http.get(this.getstaffPayroll+'?inst_id='+instID+'&academic_id='+academicID+'&month='+month+'&year='+year, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  payrollData(data: object): Observable<any> {
    return this._http.post(this.payroll, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  getStaffpayrollDataByIdData(instID: any, academicID: any, staff_id:any): Observable<any> {
    return this._http.get(this.getStaffPayrollByID+'?inst_id='+instID+'&academic_id='+academicID+'&staff_id='+staff_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
 
}