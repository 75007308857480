<div class="modal-header">
    <h5 class="modal-title">Edit Section</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Section Name</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Section">
                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                    <p class="input-error-msg">Please enter section name</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="updateSection()">Update</button>
            </div>
        </form>
    </div>
</div>
