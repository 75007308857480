import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class LibraryService {
  constructor(
    private _http: HttpClient,
  ) { }

  private bookCategory = `${environment.APIURL}/library/bookCategory/`;
  private book = `${environment.APIURL}/library/book/`;
  private member = `${environment.APIURL}/library/member/`;
  private revert = `${environment.APIURL}/common/deleteData/`;
  private searchBook = `${environment.APIURL}/library/searchBook/`;
  private issueBook = `${environment.APIURL}/library/bookIssue/`;
  private returnBook = `${environment.APIURL}/library/bookReturn/`;

  getsearchBookData(key: any,): Observable<any> {
    return this._http.get(this.searchBook+'?key='+key, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  bookCategoryData(data: object): Observable<any> {
    return this._http.post(this.bookCategory, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  bookData(data: object): Observable<any> {
    return this._http.post(this.book, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  revertmember(dataId: any, datamodule: any ): Observable<any> {
    return this._http.get(this.revert+'?data_id='+dataId+'&module='+datamodule, {
      headers: { 'Content-Type': 'application/json' }
    });
  }


  addMemberData(data: object): Observable<any> {
    return this._http.post(this.member, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  issueBookData(data: object): Observable<any> {
    return this._http.post(this.issueBook, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  returnBookData(data: object): Observable<any> {
    return this._http.post(this.returnBook, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  

}