import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class DashboardService {
  constructor(
    private _http: HttpClient,
  ) { }

  private studentsCount = `${environment.APIURL}/dashboard/getTotalStudentsCount/`;
  private staffCount = `${environment.APIURL}/dashboard/getTotalStaffCount/`;
  private earningsData = `${environment.APIURL}/dashboard/getTotalEarningsData/`;
  private studentGenderData = `${environment.APIURL}/dashboard/getStudentsCountByGender/`;
  private studentAttendanceData = `${environment.APIURL}/dashboard/getStudentsAttendanceCountByPeriod/`;
  private feesCollectionData = `${environment.APIURL}/dashboard/getFeesCollectionData/`;

  getStudentsCount(inst_id: number, academic_id: number): Observable<any> {
    return this._http.get(this.studentsCount+'?inst_id='+inst_id+'&academic_id='+academic_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getStaffCount(inst_id: number, academic_id: number): Observable<any> {
    return this._http.get(this.staffCount+'?inst_id='+inst_id+'&academic_id='+academic_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getEarningsData(inst_id: number, academic_id: number): Observable<any> {
    return this._http.get(this.earningsData+'?inst_id='+inst_id+'&academic_id='+academic_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }
  
  getstudentGenderData(inst_id: number, academic_id: number): Observable<any> {
    return this._http.get(this.studentGenderData+'?inst_id='+inst_id+'&academic_id='+academic_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getstudentAttendanceData(inst_id: number, academic_id: number, class_id: number, section_id: number, period: any): Observable<any> {
    return this._http.get(this.studentAttendanceData+'?inst_id='+inst_id+'&academic_id='+academic_id+'&class_id='+class_id+'&section_id='+section_id+'&period='+period, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

  getFeesCollectionData(inst_id: number, academic_id: number): Observable<any> {
    return this._http.get(this.feesCollectionData+'?inst_id='+inst_id+'&academic_id='+academic_id, {
      headers: { 'Content-Type': 'application/json'}
    })
  }

}