<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-4">Fees Collection </h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-5">
            <!--<a href="../add/" class="content-header-link">Add New Student</a>
            <a href="../import/" class="content-header-link">Import Students</a>-->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Transaction Id<small class="req"> *</small></label>
                            <input type="text" class="form-group" formControlName="Txn_id" value="">
                            <div *ngIf="rObj.controls.Txn_id.invalid && (rObj.controls.Txn_id.dirty || rObj.controls.Txn_id.touched)">
                                <p class="input-error-msg">Please enter transaction id</p>
                            </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="FeePayData.length">
                        <thead>
                            <tr>
                                <th class="left-align">Student Name</th>
                                 <th>Fees Type</th>
                                 <th>Amount</th>
                                 <th>Payment Date</th>
                                 <th>Payment Mode</th> 
                                 <th>Total Amount</th>
                                 <th>Transaction Id</th>
                                 <!-- <th>Status</th> -->
                                 <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let f of FeePayData">
                               
                                <tr>                             
                                    <td>
                                        <ng-container *ngFor="let stud of studentData">
                                            <ng-container *ngIf="stud.ID == f.student_id">
                                                {{stud.full_name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let type of feeTypeData">
                                            <ng-container *ngIf="type.ID == f.fees_type">
                                                {{type.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{f.amount}}</td>
                                    <td>{{f.payment_date}}</td>
                                    <td>{{f.payment_mode}}</td>
                                    <td>{{totalAmount(f.amount, f.fine_amount)}}</td>   
                                    <td>{{f.txn_id}}</td>
                                    
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>   
                    <div *ngIf="!FeePayData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>         
            </div>
        </div>
    </div>
</div>