import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SettingsService } from 'src/app/services/settings.service';
import { AcademicyearsModalComponent } from 'src/app/components/modal/academicyearsmodal/academicyearsmodal.component';

@Component({
  selector: 'app-settings-add-academic-years',
  templateUrl: './add.component.html',
  //styleUrls: ['./dashboard.component.css']
})

export class AcademicYearsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public academicYearsData: any[] = [];

    public activeAcademicID = this.userData.AcademicID;

    dtOptions: any = {};
    modalRef: BsModalRef;

    currentYear: number = new Date().getFullYear();
    years: number[] = [];
    currentMonth: number = new Date().getMonth() + 1;
    months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    constructor(
        private _gService: GlobalService,
        private _sService: SettingsService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        StartYear: [this.currentYear, Validators.required],
        StartMonth: [this.currentMonth, Validators.required],
        EndYear: [this.currentYear, Validators.required],
        EndMonth: [this.currentMonth, Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        let yearsList = this.currentYear + 5;
        
        for (let year = yearsList; year >= 2020; year--) {
            this.years.push(year);
        }

        this._gService.getAcademicYearsDataByInstID(this.instID).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                this.academicYearsData = JSON.parse(ayRes.result);
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    getMonthName(monthNumber: number) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
      
        return date.toLocaleString('en-US', {
          month: 'long',
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    openAcademicYearModal(academicyearId: number) {
        this.modalRef = this.modalService.show(AcademicyearsModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
               academicyearsID : academicyearId,
            } 
        });
    }


    saveAcademicYear() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                start_year: rObj.StartYear,
                start_month: rObj.StartMonth,
                end_year: rObj.EndYear,
                end_month: rObj.EndMonth
            }
            this._sService.saveAcademicYearData(ayData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Academic Year Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}