import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { PayrollService } from 'src/app/services/payroll.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeesService } from 'src/app/services/fees.service';
import { FeesTypemodalComponent } from 'src/app/components/modal/feesTypemodal/feesTypemodal.component';
import { FeesDiscountmodalComponent } from 'src/app/components/modal/feesDiscountmodal/feesDiscountmodal.component';

@Component({
  selector: 'app-fees-feesDiscount',
  templateUrl: './feesDiscount.component.html',
})

export class feesDiscountComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public feesDiscountData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Discount_code: ['',Validators.required],
        Discount_amount: [''],
        Discount_type: ['percentage'],
        Description: [''],
        Percentage: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_discount').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesDiscountData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openFeesDiscountEditModal(feesDiscountID: number) {
        this.modalRef = this.modalService.show(FeesDiscountmodalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                feesDiscountID : feesDiscountID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveData() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                name: rObj.Name,
                discount_code: rObj.Discount_code,
                discount_type: rObj.Discount_type,
                discount_amount: rObj.Discount_type == 'fix' ? rObj.Discount_amount : '',
                discount_percentage: rObj.Discount_type == 'percentage' ? rObj.Percentage : '',
                description: rObj.Description,

            }
            console.log(mData)
            this._aService.feesDiscountData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Fees Discount Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}