<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Class</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="/academic/section" class="content-header-link">Add Section</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Class
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Select Medium</label>
                            <small class="req"> *</small>
                            <div class="radio required" *ngFor="let medium of mediumData">
                                <label class="radio-inline">
                                    <input type="radio" value="{{medium.ID}}" formControlName="MediumType">{{medium.name}}
                                </label>
                            </div>
                            <div *ngIf="rObj.controls.MediumType.invalid && (rObj.controls.MediumType.dirty || rObj.controls.MediumType.touched)">
                                <p class="input-error-msg">Please select medium type</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Class Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Class">
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter class name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Sections</label>
                            <small class="req"> *</small>
                            <div class="section-list">
                                <label *ngFor="let section of sectionData.slice().reverse();" formArrayName="Section" style="margin-right: 15px;">
                                    <input type="checkbox" [value]="section.ID" (change)="onSectionSelect($event)">
                                    {{section.name}}		                        
                                </label>
                            </div>    
                            <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                                <p class="input-error-msg">Please select a section</p>
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveClass()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Class List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="classData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Medium</th>
                                <th>Class Name</th>
                                <th class="align-left">Sections</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let class of classData.slice().reverse()">
                            <td class="align-left">
                                <ng-container *ngFor="let medium of mediumData">
                                    <ng-container *ngIf="medium.ID == class.medium">
                                     {{medium.name}}
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>{{class.name}}</td>
                            <td>
                                <!-- <p *ngFor="let section of stringToArray(class.sections)">{{getSectionName(section)}}</p> -->
                                <ng-container *ngFor="let section of stringToArray(class.sections)">
                                    <ng-container *ngFor="let s of sectionData">
                                        <p class="td-section-list" *ngIf="section == s.ID">{{s.name}}</p>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openClassEditModal(class.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(class.ID, 'class', 'class')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!classData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>