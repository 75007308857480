<div class="section-wrapper">

    <section class="contact-us">
        <div class="container">

            <div class="row contact-us-wrapper">
                <div class="col-md-6">
                    <div class="location-wrapper">
                        <div class="google-map-wrapper">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30147.936761573445!2d72.945557!3d19.17369745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b8f20aff8003%3A0x162a4dae186b3659!2sMulund%20West%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1716957191480!5m2!1sen!2sin" 
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <p class="address">Mulund West, Mumbai, Maharashtra 400080</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="contact-us-form-wrapper">
                        <h2 class="section-title">Get in touch with us</h2>
                        <form class="contact-us-form" [formGroup]="rObj">
                            <input type="text" formControlName="Name" placeholder="Full name">
                            <input type="text" formControlName="Email" placeholder="Your email">
                            <textarea type="text" formControlName="Message" placeholder="Enter your message" rows="3"></textarea>
                        </form>
                        <div class="submit-btn-wrapper">
                            <button class="btn">
                                <input class="submit-btn" type="submit" (click)="submit()">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

</div>
