import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router,RouteReuseStrategy } from '@angular/router';
import { FeesService } from 'src/app/services/fees.service';
@Component({
  selector: 'app-fees-searchFeePay',
  templateUrl: './searchFeePay.component.html'
})

export class SearchFeePayComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public FeePayData: any[] = [];
    public feeTypeData: any[] = [];
    public studentData: any[] = [];
    


    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
    ) {}

    public rObj = this._fBuilder.group({
        Txn_id: ['', Validators.required], 
    })

    ngOnInit(): void {

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.feeTypeData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    gotoEditLink(dataID: number){
        this._router.navigate(['/fees/fees-collection/collectFee/'+dataID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    search() {
        let robj = this.rObj.value;
        console.log(robj.Txn_id)
        this._aService.getPayData(robj.Txn_id).subscribe(Res => {
            console.log(Res)
            if (Res.status === true) {
                this.FeePayData = JSON.parse(Res.result)
            } else {
                this.FeePayData = [];
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        
       
    }

    totalAmount(amt : any, f_amt :any){  
        return parseInt(amt)+parseInt(f_amt);   
    }
}