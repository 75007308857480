import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
  
@Component({
    selector: 'app-drivermodal',
    templateUrl: './drivermodal.component.html',
    styleUrls: [],
})

export class DrivermodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public driverID: number;
    public driverData: any[] = [];
    public vehicleData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: TransportService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Vehicle_no: ['', Validators.required],
        D_name: ['', Validators.required],
        D_phone: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],
        D_altPhone: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],
        D_address: ['', Validators.required],
        D_Confirmaddress: ['', Validators.required],
        Driving_licence: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'driver').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.driverData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vehicle').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.vehicleData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getDataByID(this.driverID, 'driver').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Vehicle_no: sData[0].vehicle_no,
                    D_name: sData[0].name,
                    D_phone: sData[0].phone_no,
                    D_altPhone: sData[0].alternate_no,
                    D_address: sData[0].present_addr,
                    D_Confirmaddress: sData[0].permanent_addr,
                    Driving_licence: sData[0].license_no,
                  
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateDriver() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.driverID,
                vehicle_no: rObj.Vehicle_no,
                name: rObj.D_name,
                phone_no: rObj.D_phone,
                alternate_no: rObj.D_altPhone,
                present_addr: rObj.D_address,
                permanent_addr: rObj.D_Confirmaddress,
                license_no: rObj.Driving_licence,
               
             }
            this._aService.driverData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Driver Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}