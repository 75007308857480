<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Subjects</h1>
        <div class="header-msg col-md-6">
                      </div>
        <!-- <div class="link-container col-md-3">
            <a href="/academic/section/add" class="content-header-link">Add Section</a>
        </div> -->
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Subject
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Subject Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Subject">
                            <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                                <p class="input-error-msg">Please enter subject name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Subject Type</label>
                            <small class="req"> *</small>
                            <div class="radio required">
                                <label class="radio-inline">
                                    <input type="radio" value="theory" formControlName="subjectType">Theory 
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" value="practical" formControlName="subjectType">Practical
                                </label>
                                <div *ngIf="rObj.controls.subjectType.invalid && (rObj.controls.subjectType.dirty || rObj.controls.subjectType.touched)">
                                    <p class="input-error-msg">Please select subject type</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Subject Code</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="subjectCode">
                            <div *ngIf="rObj.controls.subjectCode.invalid && (rObj.controls.subjectCode.dirty || rObj.controls.subjectCode.touched)">
                                <p class="input-error-msg">Please select subject code</p>
                            </div>
                        </div>
                        <div class="form-group form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveSubject()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Subjects  List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="subjectsData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Subject Name</th>
                                <th>Subject Type</th>
                                <th>Subject Code</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let subject of subjectsData">
                            <td class="align-left">{{subject.name}}</td>
                            <td>{{subject.type}}</td>
                            <td>{{subject.code}}</td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openSubjectEditModal(subject.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(subject.ID, 'subjects', 'subject')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!subjectsData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
        
</div>