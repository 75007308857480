<div class="console-content">
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-4"> Id Card Template</h1>
        <div class="link-container col-md-5">
         
        </div>
    </div>

    <div class="panel">
        <div class="filter-options">
            <form [formGroup]="rObj">    
            <div class="row">
                <div class="col-md-3 form-group">
                    <label>Department
                    <small class="req"> *</small></label>
                    <select name="class" formControlName="Department" class="form-select" style="width: 100%;">
                        <option value="">Please Select</option>
                        <option *ngFor="let dep of departmentData" value="{{dep.ID}}">{{dep.name}}</option>               
                    </select>
                        <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                        <p class="input-error-msg">Please select department</p>
                    </div>
                </div>
                <div class="col-md-3 form-group">
                    <label>Designation
                        <small class="req"> *</small></label>
                        <select name="class" formControlName="Designation" class="form-select" style="width: 100%;">
                            <option value="">Please Select</option>
                            <option *ngFor="let des of designationData" value="{{des.ID}}">{{des.name}}</option>               
                        </select>
                        <div *ngIf="rObj.controls.Designation.invalid && (rObj.controls.Designation.dirty || rObj.controls.Designation.touched)">
                            <p class="input-error-msg">Please select designation</p>
                        </div>
                </div> 
                <div class="col-md-3 form-group">
                    <label>Id Template
                        <small class="req"> *</small></label>
                        <select formControlName="Template" class="form-select" style="width: 100%;">
                            <option value="">Please Select</option>
                            <option *ngFor="let tem of idCardListData" value="{{tem.ID}}">{{tem.name}}</option> 
                        </select>
                        <div *ngIf="rObj.controls.Template.invalid && (rObj.controls.Template.dirty || rObj.controls.Template.touched)">
                            <p class="input-error-msg">Please select template</p>
                        </div>
                </div>
                <div class="col-md-3 btn-container" style="margin-top: 25px;" >
                    <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="search()">Search</button>
                </div> 
                <!-- <div class="col-md-2 btn-container" style="margin-top: 25px; padding-left: 39px;">
                    <button class="btn primary-btn small-btn" [disabled]="!selectedIds.length" (click)="search()">Generate</button>
                </div> -->
            </div> 
            </form>                     
        </div> 
        
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="staffListData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="openGenerateId(rObj.controls.Template.value)">Generate</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffListData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                                <th class="align-left">Staff Id</th>
                                <th>Staff Name</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Mobile</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of staffListData">
                                <td>
                                  <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                </td>
                                <td class="align-left">{{s.staff_id}} </td>
                                <td>{{s.full_name}}</td>
                                <td>{{s.department_name}}</td>
                                <td>{{s.designation_name}}</td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.role}}</td>
                                <!-- <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'students', ' student')"><i class="far fa-trash-alt"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!staffListData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>