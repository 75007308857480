<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Edit Admin Profile</h1>
            <div class="btn-container col-md-6">
                <button class="submit-btn primary-btn" [disabled]="!rObj.valid" (click)="updateInst()">Update</button>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-header">
                        <div class="panel-title">
                            Admin Details:
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>First name</label>
                                            <small class="req">*</small>
                                            <input type="text" formControlName="First_name" class="form-control date-input">
                                            <div *ngIf="rObj.controls.First_name.invalid && (rObj.controls.First_name.dirty || rObj.controls.First_name.touched)">
                                                <p class="input-error-msg">Please enter first name</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Last Name</label>
                                            <small class="req">*</small>
                                            <input type="text" formControlName="Last_name" class="form-control date-input"  >
                                            <div *ngIf="rObj.controls.Last_name.invalid && (rObj.controls.Last_name.dirty || rObj.controls.Last_name.touched)">
                                                <p class="input-error-msg">Please enter institution first name</p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Email</label>
                                            <small class="req"> *</small>
                                            <input type="email" formControlName="Admin_Email" class="form-control date-input" >
                                            <div *ngIf="rObj.controls.Admin_Email.invalid && (rObj.controls.Admin_Email.dirty || rObj.controls.Admin_Email.touched)">
                                                <p class="input-error-msg">Please enter institution first name</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                            <div class="form-group">
                                <label>Admin Photo</label>
                                <div class="inst-icon-container">
                                    <div class="img-wrapper">
                                        <img class="inst-icon photo-preview" [src]="imageSrc ? imageSrc : '/assets/img/default-user-avatar.png'">
                                    </div>
                                    <div class="upload-btn-wrapper" *ngIf="!imageSrc">
                                        <button class="upload-btn">Select Photo</button>
                                        <input type="file" (change)="onFileChange($event)">
                                    </div>
                                    <div class="upload-btn-wrapper" *ngIf="imageSrc">
                                        <button class="upload-btn" (click)="removeImg()">Remove Photo</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>