<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-6">Branches List</h1>
        <div class="link-container col-md-6">
            <a href="/branches/add" class="content-header-link">Add New Branch</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">               
                <!-- <div class="action-btn-container">
                    <span class="rows_selected" id="select_count">0 Selected</span>
                    <button id="delete_records" data-name="institutions" class="btn primary-btn small-btn">Delete</button>
                </div> -->

                <ng-container *ngIf="instList.length">
                    <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                        <span class="rows_selected">{{selectedIds.length}} Selected</span>
                        <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('institutions', 'institution')">Delete</button>
                    </div>
                </ng-container>
                <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="instList.length">
                    <thead>
                        <tr>
                            <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
                            <th>Code</th>
                            <th>Institution Branch Name</th>
                            <th>Institution Address</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th class="no-sort">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let inst of instList">
                            <td>
                                <input type="checkbox" class="list-checkbox" [checked]="inst.isSelected" (change)="onChecked(inst.ID, $event)">
                            </td>
                            <td class="code">{{inst.inst_code}}</td>
                            <td class="name">{{inst.inst_name}}</td>
                            <td class="branch">{{inst.inst_address}}</td>
                            <td class="email">{{inst.inst_email}}</td>
                            <td class="phone">{{inst.inst_phone}}</td>
                            <td class="action-btn">
                                <a title="Edit" (click)="goToEditLink(inst.ID)" class="edit-btn"><i class="far fa-edit"></i></a>
                                <a title="Delete" class="del-btn" (click)="deleteData(inst.ID, 'institutions', 'institution')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!instList.length">
                    <div class="noDataWrapper" style="display: flex;align-items: center; justify-content: center;">
                        <img src="/assets/img/No-data-found.png" width="370px">
                    </div>
                    <p style="text-align: center;">Data Not Found</p>
                </div>
            </div>
        </div>
    </div>
</div>