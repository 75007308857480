<div class="modal-header">
    <h5 class="modal-title">Pay Fees</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Staff Name</label>
                <small class="req "> *</small>
                <input type="text" class="form-control" formControlName="Staff_name" readonly>
            </div>
            <div class="form-group">
                <label>Month-Year</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Month_year"  readonly>
            </div>
           
            <div class="form-group">
                <label>Payment Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Date"  readonly>
            </div>
           <div class="form-group">
                <label>Payment Amount</label>
                <small class="req "> *</small>
                <input type="number" class="form-control " formControlName="Payment_amount" readonly>
            </div>
            <div class="form-group">
                <label>Payment Mode</label>
                <br>
                <div class="form-check inline" style="margin-right:20px;">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cash" >
                    <label class="form-check-label" for="flexCheckDefault">Cash</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cheque">
                    <label class="form-check-label" for="flexCheckDefault">Cheque</label>
                </div>   
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="bank_transfer">
                    <label class="form-check-label" for="flexCheckDefault">Bank Transfer</label>
                </div>  
            </div>
        
            <div class="form-group ">
                <label>Note</label>
                <textarea type="text" class="form-control " formControlName="Note"></textarea>
            </div>
            
            <!-- <div class="modal-footer">
                <div class="total-fees">{{totalAmount}}</div>
                  <button type="submit" class="btn primary-btn small-btn" (click)="Pay()" >Pay</button>
                  <button type="submit" class="btn primary-btn small-btn" (click)="PayAndPrint()" >Pay &amp; Print</button>
              </div> -->
        </form>
    </div>
</div>
<div class="modal-footer" style="justify-content: space-between;">
    <!-- <div class="total-fees">{{totalAmount}}</div> -->
    <div class="row">
        <div class="col-md-4">
        <button type="submit" class="btn primary-btn small-btn" (click)="Pay()" >Pay</button>
        </div>
        <div class="col-md-8">
        <button type="submit" class="btn primary-btn small-btn" (click)="PayAndPrint()" >Pay &amp; Print</button>
        </div>
    </div>
      <!-- <button type="submit" class="btn btn-primary payprint_btn" name="payprint_btn" data-whatever="@mdo" data-toggle="modal1" data-target="#viewModal">Pay &amp; Print </button>  -->
  </div>
