<div class="modal-header">
    <h5 class="modal-title">Edit Syllabus</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Syllabus For</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Syllabus_for">
                <div *ngIf="rObj.controls.Syllabus_for.invalid && (rObj.controls.Syllabus_for.dirty || rObj.controls.Syllabus_for.touched)">
                    <p class="input-error-msg">Please enter syllabus name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Class</label>
                <small class="req"> *</small>
                <select name="class" formControlName="Class" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                 </select>    
                 <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                    <p class="input-error-msg">Please select Class</p>
                </div>
            </div>
            <div class="form-group">
                <label>Sections</label>
                <small class="req"> *</small>
                <select name="class" formControlName="Section" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let s of sectionData" value="{{s.ID}}">{{s.name}}</option>               
                 </select> 
                <!-- <div class="section-list">
                    <label *ngFor="let section of sectionData.slice().reverse();" formArrayName="Section" style="margin-right: 15px;">
                        <input type="checkbox" [value]="section.ID" (change)="onSectionSelect($event)">
                        {{section.name}}		                        
                    </label>
                </div>     -->
                <div *ngIf="rObj.controls.Section.errors?.['required'] && rObj.controls.Section.touched">
                    <p class="input-error-msg">Please select a section</p>
                </div>
            </div>
            <div class="form-group">
                <label>Subject</label>
                <small class="req"> *</small>
                <select name="class" formControlName="Subject" class="form-select" >
                    <option value="">Please Select</option>
                    <option *ngFor="let sub of subjectData" value="{{sub.ID}}">{{sub.name}}</option>               
                 </select>    
                 <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                    <p class="input-error-msg">Please enter class name</p>
                </div>
            </div>
            <div style="margin-left: 86% !important;">
                <button class="btn default-btn extra-small-btn inline" (click)="addlData()">Add </button>
            </div>
            <div formArrayName="Lessons">
                <div *ngFor="let detail of lData().controls; let i=index" style="margin-bottom: 15px ;">
                    <div class="row" [formGroupName]="i">
                        <div class="col-md-10">
                            <div class="form-group">
                                <label>Lesson Name</label>
                                <small class="req"> *</small>
                                <input type="text" class="form-control" formControlName="Name">
                            </div>
                        </div>
                        <div class="col-md-1" >
                            <button *ngIf="i != 0" class="default-btn"style="margin-top: 300%;" (click)="removeLData(i, lData().controls[i].value.Lesson_id)"><i class="far fa-trash-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateSyllabus()">Update</button>
            </div>
        </form> 
    </div>
</div>
