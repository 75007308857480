import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LibraryService } from 'src/app/services/library.service';
import { BookCategorymodalComponent } from 'src/app/components/modal/bookCategorymodal/bookCategorymodal.component';
import { AddbookmodalComponent } from 'src/app/components/modal/addBookmodal/addBookmodal.component';


@Component({
  selector: 'app-library-addBook',
  templateUrl: './addBook.component.html',
})

export class addbookComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    
    public bookData: any[] = [];
    public bookCategoryData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: LibraryService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Title: ['', Validators.required],
        Book_no: ['', Validators.required],
        Isbn_no: ['', Validators.required],
        Author: ['', Validators.required],
        Publisher: ['', Validators.required],
        Qty: ['', Validators.required],
        Category: ['', Validators.required],
        Subject: ['', Validators.required],
        Language: ['', Validators.required],
        Cost: ['', Validators.required],
        Rack_no: ['', Validators.required],
        Book_image: [''],
      
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'book_category').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.bookCategoryData = JSON.parse(mRes.result);
                // console.log(this.bookData)
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'books').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.bookData = JSON.parse(mRes.result);
                console.log(this.bookData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openAddbookEditModal(bookID: number) {
        this.modalRef = this.modalService.show(AddbookmodalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                bookID: bookID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }

    saveBook() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                title: rObj.Title,
                book_no: rObj.Book_no,
                isbn_no: rObj.Isbn_no,
                author: rObj.Author,
                publisher: rObj.Publisher,
                qty: rObj.Qty,
                category: rObj.Category,
                subject: rObj.Subject,
                language: rObj.Language,
                cost: rObj.Cost,
                rack_no: rObj.Rack_no,
                cover_path: this.imageSrc,

            }
            console.log(mData)
            this._aService.bookData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Book details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}