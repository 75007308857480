<div class="modal-header">
    <h5 class="modal-title">Pay Fees</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Date">
                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                    <p class="input-error-msg">Please select Date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Payment Mode</label>
                <br>
                <div class="form-check inline" style="margin-right:20px;">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cash">
                    <label class="form-check-label" for="flexCheckDefault">Cash</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="cheque">
                    <label class="form-check-label" for="flexCheckDefault">Cheque</label>
                </div> 
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="dd">
                    <label class="form-check-label" for="flexCheckDefault">DD</label>
                </div>   
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="bank_transfer">
                    <label class="form-check-label" for="flexCheckDefault">Bank Transfer</label>
                </div>  
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="upi">
                    <label class="form-check-label" for="flexCheckDefault">UPI</label>
                </div>  
                <div class="form-check inline">
                    <input class="form-check-input" type="radio" formControlName="Payment_mode" value="card">
                    <label class="form-check-label" for="flexCheckDefault">Card</label>
                </div>  
            </div>
            <div class="form-group">
                <label>Note</label>
                <textarea type="text" class="form-control" formControlName="Note"></textarea>
            </div>            
            <div *ngFor="let f of feeDataArray">
                <h5 style="font-weight: 600;font-size: 15px;">{{f[0].fees_group_name}} </h5>
                <div class="row" style="text-align: center;">
                    <div class="col-md-6">
                        Amount
                    </div>
                    <div class="col-md-6">
                        {{f[0].total_amount}}
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col-md-6">
                        Fine
                    </div>
                    <div class="col-md-6">
                        {{dueCheck(f[0].due_date) ? (f[0].fine_type == 'percentage' ? (f[0].total_amount * f[0].percentage)/100 : f[0].fine_amount) : '-'}}
                    </div>
                </div>
            </div>
            <div class="row" style="text-align: center;">
                <div class="col-md-6">
                    <h5 style="font-weight: 700; font-size: 15px;">Total Amount </h5>
                </div>
                <div class="col-md-6">
                    <span><h5 style="font-weight: 700; font-size: 15px;">{{total_amt_final}}</h5></span>
                </div>
            </div>
        </form>   
    </div>
</div>
<div class="modal-footer" style="justify-content: space-between;">
    <!-- <div class="total-fees">{{totalAmount}}</div> -->
    <div class="row">
        <div class="col-md-4">
        <button type="submit" class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="Pay()">Pay</button>
        </div>
        <div class="col-md-8">
        <button type="submit" class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="PayAndPrint()">Pay &amp; Print</button>
        </div>
    </div>
    <!-- <button type="submit" class="btn btn-primary payprint_btn" name="payprint_btn" data-whatever="@mdo" data-toggle="modal1" data-target="#viewModal">Pay &amp; Print </button>  -->
</div>
