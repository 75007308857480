import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
  
@Component({
    selector: 'app-pick-upmodal',
    templateUrl: './pick-upmodal.component.html',
    // styleUrls: ['./pick-upmodal.component.css'],
})

export class PickUpModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public pickUpID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public pickUpData: any[] = [];

    constructor(
        private _gService: GlobalService,
        private _tService: TransportService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        PickUpPoint: ['', Validators.required],
        Latitude: ['', Validators.required],
        Longitude: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.pickUpID, 'pickup_points').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    PickUpPoint: sData[0].name,
                    Latitude: sData[0].latitude,
                    Longitude: sData[0].longitude,
                });
            }
            this._gService.showLoader(false);
        })
    }

    update() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.pickUpID,
                name: rObj.PickUpPoint,
                latitude: rObj.Latitude,
                longitude: rObj.Longitude,
            }
            this._tService.pickUpData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Pick Up Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}