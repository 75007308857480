<div class="modal-header">
    <h5 class="modal-title">Edit Route Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form method="post" [formGroup]="rObj">
            <div class="form-group">
                <label>Vehicle No.</label>
                    <small class="req"> *</small>
                    <select formControlName="Vehicle_no" class="form-control" >
                        <option value="">Please Select</option>
                        <option *ngFor="let No of vehicleData" value="{{No.ID}}">{{No.vehicle_no}}</option>
    
                     </select>
                     <div *ngIf="rObj.controls.Vehicle_no.invalid && (rObj.controls.Vehicle_no.dirty || rObj.controls.Vehicle_no.touched)">
                        <p class="input-error-msg">Please select vehicle number</p>
                    </div>
            </div>

            <div class="form-group">
                <label>Route Name</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Route_name" class="form-control">
                <div *ngIf="rObj.controls.Route_name.invalid && (rObj.controls.Route_name.dirty || rObj.controls.Route_name.touched)">
                    <p class="input-error-msg">Please enter route name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Route Start Place</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Route_start" class="form-control">
                <div *ngIf="rObj.controls.Route_start.invalid && (rObj.controls.Route_start.dirty || rObj.controls.Route_start.touched)">
                    <p class="input-error-msg">Please enter route start</p>
                </div>
            </div>
            <div class="form-group">
                <label>Route End Place</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Route_end" class="form-control">
                <div *ngIf="rObj.controls.Route_end.invalid && (rObj.controls.Route_end.dirty || rObj.controls.Route_end.touched)">
                    <p class="input-error-msg">Please enter route end</p>
                </div>
            </div>
            <div class="form-group">
                <label>Route Fare</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Route_fare" class="form-control">
                <div *ngIf="rObj.controls.Route_fare.invalid && (rObj.controls.Route_fare.dirty || rObj.controls.Route_fare.touched)">
                    <p class="input-error-msg">Please enter route fare</p>
                </div>
            </div> 

            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateRoute()">Update</button>
            </div>
        </form>
    </div>
</div>
