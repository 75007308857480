import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class StaffService {
  constructor(
    private _http: HttpClient,
  ) { }

  private department = `${environment.APIURL}/staff/department/`;
  private designation = `${environment.APIURL}/staff/designation/`;
  private leave = `${environment.APIURL}/staff/leaveType/`;
  private staff = `${environment.APIURL}/staff/addUpdate/`;
  private staffList = `${environment.APIURL}/staff/getStaffsByDeptDesn/`;
  private applyLeave = `${environment.APIURL}/staff/applyLeave/`;
  private getapplyLeave = `${environment.APIURL}/staff/getLeavesByStaffID/`;
  private addleave = `${environment.APIURL}/staff/addLeave/`;
  private getTeachers = `${environment.APIURL}/staff/getTeachersData/`;
  private getIdCardTemplate = `${environment.APIURL}/idCard/getTemplates/?type=staff`;
  private getstaffDocument = `${environment.APIURL}/staff/getStaffDocumentByID/`
  private getstaffDataByUserId = `${environment.APIURL}/common/getDataByUserID/`


  departmentData(data: object): Observable<any> {
    return this._http.post(this.department, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  designationData(data: object): Observable<any> {
    return this._http.post(this.designation, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  leaveData(data: object): Observable<any> {
    return this._http.post(this.leave, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  staffData(data: object): Observable<any> {
    return this._http.post(this.staff, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  staffListData(instID: number, academicID: number, departmentID: any, designationID: any): Observable<any> {
    return this._http.get(this.staffList+'?inst_id='+instID+'&academic_id='+academicID+'&department='+departmentID+'&designation='+designationID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getapplyLeaveData(instID: number, academicID: number, staff_ID: any): Observable<any> {
    return this._http.get(this.getapplyLeave+'?inst_id='+instID+'&academic_id='+academicID+'&staff_id='+ staff_ID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  applyLeaveData(data: object): Observable<any> {
    return this._http.post(this.applyLeave, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  addLeaveData(data: object): Observable<any> {
    return this._http.post(this.addleave, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getTeachersData(instID: number, academicID: number): Observable<any> {
    return this._http.get(this.getTeachers+'?inst_id='+instID+'&academic_id='+academicID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getIdCardTemplateData(): Observable<any> {
    return this._http.get(this.getIdCardTemplate, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getStaffDocumentData(staffID: number): Observable<any> {
    return this._http.get(this.getstaffDocument+'?staff_id='+staffID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  getStaffDataByUserIdData(userId: number, module: any): Observable<any> {
    return this._http.get(this.getstaffDataByUserId+'?user_id='+userId+'&module='+module, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

}