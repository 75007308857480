import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AdminHeaderComponent } from 'src/app/components/common/admin-header/admin-header.component';
// import { AdminSidebarComponent } from 'src/app/components/common/admin-sidebar/admin-sidebar.component';
import { WebsiteComponent } from './website.component';
import { WebsitePageComponent } from './page/page.component';
// import { modulesAccessComponent } from './modules-access/modules-access.component';

// Default Theme Components //
import { WebsiteDefaultHeaderComponent } from './themes/default/header/default-header.component';
import { WebsiteDefaultFooterComponent } from './themes/default/footer/default-footer.component';
//aliceBlue
import { WebsiteAliceBlueHeaderComponent } from './themes/aliceBlue/header/aliceBlue-header.component';
import { WebsiteAliceBlueFooterComponent } from './themes/aliceBlue/footer/aliceBlue-footer.component';
//monochromeBlue
import { WebsiteMonochromeBlueHeaderComponent } from './themes/monochromeBlue/header/monochromeBlue-header.component';
import { WebsiteMonochromeBlueFooterComponent } from './themes/monochromeBlue/footer/monochromeBlue-footer.component';
//greenOpaque
import { WebsiteGreenOpaqueHeaderComponent } from './themes/greenOpaque/header/greenOpaque-header.component';
import { WebsiteGreenOpaqueFooterComponent } from './themes/greenOpaque/footer/greenOpaque-footer.component';
//pinkGrey
import { WebsitePinkGreyHeaderComponent } from './themes/pinkGrey/header/pinkGrey-header.component';
import { WebsitePinkGreyFooterComponent } from './themes/pinkGrey/footer/pinkGrey-footer.component';
//violet 
import { WebsiteVoiletHeaderComponent } from './themes/violet/header/violet-header.component';
import { WebsiteVioletFooterComponent } from './themes/violet/footer/violet-footer.component';

import { AdmissionFormComponent } from './components/admissionForm/admissionForm.component';
import { ContactUsComponent } from './components/contactUsForm/contactUsForm.component';
import { TrackingModalComponent } from 'src/app/components/modal/trackingmodal/trackingmodal.component';
let routes: Routes = [
    {
        path: '', component: WebsiteComponent,
        children: [
            { path: 'website', component: WebsitePageComponent },
            { path: 'website/:slug', component: WebsitePageComponent },
        ] 
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
  })

export class WebsiteRoutingModule { }
export const WebsiteRoutes = [
    WebsiteComponent,
    WebsitePageComponent,
    WebsiteDefaultHeaderComponent,
    WebsiteDefaultFooterComponent,
    WebsiteAliceBlueHeaderComponent,
    WebsiteAliceBlueFooterComponent,
    WebsiteMonochromeBlueHeaderComponent,
    WebsiteMonochromeBlueFooterComponent,
    WebsiteGreenOpaqueHeaderComponent,
    WebsiteGreenOpaqueFooterComponent,
    WebsitePinkGreyHeaderComponent,
    WebsitePinkGreyFooterComponent,
    WebsiteVoiletHeaderComponent,
    WebsiteVioletFooterComponent,
    AdmissionFormComponent,
    ContactUsComponent,
    TrackingModalComponent
]