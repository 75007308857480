import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { HostelService } from 'src/app/services/hostel.service';
  
@Component({
    selector: 'app-hostelRoomsmodal',
    templateUrl: './hostelRoomsmodal.component.html',
    styleUrls: ['./hostelRoomsmodal.component.css'],
})

export class HostelRoomsmodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public hostelRoomID: number;

    public HostelData: any[] = [];
    public RoomtypeData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: HostelService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Room_no: ['', Validators.required],
        Hostels: ['', Validators.required],
        Room_Type: ['', Validators.required],
        No_beds: ['', Validators.required],
        Cost: ['', Validators.required],
        Description: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.HostelData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room_type').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.RoomtypeData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getDataByID(this.hostelRoomID, 'hostel_room').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Room_no: sData[0].room_no,
                    Hostels: sData[0].hostel,
                    Room_Type: sData[0].room_type,
                    No_beds: sData[0].no_beds,
                    Cost: sData[0].cost_bed,
                    Description: sData[0].description,
                });
            }
            this._gService.showLoader(false);
        })


    }

    updateRoomDetails() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.hostelRoomID,
                room_no: rObj.Room_no,
                hostel: rObj.Hostels,
                room_type: rObj.Room_Type,
                no_beds: rObj.No_beds,
                cost_bed: rObj.Cost,
                description: rObj.Description,
            }
            this._aService.hostelRoomsData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Hostel Room details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}