import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { UserPanelRoutingModule, UserPanelRoutes } from './userpanel-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UniquePipe } from 'src/app/pipe/unique.pipe';
import { Select2Module } from 'ng-select2-component';

@NgModule({
  declarations: [
    UserPanelRoutes,
    UniquePipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    UserPanelRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    AutocompleteLibModule,
    AngularEditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    Select2Module
    
  ],
  exports: []
})
export class UserPanelModule { }