import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';

@Component({
  selector: 'app-staff-bulkImport',
  templateUrl: './bulkImport.component.html',
})

export class StaffbulkImportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public bulkImportData: any[] = [];
    public departmentData: any[] = [];
    public designationData: any[] = [];
    // public classSectionData: any[] = [];
    fileSrc:any = '';
    status:boolean = false;

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Department: ['', Validators.required],
        Designation: ['', Validators.required],
        Import_file: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.designationData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })


        // this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        //     this._gService.showLoader(true);
        //     this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
        //         console.log(dataRes)
        //         if(dataRes.status === true) {
        //             let result = JSON.parse(dataRes.result)
        //             this.classSectionData = JSON.parse(result[0].sections)
        //             console.log(this.classSectionData)

        //         }
        //     })
        //     this._gService.showLoader(false);
        // })
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.fileSrc = reader.result;          
           }
        }
    }

    // openMediumEditModal(mediumID: number) {
    //     this.modalRef = this.modalService.show(MediumModalComponent, {
    //         class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
    //         backdrop : 'static',
    //         keyboard : false,
    //         initialState : {
    //             mediumID: mediumID,
    //         } 
    //     });
    // }

    // deleteData(dataID: number, moduleName: string, moduleTitle: string) {
    //     this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    // }

    importStaff() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                department: rObj.Department,
                designation: rObj.Designation,
                import_file: this.fileSrc
            }
            console.log(mData)
            this._aService.bulkImportData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/student/list/'+rObj.Department+'/'+rObj.Designation]);
                    this._gService.triggerSnackbar('Bulk imported  Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}