import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { AssignmentModalComponent } from 'src/app/components/modal/assignmentmodal/assignmentmodal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-homework-homeworkList',
  templateUrl: './homeworkList.component.html',
})

export class HomeworkListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectData: any[] = [];
    public assignmentData: any[] = [];
    public mediumData: any[] = [];
    imageSrc:any = '';
    status:boolean = false;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AssignmentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Subject: ['', Validators.required],
        Medium: [''],
        Homework_date: ['', Validators.required],
        Submission_date: ['', Validators.required],
        Document: ['', Validators.required],
        Description: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.subjectData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assignment').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.assignmentData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        
         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           
        }
        // alert(this.status)
        // alert(this.imageSrc)
    }

    removeImg() {
        this.status = false
        this.imageSrc = '';
    }
    openEditModal(homeworkID: number) {
        this._router.navigate(['/homework/edit/'+homeworkID])
    }

    getDate(date: any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

}