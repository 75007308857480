<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-6">Notifications List</h1>
        <div class="header-msg col-md-5"></div>
        <!-- <div class="link-container col-md-5">
            <a href="../staff/add/" class="content-header-link">Add New Staff</a>
            <a href="../staff/import/" class="content-header-link">Import Staff</a>
        </div> -->
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="notificationData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('staff', 'staff')">Delete</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="notificationData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
		                        <th class="align-left" style="width: 76%;">Title</th>
		                        <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of notificationData">
                                <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                </td>
                                <td class="align-left" (click)="gotoPage(s.link)">{{s.title}}</td>
                                <td>{{getDate(s.datetime)}}</td>
                                <!-- <td class="align-left">
                                    <img [src]="s.photo_path ? imgURL+s.photo_path : (s.gender == 'male' ? '/assets/img/male-worker.png' : '/assets/img/female-worker.png')" style="width: 40px; border: 1px solid #dcdcdc;">
                                    {{s.full_name}}
                                </td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.email}}</td>
                                <td>{{s.department_name}}</td>
                                <td>{{s.designation_name}}</td>
                                <td>{{getUserRoleName(s.role)}}</td>
                                <td>{{s.gender}}</td>
                                <td>{{s.qualification}}</td> -->
                                <!-- <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'staff', 'delete staff')"><i class="far fa-trash-alt"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!notificationData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>            
                            
            </div>
        </div>
    </div>
</div>