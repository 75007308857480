<div class="modal-header">
    <h5 class="modal-title">Add Member</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label> Library Card Number</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="libraryCard_no">
                <div *ngIf="rObj.controls.libraryCard_no.invalid && (rObj.controls.libraryCard_no.dirty || rObj.controls.libraryCard_no.touched)">
                    <p class="input-error-msg">Please enter card number</p>
                </div>
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="addMember()">Add </button>
            </div>
        </form>
    </div>
</div>
