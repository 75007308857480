<div class="modal-header">
    <h5 class="modal-title">Edit Vehicle Details</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Route</label>
                    <small class="req"> *</small>
                    <select formControlName="Route" class="form-control" >
                        <option value="">Please Select</option>
                        <option *ngFor="let r of routeData" value="{{r.ID}}">{{r.route_name}}</option>
                     </select>
                     <div *ngIf="rObj.controls.Route.invalid && (rObj.controls.Route.dirty || rObj.controls.Route.touched)">
                        <p class="input-error-msg">Please select Route</p>
                    </div>
            </div>
            <div class="form-group">
                <label>Destination Name</label>
                <small class="req"> *</small>
                <input type="text" formControlName="Dest_name" class="form-control" >
                <div *ngIf="rObj.controls.Dest_name.invalid && (rObj.controls.Dest_name.dirty || rObj.controls.Dest_name.touched)">
                    <p class="input-error-msg">Please enter destination name</p>
                </div>
            </div>
            <div class="form-group">
                <label>Stop Time</label>
                <small class="req"> *</small>
                <input type="time" formControlName="Time" class="form-control" >
                <div *ngIf="rObj.controls.Time.invalid && (rObj.controls.Time.dirty || rObj.controls.Time.touched)">
                    <p class="input-error-msg">Please select time</p>
                </div>
            </div>
            <div class="form-group">
                <label>Fare</label>
                <small class="req"> *</small>
                <input type="number" formControlName="Fare" class="form-control" >
                <div *ngIf="rObj.controls.Fare.invalid && (rObj.controls.Fare.dirty || rObj.controls.Fare.touched)">
                    <p class="input-error-msg">Please enter fare </p>
                </div>
            </div>
           
            <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="updateDestination()">Update</button>
            </div>
        </form> 
    </div>
</div>
