import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { InstService } from 'src/app/services/inst.service';

@Component({
  selector: 'app-branches-edit',
  templateUrl: './edit.component.html'
})

export class BranchesEditComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID: any = 0;
    public instData = [];

    dtOptions: DataTables.Settings = {};

    imageSrc: any = '';
    status: boolean = false;

    removeImgStatus: boolean = false;

    public imageURL = 'https://apiuat.edunationpro.com/uploads/';

    constructor(
        private _fBuilder: FormBuilder,
        private _gService: GlobalService,
        private _userService: UserService,
        private _instService: InstService,
        private _router: Router,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Name: ['', Validators.required],
        Address: ['', Validators.required],
        Pincode: ['', [Validators.required]],
        Country: ['', Validators.required],
        Email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        Phone: ['', [Validators.required, Validators.pattern(/^(6|7|8|9)(\d{9})$/)]],
        Fax: [''],
        Code: [''],
        IconPath: ['']
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this.instID = this._aRoute.snapshot.paramMap.get('instId');
        
        this._gService.getDataByID(this.instID, 'institutions').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let instData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Name: instData[0].inst_name,
                    Address: instData[0].inst_address,
                    Pincode: instData[0].inst_pincode,
                    Country: instData[0].inst_country,
                    Email: instData[0].inst_email,
                    Phone: instData[0].inst_phone,
                    Fax: instData[0].inst_fax,
                    Code: instData[0].inst_code,
                });
                this.imageSrc = instData[0].icon_path ? this.imageURL+instData[0].icon_path : '';
            }
            this._gService.showLoader(false);
        })
    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
        }
    }

    removeImg() {
        this.imageSrc = '';
        this.removeImgStatus = true;
    }

    updateInst() {
        this._gService.showLoader(true);
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let instData = {
                inst_id: this.instID,
                inst_name: rObj.Name,
                inst_address: rObj.Address,
                inst_pincode: rObj.Pincode,
                inst_country: rObj.Country,
                inst_email: rObj.Email,
                inst_phone: rObj.Phone,
                inst_fax: rObj.Fax,
                inst_code: rObj.Code,
                icon_path: this.status ? this.imageSrc : '',
                remove_icon: this.removeImgStatus,
                
            }
            console.log(instData);
            this._instService.editInstData(instData).subscribe(instRes => {
                console.log(instRes)
                if (instRes.status === true) {
                    this._router.navigate(['/branches/list']);
                    this._gService.triggerSnackbar('Branches Updated Successfully', 'success', true)
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
                this._gService.showLoader(false);
            }, err => {
                console.log(err);
                this._gService.showLoader(false);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
}