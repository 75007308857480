
<div class="section-wrapper">

        <section class="admission-form">
            <div class="container">
                <div class="admission-form-main-wrapper">
                    <div class="row admission-title-wrapper">
                        <h2 class="section-title">Online Admission Form</h2>
                        <div class="btn-main-wrapper">
                            <a class="button-wrapper">
                                <button class="secondary-btn mt-0" (click)="openStatusModal()" *ngIf="!showConfirmation">Check Your Form Status</button>
                            </a>
                            <a class="button-wrapper" href="#">
                                <button class="cta-btn mt-0" *ngIf="!showConfirmation">Download Application Form</button>
                            </a>
                            <a class="button-wrapper" href="#">
                                <button class="cta-btn mt-0" (click)="Print()" *ngIf="showConfirmation">Print</button>
                            </a>
                        </div>
                    </div>
                    <form [formGroup]="rObj" class="admission-form-wrapper" *ngIf="!showConfirmation">
                        <ng-container formArrayName="inputList">
                            <ng-container *ngFor="let obj of rObj.controls.inputList.controls; let i = index">
                                <div class="control-box-wrapper" [formGroupName]="i">
                                    <div class="instruction content-box">
                                        <p class="heading">Instructions</p>
                                        <ul class="instruction-list">
                                            <li class="list-point">
                                                Please enter your institution online admission instructions here.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="basic-details content-box" *ngIf="allowedFields.includes('1') || allowedFields.includes('2') || allowedFields.includes('3') || allowedFields.includes('4') || allowedFields.includes('5') || allowedFields.includes('6') || allowedFields.includes('7') || allowedFields.includes('20')">
                                        <p class="heading">
                                            Basic Details
                                        </p>
                                        <div class="content-form-wrapper">
                                            <div class="row row-wrapper">
                                                <div class="col-md-4" *ngIf="allowedFields.includes('1')">
                                                    <div class="input-box">
                                                        <select formControlName="Class" class="class-number" required>
                                                            <option value="">--Select class--</option>
                                                            <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                                        </select>
                                                        <div *ngIf=" rObj.controls.inputList.controls[i].controls.Class.invalid && (rObj.controls.inputList.controls[i].controls.Class.dirty || rObj.controls.inputList.controls[i].controls.Class.touched)">
                                                            <p class="input-error-msg">Please select Class Name</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('2')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="First_name" placeholder="Enter first name" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.First_name.invalid && (rObj.controls.inputList.controls[i].controls.First_name.dirty || rObj.controls.inputList.controls[i].controls.First_name.touched)">
                                                        <p class="input-error-msg">Please select First Name</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('3')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="Last_name" placeholder="Enter last name" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Last_name.invalid && (rObj.controls.inputList.controls[i].controls.Last_name.dirty || rObj.controls.inputList.controls[i].controls.Last_name.touched)">
                                                        <p class="input-error-msg">Please select Last Name</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-wrapper" *ngIf="allowedFields.includes('5')">
                                                <div class="col-md-4">
                                                    <div class="input-box">
                                                        <select formControlName="Gender" class="class-number" required>
                                                            <option value="">Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                        <div *ngIf=" rObj.controls.inputList.controls[i].controls.Gender.invalid && (rObj.controls.inputList.controls[i].controls.Gender.dirty || rObj.controls.inputList.controls[i].controls.Gender.touched)">
                                                            <p class="input-error-msg">Please select Gender</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('4')">
                                                    <div class="input-box">
                                                        <input type="date" formControlName="Birth_date" placeholder="Date of birth" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Birth_date.invalid && (rObj.controls.inputList.controls[i].controls.Birth_date.dirty || rObj.controls.inputList.controls[i].controls.Birth_date.touched)">
                                                        <p class="input-error-msg">Please select Birth date</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('6')">
                                                    <div class="input-box">
                                                        <input type="number" formControlName="Mobile_no" placeholder="Mobile Number" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Mobile_no.invalid && (rObj.controls.inputList.controls[i].controls.Mobile_no.dirty || rObj.controls.inputList.controls[i].controls.Mobile_no.touched)">
                                                        <p class="input-error-msg">Please select Mobile no</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-wrapper" *ngIf="allowedFields.includes('7')">
                                                <div class="col-md-6">
                                                    <div class="input-box">
                                                        <input type="email" formControlName="Email" placeholder="Email" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Email.invalid && (rObj.controls.inputList.controls[i].controls.Email.dirty || rObj.controls.inputList.controls[i].controls.Email.touched)">
                                                        <p class="input-error-msg">Please select Email</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="allowedFields.includes('20')">
                                                    <div class="input-box std-photo-wrapper">
                                                        <label for="std-photo">Upload Student Photo:</label>
                                                        <input class="PhotoUpload" type="file" id="std-photo" formControlName="PhotoUpload" placeholder="Upload student photo">
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Birth_date.invalid && (rObj.controls.inputList.controls[i].controls.Birth_date.dirty || rObj.controls.inputList.controls[i].controls.Birth_date.touched)">
                                                        <p class="input-error-msg">Please select Birth date</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="parent-detail content-box"  *ngIf="allowedFields.includes('10') || allowedFields.includes('11')">
                                        <p class="heading">
                                            Parent Details
                                        </p>
                                        <div class="content-form-wrapper">
                                            <div class="row row-wrapper">
                                                <div class="col-md-6"  *ngIf="allowedFields.includes('10')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="Father_name" placeholder="Enter your father name" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Father_name.invalid && (rObj.controls.inputList.controls[i].controls.Father_name.dirty || rObj.controls.inputList.controls[i].controls.Father_name.touched)">
                                                        <p class="input-error-msg">Please select Father name</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="input-box"  *ngIf="allowedFields.includes('11')">
                                                        <input type="text" formControlName="Mother_name" placeholder="Enter your mother name" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Mother_name.invalid && (rObj.controls.inputList.controls[i].controls.Mother_name.dirty || rObj.controls.inputList.controls[i].controls.Mother_name.touched)">
                                                        <p class="input-error-msg">Please select Mother name</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="guardian-detail content-box" *ngIf="allowedFields.includes('12') || allowedFields.includes('13') || allowedFields.includes('14') || allowedFields.includes('15') || allowedFields.includes('16') || allowedFields.includes('17') || allowedFields.includes('18') || allowedFields.includes('19')">
                                        <p class="heading">Guardian Details</p>
                                        <div class="content-form-wrapper">
                                            <div class="row row-wrapper">
                                                <div class="col-md-6" *ngIf="allowedFields.includes('12')">
                                                    <div class="radio-buttons-wrapper">
                                                        <div class="radio-button">
                                                            <input type="radio" id="father" formControlName="Guardian_is" value="father">
                                                            <label for="father">Father</label>
                                                        </div>
                                                        <div class="radio-button">
                                                            <input type="radio" id="mother" formControlName="Guardian_is" value="mother">
                                                            <label for="mother">Mother</label>
                                                        </div>
                                                        <div class="radio-button">
                                                            <input type="radio" id="other" formControlName="Guardian_is" value="other">
                                                            <label for="other">Other</label>
                                                        </div>
                                                        <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_is.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_is.dirty || rObj.controls.inputList.controls[i].controls.Guardian_is.touched)">
                                                            <p class="input-error-msg">Please enter Guardian </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="allowedFields.includes('18')">
                                                    <div class="input-box upload-file-wrapper">
                                                        <label for="img-file">Upload Guardian Photo:</label>
                                                        <input class="Guardian_photo" id="img-file" type="file" formControlName="Guardian_photo" placeholder="Guardian photo">
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="row row-wrapper">
                                                <div class="col-md-4" *ngIf="allowedFields.includes('13')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="Guardian_name" placeholder="Enter Guardian Name" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_name.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_name.dirty || rObj.controls.inputList.controls[i].controls.Guardian_name.touched)">
                                                        <p class="input-error-msg">Please enter Guardian name </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('14')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="Guardian_relation" placeholder="Guardian Relation" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_relation.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_relation.dirty || rObj.controls.inputList.controls[i].controls.Guardian_relation.touched)">
                                                        <p class="input-error-msg">Please enter Guardian relation </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('16')">
                                                    <div class="input-box">
                                                        <input type="email" formControlName="Guardian_email" placeholder="Guardian Email" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_email.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_email.dirty || rObj.controls.inputList.controls[i].controls.Guardian_email.touched)">
                                                        <p class="input-error-msg">Please enter Guardian email </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-wrapper">
                                                <div class="col-md-4" *ngIf="allowedFields.includes('15')">
                                                    <div class="input-box">
                                                        <input type="phone-number" formControlName="Guardian_number" placeholder="Enter Your Mobile number" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_number.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_number.dirty || rObj.controls.inputList.controls[i].controls.Guardian_number.touched)">
                                                        <p class="input-error-msg">Please enter Guardian number </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('17')">
                                                    <div class="input-box">
                                                        <input type="text" formControlName="Guardian_occupation" placeholder="Guardian Occupation" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_occupation.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_occupation.dirty || rObj.controls.inputList.controls[i].controls.Guardian_occupation.touched)">
                                                        <p class="input-error-msg">Please enter Guardian occupation </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" *ngIf="allowedFields.includes('19')">
                                                    <div class="input-box">
                                                        <input type="text" class="guardian-address" formControlName="Guardian_address" placeholder="Guardian Address" required>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Guardian_address.invalid && (rObj.controls.inputList.controls[i].controls.Guardian_address.dirty || rObj.controls.inputList.controls[i].controls.Guardian_address.touched)">
                                                        <p class="input-error-msg">Please enter Guardian address </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="std-address-wrapper content-box" *ngIf="allowedFields.includes('9') || allowedFields.includes('8')">
                                        <p class="heading">Student Address Details</p>
                                        <div class="content-form-wrapper">
                                            <div class="row row-wrapper std-address">
                                                <div class="col-md-6" *ngIf="allowedFields.includes('9')">
                                                    <div class="input-box">
                                                        <label for="c-address">Current Address:</label><br>
                                                        <textarea formControlName="Current_address" id="c-address" rows="3" placeholder="Current Address" required></textarea>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Current_address.invalid && (rObj.controls.inputList.controls[i].controls.Current_address.dirty || rObj.controls.inputList.controls[i].controls.Current_address.touched)">
                                                        <p class="input-error-msg">Please enter Current address </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6" *ngIf="allowedFields.includes('8')">
                                                    <div class="input-box">
                                                        <label for="p-address">Permanent Address:</label><br>
                                                        <textarea formControlName="Permanent_address" id="p-address" rows="3" placeholder="Permanent Address" required></textarea>
                                                    </div>
                                                    <div *ngIf=" rObj.controls.inputList.controls[i].controls.Permanent_address.invalid && (rObj.controls.inputList.controls[i].controls.Permanent_address.dirty || rObj.controls.inputList.controls[i].controls.Permanent_address.touched)">
                                                        <p class="input-error-msg">Please enter Permanent address </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-row">
                                        <a class="button-wrapper">
                                            <button class="cta-btn" value="submit" (click)="SubmitApplication()">{{online_pay && fees ? 'Pay & Submit' : 'Submit'}}</button>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </form>
                    <ng-container *ngIf="showConfirmation">
                        <ng-container *ngFor="let a of admissionDetails">
                            <div class="row admissionFinal-title-wrapper">
                                <!-- <h2 class="section-title">Online Admission Form</h2> -->
                                <div class="row col-md-6 right-heading">
                                    <div class="col-md-2 heading">
                                        Reference No {{a.ref_no}}
                                    </div>
                                    <div class="col-md-2 heading">
                                        Form Status {{a.status == '1'? 'Submitted' : 'Not Submitted'}}
                                    </div>
                                    <div class="col-md-3 heading">
                                        Payment Status <br> {{a.payment_status == '1'? 'Paid' : 'UnPaid'}}
                                    </div>
                                    <div class="col-md-3 heading">
                                        Application Date <br> {{a.create_date}}
                                    </div>
                                    <div class="col-md-2 heading" *ngIf="a.payment_status == 'Paid'">
                                        TransactionID  {{a.txn_id}}
                                    </div>
                                </div>
                                <div class="row col-md-6 btn-main-wrapper" style="margin-left: 320px;">
                                    <div class="student-photo col-md-6">
                                        <img class="photo-preview" [src]="a.photo_path ? imgURL+a.photo_path : '/assets/img/default-user-avatar.png'" width="70px">
                                    </div>
                                    <div class="student-photo col-md-6">
                                        <img class="photo-preview" [src]="a.guardian_photo_path ? imgURL+a.guardian_photo_path : '/assets/img/default-user-avatar.png'" width="70px">
                                    </div>
                                </div>
                            </div>
                            <form [formGroup]="rObj" class="admissionFinal-form-wrapper">
                                <ng-container formArrayName="inputList">
                                    <ng-container *ngFor="let obj of rObj.controls.inputList.controls; let i = index">
                                        <div class="control-box-wrapper" [formGroupName]="i">
                                            <!-- <div class="instruction content-box">
                                                <p class="heading">Instructions</p>
                                                <ul class="instruction-list">
                                                    <li class="list-point">
                                                        Please enter your institution online admission instructions here.
                                                    </li>
                                                </ul>
                                            </div> -->
                                            <div class="basic-details content-box" *ngIf="allowedFields.includes('1') || allowedFields.includes('2') || allowedFields.includes('3') || allowedFields.includes('4') || allowedFields.includes('5') || allowedFields.includes('6') || allowedFields.includes('7') || allowedFields.includes('20')">
                                                <p class="heading">
                                                    Basic Details
                                                </p>
                                                <div class="content-form-wrapper">
                                                    <div class="row row-wrapper">
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('1')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Class</div>
                                                                <div class="col-md-5 ">{{a.class_name}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('2')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">First Name</div>
                                                                <div class="col-md-5 ">{{a.first_name}}</div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('3')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Last Name</div>
                                                                <div class="col-md-5 ">{{a.last_name}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row row-wrapper" *ngIf="allowedFields.includes('5')">
                                                        <div class="col-md-4">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Gender</div>
                                                                <div class="col-md-5 ">{{a.gender}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('4')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Date of Birth</div>
                                                                <div class="col-md-5 ">{{a.dob}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('6')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Mobile No.</div>
                                                                <div class="col-md-5 ">{{a.mobile}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row row-wrapper" *ngIf="allowedFields.includes('7')">
                                                        <div class="row">
                                                            <div class="col-md-5 label">Email</div>
                                                            <div class="col-md-5 ">{{a.email}}</div>
                                                        </div>
                                                        <!-- <div class="col-md-6" *ngIf="allowedFields.includes('20')">
                                                            <div class="input-box std-photo-wrapper">
                                                                <label for="std-photo">Upload Student Photo:</label>
                                                                <input class="PhotoUpload" type="file" id="std-photo" formControlName="PhotoUpload" placeholder="Upload student photo">
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
        
                                            </div>
                                            <div class="parent-detail content-box"  *ngIf="allowedFields.includes('10') || allowedFields.includes('11')">
                                                <p class="heading">
                                                    Parent Details
                                                </p>
                                                <div class="content-form-wrapper">
                                                    <div class="row row-wrapper">
                                                        <div class="row">
                                                            <div class="col-md-5 label">Fathers Name</div>
                                                            <div class="col-md-5 ">{{a.father_name}}</div>
                                                            
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-5 label">Mother Name</div>
                                                            <div class="col-md-5 ">{{a.mother_name}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="guardian-detail content-box" *ngIf="allowedFields.includes('12') || allowedFields.includes('13') || allowedFields.includes('14') || allowedFields.includes('15') || allowedFields.includes('16') || allowedFields.includes('17') || allowedFields.includes('18') || allowedFields.includes('19')">
                                                <p class="heading">Guardian Details</p>
                                                <div class="content-form-wrapper">
                                                    <div class="row row-wrapper">
                                                        <div class="col-md-6" *ngIf="allowedFields.includes('12')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">If Guardian is</div>
                                                                <div class="col-md-5 ">{{a.guardian_is}}</div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-6" *ngIf="allowedFields.includes('18')">
                                                            <div class="input-box upload-file-wrapper">
                                                                <label for="img-file">Upload Guardian Photo:</label>
                                                                <input class="Guardian_photo" id="img-file" type="file" formControlName="Guardian_photo" placeholder="Guardian photo">
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="row row-wrapper">
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('13')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Name</div>
                                                                <div class="col-md-5 ">{{a.guardian_name}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('14')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Relation</div>
                                                                <div class="col-md-5 ">{{a.guardian_relation}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('16')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Email</div>
                                                                <div class="col-md-5 ">{{a.guardian_email}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row row-wrapper">
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('15')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Number</div>
                                                                <div class="col-md-5 ">{{a.guardian_mobile}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('17')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Occupation</div>
                                                                <div class="col-md-5 ">{{a.guardian_occupation}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="allowedFields.includes('19')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Guardian Address</div>
                                                                <div class="col-md-5 ">{{a.guardian_address}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="std-address-wrapper content-box" *ngIf="allowedFields.includes('9') || allowedFields.includes('8')">
                                                <p class="heading">Student Address Details</p>
                                                <div class="content-form-wrapper">
                                                    <div class="row row-wrapper std-address">
                                                        <div class="col-md-6" *ngIf="allowedFields.includes('9')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Current Address</div>
                                                                <div class="col-md-5 ">{{a.current_address}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6" *ngIf="allowedFields.includes('8')">
                                                            <div class="row">
                                                                <div class="col-md-5 label">Permanent Address</div>
                                                                <div class="col-md-5 ">{{a.permanent_address}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </form>
                        <!-- <div class="content-box">
                        <p class="heading">Acknowlegement Message</p>
                        <ul class="instruction-list">
                            <li class="list-point">
                                Student Added Sucessfully And your Reference number is {{ref_no}}
                            </li>
                        </ul>
                    </div> -->
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </section>
</div>

    