import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class TransportService {
  constructor(
    private _http: HttpClient,
  ) { }

  private vehicle = `${environment.APIURL}/transport/vehicle/`;
  private driver = `${environment.APIURL}/transport/driver/`;
  private vehicleType = `${environment.APIURL}/transport/vehicleType/`;
  private route = `${environment.APIURL}/transport/route/`;
  private destination = `${environment.APIURL}/transport/destination/`;
  private tracking = `${environment.APIURL}/transport/allocation/`;
  private pickUpPoint = `${environment.APIURL}/transport/pickupPoint/`;
  private pickUpRoute = `${environment.APIURL}/transport/routePickupPoint/`;
  private getpickUpRoute = `${environment.APIURL}/transport/getRoutePickupPointsByRouteID/`;

  getPickUpRouteData(instID: number, route_id: number): Observable<any> {
    return this._http.get(this.getpickUpRoute+'?inst_id='+instID+'&route_id='+route_id, {
      headers: { 'Content-Type': 'application/json' }
    })
  }

  pickUpRouteData(data: object): Observable<any> {
    return this._http.post(this.pickUpRoute, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  pickUpData(data: object): Observable<any> {
    return this._http.post(this.pickUpPoint, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  vehicleData(data: object): Observable<any> {
    return this._http.post(this.vehicle, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  vehicleTypeData(data: object): Observable<any> {
    return this._http.post(this.vehicleType, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  driverData(data: object): Observable<any> {
    return this._http.post(this.driver, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  routeData(data: object): Observable<any> {
    return this._http.post(this.route, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  destinationData(data: object): Observable<any> {
    return this._http.post(this.destination, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  allocationData(data: object): Observable<any> {
    return this._http.post(this.tracking, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}