<div class="console-content">
    <div class="console-content-header row">
        <h1 class="title col-md-3">Import Staffs</h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="btn-container link-container col-md-4">
            <a href="/uploads/sample-staff-import-file.csv" class="content-header-link" download="">Download Sample Import File</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Import Details:
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">

                        <div class="form-group">
                            <label>Department
                                <small class="req"> *</small></label>
                                <select name="class" formControlName="Department" class="form-select" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let dept of departmentData" value="{{dept.ID}}">
                                    {{dept.name}}
                                    </option>         
                                </select>
                                <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                    <p class="input-error-msg">Please select Department</p>
                                </div>
                        </div>
                        <div class="form-group">
                            <label>Designation
                                <small class="req"> *</small></label>
                                <select name="class" formControlName="Designation" class="form-select" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let des of designationData" value="{{des.ID}}">
                                    {{des.name}}
                                    </option>         
                                </select>
                                <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                    <p class="input-error-msg">Please select Department</p>
                                </div>
                        </div>
                        <div class="form-group">
                            <label>Select CSV File</label>
                            <small class="req"> *</small>
                                <input type="file" formControlName="Import_file" class="form-control" (change)="onFileChange($event)">
                                <div *ngIf="rObj.controls.Import_file.invalid && (rObj.controls.Import_file.dirty || rObj.controls.Import_file.touched)">
                                    <p class="input-error-msg">Please select Import_file</p>
                                </div>
                        </div>

                        <div class="form-group form-btn-wrapper">
                            <button class="btn primary-btn small-btn content-header-link" [disabled]="!rObj.valid" (click)="importStaff()">Import</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Import Instructions:
                    </div>
                </div>
                <div class="panel-body">   
                                            <br>
                    1. Your CSV data should be in the format below. The first line of your CSV file should be the column headers as in the table example. Also make sure that your file is UTF-8 to avoid unnecessary encoding problems.          
                  <br>

                    2. If the column you are trying to import is date make sure that is formatted in format Y-m-d (2018-06-06). <br>
                    3. Duplicate Admission Number (unique) rows will not be imported.                        <br>
                    4. For student Gender use Male, Female value.                        <br>

                    5. For student Blood Group use O+, A+, B+, AB+, O-, A-, B-, AB- value.<br>

                    6. For RTE use Yes, No value.<br>

                    7. For If Guardian Is user father,mother,other value.<br>

                    8. Category name comes from other table so for category, enter Category Id (Category Id can be found on category page ).<br>

                    9. Student house comes from other table so for student house, enter Student House Id (Student House Id can be found on student house page ).                        <hr>
                </div>
            </div>
        </div>
    </div>
        
</div>