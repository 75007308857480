import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class TaskService {
  constructor(
    private _http: HttpClient,
  ) { }

  private task = `${environment.APIURL}/taskManagement/assignTask/`;
  // private studentList = `${environment.APIURL}/student/getStudentsByClassSection/`;
 
  taskData(data: object): Observable<any> {
    return this._http.post(this.task, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
  
  // studentListData(instID: number, academicID: number, classID: any, section: any): Observable<any> {
  //   return this._http.get(this.studentList+'?inst_id='+instID+'&academic_id='+academicID+'&class='+classID+'&section='+section, {
  //     headers: { 'Content-Type': 'application/json' }
  //   })
  // }
  
  
}