<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-2">Vendor List</h1>
        <div class="header-msg col-md-5">
                      </div>
        <div class="link-container col-md-5">
            <a href="store-management/vendor/add/" class="content-header-link">Add Vendor</a>
           
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="vendorData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('vendors', 'vendor')">Delete</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="vendorData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th>
		                        <th class="align-left">Company Name </th>
			                    <th>Company Phone</th>
			                    <th>Company Email</th>
			                    <th>City </th>
		                        <th>Contact Person Name</th>
			                    <th>Contact Person Phone</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let v of vendorData">
                                <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="v.isSelected" (change)="onChecked(v.ID, $event)">
                                </td>
                                <td  class="align-left">{{v.company_name}}</td>
                                <td>{{v.company_phone}}</td>
                                <td>{{v.company_email}}</td>
                                <td>{{v.city}}</td>
                                <td>{{v.contact_person_name}}</td>
                                <td>{{v.contact_person_phone}}</td>
                                <td class="action-btn">
                                    <!-- <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a> -->
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(v.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(v.ID, 'vendor', 'delete vendor')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!vendorData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>           

                            
            </div>
        </div>
    </div>
</div>