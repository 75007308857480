import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-assign-subjectmodal',
    templateUrl: './assign-subjectmodal.component.html',
    styleUrls: ['./assign-subjectmodal.component.css'],
})
// , AfterViewInit 
export class AssignsubjectModalComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public assignSubID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public sectionData: any[] = [];
    public subjectData: any[] = [];
    public classData: any[] = [];
    public classSectionData: any[] = [];
    public assignSubData: any[] = [];
    public mediumData: any[] = [];
    public selectSections: string;
   
    public sectionValue: any[] = [];
    public subjectValue: any[] = [];
    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    public rObj : any = this._fBuilder.group({
        Class: ['', Validators.required],
        Medium: [''],
        Section: this._fBuilder.array([], Validators.required),
        Subject: this._fBuilder.array([], Validators.required)
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(aRes => {
            console.log(aRes)
            if (aRes.status === true) {
                this.subjectData = JSON.parse(aRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assign_subject').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.assignSubData = JSON.parse(sRes.result);
                
                this.rObj = this._fBuilder.group({                    
                    Class: ['', Validators.required],
                    Section: this._fBuilder.array(this.sectionData.reverse().map(x => new FormControl(false), Validators.required)),
                    Subject: this._fBuilder.array(this.subjectData.reverse().map(x => new FormControl(false), Validators.required))
                })
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.assignSubID, 'assign_subject').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let asData = JSON.parse(dataRes.result)[0];
                this._gService.getDataByID(asData.class, 'class').subscribe(dataRes => {  
                    console.log(dataRes)
                    if(dataRes.status === true) {
                        let result = JSON.parse(dataRes.result)
                        let sectionsArray = JSON.parse(result[0].sections)
                        let sectionNamesArray = result[0].section_names.split(',')
                        this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                            return {'id': section_id, 'name': sectionNamesArray[index]}
                        })
                        console.log(this.classSectionData)
                    }
                })
                console.log(asData)
                this.rObj.patchValue({
                    Class: asData.class,
                    Medium: asData.medium,
                    Section: this.sectionData.map(x => ({value: x.ID, isSelected: asData.sections.indexOf(x.ID) > -1})),
                    Subject: this.subjectData.map(x => ({value: x.ID, isSelected: asData.subjects.indexOf(x.ID) > -1}))
                });

                this.sectionValue = JSON.parse(asData.sections);
                this.subjectValue = JSON.parse(asData.subjects);

                console.log(this.sectionValue)
                console.log(this.subjectValue)

                console.log(asData.sections.indexOf(51) > -1)

            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this._gService.showLoader(false);
        })

        console.log(this.rObj.controls['Section'])
        console.log(this.rObj.controls['Section'])

       

        
    }

    // ngAfterViewInit(): void {
    //     this._gService.getDataByID(this.assignSubID, 'assign_subject').subscribe(dataRes => {
    //         console.log(dataRes);
    //         if (dataRes.status === true) {
    //             let asData = JSON.parse(dataRes.result)[0];
    //             console.log(asData)
    //             this.rObj.patchValue({
    //                 Class: asData.class,
    //                 Section: this.sectionData.map(x => ({value: x.ID, isSelected: asData.sections.indexOf(x.ID) > -1})),
    //                 Subject: this.subjectData.map(x => ({value: x.ID, isSelected: asData.subjects.indexOf(x.ID) > -1}))
    //             });

    //             this.sectionValue = JSON.parse(asData.sections);
    //             this.subjectValue = JSON.parse(asData.subjects);

    //             console.log(this.sectionValue)
    //             console.log(this.subjectValue)

    //         }
    //         this._gService.showLoader(false);
    //     })
    // }

    // onSectionSelect(index: number, value: number, e: any) {
    //     const selectedSections = <FormArray>this.rObj.controls.Section;

    //     if (e.target.checked) {
    //         selectedSections.controls[index].setValue({value: value, isSelected: true});
    //     } else {
    //         selectedSections.controls[index].setValue({value: value, isSelected: false});
    //     }

    //     this.sectionValue = selectedSections.value.filter((x: any) => x.isSelected === true).map((x: any) => x.value);

    //     console.log(this.sectionValue)
    // }

    onSectionSelect(e: any) {
        const selectedSections = <FormArray>this.rObj.controls.Section;

        selectedSections?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSections.push(new FormControl(e.target.value));
        } else {
          let index = selectedSections.controls.findIndex(x => x.value == e.target.value)
          selectedSections.removeAt(index);
        }

        this.sectionValue = selectedSections.value;

        console.log(selectedSections.value)
    }

    onSubjectSelect(e: any) {
        const selectedSubjects = <FormArray>this.rObj.controls.Subject;

        selectedSubjects?.markAllAsTouched();
      
        if(e.target.checked) {
            selectedSubjects.push(new FormControl(e.target.value));
        } else {
          let index = selectedSubjects.controls.findIndex(x => x.value == e.target.value)
          selectedSubjects.removeAt(index);
        }

        this.subjectValue = selectedSubjects.value;

        console.log(selectedSubjects.value)
    }

    updateClass() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'update',
                id: this.assignSubID,
                class: rObj.Class,
                medium: rObj.Medium,
                section: JSON.stringify(this.sectionValue),
                subject: JSON.stringify(this.subjectValue),
            }
            console.log(ayData)
            this._aService.assignsubData(ayData).subscribe(ayRes => {
                console.log(ayRes)
                this.modalRef.hide();
                if (ayRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Class Subject Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}