import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminPanelRoutingModule, AdminPanelRoutes } from './adminpanel-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@NgModule({
  declarations: [
    AdminPanelRoutes,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AdminPanelRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot()
  ],
  exports: []
})
export class AdminPanelModule { }