import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { FeesService } from 'src/app/services/fees.service';

@Component({
  selector: 'app-fees-feesReminder',
  templateUrl: './feesReminder.component.html',
})

export class FeeReminderComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public classData: any[] = [];
    public sectionData: any[] = [];
    public feesData: any[] = [];
    public subjectData: any[] = [];
    public feeReminderData: any[] = [];
    public classSectionData: any[] = [];
    public mediumData: any[] = [];
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Fees_type: ['', Validators.required],
        Subject: ['', Validators.required],
        Reminder_start_day: ['', Validators.required], 
        Reminder_frequency: ['', Validators.required],
        Reminder_type: ['', Validators.required],
        Message: [''],
        Medium: [''],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
        
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.subjectData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.feesData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
        //     console.log(mRes)
        //     if (mRes.status === true) {
        //         this.mediumData = JSON.parse(mRes.result);
        //     } else {
        //         this._gService.triggerSnackbar('No Data Found!', 'error', true);
        //     }
        //     this._gService.showLoader(false);
        // })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openReminderEditModal(mediumID: number) {
        this.modalRef = this.modalService.show(MediumModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                mediumID: mediumID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveReminder() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                // medium: rObj.Medium,
            }
            console.log(mData)
            // this._aService.mediumData(mData).subscribe(ayRes => {
            //     console.log(ayRes)
            //     if (ayRes.status === true) {
            //         this._reRoute.shouldReuseRoute = () => false;
            //         this._router.navigate([this._router.url]);
            //         this._gService.triggerSnackbar('Medium Saved Successfully', 'success', true);
            //     } else {
            //         this._gService.triggerSnackbar('Something went wrong', 'error', true);
            //     }
            // }, err => {
            //     console.log(err);
            //     this._gService.triggerSnackbar('Server Error!', 'error', true);
            // })
        }
    }

}