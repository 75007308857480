import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormGroup,FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { SyllabusService } from 'src/app/services/syllabus.service';
  
@Component({
    selector: 'app-editSyllabusmodal',
    templateUrl: './editSyllabusmodal.component.html',
    // styleUrls: ['./editSyllabusmodal.component.css'],
})

export class EditSyllabusModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public syllabusID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public classData : any[]= []
    public sectionData: any[] = [];
    public subjectData : any[]= []
    public lDataArray : any[]= []
    public tDeleteIds : any[]= []
    public sectionValue: '';
    
    public lessonPatchDataArray: FormGroup;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: SyllabusService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Syllabus_for: ['', Validators.required],
        Class: [{value: '', disabled: true}, Validators.required],
        Section: [{value: '', disabled: true}, Validators.required],
        Subject: [{value: '', disabled: true}, Validators.required], 
        Lessons: this._fBuilder.array([]),
    })
    lData(): FormArray {
        return this.rObj.get("Lessons") as FormArray
    }

    newLData(): FormGroup {
        return this._fBuilder.group({
            Name : ''
        })
    }

    addlData(){
        this.lData().push(this.newLData())
    }
    removeLData(i:number, Id:any) {
        this.lData().removeAt(i);
        this.tDeleteIds.push(Id)
        // alert(this.tDeleteIds)
    }


    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.syllabusID, 'syllabus').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                let lData =  sData[0].lessons.split(',')
                let lIDData =  sData[0].lesson_ids.split(',')
                console.log(sData)
                lData.forEach((lesson : any, index: any) => {                   
                    this.lessonPatchDataArray = this._fBuilder.group({
                        Lesson_id : lIDData[index],
                        Name : lesson
                    })     
                    console.log(this.lessonPatchDataArray)
                    if (this.lessonPatchDataArray) {
                        this.lData().push(this.lessonPatchDataArray)
                    }        
                })
                console.log(this.lData())
               
                this.rObj.patchValue({
                    Syllabus_for: sData[0].syllabus_for,
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Subject : sData[0].subject,
                    
                });
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
                console.log(this.sectionData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.subjectData = JSON.parse(sRes.result);
                console.log(this.subjectData)

            }
            this._gService.showLoader(false);
        })
    }

    updateSyllabus() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lessonData = this.rObj.controls.Lessons.controls;
            for (let i = 0; i < lessonData.length; i++) {
                this.lDataArray.push(lessonData[i].value)
            }
            let mData = {
                action: 'update',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                id: this.syllabusID,
                syllabus_for: rObj.Syllabus_for,
                class_id: rObj.Class,
                section_ids: rObj.Section,
                subject_id: rObj.Subject, 
                lessons: this.lDataArray,
                del_ids: this.tDeleteIds
            }
            console.log(mData)
            this._sService.syllabusTitleData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Syllabus Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}