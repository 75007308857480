import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HostelService } from 'src/app/services/hostel.service';
import { HostelRoomsmodalComponent } from 'src/app/components/modal/hostelRoomsmodal/hostelRoomsmodal.component';

@Component({
    selector: 'app-hostel-hostelRooms',
    templateUrl: './hostelRooms.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class hostelRoomsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public hostelroomsData: any[] = [];
    public HostelData: any[] = [];
    public RoomtypeData: any[] = [];

    public sectionValue: '';

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: HostelService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Room_no: ['', Validators.required],
        Hostels: ['', Validators.required],
        Room_Type: ['', Validators.required],
        No_beds: ['', Validators.required],
        Cost: ['', Validators.required],
        Description: ['', Validators.required],
    
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.hostelroomsData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.HostelData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'hostel_room_type').subscribe(hRes => {
            console.log(hRes)
            if (hRes.status === true) {
                this.RoomtypeData = JSON.parse(hRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.showLoader(false);

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    // getSectionName(sectionID: number) {
    //     this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
    //         if (sRes.status === true) {
    //             let sData = JSON.parse(sRes.result)[0];
    //             return sData
    //         }
    //     })
    // }

    openHostelRoomsEditModal(hostelRoomID: number ) {
        this.modalRef = this.modalService.show(HostelRoomsmodalComponent, {
            class: 'modal-dialog-centered hosteldetail-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                hostelRoomID: hostelRoomID
            } 
        });
    }//remianing

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    // onSectionSelect(e: any) {
    //     const selectedSections = <FormArray>this.rObj.controls.Section;

    //     selectedSections?.markAllAsTouched();
      
    //     if(e.target.checked) {
    //         selectedSections.push(new FormControl(e.target.value));
    //     } else {
    //       let index = selectedSections.controls.findIndex(x => x.value == e.target.value)
    //       selectedSections.removeAt(index);
    //     }

    //     this.sectionValue = selectedSections.value;

    //     console.log(selectedSections.value)
    // }

    saveDetails() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                room_no: rObj.Room_no,
                hostel: rObj.Hostels,
                room_type: rObj.Room_Type,
                no_beds: rObj.No_beds,
                cost_bed: rObj.Cost,
                description: rObj.Description,
            }
            this._aService.hostelRoomsData(ayData).subscribe(hRes => {
                console.log(hRes)
                if (hRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Hostel Room details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}