import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { TransportService } from 'src/app/services/transport.service';
  
@Component({
    selector: 'app-vehiclemodal',
    templateUrl: './vehiclemodal.component.html',
    styleUrls: [],
})

export class VehiclemodalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public vehicleID: number;
    public vehicleData: any[] = [];
    public vehicleTypeData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: TransportService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Vehicle_no: ['', Validators.required],
        Vehicle_model: ['', Validators.required],
        Vehicle_year: ['', Validators.required],
        Vehicle_type: ['', Validators.required],
        No_seats: ['', Validators.required],
        Max_allow: ['', Validators.required],
        Contact_person: ['', Validators.required],
        Renewal_date: ['', Validators.required],
        Tracking_id: ['', Validators.required],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vehicle_type').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.vehicleTypeData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })



        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vehicle').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.vehicleData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       


        this._gService.getDataByID(this.vehicleID, 'vehicle').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Vehicle_no: sData[0].vehicle_no,
                    Vehicle_model: sData[0].model,
                    Vehicle_year: sData[0].year_made,
                    No_seats: sData[0].no_seat,
                    Vehicle_type: sData[0].type,
                    Max_allow: sData[0].max_allowed,
                    Contact_person: sData[0].contact_person,
                    Renewal_date: sData[0].ins_renewal_date,
                    Tracking_id: sData[0].tracking_id,
                    
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateVehicle() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lData = {
                action: 'update',
                id: this.vehicleID,
                vehicle_no: rObj.Vehicle_no,
                model: rObj.Vehicle_model,
                year_made: rObj.Vehicle_year,
                type: rObj.Vehicle_type,
                no_seat: rObj.No_seats,
                max_allowed: rObj.Max_allow,
                contact_person: rObj.Contact_person,
                ins_renewal_date: rObj.Renewal_date,
                tracking_id: rObj.Tracking_id,
             }
            this._aService.vehicleData(lData).subscribe(lRes => {
                console.log(lRes)
                this.modalRef.hide();
                if (lRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Vehicle Details Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}