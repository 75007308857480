import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { SyllabusService } from 'src/app/services/syllabus.service';
  
@Component({
    selector: 'app-addSyllabusmodal',
    templateUrl: './addSyllabusmodal.component.html',
    // styleUrls: ['./addSyllabusmodal.component.css'],
})

export class AddSyllabusModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public lessonId: number;
    public lesson_name: any;
    public tDeleteIds: any[] = [];
    public topicArray: any[] = [];
    public topicPatchDataArray: FormGroup;
    
    constructor(
        private _gService: GlobalService,
        private _sService: SyllabusService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Topics: this._fBuilder.array([]),
    })

    tData(): FormArray {
        return this.rObj.get("Topics") as FormArray
    }

    newTData(): FormGroup {
        return this._fBuilder.group({
            Id:'',
            Name : ''
        })
    }

    addTData(){
        this.tData().push(this.newTData())
    }
    removeTData(i:number, Id:any) {
        this.tData().removeAt(i);
        this.tDeleteIds.push(Id)
        // alert(this.tDeleteIds)
    }

    ngOnInit(): void {
        this._gService.showLoader(true);
        // this.addTData()
        this._sService.gettopicData(this.lessonId).subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                console.log(sData)
                sData.forEach((t : any, index : any) => {
                    this.topicPatchDataArray = this._fBuilder.group({
                        Id: t.ID,
                        Name: t.name
                    })
                    console.log(this.topicPatchDataArray)

                    if(this.topicPatchDataArray) {
                        this.tData().push(this.topicPatchDataArray)
                    }
                    console.log(this.tData())

                    if(!this.topicPatchDataArray) {
                        this.addTData()
                    }
                })
                // this.rObj.patchValue({
                //     Medium: sData[0].name,
                // });
            }
            this._gService.showLoader(false);
        })
        this._gService.showLoader(false);
    }

    addTopics() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let lessonData = this.rObj.controls.Topics.controls;
            for (let i = 0; i < lessonData.length; i++) {
                this.topicArray.push(lessonData[i].value)
            }
            let mData = {
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                lesson_id: this.lessonId,
                topics: this.topicArray,
                del_ids: this.tDeleteIds
            }
            console.log(mData)
           
            this._sService.topicData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Topics Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}