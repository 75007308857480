<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-2">Collect Fee</h1>
        <div class="header-msg col-md-5"></div>
        <div class="link-container col-md-5">
            <!--<a href="../add/" class="content-header-link">Add Hostel</a>--> 
        </div>
    </div>
    <div class="panel">
        <div class="panel-body row" *ngFor="let student of studentData">
            <div class="col-md-2">
                <div class="student-photo">
                    <img [src]="student.photo_path ? imgURL+student.photo_path : (student.gender == 'male' ? '/assets/img/male-student.png' : '/assets/img/female-student.png')" width="100%">
                </div>
            </div>
            <div class="col-md-10">
                <div class="row details">
                    <div class="col-md-3"  style="margin-bottom: 15px;">Name</div>
                    <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.full_name}}</div>
                    <div class="col-md-3" style="margin-bottom: 15px;">Class</div>
                    <div class="col-md-3 head" style="margin-bottom: 15px;">
                        <ng-container *ngFor="let class of classData">
                            <ng-container *ngIf="class.ID == student.class">
                                {{class.name}}
                            </ng-container>
                        </ng-container>   
                    </div>
                    <div class=" col-md-3" style="margin-bottom: 15px;">Section</div>
                    <div class=" col-md-3 head" style="margin-bottom: 15px;">
                        <ng-container *ngFor="let section of sectionData">
                            <ng-container *ngIf="section.ID == student.section">
                                {{section.name}}
                            </ng-container>
                        </ng-container>    
                    </div>
            
                <!-- <div class="row details"> -->
                    <div class="col-md-3" style="margin-bottom: 15px;">Roll No.</div>
                    <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.roll_no}}</div>
                    <div class="col-md-3" style="margin-bottom: 15px;">Admission Date.</div>
                    <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.admission_date}}</div>
                    <div class="col-md-3" style="margin-bottom: 15px;">Register No.</div>
                    <div class="col-md-3 head" style="margin-bottom: 15px;">{{student.admission_number}}</div>
                <!-- </div> -->
            </div>
            </div>
        </div>
    </div>

    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <ng-container *ngIf="feesMasterData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="openMultiplePayModal(selectedIds)">Pay</button>
                        </div>
                    </ng-container>
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feesMasterData.length">
                        <thead>
                            <tr>
                                <th class="no-sort"><input type="checkbox"  id="select_all" (change)="checkUncheckAll($event)"></th>
                                <th>Fees Group</th>
                                 <th class="align-left">Fees Type</th>
                                 <th>Amount</th>
                                 <th>Fine Type</th>  
                                 <th>Fine Amount</th>  
                                 <th>Due Date</th>
                                 <th>Total Amount</th>
                                 <th>Transaction Id</th>
                                 <th>Status</th>
                                 <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let f of feesMasterData">
                                {{dueCheck(f.due_date)}}
                                <tr [ngClass]="isDue &&  !getTxnId(f.ID).length ? 'due-color' : ''">
                                    <td>
                                    <input type="checkbox" class ="list-checkbox" [disabled]="getTxnId(f.ID).length" [checked]="f.isSelected" (change)="onChecked(f.ID, $event)">
                                    </td>                              
                                    <td>{{f.fees_group_name}} </td>
                                    <td class="align-left">
                                        <ng-container *ngFor="let type_name of stringToArray(f.fees_type_names); let i = index">
                                            <ng-container *ngFor="let amt of stringToArray(f.amounts); let j = index">
                                                <ng-container *ngIf="i == j">
                                                 {{type_name}}- {{amt}}<br>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>{{f.total_amount}}</td>
                                    <td>{{isDue ? f.fine_type : '-'}}</td>
                                    <td>{{isDue ? (f.fine_type == 'percentage' ? (f.total_amount * f.percentage) / 100 : f.fine_amount) : '-'}}</td>
                                    <td>{{getDate(f.due_date)}}</td>
                                    <!-- <td>{{isDue ? totalAmount(f.total_amount, f.fine_type == 'percentage' ? f.percentage : f.fine_amount) : f.total_amount}}</td>    -->
                                    <td>{{getTotalAmount(f.total_amount,f.fine_type,f.fine_amount,f.percentage)}}</td>   
                                    <td>{{getTxnId(f.ID).length ? getTxnId(f.ID) : '-'}}</td> 
                                    <td>{{getTxnId(f.ID).length ? 'Paid' : 'Pending'}}</td>
                                    <td>
                                        <ng-container *ngIf="!getTxnId(f.ID).length">
                                            <button type="button" class="btn primary-btn small-btn" (click)="openPayModal(f.ID,f.total_amount, f.due_date, f.fees_master_ids, f.fine_type,f.fine_type == 'percentage' ? (f.total_amount * f.percentage) / 100 : f.fine_amount,getTotalAmount(f.total_amount,f.fine_type,f.fine_amount,f.percentage),f.fees_group)">Pay</button>
                                         </ng-container>
                                        <ng-container *ngIf="getTxnId(f.ID).length">
                                            <button type="submit" class="btn primary-btn small-btn" (click)="openReceiptModal(getTxnId(f.ID))">View</button>
                                        </ng-container>                                    
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>   
                    <div *ngIf="!feesMasterData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>         
            </div>
        </div>
    </div>
</div>