import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor.service';
  
@Component({
    selector: 'app-inventoryItemmodal',
    templateUrl: './inventoryItemmodal.component.html',
    styleUrls: [],
})

export class InventoryItemModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public itemID: number;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public inventoryCategoryData: any[] = [];

    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        Category_name: ['', Validators.required],
        Item_name: ['', Validators.required],
        Qty: ['', Validators.required],
        Description: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.itemID, 'inventory_item').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                this.rObj.patchValue({
                    Category_name: sData[0].category,
                    Item_name: sData[0].item_name,
                    Qty: sData[0].qty,
                    Description: sData[0].description,
                });
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_category').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.inventoryCategoryData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

    }

    updateItem() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.itemID,
                category: rObj.Category_name,
                item_name: rObj.Item_name,
                qty: rObj.Qty,
                description: rObj.Description,
            }
            this._aService.inventoryItemData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Inventory Item Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}